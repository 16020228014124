import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function ContratPrevCondPart(props) {
  const context = useContext(AppContext);
  const listeContrats = () => {
    let contrats = [{ value: "New", label: "Nouveau contrat" }];

    Array.isArray(context.state.ListeContratPrevoyance) &&
      context.state.ListeContratPrevoyance.map((option) => (contrats = [...contrats, { value: option.IdContrat, label: option.LibelleContrat }]));

    return contrats;
  };
  useEffect(() => {
    context.handleListeUserContratPrevoyance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            { context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel" ? (
              <>
                {props.AccessRemExpress === false && (
                  <Row>
                    <Column>
                      <DropDown
                        label="Contrat"
                        value={context.state.SelectContrat}
                        onChange={(e) => {
                          context.handleModifyUserContratPrevoyance(e.target.value);
                        }}
                        options={listeContrats()}
                        normal
                      />
                    </Column>

                    <Column>
                      <TextField label="Libellé" value={context.state.LibelleContrat} onChange={context.handleInputChange("LibelleContrat")} normal />
                    </Column>
                  </Row>
                )}
                <Row tpad>
                  <Column>
                    <TextField
                      label="Cotisation Madelin annuelle"
                      value={context.state.PrevoyanceMadelin}
                      onChange={context.handleInputChange("PrevoyanceMadelin")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                  <Column lpad>
                    <TextField
                      label="Cotisation non Madelin annuelle"
                      value={context.state.PrevoyanceNonMadelin}
                      onChange={context.handleInputChange("PrevoyanceNonMadelin")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                {props.AccessRemExpress === false && (
                  <Row tpad>
                    <Column>
                      <TextField
                        label="Assiette des garanties"
                        value={context.state.AssietteGarantie}
                        onChange={context.handleAssietteGarantie("AssietteGarantie")}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </Column>
                  </Row>
                )}
              </>
            ) : (
              <>
               {props.AccessRemExpress === false && (
                <Row>
                  <Column>
                    <TextField label="Libellé" value={context.state.LibelleContrat} onChange={context.handleInputChange("LibelleContrat")} normal />
                  </Column>
                  
                </Row>
               )}
                <Row tpad>
                  <Column>
                    <table className="mono">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <TextField className="invisible" value={"Salarié"} normal />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Employeur"} normal />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tranche A</td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheA_salarie83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheA_salarie83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheA_patronale83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheA_patronale83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche B</td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheB_salarie83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheB_salarie83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheB_patronale83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheB_patronale83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche C</td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheC_salarie83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheC_salarie83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheC_patronale83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheC_patronale83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche D</td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheD_salarie83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheD_salarie83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.Taux83Prevoyance.taux_prevoyance_trancheD_patronale83}
                              onChange={(e) => context.handleObjectChange("Taux83Prevoyance", "taux_prevoyance_trancheD_patronale83", e)}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                              normal
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratPrevCondPart;
