import "./../audit complet/simulation/simulation.css";
import { useState, useContext, useEffect } from "react";
import {  FaExclamationTriangle, FaPrint, FaSave } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import { ArbitragePptx } from "components/app/Api/Rapport/ApiRapport";
import  { Hover2 } from "components/ui/controls/hover/hover";
// import DetailTNSPageModal from "./detail/rémuneration/detailTNSPager";
// import DetailSalariePageModal from "./detail/rémuneration/detailSalariePager";
// import DropDown from "components/ui/controls/drop-down/drop-down";
// import { AuditCompletWord } from "components/app/Api/Rapport/ApiRapport";

const RemExpressArbitragePage = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [modalOptimum, setModalOptimum] = useState(false);
  const [modalChgtStatut, setModalChgtStatut] = useState(false);
  const Periode = context.state.OptiRemPeriodeActuelle === undefined ? undefined : context.state.OptiRemPeriodeActuelle[0];
  const Prenom = context.state.Prenom;
  const [circularWidth, setCircularWidth] = useState("0px");
  const [arbitrage100Rem, setArbitrage100Rem] = useState({
    abattementMax: 0,
    enveloppe: 0,
    chargesSup: 0,
    prime: 0,
    resAvtIs: 0,
    is: 0,
    reserve: 0,
    divBruts: 0,
    regime: "PFU",
    revPercus: 0,
    remPercue: 0,
    autresRevenus: 0,
    impot: 0,
    cotFacPerso: 0,
    revDispo: 0,
    cotProductives: 0,
  });
  const [arbitrage100Div, setArbitrage100Div] = useState({
    enveloppe: 0,
    chargesSup: 0,
    prime: 0,
    resAvtIs: 0,
    is: 0,
    reserve: 0,
    divBruts: 0,
    regime: "PFU",
    revPercus: 0,
    remPercue: 0,
    autresRevenus: 0,
    impot: 0,
    cotFacPerso: 0,
    revDispo: 0,
    cotProductives: 0,
  });
  const [arbitrageOptimum, setArbitrageOptimum] = useState({
    enveloppe: 0,
    chargesSup: 0,
    prime: 0,
    resAvtIs: 0,
    is: 0,
    reserve: 0,
    divBruts: 0,
    regime: "PFU",
    revPercus: 0,
    remPercue: 0,
    autresRevenus: 0,
    impot: 0,
    cotFacPerso: 0,
    revDispo: 0,
    cotProductives: 0,
  });
  useEffect(() => {
    // context.handleValueChange("OptiRemUnlockCoutEntreprise", true);
    // context.handleCalculOptiRem("cout_remuneration", "OptiRemCoutRemuneration", "", ResultatTNS,false, e);
    // context.handleCalculOptiRem("cout_entreprise", "OptiRemCoutEntreprise" + ClientConjoint, ClientConjoint, ResultatTNS, context.state["OptiRemCoutEntreprise" + ClientConjoint]);
  }, []);

  const handleCalculArbitrage = () => {
    context.handleValueChangeAsync("OptiRemOptionPfu", context.state.OptionPfu).then(() => {
      context.handleValueChangeAsync("OptiRemUnlockCoutEntreprise", true).then(() => {
        //AJOUT DE LA RESERVE
        context.handleValueChangeAsync("OptiRemReserve", context.state.ArbitrageReserve).then(() => {
          //CALCUL DU NOUVEAU RAI AVEC LA RESERVE
          context.handleCalculOptiRem("res_avant_is", "OptiRemResAvtIs", "", ResultatTNS, false, "0").then((result) => {
            const RaiArbitrage = Number(context.state.ArbitrageRai) - Number(result.Remuneration.ResAvtIs);
            const NewCoutRem = Number(context.state.RemExpressCoutRemuneration) + Number(RaiArbitrage);
            //100% PRIME
            context.handleCalculOptiRem("cout_remuneration", "OptiRemCoutRemuneration", "", ResultatTNS, false, NewCoutRem).then((result100Rem) => {
              console.log(result100Rem);
              let chargesSup = 0;
              if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
                chargesSup =
                  Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSociales) +
                  Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.CotisationsFacultativesEntreprise) -
                  Number(context.state.RemExpressCotisations);
              } else {
                chargesSup =
                  Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.TotalChargesPatronale) +
                  Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSalariale) -
                  Number(context.state.RemExpressCotisations);
              }
              const abattementMax = Number(result100Rem.Impot.Dirigeant.AbattementMax);
              const prime = Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.RemunerationNette) - Number(context.state.RemExpressRemNette);
              const resAvtIs = Number(result100Rem.Remuneration.ResAvtIs);
              const is = Number(result100Rem.Remuneration.ImpotSoc);
              const reserve = Number(context.state.ArbitrageReserve) + Number(result100Rem.Remuneration.DivBrutsAutres);
              const divBruts = Number(result100Rem.Remuneration.DivBruts);
              const regime = context.state.OptionPfu === "oui" ? "PFU" : "Barème progressif";
              const revPercus = Number(result100Rem.Remuneration.RemunerationClient.AffichageResultat.RemunerationNette) + divBruts - Number(context.state.RemExpressRemNette);
              const remPercue = Number(context.state.RemExpressRemNette);
              const autresRevenus = Number(result100Rem.Impot.AutresRevenusFoyer);
              const impot = Number(result100Rem.Impot.IRTotal);
              const cotFacPerso = context.state.RemExpressCotFacPerso;
              const cotProductives =
                Number(result100Rem.Remuneration.RemunerationClient.Rapport.RetraiteObligatoire) +
                Number(result100Rem.Remuneration.RemunerationClient.Rapport.RetraiteFacultative) +
                Number(result100Rem.Remuneration.RemunerationClient.Rapport.TotalPEEProductif) +
                Number(result100Rem.Remuneration.RemunerationClient.Rapport.TotalPERCOProductif);
              const revDispo = revPercus+remPercue + autresRevenus - impot - cotFacPerso;
              setArbitrage100Rem({
                abattementMax: abattementMax,
                enveloppe: Number(context.state.ArbitrageRai),
                chargesSup: chargesSup,
                prime: prime,
                resAvtIs: resAvtIs,
                is: is,
                reserve: reserve,
                divBruts: divBruts,
                regime: regime,
                revPercus: revPercus,
                remPercue: remPercue,
                autresRevenus: autresRevenus,
                impot: impot,
                cotFacPerso: cotFacPerso,
                revDispo: revDispo,
                cotProductives: cotProductives,
              });
              //100% DIV
              context.handleValueChangeAsync("OptiRemUnlockCoutEntreprise", false).then(() => {
                console.log("PRO2", context.state.OptiRemResAvtIs);
                context
                  .handleCalculOptiRem(
                    Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? "remuneration_nette" : "salaire_net",
                    "OptiRemRemNette",
                    "",
                    ResultatTNS,
                    false,
                    context.state.RemExpressRemNette
                  )
                  .then((result100Div) => {
                    let chargesSup = 0;
                    if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
                      chargesSup =
                        Number(result100Div.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSociales) +
                        Number(result100Div.Remuneration.RemunerationClient.AffichageResultat.CotisationsFacultativesEntreprise) -
                        Number(context.state.RemExpressCotisations);
                    } else {
                      chargesSup =
                        Number(result100Div.Remuneration.RemunerationClient.AffichageResultat.TotalChargesPatronale) +
                        Number(result100Div.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSalariale) -
                        Number(context.state.RemExpressCotisations);
                    }

                    const prime = 0;
                    const resAvtIs = Number(result100Div.Remuneration.ResAvtIs);
                    const is = Number(result100Div.Remuneration.ImpotSoc);
                    const reserve = Number(context.state.ArbitrageReserve) + Number(result100Div.Remuneration.DivBrutsAutres);
                    const divBruts = Number(result100Div.Remuneration.DivBruts);
                    const regime = context.state.OptionPfu === "oui" ? "PFU" : "Barème progressif";
                    const revPercus = Number(result100Div.Remuneration.RemunerationClient.AffichageResultat.RemunerationNette) + divBruts - Number(context.state.RemExpressRemNette);
                    const remPercue = Number(context.state.RemExpressRemNette);
                    const autresRevenus = Number(result100Div.Impot.AutresRevenusFoyer);
                    const impot = Number(result100Div.Impot.IRTotal);
                    const cotFacPerso = context.state.RemExpressCotFacPerso;
                    const cotProductives =
                      Number(result100Div.Remuneration.RemunerationClient.Rapport.RetraiteObligatoire) +
                      Number(result100Div.Remuneration.RemunerationClient.Rapport.RetraiteFacultative) +
                      Number(result100Div.Remuneration.RemunerationClient.Rapport.TotalPEEProductif) +
                      Number(result100Div.Remuneration.RemunerationClient.Rapport.TotalPERCOProductif);
                    const revDispo = revPercus+remPercue + autresRevenus - impot - cotFacPerso;
                    setArbitrage100Div({
                      enveloppe: Number(context.state.ArbitrageRai),
                      chargesSup: chargesSup,
                      prime: prime,
                      resAvtIs: resAvtIs,
                      is: is,
                      reserve: reserve,
                      divBruts: divBruts,
                      regime: regime,
                      revPercus: revPercus,
                      remPercue: remPercue,
                      autresRevenus: autresRevenus,
                      impot: impot,
                      cotFacPerso: cotFacPerso,
                      revDispo: revDispo,
                      cotProductives: cotProductives,
                    });
                    context.handleValueChangeAsync("OptiRemChoixOptimum", "manu").then(() => {
                      context.handleValueChangeAsync("OptiRemOptimumRemMini", context.state.RemExpressRemNette).then(() => {
                        //OPTIMUM
                        context.handleCalculOptiRem("optimum", "", "", ResultatTNS, false, false).then((resultOptimum) => {
                          let chargesSup = 0;
                          if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
                            chargesSup =
                              Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSociales) +
                              Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.CotisationsFacultativesEntreprise) -
                              Number(context.state.RemExpressCotisations);
                          } else {
                            chargesSup =
                              Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.TotalChargesPatronale) +
                              Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.TotalChargesSalariale) -
                              Number(context.state.RemExpressCotisations);
                          }
                          const prime = Math.max(0, Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.RemunerationNette) - Number(context.state.RemExpressRemNette));
                          const resAvtIs = Number(resultOptimum.Remuneration.ResAvtIs);
                          const is = Number(resultOptimum.Remuneration.ImpotSoc);
                          const reserve = Number(context.state.ArbitrageReserve) + Number(resultOptimum.Remuneration.DivBrutsAutres);
                          const divBruts = Number(resultOptimum.Remuneration.DivBruts);
                          const regime = resultOptimum.Remuneration.OptionPfu === "oui" ? "PFU" : "Barème progressif";
                          const revPercus = Number(resultOptimum.Remuneration.RemunerationClient.AffichageResultat.RemunerationNette) + divBruts - Number(context.state.RemExpressRemNette);
                          const remPercue = Number(context.state.RemExpressRemNette);
                          const autresRevenus = Number(resultOptimum.Impot.AutresRevenusFoyer);
                          const impot = Number(resultOptimum.Impot.IRTotal);
                          const cotFacPerso = context.state.RemExpressCotFacPerso;
                          const cotProductives =
                            Number(resultOptimum.Remuneration.RemunerationClient.Rapport.RetraiteObligatoire) +
                            Number(resultOptimum.Remuneration.RemunerationClient.Rapport.RetraiteFacultative) +
                            Number(resultOptimum.Remuneration.RemunerationClient.Rapport.TotalPEEProductif) +
                            Number(resultOptimum.Remuneration.RemunerationClient.Rapport.TotalPERCOProductif);
                          const revDispo = revPercus+remPercue + autresRevenus - impot - cotFacPerso;
                          setArbitrageOptimum({
                            enveloppe: Number(context.state.ArbitrageRai),
                            chargesSup: chargesSup,
                            prime: prime,
                            resAvtIs: resAvtIs,
                            is: is,
                            reserve: reserve,
                            divBruts: divBruts,
                            regime: regime,
                            revPercus: revPercus,
                            remPercue: remPercue,
                            autresRevenus: autresRevenus,
                            impot: impot,
                            cotFacPerso: cotFacPerso,
                            revDispo: revDispo,
                            cotProductives: cotProductives,
                          });
                        });
                      });
                    });
                  });
              });
            });
          });
        });
      });
    });
  };

  return (
    <>
      <Column fill lpad>
        <Row>
          <CardBox className="blockRoundedForceReduced">
            <Column fill style={{ minWidth: "694px", maxWidth: "940px", paddingTop: 0 }}>
              <table>
                <tbody>
                  <tr style={{ borderBottom: "transparent" }}>
                    <td>
                      <TextField
                        label="Résultat avant IS"
                        value={context.state.ArbitrageRai}
                        onChange={context.handleInputChange("ArbitrageRai")}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </td>
                    <td>
                      <TextField
                        label="Réserve"
                        value={context.state.ArbitrageReserve}
                        onChange={context.handleInputChange("ArbitrageReserve")}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </td>
                    <td>
                      <Button
                        style={{ marginTop: "16px", paddingLeft: "20px", paddingRight: "20px" }}
                        onClick={() => {
                          handleCalculArbitrage();
                        }}
                      >
                        Calculer
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Column>
            <Column>
              <Button
                style={{ marginTop: "20px" }}
                className="optiIcon"
                onClick={() => {
                  ArbitragePptx(context.state, arbitrage100Rem, arbitrage100Div, arbitrageOptimum).then((res) => {});
                }}
              >
                <FaPrint title="Imprimer" />
              </Button>
            </Column>
            <Column style={{ paddingTop: "28px" }} lpad>
              <Hover2
                style={{ width: "500px", textAlign: "justify", padding: "16px", zIndex: "9999" }}
                icon={
                  <Button style={{ backgroundColor: "red" }} className="optiIcon">
                    <FaExclamationTriangle title="Imprimer" />
                  </Button>
                }
              >
                Nous vous rappelons que dans le cadre de la simulation, les charges sociales sont considérées comme provisionnées à la clôture de l’exercice et donc déductibles fiscalement.
                Pour qu’elles le soient en pratique, il est indispensable que la distribution de dividendes intervienne avant la clôture de l’exercice en question.
                <br />
                <br />A titre d’exemple, lorsqu’un arbitrage est réalisé en 2023, il est indispensable que la distribution de dividendes intervienne avant la clôture de l’exercice 2023. Le
                cas échéant, les charges sociales générées par la distribution de dividendes ne pourront pas être déduites du résultat de l’exercice 2023.
              </Hover2>
            </Column>
            {/* <Column>
              <Button
                style={{ marginTop: "20px", backgroundColor: "red" }}
                className="optiIcon"
                onClick={() => {
                  GestionnaireScenarioPptx(context.state, ResultatTNS.state).then((res) => {});
                }}
              >
                <FaExclamationTriangle title="Imprimer" />
              </Button>
            </Column> */}
            <Modal
              width="450px"
              height="25%"
              title="Sauvegarde de la simulation"
              icon={<FaSave />}
              visible={modalSave}
              onClose={() => {
                setModalSave(false);
              }}
            >
              <Column fill>
                <Row fill center>
                  <Column fill center>
                    <TextField
                      labelStyle={{ color: "var(--app-fg-color)" }}
                      label="Libellé de la simulation"
                      value={context.state.LibelleOptimisation}
                      onChange={context.handleInputChange("LibelleOptimisation")}
                      style={{ width: "370px" }}
                    />
                  </Column>
                </Row>
                <Row className="pager-buttons">
                  <Column fill>
                    <Button
                      onClick={() => {
                        // context.handleNewSimulation("Audit prévoyance");
                        context.handleSaveAsOptimisation(context.state.IdOptimisation, "OptiRem");
                        setModalSave(false);
                      }}
                    >
                      <span>Enregistrer</span>
                    </Button>
                  </Column>
                  <Column fill>
                    <Button
                      onClick={() => {
                        context.handleSaveAsOptimisation("", "OptiRem");
                        // context.handleNewSimulation("Audit prévoyance");
                        setModalSave(false);
                      }}
                    >
                      <span>Enregistrer sous</span>
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Modal>
          </CardBox>
        </Row>
        <Row>
          <CardBox tpad className=" light-title blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">&nbsp;</td>
                  <td className="origine">100% Prime</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">100% Dividendes</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> Optimum</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className=" dark-title blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Enveloppe entreprise</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.enveloppe, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.enveloppe, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.enveloppe, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className="blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Cotisations sociales supplémentaires</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.chargesSup, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.chargesSup, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.chargesSup, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Prime</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.prime, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.prime, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.prime, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className="blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Résultat avant IS</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.resAvtIs, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.resAvtIs, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.resAvtIs, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Impôt sur les sociétés</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.is, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.is, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.is, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Mise en réserve / dividendes associés</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.reserve, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.reserve, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.reserve, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Dividendes bruts du dirigeant</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.divBruts, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.divBruts, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.divBruts, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Régime fiscal des dividendes</td>
                  <td className="origine">{arbitrage100Rem.regime}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{arbitrage100Div.regime}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {arbitrageOptimum.regime}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className="blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Revenus nets supplémentaires</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.revPercus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.revPercus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.revPercus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Rémunération déjà perçue sur l’exercice</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.remPercue, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.remPercue, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.remPercue, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Autres revenus du foyer</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.autresRevenus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.autresRevenus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.autresRevenus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Impôt sur le revenu</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.impot, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.impot, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.impot, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="libelle">Cotisations facultatives personnelles</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.cotFacPerso, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.cotFacPerso, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.cotFacPerso, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className=" dark-title blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Revenu disponible après impôt</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.revDispo, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.revDispo, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.revDispo, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
        <Row>
          <CardBox className=" dark-title blockRoundedForceReduced" bpad style={{ minWidth: "864px" }}>
            <table className="simulation tableArbitrage">
              <tbody>
                <tr>
                  <td className="libelle">Cotisations de retraite productives</td>
                  <td className="origine">{applyFormat(arbitrage100Rem.cotProductives, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="space">&nbsp;</td>
                  <td className="nouvelle pr4">
                    <div className="space">{applyFormat(arbitrage100Div.cotProductives, "uint", { thousands: " ", decimal: ",", unit: " €" })}</div>
                  </td>

                  <td className="extra">&nbsp;</td>
                  <td className="ecart"> {applyFormat(arbitrageOptimum.cotProductives, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </CardBox>
        </Row>
      </Column>
    </>
  );
};

export default RemExpressArbitragePage;
