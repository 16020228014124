import { useContext, useState, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaBookDead, FaCogs, FaFileSignature, FaHSquare, FaWheelchair } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import ContratPrevCondPart from "./ContratPrevCondPart";
import IncapCondPart from "./IncapCondPart";
import InvalCondPart from "./InvalCondPart";
import DecesCondPart from "./DecesCondPart";
import ContratPrevCondGen from "./ContratPrevCondGen";
import IncapCondGen from "./IncapCondGen";
import InvalCondGen from "./InvalCondGen";
import OptionExclusionCondGen from "./OptionExclusionCondGen";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";

const ContratPrevoyancePager = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  useEffect(() => {
    context.handleValueChange("PayeurPrimePrevoyance", props.PayeurPrime);
    setCurrentPage(1);
    if (  context.state.TypeSimulation === "Audit rémunération" && props.Outil==="") {
      context.handleValueChange("SalaireBrutContratPrevoyance", context.state.RemExpressRemBrute);
    }
    else if (props.Outil === "OptiRem") {
      context.handleValueChange("SalaireBrutContratPrevoyance", context.state["OptiRemRemBrute" + props.ClientConjoint]);
    }
    else if (context.state.TypeSimulation !== "Audit prévoyance") {
      if (
        props.visible === true &&
        props.AccessRemExpress === true &&
        props.PayeurPrime === "Entreprise" &&
        props.Statut !== "TNS Article 62" &&
        props.Statut !== "TNS individuel"
      ) {
        context.handleValueChange("SalaireBrutContratPrevoyance", context.state.RemExpressRemBrute);
      } else if (
        props.visible === true &&
        props.PayeurPrime === "Entreprise" &&
        props.Statut !== "TNS Article 62" &&
        props.Statut !== "TNS individuel"
      ) {
        const newPeriode = [
          {
            SituationPro: context.state.SituationPro,
            DebutPeriodeCarriereActuelle: context.state.DebutPeriodeCarriereActuelle,
            FinCarriereActuelle: context.state.FinCarriereActuelle,
            FinPeriodeCarriereActuelle: context.state.FinPeriodeCarriereActuelle,
            FinCarriereActuelleConjoint: context.state.FinCarriereActuelleConjoint,
            FinPeriodeCarriereActuelleConjoint: context.state.FinPeriodeCarriereActuelleConjoint,
            Statut: props.Statut,
            StatutConjoint: context.state[props.Outil + "StatutConjoint"],
            Caisse: context.state.Caisse,
            ConventionneCarcdsf: context.state.ConventionneCarcdsf,
            ConventionneCarmf: context.state.ConventionneCarmf,
            OptionRsi: context.state.OptionRsi,
            ConventionneCarpimko: context.state.ConventionneCarpimko,
            TauxCpam: context.state.TauxCpam,
            ClassePrevoyanceCarpv: context.state.ClassePrevoyanceCarpv,
            ClasseRetraiteCarpv: context.state.ClasseRetraiteCarpv,
            ClassePrevoyanceCavec: context.state.ClassePrevoyanceCavec,
            ClasseRetraiteCavec: context.state.ClasseRetraiteCavec,
            ConventionneCavp: context.state.ConventionneCavp,
            ClasseRetraiteCavp: context.state.ClasseRetraiteCavp,
            ClassePrevoyanceCipav: context.state.ClassePrevoyanceCipav,
            ClasseRetraiteCipav: context.state.ClasseRetraiteCipav,
            AncienneteCnbf: context.state.AncienneteCnbf,
            ClasseRetraiteCnbf: context.state.ClasseRetraiteCnbf,
            ClasseRetraiteCrn: context.state.ClasseRetraiteCrn,
            CrnSermentAvt2014: context.state.CrnSermentAvt2014,
            CrnColmarMetz: context.state.CrnColmarMetz,
            Atexa: context.state.Atexa,
            TauxActivite: context.state.TauxActivite,
            Effectif: context.state.Effectif,
            CavecSalarie: context.state.CavecSalarie,
            CnbfSalarie: context.state.CnbfSalarie,
            AccidentTravail: context.state.AccidentTravail,
            AgircArrcoT1Sal: context.state.AgircArrcoT1Sal,
            AgircArrcoT1Pat: context.state.AgircArrcoT1Pat,
            AgircArrcoT2Sal: context.state.AgircArrcoT2Sal,
            AgircArrcoT2Pat: context.state.AgircArrcoT2Pat,
            Transport: context.state.Transport,
            Formation: context.state.Formation,
            Apprentissage: context.state.Apprentissage,
            Regime: context.state.Regime,
            TaxeSalaire: context.state.TaxeSalaire,
            AssuranceChomage: context.state.AssuranceChomage,
            ReductionGenerale: context.state.ReductionGenerale,
            AvantageNature: context.state.AvantageNature,
            TabCotisationsManuelle: context.state.TabCotisationsManuelle,
            ConventionneCarcdsfConjoint: context.state.ConventionneCarcdsfConjoint,
            ConventionneCarmfConjoint: context.state.ConventionneCarmfConjoint,
            OptionRsiConjoint: context.state.OptionRsiConjoint,
            ConventionneCarpimkoConjoint: context.state.ConventionneCarpimkoConjoint,
            TauxCpamConjoint: context.state.TauxCpamConjoint,
            ClassePrevoyanceCarpvConjoint: context.state.ClassePrevoyanceCarpvConjoint,
            ClasseRetraiteCarpvConjoint: context.state.ClasseRetraiteCarpvConjoint,
            ClassePrevoyanceCavecConjoint: context.state.ClassePrevoyanceCavecConjoint,
            ClasseRetraiteCavecConjoint: context.state.ClasseRetraiteCavecConjoint,
            ConventionneCavpConjoint: context.state.ConventionneCavpConjoint,
            ClasseRetraiteCavpConjoint: context.state.ClasseRetraiteCavpConjoint,
            ClassePrevoyanceCipavConjoint: context.state.ClassePrevoyanceCipavConjoint,
            ClasseRetraiteCipavConjoint: context.state.ClasseRetraiteCipavConjoint,
            AncienneteCnbfConjoint: context.state.AncienneteCnbfConjoint,
            ClasseRetraiteCnbfConjoint: context.state.ClasseRetraiteCnbfConjoint,
            ClasseRetraiteCrnConjoint: context.state.ClasseRetraiteCrnConjoint,
            CrnSermentAvt2014Conjoint: context.state.CrnSermentAvt2014Conjoint,
            CrnColmarMetzConjoint: context.state.CrnColmarMetzConjoint,
            AtexaConjoint: context.state.AtexaConjoint,
            TauxActiviteConjoint: context.state.TauxActiviteConjoint,
            EffectifConjoint: context.state.EffectifConjoint,
            CavecSalarieConjoint: context.state.CavecSalarieConjoint,
            AccidentTravailConjoint: context.state.AccidentTravailConjoint,
            AgircArrcoT1SalConjoint: context.state.AgircArrcoT1SalConjoint,
            AgircArrcoT1PatConjoint: context.state.AgircArrcoT1PatConjoint,
            AgircArrcoT2SalConjoint: context.state.AgircArrcoT2SalConjoint,
            AgircArrcoT2PatConjoint: context.state.AgircArrcoT2PatConjoint,
            TransportConjoint: context.state.TransportConjoint,
            FormationConjoint: context.state.FormationConjoint,
            ApprentissageConjoint: context.state.ApprentissageConjoint,
            RegimeConjoint: context.state.RegimeConjoint,
            TaxeSalaireConjoint: context.state.TaxeSalaireConjoint,
            AssuranceChomageConjoint: context.state.AssuranceChomageConjoint,
            ReductionGeneraleConjoint: context.state.ReductionGeneraleConjoint,
            TabCotisationsManuelleConjoint: context.state.TabCotisationsManuelleConjoint,
            IdentiteEntreprise: context.state.IdentiteEntreprise,
            FormeJuridique: context.state.FormeJuridique,
            Fiscalite: context.state.Fiscalite,
            TauxISReduit: context.state.TauxISReduit,
            TauxISNormal: context.state.TauxISNormal,
            TauxISZero: context.state.TauxISZero,
            TypeRemuneration: context.state.TypeRemuneration,
            TypeRemunerationConjoint: context.state.TypeRemunerationConjoint,
            Remuneration: context.state.Remuneration,
            RemunerationConjoint: context.state.RemunerationConjoint,
            AvantageNatureConjoint: context.state.AvantageNatureConjoint,
            DividendeBrut: context.state.DividendeBrut,
            DividendeBrutAutres: context.state.DividendeBrutAutres,
            CapitalSocial: context.state.CapitalSocial,
            PartSoc: context.state.PartSoc,
            PartSocConj: context.state.PartSocConj,
            ResAvtIs: context.state.ResAvtIs,
            ImpotSoc: context.state.ImpotSoc,
            ResNet: context.state.ResNet,
            SeuilAssuj: context.state.SeuilAssuj,
            SeuilAssujConjoint: context.state.SeuilAssujConjoint,
            SetSeuilAssuj: context.state.SetSeuilAssuj,
            SetSeuilAssujConjoint: context.state.SetSeuilAssujConjoint,
            Reserve: context.state.Reserve,
          },
        ];
        ResultatSalarie.GetSalaireBrutCalculPrestation(context.state, newPeriode, props.CliConj).then((SalaireBrut) => {
          context.handleValueChange("SalaireBrutContratPrevoyance", SalaireBrut);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  const handleMenu = () => {
    let menu = [
      {
        isTitle: true,
        title: "Conditions particulières",
      },
      {
        label: "Contrat",
        icon: <FaFileSignature />,
        content: <ContratPrevCondPart AccessRemExpress={props.AccessRemExpress} Statut={props.Statut} Outil={props.Outil} PayeurPrime={props.PayeurPrime} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Incapacité",
        icon: <FaHSquare />,
        content: <IncapCondPart Outil={props.Outil } Statut={props.Statut} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Invalidité",
        icon: <FaWheelchair />,
        content: <InvalCondPart Outil={props.Outil} Statut={props.Statut} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Décès",
        icon: <FaBookDead />,
        content: <DecesCondPart Outil={props.Outil} Statut={props.Statut} ClientConjoint={props.ClientConjoint} />,
      },
      {
        isTitle: true,
        title: "Conditions générales",
        hidden:
        context.state.TypeSimulation === "Audit prévoyance" || props.Statut === "TNS Article 62" || props.Statut === "TNS individuel"
            ? false
            : true,
      },
      {
        label: "Contrat",
        icon: <FaFileSignature />,
        content: <ContratPrevCondGen AffichLibelle={false}  />,
        hidden:
        context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel"
            ? false
            : true,
      },
      {
        label: "Incapacité",
        icon: <FaHSquare />,
        content: <IncapCondGen />,
        hidden:
        context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel"
            ? false
            : true,
      },
      {
        label: "Invalidité",
        icon: <FaWheelchair />,
        content: <InvalCondGen />,
        hidden:
        context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel"
            ? false
            : true,
      },
      {
        label: "Option / Exclusion",
        icon: <FaCogs />,
        content: <OptionExclusionCondGen />,
        hidden:
        context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel"
            ? false
            : true,
      },
    ];

    if (props.AccessRemExpress === true) {
      menu = menu.slice(0, 2);
    }

    return menu;
  };
  return (
    <>
      <Pager
      id="contratFacPrevoyance"
        withMenu
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
             
              props?.onClose?.();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddContratPrevoyance(context.state.IdContratPrevoyance, props.Outil, props.ClientConjoint);
              props?.onClose?.();
            },
          },
        }}
      />
    </>
  );
};

export default ContratPrevoyancePager;
