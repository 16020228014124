import { useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './card-box.css';

/**
 * CardBox component.
 * @param {ReactDOM.props} props Component properties.
 * @returns CardBox component.
 */
 const CardBox = (props) => {
    
    // Excluded properties for automatic properties propagation
    const Excluded = ['id'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId())

    return (
        <div
            id={id}
            className={classFromProperties(props, 'card-box', Alignments, Paddings)}
            {...getOptionalProps(props, Excluded)}
        >
            {props.children}
        </div>
    );
}

export default CardBox;
