import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";

function OptionExclusionCondGen() {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row bpad>
              <DropDown
                label="Prise en charge des affections psychiatriques et psychiques"
                labelStyle={{ whiteSpace: "nowrap" }}
                value={context.state.PECPsy}
                onChange={context.handleInputChange("PECPsy")}
                options={[
                  { value: "Non", label: "Non" },
                  { value: "Oui", label: "Oui" },
                  { value: "Non communiqué", label: "Non communiqué" },
                ]}
                normal
              />
            </Row>
            {context.state.PECPsy === "Oui" && (
              <Row tpad bpad>
                <table className="mono">
                  <thead>
                    <tr>
                      <th>
                        <TextField className="invisible" value={"Délai de carence"} normal />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Condition de prise en charge"} normal />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Durée de versement"} normal />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <DropDown
                          value={context.state.PECPsyCarence}
                          onChange={context.handleInputChange("PECPsyCarence")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "Aucun" },
                            { value: "90", label: "90 jours" },
                            { value: "180", label: "180 jours" },
                            { value: "365", label: "365 jours" },
                          ]}
                          normal
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state.PECPsyDureeHospi}
                          onChange={context.handleInputChange("PECPsyDureeHospi")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "Sans condition d'hospitalisation" },
                            { value: "7", label: "Sans condition mais franchise minimale de 30 jours" },
                            { value: "10", label: "Sans condition mais franchise minimale de 60 jours" },
                            { value: "8", label: "Sans condition mais franchise minimale de 90 jours" },
                            { value: "1", label: "3 jours en service psy ou 8 jours ailleurs" },
                            { value: "12", label: "5 jours en service psy ou 8 jours ailleurs" },
                            { value: "11", label: "Hospitalisation de 1 jour" },
                            { value: "9", label: "Hospitalisation de 3 jours" },
                            { value: "2", label: "Hospitalisation de 5 jours" },
                            { value: "13", label: "Hospitalisation de 5 jours en maladie et 15 jours en invalidité" },
                            { value: "3", label: "Hospitalisation de 8 jours" },
                            { value: "4", label: "Hospitalisation de 10 jours" },
                            { value: "5", label: "Hospitalisation de 14 jours" },
                            { value: "6", label: "Hospitalisation de 15 jours" },
                          ]}
                          normal
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state.PECPsyDureeIJ}
                          onChange={context.handleInputChange("PECPsyDureeIJ")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "4", label: "Durée de l'hospitalisation" },
                            { value: "0", label: "365 jours" },
                            { value: "1", label: "365 jours sauf intervention chirurgicale" },
                            { value: "2", label: "365 jours sauf hospitalisation de + de 7 jours" },
                            { value: "3", label: "1095 jours" },
                          ]}
                          normal
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            )}
            <Row tpad bpad>
              <DropDown
                label="Prise en charge des pathologies liées au dos"
                labelStyle={{ whiteSpace: "nowrap" }}
                value={context.state.PECDos}
                onChange={context.handleInputChange("PECDos")}
                options={[
                  { value: "Non", label: "Non" },
                  { value: "Oui", label: "Oui" },
                  { value: "Non communiqué", label: "Non communiqué" },
                ]}
                normal
              />
            </Row>
            {context.state.PECDos === "Oui" && (
              <Row tpad bpad>
                <table className="mono">
                  <thead>
                    <tr>
                      <th>
                        <TextField className="invisible" value={"Délai de carence"} normal />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Condition de prise en charge"} normal />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Durée de versement"} normal />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <DropDown
                          value={context.state.PECDosCarence}
                          onChange={context.handleInputChange("PECDosCarence")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "Aucun" },
                            { value: "90", label: "90 jours" },
                            { value: "180", label: "180 jours" },
                            { value: "365", label: "365 jours" },
                          ]}
                          normal
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state.PECDosDureeHospi}
                          onChange={context.handleInputChange("PECDosDureeHospi")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "Sans condition d'hospitalisation" },
                            { value: "6", label: "Sans condition mais franchise minimale de 30 jours" },
                            { value: "9", label: "Sans condition mais franchise minimale de 60 jours" },
                            { value: "7", label: "Sans condition mais franchise minimale de 90 jours" },
                            { value: "8", label: "Couvert si une intervention chirurgicale est nécessaire" },
                            { value: "1", label: "Hospitalisation de 24h" },
                            { value: "2", label: "Hospitalisation de 24h ou intervention chirurgicale" },
                            { value: "3", label: "Hospitalisation de 48h" },
                            { value: "4", label: "Hospitalisation de 48h ou intervention chirurgicale" },
                            { value: "5", label: "Hospitalisation de 7 jours" },
                          ]}
                          normal
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state.PECDosDureeIJ}
                          onChange={context.handleInputChange("PECDosDureeIJ")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "365 jours" },
                            { value: "1", label: "365 jours sauf intervention chirurgicale" },
                            { value: "4", label: "365 jours sauf inter. chir. avec hosp. d'au moins 7 jours" },
                            { value: "2", label: "365 jours sauf hospitalisation de + de 48h" },
                            { value: "3", label: " 1095 jours" },
                          ]}
                          normal
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            )}
            <Row tpad bpad>
              <DropDown
                label="Prise en charge du temps partiel thérapeutique"
                labelStyle={{ whiteSpace: "nowrap" }}
                value={context.state.MiTempsTherapeutique}
                onChange={context.handleInputChange("MiTempsTherapeutique")}
                options={[
                  { value: "Non", label: "Non" },
                  { value: "Oui", label: "Oui" },
                  { value: "Non communiqué", label: "Non communiqué" },
                ]}
                normal
              />
            </Row>

            {context.state.MiTempsTherapeutique === "Oui" && (
              <Row tpad>
                <table className="mono">
                  <thead>
                    <tr>
                      <th>
                        <TextField className="invisible" value={"Délai de carence"} normal />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Durée de versement"} normal />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <DropDown
                          value={context.state.MTPCarence}
                          onChange={context.handleInputChange("MTPCarence")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "0", label: "Aucun" },
                            { value: "90", label: "90 jours" },
                            { value: "180", label: "180 jours" },
                            { value: "365", label: "365 jours" },
                          ]}
                          normal
                        />
                      </td>

                      <td>
                        <DropDown
                          value={context.state.MTPDureeIJ}
                          onChange={context.handleInputChange("MTPDureeIJ")}
                          options={[
                            { value: "NC", label: "Non communiqué" },
                            { value: "60", label: "60 jours" },
                            { value: "90", label: "90 jours" },
                            { value: "120", label: "120 jours" },
                            { value: "180", label: "180 jours" },
                            { value: "365", label: "365 jours" },
                            { value: "1095", label: "1095 jours" },
                          ]}
                          normal
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default OptionExclusionCondGen;
