import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import RevenusRetraiteCell from "./RevenusRetraiteCell";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import TextField from "components/ui/controls/text-field/text-field";
const RevenusRetraite = () => {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Row>
              {context.state.IdPeriodeRetraite !== "CarriereActuelle" && (
                <Column style={{ alignSelf: "flex-end" }}>
                  <Button
                    onClick={() => {
                      context.handleRevenusAuPASS();
                    }}
                  >
                    <span>Forcer les revenus au PASS</span>
                  </Button>
                </Column>
              )}
              <Column hpad>
                <TextField label="Taux d'indexation" value={context.state.MontantEvolution} onChange={context.handleCalculEvolutionRetraite} dataType="float"
                        format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }} small />
              </Column>
            </Row>
            <Row tpad>
              <Column fill>
                <table className="mono">
                  <thead>
                    <tr>
                      <th>Année</th>
                      <th>
                        {context.state.RetraiteActivite === "MicroRSI" ||
                        context.state.RetraiteActivite === "MicroBIC" ||
                        context.state.RetraiteActivite === "MicroBNC" ||
                        context.state.RetraiteActivite === "MicroCipav"
                          ?      <TextField className="invisible" value={"Chiffre d'affaires"} normal />
                          :      <TextField className="invisible" value={"Revenus"} normal />}
                      </th>
                      {context.state.DisplayTrimestre === true && (
                        <>
                          <th>  <TextField className="invisible" value={"Trimestres"} normal /></th>
                        </>
                      )}
                      {context.state.DisplayDeplafondPass === true && <th style={{ textAlign: "center" }}>Déplaf. PASS</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(context.state.DetailRetraitePasse).map((Annee, index) => {
                      return (
                        <RevenusRetraiteCell
                          key={index.toString()}
                          id={index}
                          Annee={Annee}
                          DetailRetraiteConcerne={"DetailRetraitePasse"}
                          CalculTrimestre={context.handleCalculTrimestrePasse}
                          onCheck={context.handleCheckboxDetailRetraite}
                          onModifyRevenus={context.handleRevenusRetraitePasse}
                          onModifyTrimestre={context.handleSaisieTrimestre}
                          Revenus={context.state.DetailRetraitePasse[Annee]["Salaire"]}
                          Trimestre={context.state.DetailRetraitePasse[Annee]["Trimestre"]}
                          CheckboxTrimestre={context.state.DetailRetraitePasse[Annee]["CheckboxTrimestre"]}
                          CheckboxDeplafondPass={context.state.DetailRetraitePasse[Annee]["CheckboxDeplafondPass"]}
                          DisplayDeplafondPass={context.state.DisplayDeplafondPass}
                          DisplayTrimestre={context.state.DisplayTrimestre}
                        />
                      );
                    })}
                    {Object.keys(context.state.DetailRetraiteFutur).map((Annee, index) => {
                      return (
                        <RevenusRetraiteCell
                          key={index.toString()}
                          id={index}
                          Annee={Annee}
                          DetailRetraiteConcerne={"DetailRetraiteFutur"}
                          CalculTrimestre={context.handleCalculTrimestreFutur}
                          onCheck={context.handleCheckboxDetailRetraite}
                          onModifyRevenus={context.handleRevenusRetraiteFutur}
                          onModifyTrimestre={context.handleSaisieTrimestre}
                          Revenus={context.state.DetailRetraiteFutur[Annee]["Salaire"]}
                          Trimestre={context.state.DetailRetraiteFutur[Annee]["Trimestre"]}
                          CheckboxTrimestre={context.state.DetailRetraiteFutur[Annee]["CheckboxTrimestre"]}
                          DisplayTrimestre={context.state.DisplayTrimestre}
                          DisplayDeplafondPass={context.state.DisplayDeplafondPass}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default RevenusRetraite;
