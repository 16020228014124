import { applyFormat } from "components/ui/ui-helpers";

const DetailCotisationsCavec = (props) => {
  const { DetailCotisations, DetailCSG, TotalChargesSociales } = props;

  return (
    <>
      <table className="cotisations">
        <thead>
          <tr className="dark-title">
            <th></th>
            <th>Assiette</th>
            <th>Taux</th>
            <th>Montant dû</th>
          </tr>
        </thead>
        <tbody>
          <tr className="light-title">
            <td colSpan={4}>Cotisations de {props.Prenom}</td>
          </tr>
          <tr>
            <td>Assurance maladie</td>
            <td>{DetailCotisations?.assiette_assurance_maladie}</td>
            <td>{DetailCotisations?.taux_assurance_maladie}</td>
            <td>{DetailCotisations?.assurance_maladie}</td>
          </tr>
          <tr>
            <td>Allocations familiales</td>
            <td>{DetailCotisations?.assiette_allocation_familiale}</td>
            <td>{DetailCotisations?.taux_allocation_familiale}</td>
            <td>{DetailCotisations?.allocation_familiale}</td>
          </tr>
          <tr>
            <td>Indemnités journalières</td>
            <td>{DetailCotisations?.assiette_ij_cpam}</td>
            <td>{DetailCotisations?.taux_ij_cpam}</td>
            <td>{DetailCotisations?.ij_cpam}</td>
          </tr>
          <tr>
            <td>Invalidité / Décès</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.prevoyance}</td>
          </tr>
          <tr>
            <td>Retraite de base tranche 1</td>
            <td>{DetailCotisations?.assiette_retraite_de_base1}</td>
            <td>{DetailCotisations?.taux_retraite_de_base1}</td>
            <td>{DetailCotisations?.retraite_de_base1}</td>
          </tr>
          {DetailCotisations?.retraite_de_base2 !== "0 €" && (
          <tr>
            <td>Retraite de base tranche 2</td>
            <td>{DetailCotisations?.assiette_retraite_de_base2}</td>
            <td>{DetailCotisations?.taux_retraite_de_base2}</td>
            <td>{DetailCotisations?.retraite_de_base2}</td>
          </tr>
          )}
          <tr>
            <td>Retraite complémentaire</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_complementaire}</td>
          </tr>
          <tr className="light-title">
            <td colSpan={3}>
              Cotisations sociales obligatoires <span style={{ fontSize: "8pt" }}> (hors formation et CSG-CRDS)</span>{" "}
            </td>
            <td>{applyFormat(DetailCSG?.ChargesSocialesObligatoire, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>Formation professionnelle</td>
            <td>{DetailCotisations?.assiette_formation_professionnelle}</td>
            <td>{DetailCotisations?.taux_formation_professionnelle}</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
          </tr>
          <tr>
            <td>CSG déductible</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>CSG non déductible + CRDS</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGNonDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          {Number(DetailCSG?.csg_primes) > 0 && (
            <tr>
              <td>CSG non déductible + CRDS sur épargne salariale</td>
              <td>{applyFormat(DetailCSG?.assiette_csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>{applyFormat(DetailCSG?.TauxCSG, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          )}
          <tr className="light-title">
            <td colSpan={3}>Total des cotisations sociales </td>
            <td>{applyFormat(TotalChargesSociales, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DetailCotisationsCavec;
