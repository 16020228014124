import { useEffect, useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './menu-item.css';

/**
 * Generates a menu item component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const MenuItem = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['onClick', 'selected', 'disabled', 'hidden'].concat(Alignments, Paddings, Sizes);

    /**
     * Handles a click event.
     * @param {ClickEvent} evt Click event.
     */
    const handleClick = (evt) => {
        evt.target.key = evt.target.value = props?.value;
        props?.onClick?.(evt, props?.value);
    }

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId())
    const [ isSelected, setIsSelected ] = useState(props?.selected !== undefined ? props?.selected :false);
    const [ isDisabled, setIsDisabled ] = useState(props?.isDisabled !== undefined ? props?.disabled : false);
    const [ isHidden, setIsHidden ] = useState(props?.hidden !== undefined ? props?.hidden : false);

    /**
     * On property change.
     */
    useEffect(() => {
        setIsSelected(props?.selected !== undefined ? props?.selected :false);
        setIsDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setIsHidden(props?.hidden !== undefined ? props?.hidden : false);
    }, [props?.selected, props?.disabled, props?.hidden]);

    // Render component
    return (
        <div
            id={id}
            className={classFromProperties(
                props,
                'menu-item' + 
                    (isSelected ? ' selected' : '') +
                    (isDisabled ? ' disabled' : '') +
                    (isHidden ? ' hidden' : ''),
                Alignments,
                Paddings,
                Sizes
            )}
            onClick={handleClick}
            {...getOptionalProps(props, Excluded)}
        >
            {props?.children}
        </div>
    )
}

export default MenuItem;