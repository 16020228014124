import axios from "axios";
import { ApiUrl } from "components/app/Api/ApiData";
const APIURL = ApiUrl;
export function GetUsersList(IdAdmin, Search) {
  var formData = new FormData();

  formData.append("IdAdmin", IdAdmin);
  formData.append("Search", Search);
  formData.append("CRUD", "SearchUser");
  return axios({
    method: "post",
    url: `${APIURL}Admin/Admin.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeClient) => {
      return ListeClient.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function GetUserClients(IdAdmin, IdUser) {
  var formData = new FormData();

  formData.append("IdAdmin", IdAdmin);
  formData.append("IdUser", IdUser);
  formData.append("CRUD", "GetUserClients");
  return axios({
    method: "post",
    url: `${APIURL}Admin/Admin.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeClient) => {
      return ListeClient.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}

export function GetListeDossier(IdAdmin, IdUser, IdClient) {
  var formData = new FormData();

  formData.append("IdAdmin", IdAdmin);
  formData.append("IdUser", IdUser);
  formData.append("IdClient", IdClient);
  formData.append("CRUD", "GetListeDossier");
  return axios({
    method: "post",
    url: `${APIURL}Admin/Admin.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeClient) => {
      return ListeClient.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function SetTransfertDossier(IdAdmin, IdUserToTransfert, IdClient, IdDossier) {
  var formData = new FormData();
  formData.append("IdAdmin", IdAdmin);
  formData.append("IdUserToTransfert", IdUserToTransfert);
  formData.append("IdClient", IdClient);
  formData.append("IdDossier", IdDossier);
  formData.append("CRUD", "TransfertDossier");
  return axios({
    method: "post",
    url: `${APIURL}Admin/Admin.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials:true
  }).then((ListeClient) => {
    // return ListeClient.data;
  })
  .catch(function (error) {
    if (parseInt(error.response.request.status) === 401) {
      var event = new CustomEvent("session-expired", {
        detail: {
          logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
        },
      });
      window.dispatchEvent(event);
    }
  });;
}
