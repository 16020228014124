import { useContext, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaCogs } from "react-icons/fa";
import ParamImpotSociete from "components/app/AuditComplet/SituationPro/ParamImpotSociete";

function ParamImpotSocietePageModal() {
  const [modalOpen, setmodalOpen] = useState(false);

  return (
    <>
      <Button icon onClick={() => setmodalOpen(true)}>
        <FaCogs />
      </Button>
      <Modal
        width="40%"
        height="42%"
        title="Paramétrage de l'impôt sur les sociétés"
        icon={<FaCogs />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ParamImpotSociete
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ParamImpotSocietePageModal;
