import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import LigneImpotDetaille from "components/app/AuditComplet/ContexteFiscal/LigneImpot";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaInfo, FaSync } from "react-icons/fa";
import { CalculPartFiscale } from "components/app/Api/Remuneration/ApiRemuneration";
import Hover from "components/ui/controls/hover/hover";
import ImageIR from './Image IR.png';

function StrategiePERContexteFiscal(props) {
  const context = useContext(AppContext);

  useEffect(() => {
    if (context.state.SetPartFiscale === false) {
      CalculPartFiscale(context.state).then((PartFiscale) => {
        context.handleValueChange("PartFiscale", PartFiscale);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetPartFiscale]);

  useEffect(() => {
    if (context.state.StrategiePERSetPartFiscaleRetraite === false) {
      if (context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") {
        context.handleValueChange("StrategiePERPartFiscaleRetraite", 2);
      } else {
        context.handleValueChange("StrategiePERPartFiscaleRetraite", 1);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.StrategiePERSetPartFiscaleRetraite]);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <h1>Impôt en activité</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Parts fiscales"
                  iconBefore={
                    <Button tabIndex="-1" icon onClick={() => context.handleValueChange("SetPartFiscale", false)}>
                      {context.state.SetPartFiscale === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                    </Button>
                  }
                  value={context.state.PartFiscale}
                  onChange={(e) => {
                    context.handleValueChange("SetPartFiscale", true);
                    context.handleValueChange("PartFiscale", Math.max(1, e.target.value));
                  }}
                  dataType="ufloat"
                  format={{ thousands: " ", precision: 2, decimal: "," }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Revenus nets imposables du foyer"
                  value={context.state.StrategiePERRevenuNetActivite}
                  onChange={context.handleInputChange("StrategiePERRevenuNetActivite")}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              <Column style={{ paddingTop: "41px" }}>
                <Hover style={{ width: "700px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                  {" "}
                  Les revenus nets imposables du foyer permettent de calculer un impôt sur le revenu et de déterminer l'économie d'impôt réalisée grâce à l'investissement sur un PER. Par défaut,  le montant indiqué dans la case correspond à la rémunération imposable du client après abattement forfaitaire de 10%. Si le foyer perçoit d'autres revenus, vous pouvez modifier le montant. L'exemple ci dessous pour permet de savoir quel montant reporter dans la case. <br />
                  <img style={{ height: "340px",paddingTop:"25px",borderRadius:"4px" }} src={ImageIR} alt="..." />
                </Hover>
              </Column>
            </Row>
            <Row tpad>
              <h1>Impôt à la retraite</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Parts fiscales"
                  iconBefore={
                    <Button tabIndex="-1" icon onClick={() => context.handleValueChange("StrategiePERSetPartFiscaleRetraite", false)}>
                      {context.state.StrategiePERSetPartFiscaleRetraite === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                    </Button>
                  }
                  value={context.state.StrategiePERPartFiscaleRetraite}
                  onChange={(e) => {
                    context.handleValueChange("StrategiePERSetPartFiscaleRetraite", true);
                    context.handleValueChange("StrategiePERPartFiscaleRetraite", Math.max(1, e.target.value));
                  }}
                  dataType="ufloat"
                  format={{ thousands: " ", precision: 2, decimal: "," }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Revenus nets imposables du foyer"
                  value={context.state.StrategiePERRevenuNetRetraite}
                  onChange={context.handleInputChange("StrategiePERRevenuNetRetraite")}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              <Column style={{ paddingTop: "41px" }}>
                <Hover style={{ width: "700px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                  {" "}
                  Les revenus nets imposables du foyer à la retraite permettent de calculer un impôt sur le revenu et de déterminer l'impact fiscal de la sortie du PER. Par défaut,  le montant indiqué dans la case correspond à la retraite imposable du client après abattement forfaitaire de 10%. Si le foyer perçoit d'autres revenus, vous pouvez modifier le montant. <br />
                </Hover>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default StrategiePERContexteFiscal;
