import Api from "api/api";
import { AppContext } from "components/app/RemExprertProvider";
import { AuthContext } from "components/auth/auth-provider";
import { useContext, useEffect } from "react";
export default function AppContent(props) {
  const auth = useContext(AuthContext);
  const context = useContext(AppContext);

  useEffect(() => {
    const sessionExpiredHandler = (e) => {
      auth.deconnecter(e.detail?.logoutMessage);
      context.handleValueChange("AccesSimulateur", false);
      context.handleValueChange("TypeSimulation", "");
    };
    window.addEventListener("session-expired", sessionExpiredHandler);
    
     const handle= setInterval(() => {
        Api.get("health")
          .then((response) => {
          })
          .catch((error) => {
            if (parseInt(error.response.request.status) === 401) {
              var event = new CustomEvent("session-expired", {
                detail: {
                  logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                },
              });
              window.dispatchEvent(event);
            }
          });
      }, 300000)
      
    return () => {
      window.removeEventListener("session-expired", sessionExpiredHandler);
          if (handle) {
      clearInterval(handle);
    }
    };
  }, []);


  return <>{props?.children}</>;
}
