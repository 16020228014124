import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";

function ContratPrevCondGen(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            {props.AffichLibelle === true && (
              <Row bpad>
                <Column>
                  <TextField label="Libellé" value={context.state.LibelleContrat} onChange={context.handleInputChange("LibelleContrat")} xlarge />
                </Column>
              </Row>
            )}
            <Row>
              <Column>
                <DropDown
                  label="TARIF ÉVOLUTIF AVEC L'ÂGE"
                  value={context.state.Tarification}
                  onChange={context.handleInputChange("Tarification")}
                  options={[
                    { value: "Evolutive avec l'âge", label: "Oui" },
                    { value: "Garantie à l'adhésion", label: "Non" },
                    { value: "Non communiqué", label: "Non communiqué" },
                  ]}
                  xlarge
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <DropDown
                  label="Nature"
                  value={context.state.Nature}
                  onChange={context.handleInputChange("Nature")}
                  options={[
                    { value: "Indemnitaire", label: "Indemnitaire" },
                    { value: "Forfaitaire", label: "Forfaitaire" },
                    { value: "Forfaitaire sans limite", label: "Forfaitaire sans limite" },
                    { value: "Au choix", label: "Au choix" },
                    { value: "Non communiqué", label: "Non communiqué" },
                  ]}
                  xlarge
                />
              </Column>
              {context.state.Nature === "Forfaitaire" && (
                <>
                  <Column>
                    <TextField
                      label="Limite"
                      value={context.state.LimiteNatureContrat}
                      onChange={context.handleInputChange("LimiteNatureContrat")}
                      dataType={context.state.LimiteNatureContratUnite === "€" ? "uint" : "ufloat"}
                      format={context.state.LimiteNatureContratUnite === "€" ? { thousands: " " } : { thousands: " ", precision: 2, decimal: "," }}
                      small
                    />
                  </Column>
                  <Column>
                    <DropDown
                      label="Unité"
                      value={context.state.LimiteNatureContratUnite}
                      onChange={context.handleInputChange("LimiteNatureContratUnite")}
                      options={[
                        { value: "€", label: "€" },
                        { value: "PASS", label: "x PASS" },
                      ]}
                      small
                    />
                  </Column>
                </>
              )}
            </Row>
            <Row tpad>
              <Column>
                <DropDown
                  label="Versement des prestations"
                  value={context.state.ContratPrevoyance}
                  onChange={context.handleInputChange("ContratPrevoyance")}
                  options={[
                    { value: "indemnitaire", label: "Déduction du régime obligatoire" },
                    { value: "forfaitaire", label: "Complément du régime obligatoire" },
                  ]}
                  xlarge
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratPrevCondGen;
