import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { FaArrowAltCircleRight, FaEdit, FaFile, FaGlobe, FaIdBadge, FaPlusCircle, FaTrashAlt, FaUmbrella } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import ClientPageModal from "pages/extranet/client/client pager";
import Dialogs from "components/ui/dialogs/dialogs";
import ContratPrevoyancePagerBDD from "../AuditComplet/Prevoyance/ContratPrevoyance/ContratPrevoyancePagerBDD";

const BddContrat = (props) => {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);

  useEffect(() => {
    context.handleListeUserContratPrevoyance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <Button
                  onClick={() => {
                    context.handleNewUserContratPrevoyance();
                    setmodalOpen(true);
                  }}
                >
                  <span>Nouveau contrat</span>
                  <FaPlusCircle />
                </Button>
              </Column>
            </Row>
          </Column>
        </CardBox>
        <CardBox tpad>
          <Column fill>
            <Row tpad>
              <Column scroll fill>
                <table>
                  <thead>
                    <tr>
                      <th>Libellé</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.ListeContratPrevoyance?.map((Infos, index) => {
                      return (
                        <tr key={"ContratBB" + index}>
                          <td>{Infos.LibelleContrat}</td>
                          <td>
                            {Infos.ContratMAKOFormation === undefined && (
                              <>
                                <Button
                                  onClick={() => {
                                    context.handleModifyUserContratPrevoyance(Infos.IdContrat);
                                    setmodalOpen(true);
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  onClick={() => {
                                    Dialogs.confirm("Vous êtes sur le point de supprimer le contrat de la base de données. Voulez-vous continuer ?", "Suppression d'un contrat", () => {
                                      context.handleDeleteUserContratPrevoyance(Infos.IdContrat);
                                    });
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaTrashAlt className="delete" />
                                </Button>
                                {context.state.IdUser===2000 ||context.state.IdUser===2284 }
                                <Button
                                  onClick={() => {
                                    context.handlCopyUserContratPrevoyance(Infos.IdContrat);
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaFile />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>

      <Modal
        hideClose={true}
        width="80%"
        height="80%"
        title="Ajout d'un contrat de prévoyance existant"
        icon={<FaUmbrella />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ContratPrevoyancePagerBDD
          visible={modalOpen}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default BddContrat;
