import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import StateBox from "components/ui/controls/state-box/state-box";
import Pager from "components/ui/controls/pager/pager";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

function ParamImpotSociete(props) {
  const context = useContext(AppContext);
  return (
    <>
      <Pager
        pages={[
          {
            content: (
              <Column pad>
                <CardBox>
                  <Column fill>
                    <Row>
                      <StateBox label="Taux réduit d'impôt sur les sociétés (15%)" before xlarge inline value={context.state.TauxISReduit} onClick={context.handleTauxIS("TauxISReduit")} />
                    </Row>
                    <Row tpad>
                      <StateBox label="Taux normal d'impôt sur les sociétés" before xlarge inline value={context.state.TauxISNormal} onClick={context.handleTauxIS("TauxISNormal")} />
                    </Row>
                    <Row tpad>
                      <StateBox label="Exonération de l'impôt sur les sociétés" before xlarge inline value={context.state.TauxISZero} onClick={context.handleTauxIS("TauxISZero")} />
                    </Row>
                  </Column>
                </CardBox>
              </Column>
            ),
          },
        ]}
        page={0}
        navButtons={{
          validate: {
            onClick: () => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
}

export default ParamImpotSociete;
