import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Column from "components/ui/layout/column/column";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import ContratFacProModal from "pages/audit complet/simulation/contratfacultatifpro pager";
import RemExpressContratFacProModal from "pages/audit rémunération/contratfacultatifpro pager";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe } from "react-icons/fa";

function RemExpressRemunerationSalarie(props) {
  let { Resultat, Prenom, ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const [modalContrat, setModalContrat] = useState(false);
  const [modalDetailSalarie, setModalDetailSalarie] = useState(false);

  return (
    <>
      <CardBox className="blockRoundedForce" bpad>
        <table className="resultatRemExpress">
          <tbody>
            <tr>
              <td className="libelle">Budget rémunération {Prenom}</td>
              <td className="nouvelle">
                <TextField
                  iconBefore={
                    <Button icon>
                      <FaCalculator />
                    </Button>
                  }
                  value={context.state.RemExpressCoutRemuneration}
                  onChange={(e) => {
                    context.handleCalculRemExpress("cout_remuneration", "RemExpressCoutRemuneration", ResultatTNS, e);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  small
                />
              </td>
              <td className="extra">&nbsp;</td>
            </tr>
            <tr>
              <td className="libelle">Cotisations patronales</td>
              <td className="nouvelle pr4">
                <Button
                  className="editIconOptiRem"
                  onClick={() => {
                    setModalContrat(true);
                  }}
                  icon
                  style={{ display: "inline-block" }}
                >
                  <FaEdit id="contratFacProAuditRem"/>
                </Button>
                {applyFormat(context.state.RemExpressCotisationsPatronale, "uint", { thousands: " ", decimal: ",", unit: " €" })}
              </td>
            </tr>
            <tr>
              <td className="libelle">Salaire brut</td>
              <td className="nouvelle">
                <TextField
                  iconBefore={
                    <Button icon>
                      <FaCalculator />
                    </Button>
                  }
                  value={context.state.RemExpressRemBrute}
                  onChange={(e) => {
                    context.handleCalculRemExpress("salaire_brut", "RemExpressRemBrute", ResultatTNS, e);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  small
                />
              </td>
            </tr>
            <tr>
              <td className="libelle">Cotisations salariales</td>
              <td className="nouvelle pr4">{applyFormat(context.state.RemExpressCotisationsSalariale, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td className="libelle">Salaire net</td>
              <td className="nouvelle">
                <TextField
                  iconBefore={
                    <Button icon>
                      <FaCalculator />
                    </Button>
                  }
                  value={context.state.RemExpressRemNette}
                  onChange={(e) => {
                    context.handleCalculRemExpress("salaire_net", "RemExpressRemNette", ResultatTNS, e);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  small
                />
              </td>
            </tr>
            <tr>
              <td className="libelle">Salaire imposable</td>
              <td className="nouvelle">
                <TextField
                  iconBefore={
                    <Button icon>
                      <FaCalculator />
                    </Button>
                  }
                  value={context.state.RemExpressRemImposable}
                  onChange={(e) => {
                    context.handleCalculRemExpress("brut_imposable", "RemExpressRemImposable", ResultatTNS, e);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  small
                />
              </td>
              <td className="extra">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <Modal
          width="80%"
          height="80%"
          title="Situation professionnelle"
          icon={<FaGlobe />}
          visible={modalContrat}
          onClose={() => {
            context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "",  ResultatTNS, "");
            setModalContrat(false);
          }}
        >
          <RemExpressContratFacProModal
            Outil=""
            ClientConjoint={""}
            visible={modalContrat}
            onClose={() => {
              context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "", ResultatTNS, "");
              setModalContrat(false);
            }}
          />
        </Modal>

      </CardBox>
    </>
  );
}
export default RemExpressRemunerationSalarie;
