import React from "react";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

function RenteViagere(props) {
  const { Outil } = props;

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>Paramètres des rentes viagères à titre onéreux</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="Moins de 50 ans" VarName1={Outil + "IRDirigeantRenteMoins50"} VarName2="" />
                <LigneImpotDetaille Libelle="De 50 à 59 ans" VarName1={Outil + "IRDirigeantRente5059"} VarName2="" /> 
                <LigneImpotDetaille Libelle="De 60 à 69 ans" VarName1={Outil + "IRDirigeantRente6069"} VarName2="" />
                <LigneImpotDetaille Libelle="A partir de 70 ans" VarName1={Outil + "IRDirigeantRente70"} VarName2="" />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default RenteViagere;
