import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamImpotSocietePageModal from "pages/audit complet/situation professionnelle/modules/parametre impot societe modal";

const formeJuridique = [
  {
    value: "EURL",
    label: "EURL",
  },
  {
    value: "EIRL",
    label: "EIRL",
  },
  {
    value: "EI",
    label: "Entreprise individuelle",
  },
  {
    value: "SARL",
    label: "SARL",
  },
  {
    value: "SAS",
    label: "SAS",
  },
  {
    value: "SA",
    label: "SA",
  },
  {
    value: "SASU",
    label: "SASU",
  },
  {
    value: "SELARL",
    label: "SELARL",
  },
  {
    value: "SELARLU",
    label: "SELARLU",
  },
  {
    value: "SELAS",
    label: "SELAS",
  },
  {
    value: "SELASU",
    label: "SELASU",
  },
  {
    value: "EARL",
    label: "EARL",
  },
  {
    value: "SCEA",
    label: "SCEA",
  },
];

function Entreprise(props) {
  const context = useContext(AppContext);

  const displayFiscalite = () => {
    //A VIRER POUR METTRE L'EI A L'IS
    if (context.state.FormeJuridique === "EI") {
      return [
        {
          value: "IR",
          label: "Impôt sur le revenu",
        },
      ];
    }
    const arrFiscalite = ["EURL", "EIRL", "EI", "SELARLU", "EARL"];
    if (arrFiscalite.indexOf(context.state.FormeJuridique) > -1) {
      return [
        {
          value: "IS",
          label: "Impôt sur les sociétés",
        },
        {
          value: "IR",
          label: "Impôt sur le revenu",
        },
      ];
    } else {
      return [
        {
          value: "IS",
          label: "Impôt sur les sociétés",
        },
      ];
    }
  };
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column fill>
                <TextField label="Raison sociale" value={context.state.IdentiteEntreprise} onChange={context.handleInputChange("IdentiteEntreprise")} large  />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <DropDown
                  label="Forme juridique"
                  value={context.state.FormeJuridique}
                  options={formeJuridique}
                  onChange={(e) => {
                    context.handleChoixStatut("FormeJuridique",props.ClientConjoint, e);
                  }}
                  large
                />
              </Column>
              <Column hpad>
                <DropDown
                  label="Régime fiscal"
                  value={context.state.Fiscalite}
                  options={displayFiscalite()}
                  onChange={(e) => {
                    context.handleChoixStatut("Fiscalite",props.ClientConjoint, e);
                  }}
                  large
                />
              </Column>
              {context.state.Fiscalite === "IS" && (
                <Column style={{ alignSelf: "flex-end", marginBottom: "8px" }}>
                  <ParamImpotSocietePageModal />
                </Column>
              )}
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default Entreprise;
