import { FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Row from "components/ui/layout/row/row";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";

const StrategiePERDisponibleMadelin = (props) => {
  const { Year, ClientConjoint } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Modal
        width="750px"
        height="450px"
        title=" Disponible fiscal Madelin"
        icon={<FaGlobe />}
        visible={props.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column  fill >
                      <Row tpad>
                        <blockquote>Le renseignement de ces données vous permet d'avoir un calcul de disponible Madelin précis.</blockquote>
                        </Row>
                      <Row tpad>
                        <Column>
                          <TextField
                            label="Abondement PERCO"
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.StrategiePERDisponibleAbondementPERCO}
                            onChange={context.handleInputChange("StrategiePERDisponibleAbondementPERCO")}
                            normal
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                          />
                        </Column>
                      </Row>
                      <Row tpad>
                        <Column>
                          <TextField
                            label="Cotisation prévoyance"
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.StrategiePERDisponiblePrevoyanceMadelin}
                            onChange={context.handleInputChange("StrategiePERDisponiblePrevoyanceMadelin")}
                            normal
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                          />
                        </Column>
                        <Column>
                          <TextField
                            label="Cotisation Santé"
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.StrategiePERDisponibleSanteMadelin}
                            onChange={context.handleInputChange("StrategiePERDisponibleSanteMadelin")}
                            normal
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                props?.onClose?.();
                props?.CalculDisponible();
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default StrategiePERDisponibleMadelin;
