import { Alignments, classFromProperties, getOptionalProps, Paddings, Scrolls } from '../../ui-helpers';
import './column.css';

/**
 * Column component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Column component.
 */
 const Column = (props) => {
    
    // Excluded properties for automatic properties propagation
    const Excluded = [].concat(Alignments, Paddings, Scrolls);

    // Generate component
    return (
        <div 
            className={classFromProperties(props, 'col', Alignments, Paddings, Scrolls)}
            {...getOptionalProps(props, Excluded)}
        >
            {props.children}
        </div>
    );
}

export default Column;
