import React from "react";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

const PensionRetraiteRente = (props) => {
  const { Outil } = props;

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th></th>
                  <th><div className="normal text-center">Dirigeant</div></th>
                  <th><div className="normal text-center">Conjoint</div> </th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="Pensions, retraites, rentes" VarName1={Outil + "IRDirigeantRetraite"} VarName2={Outil + "IRConjointRetraite"} />
                <LigneImpotDetaille Libelle="Pension d'invalidité" VarName1={Outil + "IRDirigeantInvalidite"} VarName2={Outil + "IRConjointInvalidite"} />
                <LigneImpotDetaille Libelle="Pensions alimentaires perçues" VarName1={Outil + "IRDirigeantAlimentaire"} VarName2={Outil + "IRConjointAlimentaire"} />
                <LigneImpotDetaille Libelle="Pensions de retraite en capital taxables à 7,5%" VarName1={Outil + "IRDirigeantCapital"} VarName2={Outil + "IRConjointCapital"} />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default PensionRetraiteRente;
