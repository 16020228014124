import { AppUIContext } from '../../../../providers/app-ui-context';
import Column from '../column/column';
import Row from '../row/row';
import './main-menu.css';

/**
 * MainMenu component.
 * @param {ReactDOM.props} props Component properties.
 * @returns MainMenu component.
 */
const MainMenu = (props) => {

    return (
        <Column className="main-menu" center>
            <Row className="main-menu-logo-box">
                <AppUIContext.Consumer>
                    {(ui) =>
                        <img className="main-menu-logo" src={ui.logo} alt={ui.name + ' ' + ui.slogan} />
                    }
                </AppUIContext.Consumer>
            </Row>
            <Row className="main-menu-icons fill">
                    {props?.children}
            </Row>
        </Column>
    );
}

export default MainMenu;