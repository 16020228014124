import { useEffect, useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './color-picker.css';

/**
 * Color picker component.
 * @param {ReactDOM.props} props Component properties
 * @returns Generated component.
 */
const ColorPicker = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'label', 'labelStyle', 'value', 'onChange', 'inline', 'before', 'disabled', 'readOnly'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId());
    const [ value, setValue ] = useState(props?.value);
    const [ isDisabled, setDisabled ] = useState(props?.disabled !== undefined ? props?.disabled : false);
    const [ isReadOnly, setReadOnly ] = useState(props?.readOnly !== undefined ? props?.readOnly : false);
    
    /**
     * Handles color picker change event.
     * @param {ChangeEvent} evt Change event.
     */
    const handleChange = (evt) => {
        // Check readonly
        if (props?.readOnly) return;

        // Update value
        props?.onChange?.(evt, evt.target.value);
    }

    /**
     * Handles value change from properties.
     */
    useEffect(() => {
        setValue(props?.value);
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setReadOnly(props?.readOnly !== undefined ? props?.readOnly : false);
    }, [props?.value, props?.disabled, props?.readOnly]);

    // Render component
    return (
        <div 
            id={id}
            className={classFromProperties(
                props,
                'color-picker' + (isDisabled ? ' disabled' : ''),
                Alignments,
                Paddings,
                ['inline', 'before']
            )}
            {...getOptionalProps(props, Excluded)}
            tabIndex='-1'
        >
            {props?.label && (!props?.inline || props?.before) ? <label style={props?.labelStyle}>{props.label}</label> : <></>}
            <input 
                className={classFromProperties(
                    props,
                    'color-picker-input',
                    Sizes
                )}
                type="color"
                value={value} onChange={handleChange}
                readOnly={isReadOnly}
            />
            {props?.label && props?.inline && !props?.before ? <label style={props?.labelStyle}>{props.label}</label> : <></>}
        </div>
    )
}

export default ColorPicker;