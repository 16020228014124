import { Alignments, classFromProperties, getOptionalProps, Paddings } from '../../ui-helpers';
import './data-grid.css';

// Register window resize event handler for data grids
window.addEventListener('resize', () => {

    // Get data grids
    const grids = document.querySelectorAll('.data-grid');
    grids.forEach((grid) => {

        // Get elements
        const firstRow = grid.querySelector('tbody tr');
        const headers = [...grid.querySelectorAll('thead tr th')];
        const cells = [...firstRow.querySelectorAll('td')];

        // Reset cells fixed sizes
        cells.forEach((cell) => {
            cell.style.width = cell.hasAttribute('width')
                ? cell.getAttribute('width')
                : 'auto';
        })

        // Get sizes
        const tableSize = grid.clientWidth;
        const firstRowSize = firstRow.clientWidth - 8;

        // Collect columns width
        const colWidth = cells.map((cell) => {
            return tableSize * (cell.clientWidth / firstRowSize);
        });

        // Apply column width to headers
        headers.forEach((header, i) => {
            // const computedStyle = getComputedStyle(header);
            const elementWidth = colWidth[i] // - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
            header.style.width = `${elementWidth}px`;
        });

        // Apply column widths to cells
        cells.forEach((cell, i) => {
            // const computedStyle = getComputedStyle(cell);
            const elementWidth = colWidth[i] + (8 / cells.length);; // - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
            cell.style.width = `${elementWidth}px`;
        });

    })
});

/**
 * Generates a data grid component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const DataGrid = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['head', 'body', 'bodyStyle'].concat(Alignments, Paddings);

    // Call resize handler once
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 1);

    // Render component
    return (
        <table
            className={classFromProperties(props, 'data-grid', Alignments, Paddings)}
            {...getOptionalProps(props, Excluded)}
        >
            <thead>
                {props?.head}
            </thead>
            <tbody style={props?.bodyStyle}>
                {props?.body}
            </tbody>
        </table>
    )
}

export default DataGrid;