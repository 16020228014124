import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import AmCharts from "@amcharts/amcharts3-react";

const EffortEparne = (props) => {
  const context = useContext(AppContext);
  var config = {
    type: "serial",
    categoryField: "category",
    columnSpacing: 30,
    columnWidth: 0.51,
    angle: 20,
    depth3D: 20,
    startDuration: 1,
    decimalSeparator: ",",
    // theme: "default",
    thousandsSeparator: " ",
    // legend: {
    //   horizontalGap: 10,
    //   useGraphSettings: true,
    //   markerSize: 10
    // },
    dataProvider: props.data,
    valueAxes: [
      {
        id: "ValueAxis-1",
        stackType: "regular",
        totalText: "",
        unit: " €",
        color: "",
        gridAlpha: 0,
        gridColor: "#CCCCCC",
        title: "",
        titleRotation: 1,
      },
    ],
    graphs: [
      {
        balloonText: "<b>[[category]]</b><br><span style='font-size:14px'> <b>[[value]] €</b></span>",
        color: "#FFFFFF",
        fillAlphas: 1,
        fillColorsField: "color",
        labelText: "[[value]] €",
        lineThickness: 0,
        title: "Versements",
        type: "column",
        valueField: "value1",
      },
    ],

    categoryAxis: {
      gridAlpha: 0,
      gridColor: "#CCCCCC",
    },
  };

  return (
    <>
      <AmCharts.React
        className="Graph"
        style={{
          width: "700px",
          height: "330px",
        }}
        options={config}
      />
    </>
  );
};

export default EffortEparne;
