import Modal from "components/ui/modal/modal";
import { FaFileAlt } from "react-icons/fa";
import ContratFraisProPager from "components/app/AuditComplet/Prevoyance/ContratFraisPro/ContratFraisProPager";

const  ContratFraisProPageModal=(props)=> {
  return (
    <>
      <Modal
       hideClose={true}
        width="80%"
        height="80%"
        title="Ajout d'un contrat de frais professionnels existant"
        icon={<FaFileAlt/>}
        visible={props?.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <ContratFraisProPager
         AccessRemExpress={props.AccessRemExpress}
        visible={props?.visible}
        Outil={props.Outil}
        ClientConjoint={props.ClientConjoint}
          onClose={() => {
            props?.onClose?.();
          }}
        />
      </Modal>
    </>
  );
}

export default ContratFraisProPageModal;
