import { Scheduler, SchedulerEntry } from "./scheduler";


export function majAuditComplet(RemExpertProvider,saveDossier){
    const scheduler = new Scheduler(() => { RemExpertProvider.handleValueChange('v2',false) }, (err) => { alert(err) });
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("situationPro")!==null }, () => { document.getElementById("situationPro").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("editSituationPro")!==null }, () => { document.getElementById("editSituationPro").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratsFacPro")!==null }, () => { document.getElementById("contratsFacPro").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return true }, () => {
        const contratsPro=[];
        document.querySelectorAll('.editContratFacPro').forEach(contrat=>{
            contratsPro.push(contrat.id);
        })
      contratsPro.forEach((id,index)=>{
        scheduler.push(new SchedulerEntry(2000, () => { return true }, () => {  document.getElementById(id).parentElement.click();}));
        const typeContrat=    document.getElementById(id).closest("tr").querySelector("td").innerText;
        // eslint-disable-next-line default-case
        switch (typeContrat){
          case "Retraite":
            scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacRetraiteEntreprise_validate").parentElement.click();}));
          break;
          case "Prévoyance":
            scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
          break;
          case "Santé":
            scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
          break;
          case "Frais professionnels":
            scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
          break;

        }
      })
      scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("situationProPager")!==null }, () => { document.getElementById("situationProPager_validate").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("retraite")!==null }, () => { document.getElementById("retraite").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("paramRetraite")!==null }, () => { document.getElementById("paramRetraite").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratFacPerso")!==null }, () => { document.getElementById("contratFacPerso").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return true }, () => { 
        const contratsPerso=[];
        document.querySelectorAll('.editContratFacPerso').forEach(contrat=>{
            contratsPerso.push(contrat.id);
        })
        contratsPerso.forEach((id,index)=>{
          
          scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById(id).parentElement.click();}));
          const typeContrat=   document.getElementById(id).closest("tr").querySelector("td").innerText;
            // eslint-disable-next-line default-case
            switch (typeContrat){
              case "Retraite":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacRetraitePerso_validate").parentElement.click();}));
              break;
              case "Prévoyance":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
              break;
              case "Santé":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
              break;
              case "Frais professionnels":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
              break;

            }

        })
        scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("paramRetraitePager")!==null }, () => { document.getElementById("paramRetraitePager_validate").parentElement.click(); })); 
        scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("resultat")!==null }, () => { document.getElementById("resultat").parentElement.click(); })); 
       if(saveDossier===true){
         scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveModal")!==null }, () => { document.getElementById("saveModal").parentElement.click(); })); 
         scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveDossier")!==null }, () => { document.getElementById("saveDossier").parentElement.click(); })); 
        }
        scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("DossierAuditCompletPage")!==null }, () => { document.getElementById("DossierAuditCompletPage").parentElement.click(); })); 
      })); 
      
    }));
    scheduler.start();
}
export function majAuditRetraite(RemExpertProvider,saveDossier,displayOngletDossier){
  console.log('PTR',RemExpertProvider)
    const scheduler = new Scheduler(() => { RemExpertProvider.handleValueChange('v2',false) }, (err) => { alert(err) });
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("carriere")!==null }, () => { document.getElementById("carriere").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("paramRetraite")!==null }, () => { document.getElementById("paramRetraite").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratFacPerso")!==null }, () => { document.getElementById("contratFacPerso").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return true }, () => { 
        const contratsPerso=[];
        document.querySelectorAll('.editContratFacPerso').forEach(contrat=>{
            contratsPerso.push(contrat.id);
        })
        contratsPerso.forEach((id,index)=>{
          
          scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById(id).parentElement.click();}));
          const typeContrat=   document.getElementById(id).closest("tr").querySelector("td").innerText;
            // eslint-disable-next-line default-case
            switch (typeContrat){
              case "Retraite":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacRetraitePerso_validate").parentElement.click();}));
              break;
              case "Prévoyance":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
              break;
              case "Santé":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
              break;
              case "Frais professionnels":
                scheduler.push(new SchedulerEntry(2000, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
              break;

            }

        })
        scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("paramRetraitePager")!==null }, () => { document.getElementById("paramRetraitePager_validate").parentElement.click(); })); 
        scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("resultatRetraite")!==null }, () => { document.getElementById("resultatRetraite").parentElement.click(); })); 
        if(saveDossier===true){
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveModal")!==null }, () => { document.getElementById("saveModal").parentElement.click(); })); 
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveDossier")!==null }, () => { document.getElementById("saveDossier").parentElement.click(); })); 
        } 
        if(displayOngletDossier===true){
          scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("DossierAuditRetraitePage")!==null }, () => { document.getElementById("DossierAuditRetraitePage").parentElement.click(); })); 
        }
        })); 
    scheduler.start();
}


export function majAuditPrevoyance(RemExpertProvider,saveDossier){
    const scheduler = new Scheduler(() => {RemExpertProvider.handleValueChange('v2',false)  }, (err) => { alert(err) });
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratExistants")!==null }, () => { document.getElementById("contratExistants").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return true }, () => {
           const contratsPro=[];
        document.querySelectorAll('.editContratFacPro').forEach(contrat=>{
            contratsPro.push(contrat.id);
        })
      contratsPro.forEach((id,index)=>{
        scheduler.push(new SchedulerEntry(100, () => { return true }, () => { document.getElementById(id).parentElement.click();}));
        const typeContrat=    document.getElementById(id).closest("tr").querySelector("td").innerText;
        // eslint-disable-next-line default-case
        switch (typeContrat){
          case "Retraite":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacRetraiteEntreprise_validate").parentElement.click();}));
          break;
          case "Prévoyance":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
          break;
          case "Santé":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
          break;
          case "Frais professionnels":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
          break;

        }
      })
      scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("contratsPreco")!==null }, () => { document.getElementById("contratsPreco").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return true }, () => { 
        const contratPreco=[];
        document.querySelectorAll('.editContratPreco').forEach(contrat=>{
            contratPreco.push(contrat.id);
        })

        contratPreco.forEach((id,index)=>{
          
          scheduler.push(new SchedulerEntry(100, () => { return true }, () => {  document.getElementById(id).parentElement.click();}));
          const typeContrat=    document.getElementById(id).closest("tr").querySelector("td").innerText;
            // eslint-disable-next-line default-case
            switch (typeContrat){
              case "Retraite":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacRetraiteEntreprise_validate").parentElement.click();}));
              break;
              case "Prévoyance":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
              break;
              case "Santé":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
              break;
              case "Frais professionnels":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
              break;

            }

        })
        scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("resultatPrevoyance")!==null }, () => { document.getElementById("resultatPrevoyance").parentElement.click(); })); 
        if(saveDossier===true){
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveModal")!==null }, () => { document.getElementById("saveModal").parentElement.click(); })); 
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveDossier")!==null }, () => { document.getElementById("saveDossier").parentElement.click(); })); 
        }
        scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("DossierAuditPrevoyancePage")!==null }, () => { document.getElementById("DossierAuditPrevoyancePage").parentElement.click(); })); 
      })); 
      
    }));
    scheduler.start();
}
export function majAuditRemuneration(RemExpertProvider,saveDossier,displayOngletDossier){
    const scheduler = new Scheduler(() => {RemExpertProvider.handleValueChange('v2',false) }, (err) => { alert(err) });
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("Revenus")!==null }, () => { document.getElementById("Revenus").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratFacProAuditRem")!==null }, () => { document.getElementById("contratFacProAuditRem").parentElement.click(); }));
    scheduler.push(new SchedulerEntry(100, () => { return true }, () => {
        const contratsPro=[];
        document.querySelectorAll('.editContratFacPro').forEach(contrat=>{
            contratsPro.push(contrat.id);
        })
    contratsPro.forEach((id,index)=>{
        scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById(id).parentElement.click();}));
        const typeContrat=    document.getElementById(id).closest("tr").querySelector("td").innerText;
        // eslint-disable-next-line default-case
        switch (typeContrat){
          case "Retraite":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacRetraiteEntreprise_validate").parentElement.click();}));
          break;
          case "Prévoyance":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
          break;
          case "Santé":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
          break;
          case "Frais professionnels":
            scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
          break;

        }
      })
      scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("contratFacProAuditRemPager")!==null }, () => { document.getElementById("contratFacProAuditRemPager_validate").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return document.getElementById("contratFacPersoAuditRem")!==null }, () => { document.getElementById("contratFacPersoAuditRem").parentElement.click(); })); 
      scheduler.push(new SchedulerEntry(100, () => { return true }, () => { 

        const contratsPerso=[];
        document.querySelectorAll('.editContratFacPerso').forEach(contrat=>{
            contratsPerso.push(contrat.id);
        })
        contratsPerso.forEach((id,index)=>{
          
          scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById(id).parentElement.click();}));
          const typeContrat=   document.getElementById(id).closest("tr").querySelector("td").innerText;
            // eslint-disable-next-line default-case
            switch (typeContrat){
              case "Retraite":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacRetraitePerso_validate").parentElement.click();}));
              break;
              case "Prévoyance":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacPrevoyance_validate").parentElement.click();}));
              break;
              case "Santé":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacSante_validate").parentElement.click();}));
              break;
              case "Frais professionnels":
                scheduler.push(new SchedulerEntry(500, () => { return true }, () => { document.getElementById("contratFacFraisPro_validate").parentElement.click();}));
              break;

            }

        })
        scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("contratFacPersoAuditRemPager")!==null }, () => { document.getElementById("contratFacPersoAuditRemPager_validate").parentElement.click(); })); 
        if(saveDossier===true){
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveModal")!==null }, () => { document.getElementById("saveModal").parentElement.click(); })); 
          scheduler.push(new SchedulerEntry(500, () => { return document.getElementById("saveDossier")!==null }, () => { document.getElementById("saveDossier").parentElement.click(); })); 
        }
        if(displayOngletDossier===true){
        scheduler.push(new SchedulerEntry(2000, () => { return document.getElementById("DossierAuditRemunerationPage")!==null }, () => { document.getElementById("DossierAuditRemunerationPage").parentElement.click(); })); 
        }
      })); 
    }));
    scheduler.start();
}