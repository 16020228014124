import React from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";

const LigneDetailFicheDePaie = ({ Libelle, Assiette, TauxSalariale, CotisationSalariale, TauxPatronale, CotisationPatronale }) => {
  if (CotisationSalariale === 0 && CotisationPatronale === 0) {
    return null;
  } else {
    return (
      <tr>
        <td>{Libelle}</td>
        <td className="text-center">{Assiette === "" ? "" : applyFormat(Math.max(0, Assiette), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
        <td className="text-center">{TauxSalariale === "" ? "" : applyFormat(Math.max(0, TauxSalariale), "ufloat", { thousands: " ", decimal: ",", unit: "%", precision: 3 })}</td>
        <td className="text-right">{applyFormat(Math.max(0, CotisationSalariale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
        <td className="text-center">{TauxPatronale === "" ? "" : applyFormat(Math.max(0, TauxPatronale), "ufloat", { thousands: " ", decimal: ",", unit: "%", precision: 3 })}</td>
        <td className="text-right">{CotisationPatronale === "" ? "" : applyFormat(Math.max(0, CotisationPatronale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      </tr>
    );
  }
};

const DetailFicheDePaie = ({ DetailFicheDePaie }) => {
  return (
    <Column fill>
      <CardBox bpad>
        <Column fill>
          <Row>
            {DetailFicheDePaie !== undefined && (
              <table className="ficheDePaie">
                <tbody>
                  {/* <tr>
                  <td colSpan="3">Total charges patronales</td>
                  <td className="text-center">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.TotalChargesPatronale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr> */}
                  <tr>
                    <td colSpan="3" className="light-title">
                      SALAIRE BRUT
                    </td>
                    <td className="text-right light-title">
                      {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.AssietteSalaireBrut), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Salaire de base</td>
                    <td className="text-right">
                      {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.AssietteSalaireBrut), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} style={{ backgroundColor: "white" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Article 82 / PER individuel</td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.InfoCotisation82), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} style={{ backgroundColor: "white" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Avantages en nature</td>
                    <td className="text-right">
                      {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.InfoAvantageNature), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} style={{ backgroundColor: "white" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Dépassement du disponible social 83</td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.DepassementSocial83), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} style={{ backgroundColor: "white" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="light-title">
                      BASE SOCIALE ANNUELLE
                    </td>
                    <td className="text-right light-title">
                      {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>

                  <tr className="dark-title">
                    <th colSpan="2"></th>
                    <th colSpan="2" className="text-center">
                      Salarié
                    </th>
                    <th colSpan="2" className="text-center">
                      Employeur
                    </th>
                  </tr>
                  <tr>
                    <td className="bg-white"></td>
                    <td className="text-center light-title">Assiette</td>
                    <td className="text-center light-title">Taux</td>
                    <td className="text-right light-title">Cotisations</td>
                    <td className="text-center light-title">Taux</td>
                    <td className="text-right light-title">Cotisations</td>
                  </tr>
                  <LigneDetailFicheDePaie
                    Libelle={"Assurance maladie"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.assurance_maladie}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.AssuranceMaladieSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.assurance_maladie}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AssuranceMaladiePatronale}
                  />

                  <LigneDetailFicheDePaie
                    Libelle={"Allocations familiales"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.allocation_familiale}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AllocationFamilialePatronale}
                  />

                  <LigneDetailFicheDePaie
                    Libelle={"Aide au logement"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteAideAuLogement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.aide_logement}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AideLogementTousPatronale}
                  />

                  <LigneDetailFicheDePaie
                    Libelle={"Assurance vieillesse - veuvage"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.assurance_vieillesse_veuvage}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.AssuranceVieillesseVeuvageSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.assurance_vieillesse_veuvage}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AssuranceVieillesseVeuvagePatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Assurance vieillesse plafonnée"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassementMinPass}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.assurance_vieillesse_plafonnee}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.AssuranceVieillessePlafonneeSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.assurance_vieillesse_plafonnee}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AssuranceVieillessePlafonneePatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Accident du travail"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.accident_travail}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AccidentTravailPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Pénibilité de base"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.penibilite}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.PenibilitePatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Construction"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.construction}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ConstructionPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Apprentissage"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.apprentissage}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ApprentissagePatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Formation"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.formation}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.FormationPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Transport"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.transport}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.TransportPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Contribution syndicale"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteSbDepassement}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.contribution_syndicale}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ContributionSyndicalePatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Forfait social prévoyance"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteForfaitSocialPrevoyance}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.forfait_social_prevoyance83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ForfaitSocialPrevoyance83Patronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Forfait social sur article 83 retraite"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteForfaitSocialRetraite}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.forfait_social_retraite83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ForfaitSocialRetraite83Patronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite AGIRC-ARRCO T1"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteAgircArrco}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.retraite_complementaire_agircarrco}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.RetraiteAgircArrcoSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.retraite_complementaire_agircarrco}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.RetraiteAgircArrcoPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite AGIRC-ARRCO T2"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteAgircArrcoT2}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.retraite_complementaire_agircarrcoT2}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.RetraiteAgircArrcoSalarialeT2}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.retraite_complementaire_agircarrcoT2}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.RetraiteAgircArrcoPatronaleT2}
                  />

                  {DetailFicheDePaie.TabCotisation?.CavecSalarieSalariale + DetailFicheDePaie.TabCotisation?.CavecSalariePatronale > 0 && (
                    <tr>
                      <td>Retraite complémentaire CAVEC</td>
                      <td className="text-center">-</td>
                      <td className="text-center">-</td>
                      <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabCotisation?.CavecSalarieSalariale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">-</td>
                      <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabCotisation?.CavecSalariePatronale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                  )}
                  {DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseForfaitaireSalariale + DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseForfaitairePatronale > 0 && (
                    <>
                      <tr>
                        <td>Retraite de base forfaitaire CNBF</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-right">
                          {applyFormat(Math.max(0, DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseForfaitaireSalariale), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                        </td>
                        <td className="text-center">-</td>
                        <td className="text-right">
                          {applyFormat(Math.max(0, DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseForfaitairePatronale), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                        </td>
                      </tr>
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite de base proportionnelle CNBF"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteBaseProportionnelleSalarie}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteBaseProportionnelle}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseProportionnelleSalariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteBaseProportionnelle}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteBaseProportionnellePatronale}
                      />
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite complémentaire CNBF Tranche 1"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteComplementaire1}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteComplementaire1}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire1Salariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteComplementaire1}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire1Patronale}
                      />
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite complémentaire CNBF Tranche 2"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteComplementaire2}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteComplementaire2}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire2Salariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteComplementaire2}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire2Patronale}
                      />
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite complémentaire CNBF Tranche 3"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteComplementaire3}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteComplementaire3}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire3Salariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteComplementaire3}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire3Patronale}
                      />
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite complémentaire CNBF Tranche 4"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteComplementaire4}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteComplementaire4}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire4Salariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteComplementaire4}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire4Patronale}
                      />
                      <LigneDetailFicheDePaie
                        Libelle={"Retraite complémentaire CNBF Tranche 5"}
                        Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCnbfRetraiteComplementaire5}
                        TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.CnbfRetraiteComplementaire5}
                        CotisationSalariale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire5Salariale}
                        TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.CnbfRetraiteComplementaire5}
                        CotisationPatronale={DetailFicheDePaie.TabCotisation?.CnbfRetraiteComplementaire5Patronale}
                      />
                    </>
                  )}
                  <LigneDetailFicheDePaie
                    Libelle={"APEC"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteApec}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.apec}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.ApecSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.apec}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ApecPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"CET"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCet}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.cet}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.CetSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.cet}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.CetPatronale}
                  />

                  <LigneDetailFicheDePaie
                    Libelle={"CEG T1"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteAgircArrco}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.ceg}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.CegSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.ceg}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.CegPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"CEG T2"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteAgircArrcoT2}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.cegT2}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.CegSalarialeT2}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.cegT2}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.CegPatronaleT2}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Assurance chômage TA"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheA}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.assurance_chomageTA}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.ChomageTASalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.assurance_chomageTA}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ChomageTAPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Assurance chômage TB"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheB}
                    TauxSalariale={DetailFicheDePaie.TabTauxSalariale?.assurance_chomageTB}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation?.ChomageTBSalariale}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.assurance_chomageTB}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.ChomageTBPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"AGS"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteApec}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.ags}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.AgsPatronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Prévoyance complémentaire 83 TA"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheA83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheA_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheA_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheA_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheA_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Prévoyance complémentaire 83 TB"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheB83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheB_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheB_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheB_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheB_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Prévoyance complémentaire 83 TC"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheC83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheC_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheC_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheC_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheC_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Prévoyance complémentaire 83 TD"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheD83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheD_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheD_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_prevoyance_trancheD_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.prevoyance_trancheD_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Frais de santé 83"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteFraisSante}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_sante_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.sante_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_sante_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.sante_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite supplémentaire 83 TA"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheA83Retraite}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheA_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.retraite_trancheA_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheA_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.retraite_trancheA_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite supplémentaire 83 TB"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheB83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheB_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.retraite_trancheB_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheB_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.retraite_trancheB_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite supplémentaire 83 TC"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheC83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheC_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.retraite_trancheC_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheC_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.retraite_trancheC_patronale83}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Retraite supplémentaire 83 TD"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTrancheD83}
                    TauxSalariale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheD_salarie83}
                    CotisationSalariale={DetailFicheDePaie.TabCotisation83?.retraite_trancheD_salarie83}
                    TauxPatronale={DetailFicheDePaie.TabTaux83?.taux_retraite_trancheD_patronale83}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation83?.retraite_trancheD_patronale83}
                  />

                  <LigneDetailFicheDePaie
                    Libelle={"Taxe sur les salaires T1"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTaxeSalaireT1}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.taxe_salaireT1}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.TaxeSalaireT1Patronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Taxe sur les salaires T2"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTaxeSalaireT2}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.taxe_salaireT2}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.TaxeSalaireT2Patronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Taxe sur les salaires T3"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTaxeSalaireT3}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.taxe_salaireT3}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.TaxeSalaireT3Patronale}
                  />
                  <LigneDetailFicheDePaie
                    Libelle={"Taxe sur les salaires T4"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteTaxeSalaireT4}
                    TauxSalariale={0}
                    CotisationSalariale={0}
                    TauxPatronale={DetailFicheDePaie.TabTauxPatronale?.taxe_salaireT4}
                    CotisationPatronale={DetailFicheDePaie.TabCotisation?.TaxeSalaireT4Patronale}
                  />
                  {DetailFicheDePaie.TabCotisationsSalarieManuelle?.map((CotisationManuelle) => (
                         <tr>
                         <td>{CotisationManuelle.Libelle}</td>
                         <td className="text-center">{CotisationManuelle.Assiette === "" ? "" : applyFormat(Math.max(0, CotisationManuelle.Assiette), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                         <td className="text-center">{CotisationManuelle.TauxSalariale === "" ? "" : applyFormat(Math.max(0, CotisationManuelle.TauxSalariale), "ufloat", { thousands: " ", decimal: ",", unit: "%", precision: 3 })}</td>
                         <td className="text-right">{CotisationManuelle.CotisationSalariale === "" ? "" :applyFormat( CotisationManuelle.CotisationSalariale, "int", { thousands: " ", decimal: ",", unit: " €" })}</td>
                         <td className="text-center">{CotisationManuelle.TauxPatronale === "" ? "" : applyFormat(Math.max(0, CotisationManuelle.TauxPatronale), "ufloat", { thousands: " ", decimal: ",", unit: "%", precision: 3 })}</td>
                         <td className="text-right">{CotisationManuelle.CotisationPatronale === "" ? "" : applyFormat(CotisationManuelle.CotisationPatronale, "int", { thousands: " ", decimal: ",", unit: " €" })}</td>
                       </tr>
                  ))}

                  <LigneDetailFicheDePaie
                    Libelle={"CSG déductible"}
                    Assiette={DetailFicheDePaie.AssietteFicheDePaie?.AssietteCsg}
                    TauxSalariale={DetailFicheDePaie.TabRecap?.TauxCsgDeductible}
                    CotisationSalariale={DetailFicheDePaie.TabRecap?.CsgDeductible}
                    TauxPatronale={""}
                    CotisationPatronale={""}
                  />
                  <tr>
                    <td colSpan="3" style={{ fontWeight: "bold" }}>
                      Total charges déductibles
                    </td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.TotalChargeDeductible), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td></td>
                    <td className="text-right">
                      {applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.ChargesPatronaleSansReduction), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                  </tr>
                  <tr>
                    <td>CSG-CRDS non déductible</td>
                    <td className="text-center">{applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.AssietteCsg), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="text-center">
                      {applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.TauxCsgNonDeductible), "ufloat", { thousands: " ", decimal: ",", unit: "%", precision: 3 })}
                    </td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.CsgNonDeductible), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={2}></td>
                  </tr>

                  {DetailFicheDePaie.TabCotisation?.CsgESPrimes !== 0 && (
                    <tr>
                      <td>CSG-CRDS non déductible sur épargne investie</td>
                      <td className="text-center">
                        {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.AssietteCsgESPrimes), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center">
                        {applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.TauxCsgDeductible + DetailFicheDePaie.TabRecap?.TauxCsgNonDeductible), "ufloat", {
                          thousands: " ",
                          decimal: ",",
                          unit: "%",
                          precision: 3,
                        })}
                      </td>
                      <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabCotisation?.CsgESPrimes), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td colSpan={2}></td>
                    </tr>
                  )}
                  {DetailFicheDePaie.TabRecap?.ReductionGenerale !== 0 && (
                    <tr>
                      <td colSpan={5}>Réduction générale</td>
                      <td className="text-right">-{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.ReductionGenerale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="3" className="light-title">
                      SALAIRE NET A PAYER
                    </td>
                    <td className="text-right light-title">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.RecapSalaireNet), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Salaire imposable avant dépassement</td>
                    <td className="text-right">
                      {applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.SalaireImposableAvantDepassement), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Réintégration article 82 / PER individuel</td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.InfoCotisation82), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Réintégration avantages en nature</td>
                    <td className="text-right">
                      {applyFormat(Math.max(0, DetailFicheDePaie.AssietteFicheDePaie?.InfoAvantageNature), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Part patronale de la complémentaire santé</td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.RecapSantePatronale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Dépassement du disponible fiscal 83</td>
                    <td className="text-right">{applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.DepassementFiscal83), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="light-title">
                      SALAIRE NET FISCAL
                    </td>
                    <td className="text-right light-title">
                      {applyFormat(Math.max(0, DetailFicheDePaie.TabRecap?.SalaireNetFiscale), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td colSpan={4} className="bg-white"></td>
                  </tr>
                  {/*
               
                <tr>
                  <td colSpan="3"></td>
                  <td className="text-right">{<NumberFormat value={DetailFicheDePaie.TabRecap?.RecapSantePatronale} thousandSeparator={" "} suffix={" €"} displayType={"text"} />}</td>
                </tr>
                <tr>
                  <td colSpan="3">Dépassement du disponible fiscal 83</td>
                  <td className="text-right">{<NumberFormat value={DetailFicheDePaie.TabRecap?.DepassementFiscal83} thousandSeparator={" "} suffix={" €"} displayType={"text"} />}</td>
                </tr>
                <tr className="bg-primary text-white">
                  <td colSpan="3"> SALAIRE NET FISCAL</td>
                  <td className="text-right">{<NumberFormat value={DetailFicheDePaie.TabRecap?.SalaireNetFiscale} thousandSeparator={" "} suffix={" €"} displayType={"text"} />}</td>
                </tr> */}
                </tbody>
              </table>
            )}
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailFicheDePaie;
