import React, { useEffect, useState } from "react";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import { AppContext } from "components/app/RemExprertProvider";
import { useContext } from "react";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync } from "react-icons/fa";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";

const DeductionEpargne = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const { Outil } = props;
  let Annee = new Date().getFullYear();
  const [PlafondTotalClient, SetPlafondTotalClient] = useState(0);
  const [PlafondTotalConjoint, SetPlafondTotalConjoint] = useState(0);

  const handleCalculPlafondPerp = () => {
    let PlafondTotalCotisationPerpDirigeant = 0;
    let PlafondTotalCotisationPerpConjoint = 0;

    PlafondTotalCotisationPerpDirigeant = Math.max(
      0,
      Number(context.state[Outil + "IRDirigeantPlafondNM1"]) +
        Number(context.state[Outil + "IRDirigeantPlafond1"]) +
        Number(context.state[Outil + "IRDirigeantPlafond2"]) +
        Number(context.state[Outil + "IRDirigeantPlafond3"])
    );

    PlafondTotalCotisationPerpConjoint = Math.max(
      0,
      Number(context.state[Outil + "IRConjointPlafondNM1"]) +
        Number(context.state[Outil + "IRConjointPlafond1"]) +
        Number(context.state[Outil + "IRConjointPlafond2"]) +
        Number(context.state[Outil + "IRConjointPlafond3"])
    );

    SetPlafondTotalClient(PlafondTotalCotisationPerpDirigeant);
    SetPlafondTotalConjoint(PlafondTotalCotisationPerpConjoint);
  };

  useEffect(() => {
    handleCalculPlafondPerp();
  });

  useEffect(() => {
    // if (context.state.SetIRDirigeantPlafondNM1 === false) {

    const PeriodeClient = context.state[Outil + "PeriodeActuelle"][0];
    const PeriodeConjoint = context.state[Outil + "PeriodeActuelleConjoint"] === undefined ? undefined : context.state[Outil + "PeriodeActuelleConjoint"][0];
    let BrutImposableClient = 0;
    let BrutImposableConjoint = 0;
    if (Outil === "OptiRem") {
      BrutImposableClient += Number(ResultatTNS.state.OptiRemRemuneration?.BrutImposable);

      if (PeriodeConjoint !== undefined) {
        BrutImposableConjoint += Number(ResultatTNS.state.OptiRemRemunerationConjoint?.BrutImposable);
      } else if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun") {
        BrutImposableConjoint += Number(ResultatTNS.state.OptiRemRemunerationConjoint?.BrutImposable);
      }
    } else {
      if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
        BrutImposableClient += Number(ResultatTNS.state.RemunerationTNS.BrutImposable);
      } else {
        BrutImposableClient += Number(ResultatSalarie.state["RemunerationSalarie"].BrutImposable);
      }

      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
      } else {
        BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
      }

      if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun") {
        if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
          BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
        } else {
          BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
        }
      }
    }

    if (PeriodeClient !== undefined || context.state.TypeSimulation === "Audit rémunération") {
      let DividendeClient = PeriodeClient && PeriodeClient.DividendeBrut ? PeriodeClient.DividendeBrut : 0;
      const DividendeConjoint = PeriodeConjoint && PeriodeConjoint.DividendeBrut ? PeriodeConjoint.DividendeBrut : 0;
      if (context.state.TypeSimulation === "Audit rémunération") {
        BrutImposableClient = context.state.RemExpressRemImposable;
        DividendeClient = context.state.RemExpressDividendeBrut;
      }
      ResultatTNS.GetImpot(Outil, context.state, BrutImposableClient, BrutImposableConjoint, Number(DividendeClient) + Number(DividendeConjoint)).then((DetailImpot) => {
        if (context.state[Outil + "SetIRDirigeantPlafondNM1"] === false) {
          context.handleValueChange(Outil + "IRDirigeantPlafondNM1", DetailImpot.PlafondPerpTheoriqueDirigeant);
        }
        if (context.state[Outil + "SetIRConjointPlafondNM1"] === false) {
          context.handleValueChange(Outil + "IRConjointPlafondNM1", DetailImpot.PlafondPerpTheoriqueConjoint);
        }
      });
    }

    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state[Outil + "SetIRDirigeantPlafondNM1"], context.state[Outil + "SetIRConjointPlafondNM1"]]);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="normal text-center">Dirigeant</div>
                  </th>
                  <th>
                    <div className="normal text-center">Conjoint</div>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle={"Plafond non utilisé sur les revenus  " + (Annee - 3)} VarName1={Outil + "IRDirigeantPlafond1"} VarName2={Outil + "IRConjointPlafond1"} />
                <LigneImpotDetaille Libelle={"Plafond non utilisé sur les revenus  " + (Annee - 2)} VarName1={Outil + "IRDirigeantPlafond2"} VarName2={Outil + "IRConjointPlafond2"} />
                <LigneImpotDetaille Libelle={"Plafond non utilisé sur les revenus  " + (Annee - 1)} VarName1={Outil + "IRDirigeantPlafond3"} VarName2={Outil + "IRConjointPlafond3"} />

                <tr>
                  <td>{"Plafond calculé sur les revenus  " + (Annee - 1)}</td>
                  <td>
                    <TextField
                      iconBefore={
                        <Button tabIndex="-1" icon onClick={() => context.handleValueChange(Outil + "SetIRDirigeantPlafondNM1", false)}>
                          {context.state[Outil + "SetIRDirigeantPlafondNM1"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      value={context.state[Outil + "IRDirigeantPlafondNM1"]}
                      onChange={(e) => {
                        context.handleValueChange(Outil + "SetIRDirigeantPlafondNM1", true);
                        context.handleValueChange(Outil + "IRDirigeantPlafondNM1", e.target.value);
                      }}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                  <td>
                    <TextField
                      iconBefore={
                        <Button tabIndex="-1" icon onClick={() => context.handleValueChange(Outil + "SetIRConjointPlafondNM1", false)}>
                          {context.state[Outil + "SetIRConjointPlafondNM1"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      value={context.state[Outil + "IRConjointPlafondNM1"]}
                      onChange={(e) => {
                        context.handleValueChange(Outil + "SetIRConjointPlafondNM1", true);
                        context.handleValueChange(Outil + "IRConjointPlafondNM1", e.target.value);
                      }}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>{"Plafond pour les cotisations versées en " + Annee}</td>
                  <td>
                    <TextField disabled value={PlafondTotalClient} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                  </td>
                  <td>
                    <TextField disabled value={PlafondTotalConjoint} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                  </td>
                </tr>
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default DeductionEpargne;
