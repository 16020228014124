import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointCnavpl = (props) => {
  const {resultRetraite,ClientConjoint}=props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };

  return (
    <>
                  {context.state["Carriere"+ClientConjoint].filter(
                    (Carriere) =>
                      (Carriere.Nature === "Carcdsf" ||
                        Carriere.Nature === "Carcdsf2" ||
                        Carriere.Nature === "Carmf" ||
                        Carriere.Nature === "Carpimko" ||
                        Carriere.Nature === "Carpv" ||
                        Carriere.Nature === "Cavec" ||
                        Carriere.Nature === "Crn" ||
                        Carriere.Nature === "Cavamac" ||
                        Carriere.Nature === "Cavp" ||
                        Carriere.Nature === "Cavom" ||
                        Carriere.Nature === "MicroCipav" ||
                        Carriere.Nature === "Cipav") 
                  ).length > 0 && (
                    <tr>
                      <td>CNAVPL</td>
                      <td>
                        <TextField
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteBaseCnavpl}
                          onChange={(e)=>{context.handlePointRetraite(ClientConjoint,"RetraiteBaseCnavpl",e)}}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          small
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state["TableauPoints"+ClientConjoint].AnneeFinCnavpl}
                          onChange={(e)=>{
                            context.handlePointRetraite(ClientConjoint,"AnneeFinCnavpl",e)
                            props.CalculRetraite();
                          }}
                       
                          options={[
                            { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                            { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
                          ]}
                          small
                        />
                      </td>
                      <td>
                        <TextField
                          value={resultRetraite?.InfosRetraite?.Cnavpl?.General?.PointCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Cnavpl?.General?.PointCalcule}
                          disabled={true}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          small
                        />
                      </td>
                    </tr>
                  )}
    </>
  );
};
export default PointCnavpl;
