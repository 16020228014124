export const TabTauxConversion = {
    1952: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 0, 66: 0, 67: 0, 68: 0, 69: 0, 70: 3.99 },
    1953: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 0, 66: 0, 67: 0, 68: 0, 69: 3.91, 70: 3.97 },
    1954: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 0, 66: 0, 67: 0, 68: 3.81, 69: 3.89, 70: 3.95 },
    1955: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 0, 66: 0, 67: 3.69, 68: 3.79, 69: 3.87, 70: 3.93 },
    1956: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 0, 66: 3.55, 67: 3.67, 68: 3.77, 69: 3.85, 70: 3.91 },
    1957: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 0, 65: 3.42, 66: 3.54, 67: 3.65, 68: 3.75, 69: 3.83, 70: 3.89 },
    1958: { 60: 0, 61: 0, 62: 0, 63: 0, 64: 3.3, 65: 3.4, 66: 3.52, 67: 3.63, 68: 3.73, 69: 3.81, 70: 3.87 },
    1959: { 60: 0, 61: 0, 62: 0, 63: 3.19, 64: 3.28, 65: 3.38, 66: 3.5, 67: 3.61, 68: 3.71, 69: 3.79, 70: 3.85 },
    1960: { 60: 0, 61: 0, 62: 3.07, 63: 3.17, 64: 3.26, 65: 3.36, 66: 3.48, 67: 3.59, 68: 3.69, 69: 3.77, 70: 3.83 },
    1961: { 60: 0, 61: 2.97, 62: 3.06, 63: 3.16, 64: 3.25, 65: 3.35, 66: 3.47, 67: 3.57, 68: 3.67, 69: 3.75, 70: 3.81 },
    1962: { 60: 2.88, 61: 2.96, 62: 3.04, 63: 3.14, 64: 3.23, 65: 3.33, 66: 3.45, 67: 3.55, 68: 3.65, 69: 3.73, 70: 3.79 },
    1963: { 60: 2.87, 61: 2.95, 62: 3.03, 63: 3.12, 64: 3.21, 65: 3.31, 66: 3.43, 67: 3.53, 68: 3.63, 69: 3.71, 70: 3.77 },
    1964: { 60: 2.85, 61: 2.93, 62: 3.01, 63: 3.11, 64: 3.2, 65: 3.3, 66: 3.42, 67: 3.51, 68: 3.61, 69: 3.69, 70: 3.75 },
    1965: { 60: 2.84, 61: 2.92, 62: 3, 63: 3.09, 64: 3.19, 65: 3.28, 66: 3.4, 67: 3.49, 68: 3.59, 69: 3.67, 70: 3.73 },
    1966: { 60: 2.83, 61: 2.91, 62: 2.99, 63: 3.08, 64: 3.17, 65: 3.26, 66: 3.38, 67: 3.48, 68: 3.58, 69: 3.66, 70: 3.72 },
    1967: { 60: 2.81, 61: 2.89, 62: 2.97, 63: 3.06, 64: 3.16, 65: 3.25, 66: 3.37, 67: 3.46, 68: 3.56, 69: 3.64, 70: 3.7 },
    1968: { 60: 2.8, 61: 2.88, 62: 2.96, 63: 3.05, 64: 3.14, 65: 3.23, 66: 3.35, 67: 3.44, 68: 3.54, 69: 3.62, 70: 3.68 },
    1969: { 60: 2.79, 61: 2.87, 62: 2.95, 63: 3.04, 64: 3.13, 65: 3.22, 66: 3.34, 67: 3.42, 68: 3.52, 69: 3.6, 70: 3.66 },
    1970: { 60: 2.78, 61: 2.86, 62: 2.93, 63: 3.02, 64: 3.11, 65: 3.2, 66: 3.32, 67: 3.41, 68: 3.51, 69: 3.59, 70: 3.65 },
    1971: { 60: 2.77, 61: 2.85, 62: 2.92, 63: 3.01, 64: 3.09, 65: 3.18, 66: 3.3, 67: 3.39, 68: 3.49, 69: 3.57, 70: 3.63 },
    1972: { 60: 2.75, 61: 2.83, 62: 2.91, 63: 3, 64: 3.08, 65: 3.17, 66: 3.29, 67: 3.37, 68: 3.47, 69: 3.55, 70: 3.61 },
    1973: { 60: 2.74, 61: 2.82, 62: 2.89, 63: 2.98, 64: 3.06, 65: 3.15, 66: 3.27, 67: 3.36, 68: 3.46, 69: 3.54, 70: 3.6 },
    1974: { 60: 2.73, 61: 2.81, 62: 2.88, 63: 2.97, 64: 3.05, 65: 3.14, 66: 3.26, 67: 3.34, 68: 3.44, 69: 3.52, 70: 3.58 },
    1975: { 60: 2.72, 61: 2.8, 62: 2.87, 63: 2.96, 64: 3.04, 65: 3.13, 66: 3.25, 67: 3.32, 68: 3.42, 69: 3.5, 70: 3.56 },
    1976: { 60: 2.71, 61: 2.79, 62: 2.86, 63: 2.94, 64: 3.02, 65: 3.11, 66: 3.23, 67: 3.31, 68: 3.41, 69: 3.49, 70: 3.55 },
    1977: { 60: 2.7, 61: 2.77, 62: 2.84, 63: 2.93, 64: 3.01, 65: 3.1, 66: 3.22, 67: 3.29, 68: 3.39, 69: 3.47, 70: 3.53 },
    1978: { 60: 2.69, 61: 2.76, 62: 2.83, 63: 2.91, 64: 3, 65: 3.08, 66: 3.2, 67: 3.28, 68: 3.38, 69: 3.46, 70: 3.52 },
    1979: { 60: 2.68, 61: 2.75, 62: 2.82, 63: 2.9, 64: 2.99, 65: 3.07, 66: 3.19, 67: 3.26, 68: 3.36, 69: 3.44, 70: 3.5 },
    1980: { 60: 2.67, 61: 2.74, 62: 2.81, 63: 2.89, 64: 2.98, 65: 3.06, 66: 3.18, 67: 3.25, 68: 3.35, 69: 3.43, 70: 3.49 },
    1981: { 60: 2.66, 61: 2.73, 62: 2.8, 63: 2.88, 64: 2.96, 65: 3.04, 66: 3.16, 67: 3.23, 68: 3.33, 69: 3.41, 70: 3.47 },
    1982: { 60: 2.64, 61: 2.72, 62: 2.79, 63: 2.87, 64: 2.95, 65: 3.03, 66: 3.15, 67: 3.22, 68: 3.32, 69: 3.4, 70: 3.46 },
    1983: { 60: 2.63, 61: 2.7, 62: 2.77, 63: 2.85, 64: 2.94, 65: 3.02, 66: 3.14, 67: 3.2, 68: 3.3, 69: 3.38, 70: 3.44 },
    1984: { 60: 2.62, 61: 2.69, 62: 2.76, 63: 2.84, 64: 2.92, 65: 3, 66: 3.12, 67: 3.19, 68: 3.29, 69: 3.37, 70: 3.43 },
    1985: { 60: 2.61, 61: 2.68, 62: 2.75, 63: 2.83, 64: 2.91, 65: 2.99, 66: 3.11, 67: 3.17, 68: 3.27, 69: 3.35, 70: 3.41 },
    1986: { 60: 2.6, 61: 2.67, 62: 2.74, 63: 2.82, 64: 2.9, 65: 2.98, 66: 3.1, 67: 3.16, 68: 3.26, 69: 3.34, 70: 3.4 },
    1987: { 60: 2.59, 61: 2.66, 62: 2.73, 63: 2.81, 64: 2.89, 65: 2.97, 66: 3.09, 67: 3.14, 68: 3.24, 69: 3.32, 70: 3.38 },
    1988: { 60: 2.58, 61: 2.65, 62: 2.72, 63: 2.8, 64: 2.87, 65: 2.95, 66: 3.07, 67: 3.13, 68: 3.23, 69: 3.31, 70: 3.37 },
    1989: { 60: 2.58, 61: 2.65, 62: 2.71, 63: 2.79, 64: 2.86, 65: 2.94, 66: 3.06, 67: 3.12, 68: 3.22, 69: 3.3, 70: 3.36 },
    1990: { 60: 2.57, 61: 2.64, 62: 2.7, 63: 2.78, 64: 2.85, 65: 2.93, 66: 3.05, 67: 3.1, 68: 3.2, 69: 3.28, 70: 3.34 },
    1991: { 60: 2.56, 61: 2.63, 62: 2.69, 63: 2.76, 64: 2.84, 65: 2.91, 66: 3.03, 67: 3.09, 68: 3.19, 69: 3.27, 70: 3.33 },
    1992: { 60: 2.55, 61: 2.62, 62: 2.68, 63: 2.75, 64: 2.82, 65: 2.89, 66: 3.01, 67: 3.08, 68: 3.18, 69: 3.26, 70: 3.32 },
    1993: { 60: 2.54, 61: 2.61, 62: 2.68, 63: 2.74, 64: 2.81, 65: 2.87, 66: 2.99, 67: 3.07, 68: 3.17, 69: 3.25, 70: 3.31 },
    1994: { 60: 2.53, 61: 2.6, 62: 2.67, 63: 2.73, 64: 2.79, 65: 2.85, 66: 2.97, 67: 3.05, 68: 3.15, 69: 3.23, 70: 3.29 },
    1995: { 60: 2.52, 61: 2.59, 62: 2.66, 63: 2.72, 64: 2.77, 65: 2.83, 66: 2.95, 67: 3.04, 68: 3.14, 69: 3.22, 70: 3.28 },
    1996: { 60: 2.51, 61: 2.58, 62: 2.65, 63: 2.7, 64: 2.76, 65: 2.81, 66: 2.93, 67: 3.03, 68: 3.13, 69: 3.21, 70: 3.27 },
    1997: { 60: 2.5, 61: 2.57, 62: 2.64, 63: 2.69, 64: 2.74, 65: 2.79, 66: 2.91, 67: 3.02, 68: 3.12, 69: 3.2, 70: 3.26 },
    1998: { 60: 2.49, 61: 2.56, 62: 2.63, 63: 2.68, 64: 2.72, 65: 2.77, 66: 2.89, 67: 3.01, 68: 3.11, 69: 3.19, 70: 3.25 },
    1999: { 60: 2.48, 61: 2.55, 62: 2.62, 63: 2.66, 64: 2.71, 65: 2.75, 66: 2.87, 67: 3, 68: 3.1, 69: 3.18, 70: 3.24 },
    2000: { 60: 2.47, 61: 2.54, 62: 2.61, 63: 2.65, 64: 2.69, 65: 2.73, 66: 2.85, 67: 2.99, 68: 3.09, 69: 3.17, 70: 3.23 },
  };