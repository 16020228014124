import axios from "axios";
import { API_URL } from "../paths";

/**
 * API token.
 */
const API_TOKEN = 'IT28pTs28GHpsd+3aeQdnTQnhCKLR9l2rA4zpjPEWcaP/jjNgcLAvLiCLtaY6h7YFd6PwB1LP2KmKkP/cIHqsZYjmm08jChs4oHMMvKFJrI=';

/**
 * API manager.
 */
class Api {

    /**
     * Normalizes an uniform resource identifier.
     * @param {string} uri Uniform resource identifier to normalize.
     * @returns  Normalized uniform resources identifier. 
     */
    static normalizeUri(uri) {
        uri = uri.substring(0, 1) === '/' ? uri.substring(1) : uri;
        uri = uri.substring(uri.length - 1, 1) === '/' ? uri.substring(0, uri.length - 1) : uri;
        return uri;
    }

    /**
     * Gets api path.
     * @param {string} uri Uniform resource identifier. 
     * @returns Normalized uniform resources identifier.
     */
    static getPath(uri) {

        // Normalize uri
        uri = this.normalizeUri(uri);
        return `${API_URL}/${uri}`;
    }

    /**
     * Starts a get http request.
     * @param {string} uri Uniform resource identifier. 
     * @param {any} params Get parameters.
     * @param {any} headers Extra headers.
     * @returns Axios promise.
     */
    static get(uri, params = {}, headers = {}) {

        // Prepare headers
        headers = {
            ...headers,
            token: API_TOKEN
        }

        // Call api
        return axios.get(this.getPath(uri), { headers: headers, params: params, withCredentials: true });
    }

    /**
     * Starts a put http request.
     * @param {string} uri Uniform resource identifier. 
     * @param {any} data Post data.
     * @param {any} headers Extra headers.
     * @returns Axios promise.
     */
    static put(uri, data, headers = {}) {

        // Prepare headers
        headers = {
            ...headers,
            token: API_TOKEN
        }

        // Call api
        return axios.put(this.getPath(uri), data, { headers: headers, withCredentials: true });
    }

    /**
     * Submits a form to api.
     * @param {string} uri Uniform resource identifier.
     * @param {FormData} formData Form data.
     * @param {any} headers Extra headers.
     * @returns  Axios promise.
     */
    static submit(uri, formData, headers = {}) {
        // Prepare headers
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data',
            token: API_TOKEN
        }

        // Call api 
        return axios.post(this.getPath(uri), formData, { headers: headers, withCredentials: true});
    }

    /**
     * Starts a delete http request.
     * @param {string} uri Uniform resource identifier. 
     * @param {any} params Get parameters.
     * @param {any} headers Extra headers.
     * @returns Axios promise.
     */
    static delete(uri, params = {}, headers = {}) {

        // Prepare headers
        headers = {
            ...headers,
            token: API_TOKEN
        }

        return axios.delete(this.getPath(uri), { params: params, headers: headers, withCredentials: true });
    }

    /**
     * Starts an upload http request.
     * @param {string} uri Uniform resource identifier. 
     * @param {HTMLInputElement} elem Input file element.
     * @param {Function} progressCallback Progress callback.
     * @param {any} params Get parameters.
     * @param {any} headers Extra headers.
     * @returns Axios promise.
     */
    static upload(uri, elem, progressCallback, params = {}, headers = {}) {

        // Generate form data
        const formData = new FormData();
        elem.files.forEach(file => {
            formData.append('files', file);
        });

        // Prepare headers
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data',
            token: API_TOKEN
        }

        return axios.post(this.getPath(uri), formData, { params: params, headers: headers, withCredentials: true, onUploadProgress: progressCallback });
    }
}
export default Api;
