import AdminDossier from "components/app/Admin/Dossiers";

const AdminPage = (props) => {


  return (
  <AdminDossier AccessDossier={props.AccessDossier}/>
)};

export default AdminPage;
