import { useState, useEffect, useContext } from "react";
import { FaAward, FaCalculator, FaCalendar, FaChartArea, FaChess, FaFileSignature, FaIdBadge, FaLandmark, FaUniversity } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import StrategiePERSituationPro from "./ParamStrategiePER/SituationPro";
import StrategiePERStrategie from "./ParamStrategiePER/Strategie";
import StrategiePERContexteFiscal from "./ParamStrategiePER/ContexteFiscal";
import StrategiePERImpotRetraite from "./ParamStrategiePER/ImpotRetraite";
import StrategiePERContrat from "./ParamStrategiePER/ContratPER";

const ParamStrategiePERModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);
  return (
    <>
      <Pager
        id="paramRetraitePager"
        withMenu
        pages={[
          {
            isTitle: true,
            title: "Paramètres",
            subTitle: "Paramètres de la stratégie",
          },
          {
            label: "Situation professionnelle",
            icon: <FaIdBadge />,
            content: <StrategiePERSituationPro />,
          },
          {
            label: "Contexte fiscal",
            icon: <FaUniversity />,
            content: <StrategiePERContexteFiscal />,
          },
          {
            label: "Stratégie",
            icon: <FaChess />,
            content: <StrategiePERStrategie Results={props?.Results} CalculDisponible={props?.CalculDisponible} Disponible163Calcule={props?.Disponible163Calcule} />,
          },
          {
            label: "Contrat PER",
            icon: <FaFileSignature />,
            content: <StrategiePERContrat Results={props?.Results} CalculCapital={props?.CalculCapital} />,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          if (page === 3 || page === 4) {
            props?.CalculCapital();
          }
          setCurrentPage(page);
        }}
        validateDisabled={currentPage === 4 ? false : true}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},

          validate: {
            onClick: (evt) => {
              props?.CalculCapital();
              //e context.GetDateDepartRetraite(props.ClientConjoint,context.state);
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ParamStrategiePERModal;
