import "./precisions.css";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { RISContext } from "./RISProvider";

const PrecisionsRIS = (props) => {
  const context = useContext(AppContext);
  const RIS = useContext(RISContext);
  const [RSIChoiceAll, setRISChoiceAll] = useState("RsiCommercant");

  // console.log("PRECISE", RIS.InfosBlocageRIS);
  const carriereRSI = [
    {
      value: "RsiCommercant",
      label: "Commerçant",
    },
    {
      value: "RsiArtisan",
      label: "Artisan",
    },
    {
      value: "Ravgdt",
      label: "Débitant de tabac",
    },
  ];

  const absencePointRGSelect = [
    {
      value: "SalarieNonCadre",
      label: "Salarié non cadre du  secteur privé",
    },
    {
      value: "SalarieCadre",
      label: "Salarié cadre du  secteur privé",
    },
    {
      value: "SalarieNonCadreCFE",
      label: "Salarié non cadre cotisant CFE",
    },
    {
      value: "SalarieCadreCFE",
      label: "Salarié cadre cotisant CFE",
    },
    {
      value: "SalarieNonCadreAgricole",
      label: "Salarié non cadre du secteur agricole",
    },
    {
      value: "SalarieCadreAgricole",
      label: "Salarié cadre du secteur agricole",
    },
    {
      value: "SalarieIrcantec",
      label: "Salarié non titulaire de l’Etat",
    },
  ];

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            {Boolean(RIS.InfosBlocageRIS.AbsencePointRG) === true && (
              <>
                <Row>
                  <h1>Précision sur une période</h1>
                </Row>
                <Row>
                  <blockquote>
                    Le logiciel a détecté une periode d'activité au régime général en {new Date().getFullYear() - 1} sans affiliation à un régime complémentaire. Merci de préciser la nature
                    de l'activité :
                  </blockquote>
                </Row>
                <Row tpad bpad>
                  <DropDown
                    label="Nature de l'activité"
                    value={RIS.InfosBlocageRIS.AbsencePointRG}
                    onChange={(e) => {
                      RIS.handleCorrectionBlocageRIS("AbsencePointRG", "", "", e);
                    }}
                    options={absencePointRGSelect}
                    normal
                  />
                </Row>
              </>
            )}
            {Boolean(RIS.InfosBlocageRIS.PeriodeCnavpl) === true && (
              <>
                <Row>
                  <h1>Période d’affiliation à un régime de profession libérale</h1>
                </Row>
                <Row>
                  <blockquote>
                  Le logiciel a détecté une période d’affiliation au régime de base des professions libérales où au régime de base des avocats. 
                  Nous vous rappelons que dans le cadre de ces régimes (hors CIPAV), l'entrée en jouissance de la pension de retraite est fixée au 1<sup>er</sup> jour du trimestre civil qui suit la demande. Dès lors, le logiciel fixe automatiquement un départ au 1<sup>er</sup> jour d'un trimestre civil. Si vous souhaitez modifier l'âge de départ en retraite, <b>nous vous conseillons vivement de fixer la date  à un 1<sup>er</sup> jour de trimestre civil.</b>
                  </blockquote>
                </Row>
              </>
            )}
            {Boolean(RIS.InfosBlocageRIS.AidantFamilial) === true && (
              <>
                <Row>
                  <h1> Aidant familial à la MSA</h1>
                </Row>
                <Row>
                  <blockquote>
                    Le logiciel a détecté une période d’affiliation à la MSA en tant qu’aidant familial. Nous vous informons que le statut n’est pas intégré à ce jour dans le simulateur.
                    N’hésitez pas à nous contacter si vous souhaitez des informations complémentaires.
                  </blockquote>
                </Row>
              </>
            )}
            {Boolean(RIS.InfosBlocageRIS.CotisAssi) === true && (
              <>
                <Row>
                  <h1>Précisions sur la répartition des trimestres</h1>
                </Row>
                <Row>
                  <blockquote>
                    Le logiciel a détecté une ou plusieurs années au cours de laquelle ont été comptabilisés des trimestres cotisés et assimilés. Par défaut, le logiciel détermine le montant
                    des trimestres cotisés en fonction du salaire retenu sur l'année et compare les trimestres calculés avec le total des trimestres indiqués sur l'année. La différence est
                    affectée aux trimestres assimilés. Vous pouvez toutefois modifier cette répartition en cliquant directement sur les trimestres cotisés ou assimilés. En présence de
                    périodes de maladie, maternité, invalidité, le logiciel attribue par défaut les trimestres à la prestation maladie. Vous pouvez modifier ce choix dans le tableau
                    ci-dessous en cliquant directement sur les trimestres cotisés ou assimilés.
                  </blockquote>
                </Row>
                {console.log(RIS)}
                <Row tpad bpad>
                  <table className="mono repartTrim">
                    <thead>
                      <tr>
                        <th>Année</th>
                        <th>Régime général</th>
                        <th>SSI</th>
                        {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroRSI")).length > 0 && <th>Micro RSI</th>}
                        {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroBIC")).length > 0 && <th>Micro BIC</th>}
                        {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroBNC")).length > 0 && <th>Micro BNC</th>}
                        <th>AVPF</th>
                        <th>Chômage</th>
                        <th>Maladie</th>
                        <th>Maternité</th>
                        <th>Invalidité</th>
                        <th>Militaire</th>
                        <th>Trim. retenus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(RIS.InfosBlocageRIS.CotisAssi).map((data, index) => {
                        return (
                          <tr key={"Blocage" + index}>
                            <td className="text-center">{data[0]}</td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "RegimeGeneral") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "RegimeGeneral").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "RegimeGeneral"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "RSICommercant") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "RSICommercant").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "RSICommercant"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroRSI")).length > 0 && (
                              <td>
                                {data[1].find((Liste) => Liste.Regime === "MicroRSI") && (
                                  <TextField
                                    className="center"
                                    value={data[1].find((Liste) => Liste.Regime === "MicroRSI").Trimestres}
                                    onChange={(e) => {
                                      RIS.handleCorrectionBlocageRIS(
                                        "CotisAssi",
                                        data[0],
                                        data[1].findIndex((Liste) => Liste.Regime === "MicroRSI"),
                                        e
                                      );
                                    }}
                                  />
                                )}
                              </td>
                            )}
                            {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroBIC")).length > 0 && (
                              <td>
                                {data[1].find((Liste) => Liste.Regime === "MicroBIC") && (
                                  <TextField
                                    className="center"
                                    value={data[1].find((Liste) => Liste.Regime === "MicroBIC").Trimestres}
                                    onChange={(e) => {
                                      RIS.handleCorrectionBlocageRIS(
                                        "CotisAssi",
                                        data[0],
                                        data[1].findIndex((Liste) => Liste.Regime === "MicroBIC"),
                                        e
                                      );
                                    }}
                                  />
                                )}
                              </td>
                            )}
                            {Object.entries(RIS.InfosBlocageRIS.CotisAssi).filter((i) => i[1].find((carriere) => carriere.Arr === "CarriereMicroBNC")).length > 0 && (
                              <td>
                                {data[1].find((Liste) => Liste.Regime === "MicroBNC") && (
                                  <TextField
                                    className="center"
                                    value={data[1].find((Liste) => Liste.Regime === "MicroBNC").Trimestres}
                                    onChange={(e) => {
                                      RIS.handleCorrectionBlocageRIS(
                                        "CotisAssi",
                                        data[0],
                                        data[1].findIndex((Liste) => Liste.Regime === "MicroBNC"),
                                        e
                                      );
                                    }}
                                  />
                                )}
                              </td>
                            )}
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Avpf") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Avpf").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Avpf"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Chomage") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Chomage").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Chomage"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Maladie") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Maladie").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Maladie"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Maternite") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Maternite").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Maternite"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Invalidite") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Invalidite").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Invalidite"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {data[1].find((Liste) => Liste.Regime === "Militaire") && (
                                <TextField
                                  className="center"
                                  value={data[1].find((Liste) => Liste.Regime === "Militaire").Trimestres}
                                  onChange={(e) => {
                                    RIS.handleCorrectionBlocageRIS(
                                      "CotisAssi",
                                      data[0],
                                      data[1].findIndex((Liste) => Liste.Regime === "Militaire"),
                                      e
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <TextField className="center" disabled value={data[1].find((Liste) => Liste.TrimestreRetenu >= 0).TrimestreRetenu} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
              </>
            )}
            {Boolean(RIS.InfosBlocageRIS.CarriereRSICommercant) === true && (
              <>
                <Row>
                  <h1>Période d’affiliation à la sécurité sociale des indépendants</h1>
                </Row>

                <blockquote className="tpad">
                  Le logiciel a détecté une ou plusieurs périodes d’affiliation à la sécurité sociale des indépendants. Merci de préciser s’il s’agit d’une affiliation en tant que commerçant
                  ou en tant qu’artisan.
                </blockquote>
                <Row tpad>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "280px" }}>Définir la caisse pour toutes les années</td>
                        <td>
                          <DropDown
                            value={RSIChoiceAll}
                            onChange={(e) => {
                              setRISChoiceAll(e.target.value);
                              RIS.handleCorrectionBlocageRIS("CarriereRSICommercant", "", "RSIChoiceAll", e);
                            }}
                            options={carriereRSI}
                            normal
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <tr>
                        <th className="text-center">Année</th>
                        <th className="text-center">
                          <div className="normal">Caisse</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(RIS.InfosBlocageRIS.CarriereRSICommercant).map((data, index) => {
                        return (
                          <tr>
                            <td className="text-center">{data[0]}</td>
                            <td>
                              <DropDown
                                value={data[1].Nature}
                                onChange={(e) => {
                                  RIS.handleCorrectionBlocageRIS("CarriereRSICommercant", data[0], "", e);
                                }}
                                options={carriereRSI}
                                normal
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
              </>
            )}

            {Boolean(RIS.InfosBlocageRIS.CarriereFonctionPublique) === true && (
              <>
                <Row tpad>
                  <h1>Période d’affiliation à la fonction publique</h1>
                </Row>
                <Row tpad>
                  <blockquote>
                    Le logiciel a détecté une période d’affiliation à la fonction publique. En l’absence de projection de la carrière sur le régime, nous vous rappelons qu’il est
                    indispensable de saisir le dernier salaire ou l’indice de fin de carrière sur la dernière période pour sa bonne prise en compte.
                  </blockquote>
                </Row>
                <Row tpad>
                  <TextField
                    label="Salaire"
                    value={RIS.InfosBlocageRIS.CarriereFonctionPublique["Salaire"]}
                    onChange={(e) => {
                      RIS.handleCorrectionBlocageRIS("CarriereFonctionPublique", "", "Salaire", e);
                    }}
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                    normal
                  />
                </Row>
                <Row>
                  <TextField
                    label="Indice de fin de carrière"
                    value={RIS.InfosBlocageRIS.CarriereFonctionPublique["IFC"]}
                    onChange={(e) => {
                      RIS.handleCorrectionBlocageRIS("CarriereFonctionPublique", "", "IFC", e);
                    }}
                    dataType="uint"
                    normal
                  />
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default PrecisionsRIS;
