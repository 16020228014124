import './ui-helpers.css';

//#region Alignment and size constants

/**
 * Alignments for property to class substitutions.
 */
export const Alignments = ['left', 'right', 'top', 'bottom', 'center', 'centerX', 'centerY', 'fill', 'fit'];

/**
 * Paddings for property to class substitutions.
 */
export const Paddings = ['lpad', 'rpad', 'hpad', 'tpad', 'bpad', 'vpad', 'pad', 'nopad', 'wrap']

/**
 * Normalized sizes.
 */
export const Sizes = ['xs', 'xsmall', 'small', 'normal', 'large', 'xlarge','xxlarge' ];

/**
 * Scroll directions.
 */
export const Scrolls = ['scroll', 'scrollY', 'scrollX'];

//#endregion

//#region Format constants

// Numeric types
export const NumericTypes = ['integer', 'int', 'uint', 'decimal', 'float', 'ufloat'];
export const NumericUnsigned = ['uint', 'ufloat'];
export const NumericSigned = ['integer', 'int', 'decimal', 'float'];
export const NumericDecimals = ['decimal', 'float', 'ufloat'];

//#endregion

//#region Properties functions

/**
 * Converts given property names to class if found in component properties.
 * @param {ReactDOM.props} props Properties.
 * @param {string} baseClass Base class name.
 * @param {Array} properties Array of property names to add to class name.
 * @returns Class name.
 */
export function classFromProperties(props, baseClass, ...properties) {
    
    // Initialize result
    let className = baseClass;
    if (props.className) className += ' ' + props.className;
    
    // Search for properties to convert to classes
    properties.forEach((list) => {
        list.forEach((property) => {
            if (props[property]) className += ' ' + property;
        });
    });

    // Return result
    return className;
}

/**
 * Gets optional properties for a sub component.
 * @param {ReactDOM.props} props Properties.
 * @param {array} excludes Excluded property names.
 * @returns Optional properties.
 */
export function getOptionalProps(props, excludes = [])
{
    // Add class name
    excludes.unshift('className');
    excludes.unshift('children');
    excludes.unshift('key');

    // Apply filter
    return Object.keys(props).reduce((names, propName) => {
        if (excludes.indexOf(propName) === -1) {
            return {
                ...names,
                [propName]: props[propName]
            };
        }
        return names;
    }, {});
}

//#endregion

//#region Identifier functions

/**
 * Gets a unique identifier.
 * @returns Unique identifier.
 */
export function getUniqId() {
    return 'ID' + String(
        Date.now().toString(32) +
        Math.random().toString(16)
    ).replace(/\./g, '');
}

//#endregion

//#region Numeric values functions

/**
 * Constrains integer value between boundaries.
 * @param {any} value Value to constrain.
 * @param {int} min Minimal allowed value.
 * @param {int} max Maximal allowed value.
 * @returns Constrained value.
 */
export function constrainToInt(value, min, max) {
    const ivalue = parseInt(value);
    if (ivalue < min) return min;
    if (ivalue > max) return max;
    return ivalue;
}

//#endregion

//#region Date functions

/**
 * Converts candidate date to real date string.
 * @param {int} year Candidate year.
 * @param {month} month Candidate month.
 * @param {day} day Candidate day.
 * @returns Real date string representation.
 */
export function getRealDateString(year, month, day) {
    const date = new Date(year, month - 1, day);
    day = date.getDate();
    month = date.getMonth() + 1;
    year = date.getFullYear();
    return day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year.toString().padStart(4, '0');
}

/**
 * Split date items from numeric or string representation.
 * @param {string} value Date string.
 * @returns Real date string representation.
 */
export function splitDate(value) {
    const parts = value.toString().split('/');
    let year, month, day;
    let result = '';
    switch(parts.length)
    {
        case 1:
            if (value.length >= 6) {
                day = constrainToInt(parseInt(parts[0].substring(0, 2)), 1, 31);
                month = constrainToInt(parseInt(parts[0].substring(2, 4)), 1, 12);
                year = parseInt(parts[0].substring(4, 8));
                year = year < 50 ? year + 2000 : year < 100 ? 1900 + year : constrainToInt(year, 1800, 9999);
                result = getRealDateString(year, month, day);
            } else result = value;
            break;
        case 2:
            result = value;
            break;
        case 3:
            day = constrainToInt(parseInt(parts[0]), 1, 31);
            month = constrainToInt(parseInt(parts[1]), 1, 12);
            year = parseInt(parts[2]);
            if (isNaN(day) || isNaN(month) || isNaN(year))
                result = value;
            else {
                year = year < 50 ? year + 2000 : year < 100 ? 1900 + year : constrainToInt(year, 1800, 9999);
                result = getRealDateString(year, month, day);
            }
            break;
        default:
            result = value;
            break;
    }

    // Return result
    return result;
}

//#endregion

//#region Format handling

/**
 * Applies format to value.
 * @param {string} value Value to format.
 * @param {string} dataType Value type name.
 * @param {Object} format Format parameters.
 * @returns Formatted value.
 */
export const applyFormat = (value, dataType, format) => {
    
    // Initialize result
    let result = '';
    if (value === '') return value;

    // Assert format
    if (!dataType || dataType === 'string') return value;

    // Check numeric field
    if (NumericTypes.includes(dataType))
    {
        // Apply precision to numeric value
        const negative = value?.toString().trim().substring(0,1) === '-';
        value = Math.abs(parseFloat(value)).toFixed(format?.precision || 0).toString();

        // Get number parts
        const parts = value ? value.split('.') : '';
        const val = parts[0].trim();
        let dec = parts.length > 1 ? parts[1].trim() : '0';

        // Check thousand separator
        if (format?.thousands)
        {
            let count = 0;
            for(let i = val.length - 1; i >= 0; i--)
            {
                result = val.substring(i, i + 1) + result;
                if (((++count % 3) === 0) && i) result = (format.thousands || ' ') + result;
            }
        }
        else result = val;
        
        // Add decimal if needed
        if(NumericDecimals.includes(dataType) && (format?.precision || 0))
        {
            dec = dec.padEnd(format.precision, '0');
            result += (format.decimal || '.') + dec;
        }

        // Add negative if needed
        if (negative && !(NumericUnsigned.includes(dataType)))
            result = '-' + result;
    }
    else if (dataType === 'date')
    {
        result = splitDate(value);
    }

    // Add unit if any
    if (format?.unit)
        result += format.unit;

    // Return result
    return result;
}

/**
 * Removes format from value.
 * @param {string} value Value to restore.
 * @param {string} dataType Value type name.
 * @param {Object} format Format parameters.
 * @returns Unformatted value.
 */
export const removeFormat = (value, dataType, format) => {

    // Assert format
    if (value === '' || !format || !dataType || dataType === 'string') return value;

    // Remove unit
    const unit = format?.unit || '';
    if (value.includes(unit))
        value  = value.replace(unit, '');
    if (value.trim() === '') return '';

    // Check numeric field
    if (NumericTypes.includes(dataType))
    {
        // Remove thousand separators and decimal character
        value = (value === '') || (value === '-')  ? '0' : value.replace(new RegExp(format?.thousands || ' ', 'g'), '').replace(format?.decimal || '.', '.');
    }
    else if (dataType === 'date')
    {
        value = splitDate(value);
    }

    // Return result
    return value;
}

//#endregion

//#region Password functions

/**
 * Gets password strength score.
 * @param {string} pass Password string.
 * @returns password strength.
 */
export const strengthScore = (pass) => {
    let score = 0;
    if (!pass) return score;

    // Prevents same letter repetitions
    const letters = [];
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // Check variations
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }
    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    // Return score
    return parseInt(score);
}

//#endregion
