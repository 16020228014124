import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";

const RevenusFoncier = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);

  const handleAbattement = () => {
    let menu = [
      { value: "Micro", label: "Micro" },
      { value: "Reel", label: "Au réel" },
    ];
    if (context.state[Outil + "IRDirigeantRecette"] > 15000) {
      menu = menu.filter((k) => k.label !== "Micro");
    }
    return menu;
  };
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>Paramètres des revenus fonciers</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Montant des recettes brutes</td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRDirigeantRecette"]}
                      onChange={(e) => {
                        context.handleRecetteBrute(Outil, Outil + "IRDirigeantRecette", e);
                      }}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>Abattement appliqué</td>
                  <td>
                    <DropDown
                      value={context.state[Outil + "IRDirigeantRegimeRevenuFoncier"]}
                      onChange={context.handleInputChange(Outil + "IRDirigeantRegimeRevenuFoncier")}
                      options={handleAbattement()}
                      normal
                    />
                  </td>
                </tr>
                {context.state[Outil + "IRDirigeantRegimeRevenuFoncier"] === "Reel" && (
                  <tr>
                    <td>Charges / frais à déduire</td>
                    <td>
                      <TextField
                        value={context.state[Outil + "IRDirigeantChargeRevenuFoncier"]}
                        onChange={context.handleInputChange(Outil + "IRDirigeantChargeRevenuFoncier")}
                        disabled={context.state[Outil + "IRDirigeantRegimeRevenuFoncier"] === "Reel" ? false : true}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default RevenusFoncier;
