import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const ParamCavec = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Classe prévoyance"
            value={context.state[props.Outil + "ClassePrevoyanceCavec" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ClassePrevoyanceCavec" + props.ClientConjoint)}
            options={[
              {
                value: "NC",
                label: "Défaut",
              },
              {
                value: "1",
                label: "1",
              },
              {
                value: "2",
                label: "2",
              },
              {
                value: "3",
                label: "3",
              },
              {
                value: "4",
                label: "4",
              },
            ]}
            normal
          />
        </Column>
        {context.state.TypeSimulation !== "Audit prévoyance" && (
          <Column hpad>
            <DropDown
              label="Classe retraite"
              value={context.state[props.Outil + "ClasseRetraiteCavec" + props.ClientConjoint]}
              onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCavec" + props.ClientConjoint)}
              options={[
                {
                  value: "NC",
                  label: "Défaut",
                },
                {
                  value: "A",
                  label: "A",
                },
                {
                  value: "B",
                  label: "B",
                },
                {
                  value: "C",
                  label: "C",
                },
                {
                  value: "D",
                  label: "D",
                },
                {
                  value: "E",
                  label: "E",
                },
                {
                  value: "F",
                  label: "F",
                },
                {
                  value: "G",
                  label: "G",
                },
                {
                  value: "H",
                  label: "H",
                },
              ]}
              normal
            />
          </Column>
        )}
      </Row>
    </>
  );
};
export default ParamCavec;
