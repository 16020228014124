import axios from "axios";
import { RemoveUnecessaryData, ApiUrl } from "../ApiData";
import { User } from "../ApiUser";
const APIURL = ApiUrl;

export function GetPass() {
  return axios({
    method: "post",
    url: `${APIURL}Retraite/GetPass.php`,
    // data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function CalculTrimestre(Data) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));

  return axios({
    method: "post",
    url: `${APIURL}Retraite/CalculTrimestre.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR CALCUL TRIMESTRE",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    });
}
export function CalculTrimestreAnnee(Annee, DetailRetraiteAnnee, Activite, DetailRetraitePasse, TauxActivite) {
  var formData = new FormData();
  formData.append("Annee", Annee);
  formData.append("DetailRetraiteAnnee", JSON.stringify(DetailRetraiteAnnee));
  formData.append("Activite", Activite);
  formData.append("DetailRetraitePasse", JSON.stringify(DetailRetraitePasse));
  formData.append("TauxActivite", JSON.stringify(TauxActivite));

  return axios({
    method: "post",
    url: `${APIURL}Retraite/CalculTrimestreAnnee.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}

export function GetFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();

  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();

  day = day.length > 1 ? day : "0" + day;

  return day + "/" + month + "/" + year;
}

export function GetFormattedDateAmerican(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();

  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();

  day = day.length > 1 ? day : "0" + day;

  return year + "-" + month + "-" + day;
}

export function CalculRetraite(Data, Outil, Revenus, ClientConjoint) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Outil", Outil);
  formData.append("Revenus", Revenus);
  formData.append("ClientConjoint", ClientConjoint);

  let SalaireReference = 0;
  let annee = new Date().getFullYear();
  let cli = ClientConjoint === "ConjointAssocie" ? "Conjoint" : ClientConjoint;
  const isPeriodeActuelle = Data["Carriere" + cli].filter((periode) => periode.id === "CarriereActuelle");
  if (isPeriodeActuelle.length === 0) {
    for (const periode of Data["Carriere" + cli]) {
      if (annee in periode.Detail) {
        SalaireReference += Number(periode.Detail[annee]["Salaire"]);
      }
      if (CleanData.CarriereSalaireReferenceSaisi === false) {
        Data.CarriereSalaireReference = SalaireReference;
        Data.CarriereSalaireReferenceCalcule = SalaireReference;
        CleanData.CarriereSalaireReferenceCalcule = SalaireReference;
        CleanData.CarriereSalaireReference = SalaireReference;
      }
    }
  }
  return axios({
    method: "post",
    url: `${APIURL}Retraite.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ResultatRetraite) => {
      return ResultatRetraite.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR RETRAITE",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    });
}
export function CalculPrestationContrat(Data, ClientConjoint, ResultatRetraite, SalaireBrut83PERO, ContratSpecifique) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatRetraite", JSON.stringify(ResultatRetraite));
  formData.append("ClientConjoint", ClientConjoint);
  formData.append("SalaireBrut83PERO", SalaireBrut83PERO);
  formData.append("ContratSpecifique", JSON.stringify(ContratSpecifique));
  return axios({
    method: "post",
    url: `${APIURL}RetraitePrestationContrat.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ResultatRetraite) => {
      return ResultatRetraite.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR PRESTA CONTRAT",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    });
}

export function CalculDepartRetraite(Data) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));

  return axios({
    method: "post",
    url: `${APIURL}DateDepartRetraite.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR DEPART RETRAITE",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    })
    .then((ResultatDateDepart) => {
      return ResultatDateDepart.data;
    });
}

export function PdfReader(FileData, IdUser, Partner) {
  var formData = new FormData();
  formData.append("file", FileData);
  formData.append("IdUser", IdUser);
  formData.append("Partner", JSON.stringify(Partner));

  let url = `${APIURL}Retraite/PdfReaderControllerV2.php`;

  return axios({
    method: "post",
    url: url,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((Res) => {
      return Res;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function ReleveRGReader(FileData, IdUser, Carriere) {
  var formData = new FormData();
  formData.append("file", FileData);
  formData.append("IdUser", IdUser);
  formData.append("Carriere", JSON.stringify(Carriere));

  let url = `${APIURL}Retraite/ReleveRGReaderController.php`;

  return axios({
    method: "post",
    url: url,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((Res) => {
      return Res;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function ControlRIS(Data, IdUser, CorrectionBlocage,DateDepartRetraite) {
  var formData = new FormData();
  formData.append("File", Data.FileName);
  formData.append("IdUser", IdUser);
  formData.append("DateDepartRetraite", DateDepartRetraite);

  if (Boolean(CorrectionBlocage)) {
    formData.append("PrecisionCarriere", "ok");
    if (Boolean(CorrectionBlocage.CotisAssi)) {
      let CotisAssi = JSON.parse(JSON.stringify(CorrectionBlocage["CotisAssi"]));
      let CorrectionCotisAssi = {};

      Object.entries(CotisAssi).forEach((data, index) => {
        CotisAssi[data[0]].pop();
        CorrectionCotisAssi[data[0]] = {};
        data[1].forEach((Infos, i) => {
          if (
            Infos["Regime"] === "RegimeGeneral" ||
            Infos["Regime"] === "RSICommercant" ||
            Infos["Regime"] === "MicroRSI" ||
            Infos["Regime"] === "MicroBIC" ||
            Infos["Regime"] === "MicroBNC" ||
            Infos["Regime"] === "Avpf"
          ) {
            CorrectionCotisAssi[data[0]]["Carriere" + Infos["Regime"]] = "";
            CorrectionCotisAssi[data[0]]["Carriere" + Infos["Regime"]] = Infos["Trimestres"];
          } else {
            CorrectionCotisAssi[data[0]]["CarriereAssimile" + Infos["Regime"]] = "";
            CorrectionCotisAssi[data[0]]["CarriereAssimile" + Infos["Regime"]] = Infos["Trimestres"];
          }
        });
      });
      formData.append("CorrectionCotisAssi", JSON.stringify(CorrectionCotisAssi));
    }
    if (Boolean(CorrectionBlocage.AbsencePointRG)) {
      formData.append("CorrectionAbsencePointRG", CorrectionBlocage.AbsencePointRG);
    }
    if (Boolean(CorrectionBlocage.CarriereFonctionPublique)) {
      formData.append("CorrectionFonctionPublique", JSON.stringify(CorrectionBlocage.CarriereFonctionPublique));
    }
    if (Boolean(CorrectionBlocage.CarriereRSICommercant)) {
      let CorrectionRSI = {};
      Object.entries(CorrectionBlocage.CarriereRSICommercant).forEach((data, index) => {
        CorrectionRSI[data[0]] = data[1].Nature;
      });
      formData.append("CorrectionRSI", JSON.stringify(CorrectionRSI));
    }
    if (Boolean(CorrectionBlocage.AidantFamilial)) {
      formData.append("", "ok");
    }
  }
  let url = `${APIURL}Retraite/RIS2/ControlRISV2.php`;
  if ("RISInfos" in Data) {
    url = `${APIURL}Retraite/RIS2/ControlRISV2.php`;
    formData.append("RISInfos", JSON.stringify(Data.RISInfos));
  }
  return axios({
    method: "post",
    url: url,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((Res) => {
      return Res;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR CONTROL RIS",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    });
}
