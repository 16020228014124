import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

function ContratFraisPro(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            {props.AccessRemExpress === false && (
              <Row>
                <Column>
                  <TextField label="Libellé" value={context.state.LibelleContrat} onChange={context.handleInputChange("LibelleContrat")} normal />
                </Column>
              </Row>
            )}
            <Row tpad>
              <Column>
                <TextField
                  label="Cotisation annuelle"
                  value={context.state.FGPMontant}
                  onChange={context.handleInputChange("FGPMontant")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratFraisPro;
