import { useState } from "react";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CardBox from "components/ui/controls/card-box/card-box";
import { FaCalculator } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import DetailAssietteCotisation from "./DetailAssietteCotisation";
import Modal from "components/ui/modal/modal";
import DetailCotisationsRsiCommercantRsiArtisan from "./DetailCotisationsRsiCommercantRsiArtisan";
import DetailCotisationsCavec from "./DetailCotisationsCavec";
import DetailCotisationsCarpv from "./DetailCotisationsCarpv";
import DetailCotisationsCipav from "./DetailCotisationsCipav";
import DetailCotisationsCrn from "./DetailCotisationsCrn";
import DetailCotisationsCnbf from "./DetailCotisationsCnbf";
import DetailCotisationsCarpimko from "./DetailCotisationsCarpimko";
import DetailCotisationsCarcdsf from "./DetailCotisationsCarcdsf";
import DetailCotisationsCarmf from "./DetailCotisationsCarmf";
import DetailCotisationsCavp from "./DetailCotisationsCavp";
import DetailCotisationsMsa from "./DetailCotisationsMsa";

const DetailCotisationsTNS = (props) => {
  const { DetailCotisations, DetailCSG, TotalChargesSociales, DetailAssiette, Caisse,Prenom } = props;
  const [modalOpen, setmodalOpen] = useState(false);
  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Row>
              <Column>
                <Button
                  onClick={() => {
                    setmodalOpen(true);
                  }}
                >
                  <span>Assiette des cotisations</span>
                  <FaCalculator />
                </Button>

                <Modal
                  width="40%"
                  height="40%"
                  title="Assiette des charges sociales"
                  icon={<FaCalculator />}
                  visible={modalOpen}
                  onClose={() => {
                    setmodalOpen(false);
                  }}
                >
                  <DetailAssietteCotisation
                    Outil=""
                    DetailAssiette={DetailAssiette}
                    onClose={() => {
                      setmodalOpen(false);
                    }}
                  />
                </Modal>
              </Column>
            </Row>
            <Row tpad>
              {(Caisse === "RsiCommercant" || Caisse === "RsiArtisan") && <DetailCotisationsRsiCommercantRsiArtisan DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Cavec"  && <DetailCotisationsCavec DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Carpv"  && <DetailCotisationsCarpv DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Cipav"  && <DetailCotisationsCipav DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Crn"  && <DetailCotisationsCrn DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Cnbf"  && <DetailCotisationsCnbf DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Carpimko"  && <DetailCotisationsCarpimko ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Carcdsf"  && <DetailCotisationsCarcdsf ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Carcdsf2"  && <DetailCotisationsCarcdsf ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Carmf"  && <DetailCotisationsCarmf ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Cavp"  && <DetailCotisationsCavp ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
              {Caisse === "Msa"  && <DetailCotisationsMsa ClientConjoint={props.ClientConjoint} DetailCotisations={DetailCotisations} DetailCSG={DetailCSG}  TotalChargesSociales={TotalChargesSociales} Prenom={Prenom} />}
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default DetailCotisationsTNS;
