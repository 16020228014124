import { useContext, useEffect, useState } from "react";
import { FaCog, FaFileContract, FaImage, FaPalette, FaTrashAlt, FaUpload } from "react-icons/fa";
import { FILES_URL } from "../../../paths";
import { AuthContext } from "../../auth/auth-provider";
import Button from "../../ui/controls/button/button";
import CardBox from "../../ui/controls/card-box/card-box";
import ColorPicker from "../../ui/controls/color-picker/color-picker";
import FileField from "../../ui/controls/file-field/file-field";
import Pager from "../../ui/controls/pager/pager";
import TextField from "../../ui/controls/text-field/text-field";
import Column from "../../ui/layout/column/column";
import Row from "../../ui/layout/row/row";
import Modal from "../../ui/modal/modal";
import { ModalContext } from "../../ui/modal/modal-provider";
import "./settings.css";
import Dialogs from "components/ui/dialogs/dialogs";

const Settings = (props) => {
  // Initialize states
  const [visible, setVisible] = useState(props?.visible);
  const auth = useContext(AuthContext);
  const settings = auth.preferences;

  // On visible change
  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  // Render component
  return visible ? (
    <Modal
      title="Vos préférences"
      icon={<FaCog />}
      width="80%"
      height="80%"
      visible={visible}
      onClose={() => {
        props?.onClose?.();
      }}
    >
      <ModalContext.Consumer>
        {(modal) => (
          <Pager
            withMenu
            pages={[
              {
                isTitle: true,
                title: "Préférences",
                subTitle: "Personnalisez vos études",
              },
              {
                label: "Couleurs",
                icon: <FaPalette />,
                hidden: !settings.peutChangerCouleurs,
                content: (
                  <Column fill>
                    <CardBox>
                      <Column fill>
                        <Row>
                          <h1>Couleurs</h1>
                        </Row>
                        <Row>
                          <Column>
                            <ColorPicker
                              label="Couleur principale"
                              value={settings.couleurs && settings.couleurs.length > 0 ? settings.couleurs[0] : "#1E3C72"}
                              onChange={(evt) => {
                                auth.modifierPreferences("couleurs", [evt.target.value, settings.couleurs && settings.couleurs.length > 1 ? settings.couleurs[1] : "#2196F3"]);
                              }}
                              xsmall
                              inline
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <ColorPicker
                              label="Couleur secondaire"
                              value={settings.couleurs && settings.couleurs.length > 1 ? settings.couleurs[1] : "#2196F3"}
                              onChange={(evt) => {
                                auth.modifierPreferences("couleurs", [settings.couleurs && settings.couleurs.length > 0 ? settings.couleurs[0] : "#1E3C72", evt.target.value]);
                              }}
                              xsmall
                              inline
                            />
                          </Column>
                        </Row>
                      </Column>
                    </CardBox>
                  </Column>
                ),
              },
              {
                label: "Logo",
                icon: <FaImage />,
                hidden: !settings.peutChangerLogo && !settings.peutChangerIcone,
                content: (
                  <Column fill>
                    <CardBox>
                      <Column fill>
                        <Row>
                          <h1>Logo</h1>
                        </Row>
                        
                        {settings.peutChangerIcone && (
                          <Row>
                            <Column fill center>
                              <img id="apercu-icone" src={FILES_URL + (settings.icone || "/logo.svg")} alt="Icone" />
                              <Row>
                                <Column>
                                  <FileField
                                    id="icone"
                                    name="icone"
                                    normal
                                    onChange={(evt) => {
                                      const reader = new FileReader();
                                      reader.onload = () => {
                                        const apercuIcone = document.getElementById("apercu-icone");
                                        apercuIcone.src = reader.result;
                                      };
                                      reader.readAsDataURL(evt.target.files[0]);
                                      if (evt.target.files[0].type === "image/png" || evt.target.files[0].type === "image/jpeg")
                                        auth.modifierPreferences("fichier_icone", evt.target.files[0]);
                                      else Dialogs.error("Format d'image non reconnu", "Erreur de format");
                                    }}
                                  >
                                    Changer l'icone
                                    <FaUpload />
                                  </FileField>
                                </Column>
                                {settings.icone !== "" && (
                                  <Column>
                                    <Button
                                      tool
                                      title="Revenir à l'icone par défaut"
                                      onClick={() => {
                                        auth.modifierPreferences("icone", "");
                                      }}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Column>
                                )}
                              </Row>
                            </Column>
                          </Row>
                        )}
                        {console.log(FILES_URL+settings.logo)}
                        {settings.peutChangerLogo && (
                          <Row>
                            <Column fill center>
                              <img id="apercu-logo" src={FILES_URL + (settings.logo || "/empty.svg")} alt="Logo" />
                              <Row>
                                <Column>
                                  <FileField
                                    id="logo"
                                    name="logo"
                                    accept="image/png,image/jpeg"
                                    normal
                                    onChange={(evt) => {
                                      const reader = new FileReader();
                                      reader.onload = () => {
                                        const apercuIcone = document.getElementById("apercu-logo");
                                        apercuIcone.src = reader.result;
                                      };
                                      reader.readAsDataURL(evt.target.files[0]);
                                      if (evt.target.files[0].type === "image/png" || evt.target.files[0].type === "image/jpeg")
                                        auth.modifierPreferences("fichier_logo", evt.target.files[0]);
                                      else Dialogs.error("Format d'image non reconnu", "Erreur de format");
                                    }}
                                  >
                                    Changer le logo
                                    <FaUpload />
                                  </FileField>
                                </Column>
                                {settings.logo !== "" && (
                                  <Column>
                                    <Button
                                      tool
                                      title="Supprimer le logo"
                                      onClick={() => {
                                        auth.modifierPreferences("logo", "");
                                      }}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </Column>
                                )}
                              </Row>
                            </Column>
                          </Row>
                        )}
                      </Column>
                    </CardBox>
                  </Column>
                ),
              },
              {
                label: "Mentions légales",
                icon: <FaFileContract />,
                hidden: !settings.peutChangerMentions,
                content: (
                  <Column fill>
                    <CardBox fill>
                      <Column fill>
                        <Row>
                          <h1>Mentions légales</h1>
                        </Row>
                        <Row fill>
                          <Column fill id="mentions">
                            <TextField
                              label="Mentions légales"
                              dataType="string"
                              value={settings.mentions}
                              onChange={(evt) => auth.modifierPreferences("mentions", evt.target.value)}
                              multiline
                              rows={10}
                              style={{ width: "98%" }}
                            />
                          </Column>
                        </Row>
                      </Column>
                    </CardBox>
                  </Column>
                ),
              },
            ]}
            page={settings.peutChangerCouleurs === true ? 1 : settings.peutChangerLogo === true ? 2 : 3}
            navButtons={{
              cancel: {
                onClick: () => {
                  modal.hide();
                  props?.onCancel?.();
                },
              },
              previous: {},
              next: {},
              validate: {
                onClick: () => {
                  auth.sauverPreferences(() => {
                    modal.hide();
                    props?.onValidate?.();
                  });
                },
              },
            }}
          />
        )}
      </ModalContext.Consumer>
    </Modal>
  ) : (
    <></>
  );
};
export default Settings;
