import { useContext} from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function IncapCondGen() {
  const context = useContext(AppContext);


  const applicationFranchiseHospi = [
    {
      value: "Non communiqué",
      label: "Non communiqué",
    },
    {
      value: "Sans condition",
      label: "Sans condition",
    },
    {
      value: "Prise en charge en ambulatoire",
      label: "Prise en charge en ambulatoire",
    },
    {
      value: "Prise en charge en ambulatoire en cas d’intervention chirurgicale",
      label: "Prise en charge en ambulatoire en cas d’intervention chirurgicale",
    },
    {
      value: "Prise en charge en ambulatoire en cas d’intervention chirurgicale ou d’anesthésie",
      label: "Prise en charge en ambulatoire en cas d’intervention chirurgicale ou d’anesthésie",
    },
    {
      value: "Hosp. de 24 H",
      label: "Hospitalisation de 24h",
    },
    {
      value: "Hosp. de 48 H",
      label: "Hospitalisation de 48h",
    },
    {
      value: "Hosp. de 72 H",
      label: "Hospitalisation de 72h",
    },
    {
      value: "Hospitalisation de 72h ou ambulatoire si arrêt de travail de plus de 7 jours",
      label: "Hospitalisation de 72h ou ambulatoire si arrêt de travail de plus de 7 jours",
    },
  ];

  return (
    <>
    <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <DropDown
                  label="Franchise hospitalisation"
                  value={context.state.ApplicationFranchiseHospi}
                  onChange={context.handleInputChange("ApplicationFranchiseHospi")}
                  options={applicationFranchiseHospi}
                  xxlarge
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>

    </>
  );
}

export default IncapCondGen;
