import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

const Charges = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>CSG déductibles</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="CSG déductibles sur revenus du patrimoine" VarName1={Outil + "IRCSGDedRevenuPatrimoine"} VarName2="" />
                <tr>
                  <td colSpan={2}>Pensions alimentaires versées à des enfants majeurs en vertu d'une décision de justice avant 2006</td>
                </tr>
                <tr>
                  <td>
                    1 <sup>ier</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRPensionAlimEnfant1"]}
                      onChange={context.handleInputChange([Outil + "IRPensionAlimEnfant1"])}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    2 <sup>ième</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRPensionAlimEnfant2"]}
                      onChange={context.handleInputChange([Outil + "IRPensionAlimEnfant2"])}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Autres pensions alimentaires versées à des enfants majeurs</td>
                </tr>
                <tr>
                  <td>
                    1 <sup>ier</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRAutrePensionAlimEnfant1"]}
                      onChange={context.handleInputChange([Outil + "IRAutrePensionAlimEnfant1"])}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    2 <sup>ième</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRAutrePensionAlimEnfant2"]}
                      onChange={context.handleInputChange([Outil + "IRAutrePensionAlimEnfant2"])}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </td>
                </tr>
                <LigneImpotDetaille Libelle="Autres pensions alimentaires versées  (décision de justice avant 2006)" VarName1={Outil + "IRPensionAlim"} VarName2="" />
                <LigneImpotDetaille Libelle="Autres pensions alimentaires" VarName1={Outil + "IRAutrePensionAlim"} VarName2="" />
                <LigneImpotDetaille Libelle="Déductions fiscales diverses" VarName1={Outil + "DeductionFiscale"} VarName2="" />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default Charges;
