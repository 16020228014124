import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamResultatEntrePrisePageModal from "pages/audit complet/situation professionnelle/modules/parametre resultat entreprise modal";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaCogs, FaSync } from "react-icons/fa";
import ParamImpotSocietePageModal from "pages/audit complet/situation professionnelle/modules/parametre impot societe modal";
import Modal from "components/ui/modal/modal";
import ParamImpotSociete from "./ParamImpotSociete";
import { CalculResultatEntreprise } from "components/app/Api/Remuneration/ApiRemuneration";

function Dividendes(props) {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const Prenom=props.ClientConjoint===""?context.state.Prenom:context.state.PrenomConjoint;
  const PrenomConjoint=props.ClientConjoint!==""?context.state.Prenom:context.state.PrenomConjoint;
  useEffect(() => {
    if (context.state.SetSeuilAssuj === false) {
      CalculResultatEntreprise(context.state, "").then((ResultatEntreprise) => {
        context.handleValueChange("SeuilAssuj", ResultatEntreprise.SeuilAssuj);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetSeuilAssuj]);
  useEffect(() => {
    if (context.state.SetSeuilAssujConjoint === false) {
      CalculResultatEntreprise(context.state, "").then((ResultatEntreprise) => {
        context.handleValueChange("SeuilAssujConjoint", ResultatEntreprise.SeuilAssujConjoint);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetSeuilAssujConjoint]);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <h1>Détention capitalistique</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Capital social"
                  value={context.state.CapitalSocial}
                  onChange={context.handleInputChange("CapitalSocial")}
                  onBlur={(e) => {
                    context.handleCalculResultatEntreprise("", "CapitalSocial", e);
                  }}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label={"Détention capitalistique " + Prenom}
                  value={context.state.PartSoc}
                  onBlur={(e) => {
                    context.handleCalculResultatEntreprise("", "PartSoc", e);
                  }}
                  normal
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                />
              </Column>
              {context.state.InclureConjoint && (
                <Column lpad>
                  <TextField
                    label={"Détention capitalistique " + PrenomConjoint}
                    value={context.state.PartSocConj}
                    onBlur={(e) => {
                      context.handleCalculResultatEntreprise("", "PartSocConj", e);
                    }}
                    disabled={context.state.PartSoc === 100}
                    normal
                    dataType="ufloat"
                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                  />
                </Column>
              )}
            </Row>
            <Row tpad>
              <h1>Résultat distribué</h1>
            </Row>
            {props.Outil === "" && (
              <>
                <Row tpad>
                  <Column>
                    <TextField
                      label={context.state.InclureConjoint ? "Dividendes bruts du couple " : "Dividendes bruts pour " + context.state.Prenom}
                      value={context.state.DividendeBrut}
                      onBlur={(e) => {
                        context.handleCalculResultatEntreprise("", "DividendeBrut", e);
                      }}
                      disabled={"AccesModal" in props}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <ParamResultatEntrePrisePageModal Prenom={Prenom} PrenomConjoint={PrenomConjoint} />
                  </Column>
                </Row>
              </>
            )}
            {props.Outil !== "" && (
              <>
                <Row tpad>
                  <Column>
                    <TextField
                      value={context.state[props.Outil + "Reserve"]}
                      label="Mise en réserve"
                      onChange={context.handleInputChange(props.Outil + "Reserve")}
                      dataType="uint"
                      normal
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </Column>
                </Row>
                {props.Outil === "RemExpress" && (
                   <Row tpad>
                  <Column>
                    <Button normal onClick={() => setmodalOpen(true)}>
                      <span>Paramétrage de l'IS</span>
                    </Button>
                    <Modal
                      width="40%"
                      height="42%"
                      title="Paramétrage de l'impôt sur les sociétés"
                      icon={<FaCogs />}
                      visible={modalOpen}
                      onClose={() => {
                        setmodalOpen(false);
                      }}
                    >
                      <ParamImpotSociete
                        onClose={() => {
                          setmodalOpen(false);
                        }}
                      />
                    </Modal>
                    </Column>
                    </Row>
                )}
                <Row tpad>
                  <h1>Régime social des dividendes</h1>
                </Row>
                {props.Outil === "RemExpress" && (
                  <Row tpad>
                    <Column>
                      <TextField
                        iconBefore={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              context.handleValueChange("SetSeuilAssuj", false);
                            }}
                          >
                            {context.state.SetSeuilAssuj === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                          </Button>
                        }
                        label={"Seuil d'assujetissement pour " + context.state.Prenom}
                        labelStyle={{ whiteSpace: "nowrap" }}
                        value={context.state.SeuilAssuj}
                        onChange={(e) => {
                          context.handleValueChange("SetSeuilAssuj", true);
                          context.handleValueChange("SeuilAssuj", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </Column>
                  </Row>
                )}
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default Dividendes;
