import { useContext, useState } from "react";
import { FaUser } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import ListeAudits from "components/app/Extranet/ListeAudits";

const AuditsPage = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePages = () => {
   
    let pages = [
      {
        isTitle: true,
        title: "Extranet",
        subTitle: "Dossier client",
      },
      {
        label: context.state.Prenom+" "+context.state.Nom,
        icon: <FaUser />,
        content: <ListeAudits AccesEtude={props.AccesEtude} SetAccessEtude={props.SetAccessEtude} />,
      },
    ];
    return pages;
  };

  return (
    <Pager
      withMenu
      pages={handlePages()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default AuditsPage;
