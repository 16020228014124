import { saveAs } from "file-saver";
import { RemoveUnecessaryData, ApiUrl, PrintApiUrl } from "../ApiData";

const APIURL = ApiUrl;
const Timeout = 10000;

export async function AuditCompletPptx(Data, ResultatTns,ResultatSalarie) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  formData.append("ResultatSalarie", JSON.stringify(ResultatSalarie));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditCompletPPTX.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " BP2R " + Data.Civilite + " " + Data.Nom + ".pptx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function AuditCompletWord(Data, ResultatTns,ResultatSalarie) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  formData.append("ResultatSalarie", JSON.stringify(ResultatSalarie));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditComplet.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " BP2R " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function AuditOptiRemWord(Data, ResultatTns,ResultatSalarie) {
  let CleanData = RemoveUnecessaryData(Data);
  CleanData.OptiRemResultatPrevoyance=Data.OptiRemResultatPrevoyance;
  CleanData.OptiRemResultatPrevoyanceConjoint=Data.OptiRemResultatPrevoyanceConjoint;
  CleanData.OptiRemResultatRetraite=Data.OptiRemResultatRetraite;
  CleanData.OptiRemResultatRetraiteConjoint=Data.OptiRemResultatRetraiteConjoint;
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  formData.append("ResultatSalarie", JSON.stringify(ResultatSalarie));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditOptiRem.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude rémunération " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function AuditRetraiteWord(Data, ResultatTns) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditRetraite.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude retraite " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function AuditRetraitePptx(Data, ResultatTns) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditRetraitePPTX.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude retraite " + Data.Civilite + " " + Data.Nom + ".pptx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}

export async function AnalyseCarriereWord(Data, DataRIS,ClientConjoint) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("DataRIS", JSON.stringify(DataRIS));
  formData.append("ClientConjoint", ClientConjoint);

  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AnalyseCarriere.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Analyse de la carrière " + Data['Civilite'+ClientConjoint] + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function SyntheseCalculWord(Data, Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditSyntheseCalcul.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Synthèse des calculs " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}

export async function GestionnaireScenarioPptx(Data, ResultatTns) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Resultat", JSON.stringify(ResultatTns));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditGestionnaireScenarioPPTX.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude rémunération " + Data.Civilite + " " + Data.Nom + ".pptx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function ArbitragePptx(Data, Result100Rem,Result100Div,ResultOptimum) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Result100Rem", JSON.stringify(Result100Rem));
  formData.append("Result100Div", JSON.stringify(Result100Div));
  formData.append("ResultOptimum", JSON.stringify(ResultOptimum));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}ArbitragePPTX.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude rémunération " + Data.Civilite + " " + Data.Nom + ".pptx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}

export function RapportWORDRachatTrimestre(Data,ClientConjoint, Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ClientConjoint", ClientConjoint);
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditRachatTrimestre.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   ETUDE RACHAT DE TRIMESTRES " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })


}
export function RapportWORDRetraiteProgressive(Data,ClientConjoint, Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ClientConjoint", ClientConjoint);
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditRetraiteProgressive.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   ETUDE RETRAITE PROGRESSIVE " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })

}

export async function AuditPrevoyanceWord(Data, Resultat, Preco) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(Resultat));
  formData.append("Preco", Preco);
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditPrevoyance.php`, {
    method: "post",
    body: formData,
  })
  .then(function (res) {
    return res;
  })
  .then((res) => {
    res.clone().text().then((text) => {
      if (text.substring(0, 2) === "PK") {
        res.blob().then((blob)=>{
          saveAs(blob, dd + "-" + mm + "-" + yyyy + "  Etude prévoyance " + Data.Civilite + " " + Data.Nom + ".docx");
        });
      } else {
        console.log(text);
      }
    });
  })
    // .then((res) => res.blob())

    // .then((blob) => saveAs(blob, dd + "-" + mm + "-" + yyyy + " AUDIT PREVOYANCE " + Data.Civilite + " " + Data.Nom + ".docx"));
  // await new Promise((r) => setTimeout(r, Timeout));
}
export async function AuditPrevoyancepptx(Data, ResultatTns, Preco) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  formData.append("Preco", Preco);
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditPrevoyancePPTX.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude prévoyance " + Data.Civilite + " " + Data.Nom + ".pptx");
          });
        } else {
          console.log(text);
        }
      });
    })

  // await new Promise((r) => setTimeout(r, Timeout));
}


export function SyntheseCaculatriceAgircArrco(Data,Total) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Total", JSON.stringify(Total));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditCalculatriceAgircArrco.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   SYNTHESE AGIRC ARRCO " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })


}
export function SyntheseDeclarationPER(Data,Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditDeclarationPER.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   DECLARATION EPARGNE RETRAITE " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })


}
export function SyntheseCorrectionPER(Data,Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditCorrectionPER.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   CORRECTION DES PLAFONDS EPARGNE RETRAITE " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })


}
export function SyntheseStrategiePER(Data,Resultat) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Resultat", JSON.stringify(Resultat));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

    // ES6
    fetch(`${PrintApiUrl}AuditStrategiePER.php`, {
      method: "post",
      body: formData,
    })
    .then(function (res) {
      return res;
    })
    .then((res) => {
      res.clone().text().then((text) => {
        if (text.substring(0, 2) === "PK") {
          res.blob().then((blob)=>{
            saveAs(blob, dd + "-" + mm + "-" + yyyy + "   ETUDE EPARGNE RETRAITE " + Data.Civilite + " " + Data.Nom + ".docx");
          });
        } else {
          console.log(text);
        }
      });
    })


}

export async function ExportData(Data, ResultatTns) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ResultatTns", JSON.stringify(ResultatTns));
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  // ES6
  fetch(`${PrintApiUrl}AuditRetraiteExportData.php`, {
    method: "post",
    body: formData,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    // }
  })
    // .then(function (res) {
    //   return res;
    // })
    .then(res => res.blob())

    .then(blob => saveAs(blob, dd + "-" + mm + "-" + yyyy + " Données " + Data.Civilite + " " + Data.Nom + ".json"));
    // .then((res) => {
    //   console.log(res)
    //   // res.clone().text().then((text) => {
    //   //   if (text.substring(0, 2) === "PK") {
    //   //     res.blob().then((blob)=>{
    //   //       saveAs(blob, dd + "-" + mm + "-" + yyyy + " Etude retraite " + Data.Civilite + " " + Data.Nom + ".docx");
    //   //     });
    //   //   } else {
    //   //     console.log(text);
    //   //   }
    //   // });
    // })

  // await new Promise((r) => setTimeout(r, Timeout));
}