import React, {  useEffect } from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";

const DetailIncapacite = ({ Outil, TabIJ, GraphIJ, Affichage }) => {
  useEffect(() => {
    document.getElementById("GraphIJ").innerHTML = GraphIJ;
  }, []);

  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <h1>Prestations d'Incapacité</h1>
          </Row>
          <Row tpad bpad>
            <table>
              <thead>
                <tr className="light-title ">
                  <td></td>
                  <td className="text-center">Régime(s) obligatoire(s)</td>
                  <td className="text-center">Régime(s) facultatif(s)</td>
                  <td className="text-center">Total</td>
                </tr>
              </thead>
              <tbody>
                {TabIJ.map((data, i) => {

                  return (
                    <React.Fragment>
                      <tr>
                        <td>De {data.category.replace("-", " à ")} jours</td>
                        <td className="text-center">{applyFormat(Math.max(0, data["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        <td className="text-center">{applyFormat(Math.max(0, data["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        <td className="text-center">{applyFormat(Math.max(0, data["Total"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </Row>
          <Row tpad bpad>
            <h1>Graphique de l'incapacité</h1>
          </Row>
          <Row tpad style={{with:'500px',height:"500px"}} id="GraphIJ"></Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailIncapacite;
