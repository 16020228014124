import { useState, useContext } from "react";
import { FaEdit, FaIdBadge, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import SituationProPageModal from "./modules/situation professionnelle pager";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";

const SituationProPage = (props) => {
  const context = useContext(AppContext);
  const [clientConjoint, setClientConjoint] = useState("");
  const [modalOpen, setmodalOpen] = useState(false);

  const handleSitPro = (Statut, Caisse) => {
    if (Statut === "TNS Article 62" || Statut === "TNS individuel") {
      const corrCaisseSitPro = {
        RsiArtisan: "Artisan",
        RsiCommercant: "Commerçant",
        Carpv: "Vétérinaire",
        Cavec: "Expert comptable",
        Cipav: "Profession libérale",
        Crn: "Notaire",
        Cnbf: "Avocat",
        Cavamac: "Agent général d'assurance",
        Cavom: "Officier ministériel",
        Msa: "Exploitant agricole",
        Carmf: "Médecin",
        Carpimko: "Auxiliaire médical",
        Carcdsf: "Chirurgien-dentiste",
        Carcdsf2: "Sage-femme",
        Cavp: "Pharmacien",
      };
      return corrCaisseSitPro[Caisse];
    }
    return Statut;
  };
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Situation professionnelle pour {context.state.Prenom}</h1>
                </Row>
                <Row center>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      disabled={context.state.PeriodeActuelle.length > 0 ? true : false}
                      onClick={() => {
                        setClientConjoint("");
                        setmodalOpen(true);
                      }}
                    >
                      <span>Situation professionnelle existante</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>
                </Row>

                {context.state.PeriodeActuelle.length > 0 ? (
                  <Row tpad>
                    <table>
                      <thead>
                        <tr>
                          <th>Date de début</th>
                          <th>Date de fin</th>
                          <th>Sit.Professionnelle</th>
                          <th>Entreprise</th>
                          <th style={{ width: "80px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {context.state.PeriodeActuelle.map((Infos, Index) => (
                          <tr key={Index}>
                            <td>{applyFormat(Infos.DebutPeriodeCarriereActuelle, "date", "")}</td>
                            <td>{applyFormat(Infos.FinPeriodeCarriereActuelle, "date", "")}</td>
                            <td>{handleSitPro(Infos.Statut, Infos.Caisse)}</td>
                            <td>{Infos.IdentiteEntreprise}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  context.handleModifyDeletePeriodeActuelle(Infos.IdPeriodeActuelle, "Modifier", "", "");
                                  setClientConjoint("");
                                  setmodalOpen(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaEdit id="editSituationPro" />
                              </Button>

                              <Button
                                onClick={() => {
                                  Dialogs.confirm("Vous êtes sur le point de supprimer la période.\nVoulez-vous continuer ?", "Suppression de la période", () => {
                                    context.handleModifyDeletePeriodeActuelle(Infos.IdPeriodeActuelle, "Supprimer", "", "");
                                  });
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaTrashAlt className="delete" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                ) : (
                  <Row>
                    <Column>Aucune situation professionnelle pour {context.state.Prenom}</Column>
                  </Row>
                )}
              </Column>
            </CardBox>
          </Column>
        </Row>
        {context.state.InclureConjoint && (
          <Row>
            <Column fill>
              <CardBox tpad>
                <Column fill>
                  <Row>
                    <h1>Situation professionnelle pour {context.state.PrenomConjoint}</h1>
                  </Row>
                  <Row center>
                    <Column style={{ paddingLeft: 0 }}>
                      <Button
                        disabled={
                          context.state.PeriodeActuelle.length === 0 ||
                          context.state.PeriodeActuelleConjoint.length > 0 ||
                          (context.state.PeriodeActuelle[0]?.StatutConjoint !== undefined && context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun")
                            ? true
                            : false
                        }
                        onClick={() => {
                          setClientConjoint("Conjoint");
                          setmodalOpen(true);
                        }}
                      >
                        <span>Situation professionnelle existante</span>
                        <FaPlusCircle />
                      </Button>
                    </Column>
                  </Row>
                  {context.state.PeriodeActuelleConjoint.length > 0 ||
                  (context.state.PeriodeActuelle[0]?.StatutConjoint !== undefined && context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun") ? (
                    <Row tpad>
                      <table>
                        <thead>
                          <tr>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>Sit.Professionnelle</th>
                            <th>Entreprise</th>
                            <th style={{ width: "80px" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {context.state.PeriodeActuelle[0]?.StatutConjoint !== undefined && context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && (
                            <>
                              {context.state.PeriodeActuelle.map((Infos, Index) => (
                                <tr key={"Conj" + Index}>
                                  <td>{applyFormat(Infos.DebutPeriodeCarriereActuelle, "date", "")}</td>
                                  <td>{applyFormat(Infos.FinPeriodeCarriereActuelleConjoint, "date", "")}</td>
                                  <td>{handleSitPro(Infos.StatutConjoint, Infos.Caisse)}</td>
                                  <td>{Infos.IdentiteEntreprise}</td>
                                  <td></td>
                                </tr>
                              ))}
                            </>
                          )}

                          {context.state.PeriodeActuelleConjoint.map((Infos, Index) => (
                            <tr key={"Conj2" + Index}>
                              <td>{applyFormat(Infos.DebutPeriodeCarriereActuelle, "date", "")}</td>
                              <td>{applyFormat(Infos.FinPeriodeCarriereActuelle, "date", "")}</td>
                              <td>{handleSitPro(Infos.Statut, Infos.Caisse)}</td>
                              <td>{Infos.IdentiteEntreprise}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    context.handleModifyDeletePeriodeActuelle(Infos.IdPeriodeActuelle, "Modifier", "", "Conjoint");
                                    setClientConjoint("Conjoint");
                                    setmodalOpen(true);
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  onClick={() => {
                                    Dialogs.confirm("Vous êtes sur le point de supprimer la période.\nVoulez-vous continuer ?", "Suppression de la période", () => {
                                      context.handleModifyDeletePeriodeActuelle(Infos.IdPeriodeActuelle, "Supprimer", "", "Conjoint");
                                    });
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaTrashAlt className="delete" />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  ) : (
                    <Row>
                      <Column>Aucune situation professionnelle pour {context.state.PrenomConjoint}</Column>
                    </Row>
                  )}
                </Column>
              </CardBox>
            </Column>
          </Row>
        )}
      </Column>
      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaIdBadge />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <SituationProPageModal
          Outil=""
          ClientConjoint={clientConjoint}
          visible={modalOpen}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default SituationProPage;
