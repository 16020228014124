import { FaArrowCircleRight } from "react-icons/fa";
import Button from "../controls/button/button";
import Column from "../layout/column/column";
import Row from "../layout/row/row";
import './companies.css';

/**
 * List of companies.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Companies = (props) => {

    /**
     * Generates company rows.
     * @returns Generated rows.
     */
    const generateRows = () => {

        // Assert comanies
        if (!props?.list) return <></>;

        // Return rows
        return props.list.map((company) => {

            // Render row
            return (
                <tr key={company.id}>
                    <td>{company.name}</td>
                    <td>{company.association || 'Cabinet indépendant'}</td>
                    <td>
                        <Button icon onClick={() => {
                            props?.onSelect?.(company.id)
                        }}>
                            <FaArrowCircleRight />
                        </Button>
                    </td>
                </tr>
            )
        });
    }

    return (
        <Column className="companies" fill>
            <Row>
                <blockquote>
                    Sélectionnez le cabinet auquel vous souhaitez vous connecter.
                </blockquote>
            </Row>
            <Row vpad className="companies-row" fill>
                <Column className="companies-content" fill scroll>
                    <table>
                        <tbody>
                            {generateRows()}
                        </tbody>
                    </table>
                </Column>
            </Row>
            <Row>
                <Column fill center>
                    <Button large onClick={props?.onCancel}>Annuler</Button>
                </Column>
            </Row>
        </Column>
    )
}
export default Companies;