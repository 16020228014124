import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 30
    },
    reportTitle:{
        fontSize: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>MAKO LOGICIELS - Société à responsabilité limitée au capital de 1000,00 € 
N° Siret : 83171605500023 - R.C.S. Avignon B 831716055 - Code APE : 6201Z - TVA intracommunautaire : FR08831716055
</Text>
    </View>
  );
  
  export default InvoiceThankYouMsg