import React from "react";
import { useContext } from "react";
// import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync, FaUpload } from "react-icons/fa";
import FileField from "components/ui/controls/file-field/file-field";
import { ModalContext } from "components/ui/modal/modal-provider";
import { AppContext } from "components/app/RemExprertProvider";
import queryString from "query-string";
import { RISContext } from "../../RIS/RISProvider";
import RGProvider, { RGContext } from "./RGProvider";
// import { ModalContext } from "components/ui/modal/modal-provider";

const LectureRG = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  const RGCtx = useContext(RGContext);
  const Params = queryString.parse(window.location.search);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <blockquote>
                L'import du relevé du régime général permet d'intégrer réellement les revenus soumis à cotisations sociales au régime de base de la sécurité sociale. En effet, depuis le 1er
                juillet 2023, le RIS affiche les revenus réellement perçus, ce qui peut entrainer des erreurs sur des périodes à temps partiel notamment. Attention, il est indispensable dans
                un premier temps, d'importer le RIS pour que la correction s'applique.
              </blockquote>
            </Row>
            <Row tpad bpad>
              <FileField
                label={"Chargement fichier"}
                value=""
                onChange={RGCtx.handleUploadRG(context.state)}
                // onChange={(evt) => {
                //   console.log(evt.target.files[0]);
                // }}
                normal
              >
                <span>Envoyer un fichier</span>
                <FaUpload />
              </FileField>
            </Row>
            {RGCtx.ErrorMessage !== "" && (
              <Row tpad>
                <blockquote style={{ borderLeft: "5px solid red" }}>{RGCtx.ErrorMessage}</blockquote>
              </Row>
            )}
            {RGCtx.SuccessMessage === true && (
              <>
                <Row tpad>
                  <blockquote style={{ borderLeft: "5px solid green" }}>
                    Le rélévé carrière de carrière a bien été importé.Vous pouvez modifier les revenus dans l'onglet "Carrière" si besoin.
                    <br />
                  </blockquote>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <th>
                        <TextField className="invisible" value={"Année"} />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Période"} />
                      </th>
                      <th>
                        <TextField className="invisible" value={"Revenu"} />
                      </th>
                    </thead>
                    <tbody>
                      {RGCtx.ReleveCarriere?.map((infos) => {
                        return (
                          <>
                            <tr>
                              <td rowSpan={infos.periodes.length}>
                                <TextField style={{ textAlign: "center" }} disabled value={infos.annee} />
                              </td>
                              <td>
                                <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[0].libelle} />
                              </td>
                              <td>
                                <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[0].revenu} />
                              </td>
                            </tr>
                            {infos.periodes.length === 2 && (
                              <tr>
                                <td>
                                  <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[1].libelle} />
                                </td>
                                <td>
                                  <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[1].revenu} />
                                </td>
                              </tr>
                            )}
                            {infos.periodes.length === 3 && (
                              <tr>
                                <td>
                                  <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[2].libelle} />
                                </td>
                                <td>
                                  <TextField style={{ textAlign: "center" }} disabled value={infos.periodes[2].revenu} />
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default LectureRG;
