import "./resultatRemExpress.css";
import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaCog, FaIdBadge, FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DetailTNSPageModal from "pages/audit complet/résultat/detail/rémuneration/detailTNSPager";
import DetailSalariePageModal from "pages/audit complet/résultat/detail/rémuneration/detailSalariePager";
import RemExpressRemunerationTns from "components/app/AuditRemuneration/Resultat/RemExpressRemunerationTns";
import RemExpressRemunerationSalarie from "components/app/AuditRemuneration/Resultat/RemExpressRemunerationSalarie";
import RemExpressResAvtIS from "components/app/AuditRemuneration/Resultat/RemExpressResAvtIS";
import RemExpressRevenus from "components/app/AuditRemuneration/Resultat/RemExpressRevenus";
import { SyntheseCalculWord } from "components/app/Api/Rapport/ApiRapport";
// import DetailTNSPageModal from "./detail/rémuneration/detailTNSPager";
// import DetailSalariePageModal from "./detail/rémuneration/detailSalariePager";
// import DropDown from "components/ui/controls/drop-down/drop-down";
// import { AuditCompletWord } from "components/app/Api/Rapport/ApiRapport";

const ResultatRemExpressPage = (props) => {
  let { ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalDetail, setModalDetail] = useState(false);
  const Periode = context.state["OptiRemPeriodeActuelle" + ClientConjoint] === undefined ? undefined : context.state["OptiRemPeriodeActuelle" + ClientConjoint][0];
  const Prenom = context.state.Prenom;
  const [circularWidth, setCircularWidth] = useState("0px");

  useEffect(() => {
    context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "", ResultatTNS, "");
  }, []);

  let cotProductiveRetraiteObligatoire = 0;

  cotProductiveRetraiteObligatoire =
    ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteObligatoire === undefined
      ? 0
      : ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteObligatoire;

  let cotProductiveRetraiteFacultative = 0;
  cotProductiveRetraiteFacultative = isNaN(
    Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteFacultative) +
      Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPEEProductif) +
      Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPERCOProductif)
  )
    ? 0
    : ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteFacultative +
      ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPEEProductif +
      ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPERCOProductif;

  return (
    <>
      <Column fill>
        <Row>
          <Column fill style={{ minWidth: "500px", maxWidth: "820px" }}>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="resultatRemExpress">
                <tbody>
                  <tr>
                    <td className="libelle">Enveloppe entreprise</td>
                    <td className="nouvelle">
                      {applyFormat(context.state.RemExpressCoutEntreprise, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      {/* <TextField
                            iconBefore={
                              <Button icon>
                                <FaCalculator />
                              </Button>
                            }
                            value={context.state.RemExpressCoutEntreprise}
                            onChange={(e) => {
                              context.handleCalculRemExpress("cout_entreprise", "RemExpressCoutEntreprise", ResultatTNS, e);
                            }}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            small
                          /> */}
                    </td>
                    <td className="extra"></td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            {context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel" ? (
              <RemExpressRemunerationTns Prenom={Prenom} />
            ) : (
              <RemExpressRemunerationSalarie Prenom={Prenom} />
            )}

            {context.state.Statut !== "TNS individuel" && <RemExpressResAvtIS ClientConjoint={ClientConjoint} Periode={Periode} Prenom={Prenom} />}

            <RemExpressRevenus />
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="resultatRemExpress">
                <tbody>
                  <tr>
                    <td className="libelle">Revenu disponible</td>
                    <td className="nouvelle">
                      <TextField
                        iconBefore={
                          <Button icon>
                            <FaCalculator />
                          </Button>
                        }
                        value={context.state.RemExpressRevDispo}
                        onChange={(e) => {
                          context.handleCalculRemExpress("revenu_dispo", "RemExpressRevDispo", ResultatTNS, e);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        small
                      />
                    </td>
                    <td className="extra"></td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="resultatRemExpress">
                <tbody>
                  <tr>
                    <td className="libelle">Cotisations productives en matière de retraite</td>
                    <td className="nouvelle pr4">{applyFormat(cotProductiveRetraiteObligatoire + cotProductiveRetraiteFacultative, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="extra"></td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>

          <Column fill style={{ maxWidth: "600px" }}>
            <Row bpad className=" blockRoundedForce">
              <Column fill>
                <Row>
                  <Column fill>
                    <Button
                      onClick={() => {
                        setModalDetail(true);
                      }}
                    >
                      <FaCalculator />
                      <span>Détails {Prenom}</span>
                    </Button>
                  </Column>
                </Row>
                <Row>
                  <Column fill>
                    <Button
                      onClick={() => {
                        SyntheseCalculWord(context.state, ResultatTNS.state).then((res) => {});
                      }}
                    >
                      <span>
                        {" "}
                        <FaPrint />
                        Synthèse des calculs
                      </span>
                    </Button>
                  </Column>
                </Row>
                <Row>
                  <Column fill>
                    <Button
                      onClick={() => {
                        props.GoToSimulation();
                      }}
                    >
                      <FaCog />
                      <span>Simulation</span>
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>

            <CardBox tpad className=" blockRoundedForce dark-title">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>Cotisations productives en matière de retraite</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad className="blockRoundedForce">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>Cotisations de retraite obligatoire</td>
                    <td>{applyFormat(cotProductiveRetraiteObligatoire, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Cotisations de retraite facultative</td>
                    <td>{applyFormat(cotProductiveRetraiteFacultative, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    {/* <td>{applyFormat(TotalRenteObligatoireApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td> */}
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>
        </Row>
      </Column>
      {/* MODAL DETAIL CLIENT */}
      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetail}
        onClose={() => {
          setModalDetail(false);
        }}
      >
        {context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel" ? (
          <DetailTNSPageModal
            AccessRemExpress={true}
            ClientConjoint=""
            Outil=""
            Prenom={Prenom}
            Caisse={context.state.Caisse}
            DetailImpot={ResultatTNS.state.DetailImpot}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerp}
            GraphDisponible={ResultatTNS.state.RemExpressRemuneration?.GraphDisponible}
            DetailCotisations={ResultatTNS.state.RemExpressRemuneration?.DetailCotisations}
            DetailAssiette={ResultatTNS.state.RemExpressRemuneration?.DetailAssiette}
            DetailCSG={ResultatTNS.state.RemExpressRemuneration?.DetailCSG}
            TotalChargesSociales={ResultatTNS.state.RemExpressRemuneration?.TotalChargesSociales}
            DetailAssietteClient={ResultatTNS.state.RemExpressRemuneration?.DetailAssiette}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            AccessRemExpress={true}
            Outil=""
            ClientConjoint=""
            Prenom={Prenom}
            DetailImpot={ResultatTNS.state.DetailImpot}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerp}
            DetailFicheDePaie={ResultatTNS.state.RemExpressRemuneration?.DetailFicheDePaie}
            DetailAssietteClient={ResultatTNS.state.RemExpressRemuneration?.DetailFicheDePaie}
            GraphDisponible={ResultatTNS.state.RemExpressRemuneration?.GraphDisponible}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ResultatRemExpressPage;
