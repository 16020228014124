import { useEffect, useState } from "react";
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from "../../ui-helpers";
import './file-field.css';

/**
 * Generates a file input component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const FileField = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['label', 'labelStyle', 'value', 'onChange', 'disabled'].concat(Alignments, Paddings, Sizes);

    //#region Input events

    /**
     * Handles an input change event.
     * @param {ChangeEvent} evt Change event.
     * @returns False if event is cancelled.
     */
    const handleChange = (evt) => {

        // Update value
        const file = evt.target.files[0];
        return props?.onChange?.(evt, file);
    }

    //#endregion

    /**
     * Generates input element.
     * @returns Input element.
     */
    const generateInput = () => <input
        id={id}
        type='file'
        onChange={handleChange}
        value=''
        disabled={isDisabled}
        {...getOptionalProps(props, Excluded)}
    />

    // Initialize states
    const [id] = useState(props?.id ? props.id : getUniqId());
    const [isDisabled, setDisabled] = useState(props?.disabled !== undefined ? props?.disabled : false);

    /**
     * On value property change.
     */
    useEffect(() => {
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);        
    }, [props?.disabled, isDisabled])

    // Generate component
    return (
        <div
            className={classFromProperties(
                props,
                'file-field' +
                (isDisabled ? ' disabled' : ''),
                Alignments,
                Paddings,
                Sizes,
                ['hidden'])}
            tabIndex="-1"
        >
            {props?.label ? <label tabIndex="-1" className="file-field-label" htmlFor={id} style={props?.labelStyle}>{props.label}</label> : <></>}
            {generateInput()}
            <label htmlFor={id} className={classFromProperties(
                props,
                'file-field-button' +
                (isDisabled ? ' disabled' : ''),
                Alignments)}
            >
                {props?.children}
            </label>
        </div>
    )
}

export default FileField;