import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'flex-end',
        fontFamily: 'Helvetica-Bold'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        fontFamily: 'Helvetica-Bold'
    },
    invoiceDate: {
            fontSize: 12,
    },
    label: {
        // width: 60
    }
    
  });


  const InvoiceNo = ({invoice}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Facture n°: </Text>
                <Text style={styles.invoiceDate}>{invoice.number}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date: </Text>
                <Text >{invoice.date}</Text>
            </View >
        </Fragment>
  );
  
  export default InvoiceNo