import "./App.css";
import "./themes/default.css";
import "./themes/plpsoft.css";
import "./themes/kwiper.css";
import Footer from "./components/ui/layout/footer/footer";
import MainMenu from "./components/ui/layout/main-menu/main-menu";
import Header from "./components/ui/layout/header/header";
import { AppUIContext, AppUIContextProvider } from "./providers/app-ui-context";
import MainContent from "./components/ui/layout/main-content/main-content";
import Content from "./components/ui/layout/content/content";
import AuthProvider, { AuthContext } from "./components/auth/auth-provider";
import Button from "./components/ui/controls/button/button";
import { FaAtom, FaCog, FaCogs, FaFileInvoice, FaFolder, FaGlobe, FaSave, FaVideo } from "react-icons/fa";
import Settings from "./components/app/settings/settings";
import { createRef, useContext, useEffect, useState } from "react";
import RemExpertProvider, { AppContext } from "components/app/RemExprertProvider";
import AuditsPage from "pages/extranet/audits pager";
import AuditCompletPage from "pages/audit complet";
import AuditRetraitePage from "pages/audit retraite";
import AuditRemuPage from "pages/audit rémunération";
import AuditPrevPage from "pages/audit prévoyance";
import ClientsPage from "pages/extranet/clients pager";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Dialogs from "components/ui/dialogs/dialogs";
import AdminPage from "pages/extranet/admin";
import InvoicesPage from "pages/extranet/invoices";
import AppContent from "AppContent";
import CalculatriceAgircArrcoPage from "pages/simulateurs/calculatrice AGIRC ARRCO";
import DeclarationPERPage from "pages/simulateurs/Déclaration PER";
import CorrectionPlafondPERPage from "pages/simulateurs/Correction plafond PER";
import StrategiePERPage from "pages/simulateurs/Strategie PER";

/**
 * Application component.
 * @returns Application component.
 */
const App = () => {
  // Initialiser les états
  const [preferencesVisibles, definirPreferencesVisibles] = useState(false);
  const [accessAdmin, setAccessAdmin] = useState(false);
  const [accessInvoices, setAccessInvoices] = useState(false);
  const context = useContext(AppContext);
  const [accesEtude, setAccessEtude] = useState("");

  const handleAccessDossier = () => {
    setAccessAdmin(false);
  };

  return (
    <RemExpertProvider>
      <AppContext.Consumer>
        {(context) => (
          <AppUIContextProvider name="MAKO" slogan="Logiciels" version="3.0.1">
            <AppUIContext.Consumer>
              {(ui) => (
                <div className={"App " + ui.theme}>
                  <AuthProvider authApiUrl="login">
                    <AuthContext.Consumer>
                      {(auth) => (
                        <AppContent>
                          <div style={{ zIndex: "999999" }} className={"modal-bg" + (context.state.v2 === undefined ? " hidden" : context.state.v2 === false ? " hidden" : "")}>
                            <div style={{ height: "250px", width: "450px" }} className="modal">
                              <Row className="modal-header">
                                <Column className="modal-icon">
                                  <FaGlobe />
                                </Column>
                              </Row>
                              <Row className="modal-content" fill>
                                <Column fill>
                                  <Row fill center>
                                    <Column fill center>
                                      Mise à niveau du dossier.
                                    </Column>
                                  </Row>
                                </Column>
                              </Row>
                            </div>
                          </div>
                          {/* <Modal
                            className="toto"
                            style={{ zIndex: "999999999" }}
                            width="450px"
                            height="25%"
                            title=" "
                            icon={<FaGlobe />}
                            visible={context.state.v2 === undefined ? false : context.state.v2}
                            onClose={() => {}}
                          >
                            <Column fill>
                              <Row fill center>
                                <Column fill center>
                                  Mis à niveau du dossier.
                                </Column>
                              </Row>
                            </Column>
                          </Modal> */}
                          <MainMenu>
                            <Column>
                              <Row>
                                <Button
                                  tool
                                  onClick={() => {
                                    context.state.TypeSimulation !== "" && context.state.AdminAccess !== true ? (
                                      Dialogs.confirm(
                                        "Vous avez effectué des modifications non sauvegardées, souhaitez-vous les enregistrer pour ne pas les perdre ?",
                                        "Gestionnaire de données",
                                        () => {
                                          if (context.state.IdDossier === "") {
                                            context.handleSaveAsDossier(context.state.TypeSimulation);
                                          } else {
                                            context.handleSaveDossier();
                                          }
                                          context.handleValueChange("AccesSimulateur", false);
                                          context.handleValueChange("TypeSimulation", "");
                                          setAccessEtude("");
                                          setAccessAdmin(false);
                                          setAccessInvoices(false);
                                        },
                                        () => {
                                          context.handleValueChange("AccesSimulateur", false);
                                          context.handleValueChange("TypeSimulation", "");
                                          setAccessEtude("");
                                          setAccessAdmin(false);
                                          setAccessInvoices(false);
                                        }
                                      )
                                    ) : (
                                      <>
                                        {context.handleValueChange("AccesSimulateur", false)}
                                        {context.handleValueChange("TypeSimulation", "")}
                                        {setAccessEtude("")}
                                        {setAccessAdmin(false)}
                                        {setAccessInvoices(false)}
                                        {context.handleValueChange("AdminAccess", false)}
                                      </>
                                    );
                                  }}
                                >
                                  <FaFolder />
                                  <span>Extranet</span>
                                </Button>
                              </Row>
                              {context.state.AccesSimulateur === true && context.state.AdminAccess !== true && (
                                <Row>
                                  <Button
                                    tool
                                    onClick={() => {
                                      context.state.TypeSimulation !== "" && context.state.AdminAccess !== true ? (
                                        Dialogs.confirm(
                                          "Vous avez effectué des modifications non sauvegardées, souhaitez-vous les enregistrer pour ne pas les perdre ?",
                                          "Gestionnaire de données",
                                          () => {
                                            if (context.state.IdDossier === "") {
                                              context.handleSaveAsDossier(context.state.TypeSimulation);
                                            } else {
                                              context.handleSaveDossier();
                                            }
                                            context.handleValueChange("TypeSimulation", "");
                                            setAccessEtude("");
                                            setAccessAdmin(false);
                                          },
                                          () => {
                                            context.handleValueChange("TypeSimulation", "");
                                            setAccessEtude("");
                                            setAccessAdmin(false);
                                          }
                                        )
                                      ) : (
                                        <>
                                          {context.handleValueChange("TypeSimulation", "")}
                                          {setAccessEtude("")}
                                          {setAccessAdmin(false)}
                                          {context.handleValueChange("AdminAccess", false)}
                                        </>
                                      );
                                    }}
                                  >
                                    <FaCogs />
                                    <span>Audits</span>
                                  </Button>
                                </Row>
                              )}
                              <Row>
                                <Button
                                  tool
                                  disabled={
                                    !auth.utilisateur_reel ||
                                    (!auth.utilisateur_reel.peutChangerMentions && !auth.utilisateur_reel.peutChangerCouleurs && !auth.utilisateur_reel.peutChangerLogo)
                                  }
                                  onClick={() => {
                                    definirPreferencesVisibles(true);
                                  }}
                                >
                                  <FaCog />
                                  <span>Préférences</span>
                                </Button>
                              </Row>
                              <Row>
                                <Button
                                  tool
                                  onClick={() => {
                                    setAccessInvoices(true);
                                  }}
                                >
                                  <FaFileInvoice />
                                  <span>Factures</span>
                                </Button>
                              </Row>
                              <Row>
                                <Button tool onClick={() => window.open("https://www.youtube.com/@Makologiciels", "_blank", "noopener,noreferrer")}>
                                  <FaVideo />
                                  <span>Aide&nbsp;vidéo</span>
                                </Button>
                              </Row>
                              {(context.state.IdUser === 1 || context.state.IdUser === 2000 || context.state.IdUser === 2001 || context.state.IdUser === 2002) && (
                                <Row>
                                  <Button
                                    tool
                                    onClick={() => {
                                      setAccessAdmin(true);
                                    }}
                                  >
                                    <FaAtom />
                                    <span>Admin</span>
                                  </Button>
                                </Row>
                              )}
                            </Column>
                          </MainMenu>
                          <MainContent>
                            <Header
                              authInfos={auth}
                              userId={auth.utilisateur_identifie?.id}
                              gender={auth.utilisateur_identifie?.civilite === "MME" ? 1 : 0}
                              firstName={auth.utilisateur_identifie?.prenom}
                              lastName={auth.utilisateur_identifie?.nom}
                              subUserId={auth.utilisateur_reel?.id}
                              subGender={auth.utilisateur_reel?.civilite === "MME" ? 1 : 0}
                              subFirstName={auth.utilisateur_reel?.prenom}
                              subLastName={auth.utilisateur_reel?.nom}
                              company={auth.cabinet?.nom}
                              association={auth.groupement?.nom}
                              onDisconnect={() => {
                                auth.deconnecter();
                                context.handleValueChange("AccesSimulateur", false);
                                context.handleValueChange("TypeSimulation", "");
                              }}
                              disconnectDisabled={false}
                            />
                            {accessInvoices === true ? (
                              <Content>
                                <InvoicesPage IdCabinet={context.state.IdCabinet} />
                              </Content>
                            ) : accessAdmin === true ? (
                              <Content>
                                <AdminPage AccessDossier={handleAccessDossier} />
                              </Content>
                            ) : (
                              <Content>
                                {/* <ChangerMDP /> */}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "" && <AuditsPage AccesEtude={accesEtude} SetAccessEtude={setAccessEtude} />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit complet" && <AuditCompletPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit retraite" && <AuditRetraitePage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit rémunération" && <AuditRemuPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit prévoyance" && <AuditPrevPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Calculatrice AGIRC ARRCO" && <CalculatriceAgircArrcoPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Déclaration PER" && <DeclarationPERPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Correction plafond PER" && <CorrectionPlafondPERPage />}
                                {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Stratégie PER" && <StrategiePERPage />}
                                {context.state.AccesSimulateur === false && <ClientsPage />}
                              </Content>
                            )}
                            <Settings
                              visible={preferencesVisibles}
                              onClose={() => {
                                definirPreferencesVisibles(false);
                              }}
                            />
                            <Footer />
                          </MainContent>
                        </AppContent>
                      )}
                    </AuthContext.Consumer>
                  </AuthProvider>
                </div>
              )}
            </AppUIContext.Consumer>
          </AppUIContextProvider>
        )}
      </AppContext.Consumer>
    </RemExpertProvider>
  );
};

export default App;
