import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamFicheDePaiePageModal from "pages/audit complet/situation professionnelle/modules/parametre fiche de paie modal";
import ParamCarcdsf from "../AuditComplet/SituationPro/ParamCaisse/ParamCarcdsf";
import ParamCarmf from "../AuditComplet/SituationPro/ParamCaisse/ParamCarmf";
import ParamCarpimko from "../AuditComplet/SituationPro/ParamCaisse/ParamCarpimko";
import ParamCarpv from "../AuditComplet/SituationPro/ParamCaisse/ParamCarpv";
import ParamCavec from "../AuditComplet/SituationPro/ParamCaisse/ParamCavec";
import ParamCavp from "../AuditComplet/SituationPro/ParamCaisse/ParamCavp";
import ParamCnbf from "../AuditComplet/SituationPro/ParamCaisse/ParamCnbf";
import ParamLpaPageModal from "pages/audit complet/situation professionnelle/modules/parametre lpa modal";
import ParamCrn from "../AuditComplet/SituationPro/ParamCaisse/ParamCrn";
import ParamMsa from "../AuditComplet/SituationPro/ParamCaisse/ParamMsa";



function SituationPro(props) {
  const context = useContext(AppContext);



  return (
    <>
          <Column fill>
            <Row>
              <Column>
                <DropDown
                  label="Statut social"
                  value={context.state.Statut}
                  options={[
                    {
                      value: "TNS Article 62",
                      label: "TNS article 62",
                    },
                    {
                      value: "TNS individuel",
                      label: "TNS Individuel",
                    },
                    {
                      value: "Assimilé salarié",
                      label: "Assimilé salarié",
                    },
                  ]}
                  onChange={
                    context.handleChoixStatutRemExpress
                  }
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                {context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel" ? (
                  <DropDown label="Caisse d'affiliation" value={context.state.Caisse} options={caisse} onChange={context.handleChoixCaisse} normal />
                ) : (
                  <DropDown
                    label="Caisse d'affiliation"
                    value={"Regime"}
                    options={[
                      {
                        value: "Regime",
                        label: "Régime général",
                      },
                    ]}
                    normal
                  />
                )}
              </Column>
            </Row>
            {(context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel") && (
              <>
                {context.state.Caisse === "Carcdsf" && <ParamCarcdsf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carcdsf2" && <ParamCarcdsf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carmf" && <ParamCarmf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carpimko" && <ParamCarpimko Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carpv" && <ParamCarpv Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Cavec" && <ParamCavec Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Cavp" && <ParamCavp Outil="" ClientConjoint="" />}
                {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="" />} */}
                {context.state.Caisse === "Cnbf" && (
                  <>
                    <ParamCnbf Outil="" ClientConjoint="" />
                    {/* <Row tpad>
                      <Column>
                        <ParamLpaPageModal Outil="" ClientConjoint="" />
                      </Column>
                    </Row> */}
                  </>
                )}
                {context.state.Caisse === "Crn" && <ParamCrn Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Msa" && <ParamMsa Outil="" ClientConjoint="" />}
              </>
            )}
              {(context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel") && (
              <Row tpad>
                <Column>
                    <TextField
                  label="Avantages en nature divers"
                  value={context.state.AvantageNature}
                  onChange={context.handleInputChange("AvantageNature")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
                </Column>
              </Row>
            )}
            {(context.state.Statut === "Assimilé salarié" || context.state.Statut === "Salarié cadre" || context.state.Statut === "Salarié non cadre") && (
              <Row tpad>
                <Column>
                  <ParamFicheDePaiePageModal Outil="" ClientConjoint="" />
                </Column>
              </Row>
            )}
          </Column>
    </>
  );
}

export default SituationPro;
