import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const Versement83 = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            {context.state.TypeSimulation === "Audit retraite" && (
              <Row>
                <Column>
                  <TextField
                    label="Salaire brut"
                    value={context.state['SalaireBrutContratRetraite'+ClientConjoint]}
                    onChange={context.handleInputChange("SalaireBrutContratRetraite"+ClientConjoint)}
                    dataType="ufloat"
                    format={{ thousands: " ", unit: " €", decimal: "," }}
                    normal
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column>
                <DropDown
                  label="Assiette de cotisation"
                  value={context.state.AssietteCotisation83}
                  options={[
                    { value: "AssietteSalaireBrut", label: "% du salaire brut" },
                    { value: "AssiettePass", label: "% du PASS" },
                  ]}
                  onChange={(e) => {
                    context.handleChangeAssietteCotisation83("AssietteCotisation83", "", e);
                  }}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column fill>
                <table className="mono">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Salarié</th>
                      <th>Employeur</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tranche A</td>
                      <td>
                        <TextField
                          value={context.state.taux_retraite_trancheA_salarie83}
                          onChange={context.handleInputChange("taux_retraite_trancheA_salarie83")}
                          dataType="ufloat"
                          format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                          normal
                        />
                      </td>
                      <td>
                        <TextField
                          value={context.state.taux_retraite_trancheA_patronale83}
                          onChange={context.handleInputChange("taux_retraite_trancheA_patronale83")}
                          dataType="ufloat"
                          format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                          normal
                        />
                      </td>
                    </tr>
                    {context.state["AssietteCotisation83"] === "AssietteSalaireBrut" && (
                      <>
                        <tr>
                          <td>Tranche B</td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheB_salarie83}
                              onChange={context.handleInputChange("taux_retraite_trancheB_salarie83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheB_patronale83}
                              onChange={context.handleInputChange("taux_retraite_trancheB_patronale83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche C</td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheC_salarie83}
                              onChange={context.handleInputChange("taux_retraite_trancheC_salarie83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheC_patronale83}
                              onChange={context.handleInputChange("taux_retraite_trancheC_patronale83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche D</td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheD_salarie83}
                              onChange={context.handleInputChange("taux_retraite_trancheD_salarie83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.taux_retraite_trancheD_patronale83}
                              onChange={context.handleInputChange("taux_retraite_trancheD_patronale83")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default Versement83;
