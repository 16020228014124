import { useContext, useState, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import {  FaCogs, FaFileSignature, FaHSquare, FaWheelchair } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import ContratPrevCondGen from "./ContratPrevCondGen";
import IncapCondGen from "./IncapCondGen";
import InvalCondGen from "./InvalCondGen";
import OptionExclusionCondGen from "./OptionExclusionCondGen";

const ContratPrevoyancePagerBDD = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const handleMenu = () => {
    let menu = [
      {
        label: "Contrat",
        icon: <FaFileSignature />,
        content: <ContratPrevCondGen AffichLibelle={true} />,
      },
      {
        label: "Incapacité",
        icon: <FaHSquare />,
        content: <IncapCondGen />,
      },
      {
        label: "Invalidité",
        icon: <FaWheelchair />,
        content: <InvalCondGen />,
      },
      {
        label: "Option / Exclusion",
        icon: <FaCogs />,
        content: <OptionExclusionCondGen />,
      },
    ];

    return menu;
  };
  return (
    <>
      <Pager
        withMenu
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              props?.onClose?.();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddUpdateUserContratPrevoyance();
              props?.onClose?.();
            },
          },
        }}
      />
    </>
  );
};

export default ContratPrevoyancePagerBDD;
