import React, { useEffect } from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import TextField from "components/ui/controls/text-field/text-field";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";

const RevenusPro = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const Periode = context.state[Outil + "PeriodeActuelle"] === undefined ? undefined : context.state[Outil + "PeriodeActuelle"][0];
  const PeriodeConjoint = context.state[Outil + "PeriodeActuelleConjoint"] === undefined ? undefined : context.state[Outil + "PeriodeActuelleConjoint"][0];

  useEffect(() => {
    // if (props.visible === true) {
    if (Periode !== undefined && context.state.TypeSimulation!=="Audit rémunération") {
      if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "").then(() => {});
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "").then(() => {});
      }

      if (context.state.InclureConjoint === true && Periode?.StatutConjoint !== "Aucun") {
        if (Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel") {
          ResultatTNS.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {});
        } else {
          ResultatSalarie.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {});
        }
      }
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "Conjoint").then(() => {});
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "Conjoint").then(() => {});
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  // RemExpressRemuneration
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="normal text-center">Dirigeant</div>
                  </th>
                  <th>
                    <div className="normal text-center">Conjoint</div>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Periode === undefined ? (
                  ""
                ) : (
                  <tr>
                    <td>Rémunération déclarée sur l'activité étudiée</td>
                    <td>
                      <TextField
                        value={
                          Periode?.Statut === "TNS Article 62"
                            ? context.state.TypeSimulation === "Audit rémunération" && Outil === ""
                              ? ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable
                              : Outil === "OptiRem"
                              ? ResultatTNS.state.OptiRemRemuneration?.BrutImposable
                              : ResultatTNS.state[Outil + "RemunerationTNS"]?.BrutImposable
                            : Periode?.Statut === "TNS individuel"
                            ? 0
                            : context.state.TypeSimulation === "Audit rémunération" && Outil === ""
                            ? ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable
                            : Outil === "OptiRem"
                            ? ResultatTNS.state.OptiRemRemuneration?.BrutImposable
                            : ResultatSalarie.state[Outil + "RemunerationSalarie"]?.BrutImposable
                        }
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                        disabled={true}
                      />
                    </td>
                    <td>
                      {context.state.TypeSimulation !== "Audit rémunération" && (
                        <TextField
                          value={
                            (Periode?.StatutConjoint==='TNS Article 62' || PeriodeConjoint?.Statut === "TNS Article 62")
                              ? Outil === "OptiRem"
                                ? ResultatTNS.state.OptiRemRemunerationConjoint?.BrutImposable
                                : ResultatTNS.state[Outil + "RemunerationTNSConjoint"].BrutImposable
                              : (Periode?.StatutConjoint || PeriodeConjoint?.Statut) === "TNS individuel"
                              ? 0
                              : Outil === "OptiRem"
                              ? ResultatTNS.state.OptiRemRemunerationConjoint?.BrutImposable
                              : ResultatSalarie.state[Outil + "RemunerationSalarieConjoint"].BrutImposable
                          }
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                          disabled={true}
                        />
                      )}
                    </td>
                  </tr>
                )}
                <LigneImpotDetaille Libelle="Traitements et salaires provenant d'autres activités" VarName1={Outil + "IRDirigeantTraitement"} VarName2={Outil + "IRConjointTraitement"} />
                <LigneImpotDetaille Libelle="Autres revenus imposables (allocations chômage…)" VarName1={Outil + "IRDirigeantAutresRevenus"} VarName2={Outil + "IRConjointAutresRevenus"} />
                <tr>
                  <td>Abattement appliqué</td>
                  <td>
                    <DropDown
                      value={context.state[Outil + "IRDirigeantAbattement"]}
                      onChange={context.handleInputChange(Outil + "IRDirigeantAbattement")}
                      options={[
                        { value: "Forfaitaire", label: "Forfaitaire" },
                        { value: "Reel", label: "Au réel" },
                      ]}
                      normal
                    />
                  </td>
                  <td>
                    <DropDown
                      value={context.state[Outil + "IRConjointAbattement"]}
                      onChange={context.handleInputChange(Outil + "IRConjointAbattement")}
                      options={[
                        { value: "Forfaitaire", label: "Forfaitaire" },
                        { value: "Reel", label: "Au réel" },
                      ]}
                      normal
                    />
                  </td>
                </tr>
                {(context.state[Outil + "IRDirigeantAbattement"] === "Reel" || context.state[Outil + "IRConjointAbattement"] === "Reel") && (
                  <tr>
                    <td>Frais réels</td>
                    <td>
                      {context.state[Outil + "IRDirigeantAbattement"] === "Reel" && (
                        <TextField
                          value={context.state[Outil + "IRDirigeantFraisReel"]}
                          onChange={context.handleInputChange(Outil + "IRDirigeantFraisReel")}
                          disabled={context.state[Outil + "IRDirigeantAbattement"] === "Reel" ? false : true}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                        />
                      )}
                    </td>
                    <td>
                      {context.state[Outil + "IRConjointAbattement"] === "Reel" && (
                        <TextField
                          value={context.state[Outil + "IRConjointFraisReel"]}
                          onChange={context.handleInputChange(Outil + "IRConjointFraisReel")}
                          disabled={context.state[Outil + "IRConjointAbattement"] === "Reel" ? false : true}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                        />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default RevenusPro;
