import { ControlRIS, PdfReader } from "components/app/Api/Retraite/ApiRetraite";
import { AppContext } from "components/app/RemExprertProvider";
import { createContext, useContext, useEffect, useState } from "react";

export const RISContext = createContext();

/**
 * Generates a modal context provider component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const RISProvider = (props) => {
  // Initialize states
  const context = useContext(AppContext);
  const [Results, setResults] = useState({});
  const [InfosBlocageRIS, setInfosBlocageRIS] = useState({});
  const [ErrorMessage, setErrorMessage] = useState("");
  const [SuccessMessage, setSuccessMessage] = useState(false);
  useEffect(() => {
    handleResetRISProvider();
  }, [props.visible]);

  const handleResetRISProvider = () => {
    setResults({});
    setInfosBlocageRIS({});
    setErrorMessage("");
    setSuccessMessage(false);
  };
  const handleUploadRIS = (Infos) => (event) => {
    PdfReader(event.target.files[0], Infos.IdUser, { Partner: Infos.Partner, IdPartner: Infos.IdPartner }).then((Res) => {
      if ("error" in Res || Res.data === "SizeError") {
        setErrorMessage("Le logiciel n'a pas été en mesure de lire le relévé de carrière pour la raison suivante : Le fichier est un scan.");
      } else if (Res.data[0] === "TechnicalError") {
        setErrorMessage("Le logiciel n'a pas été en mesure de lire le relévé de carrière pour des raisons techniques.Votre RIS a été transmis à nos équipes.");
      } else if (Res.data[0] === "FileError") {
        setErrorMessage("Le logiciel n'a pas été en mesure de lire le relévé de carrière pour la raison suivante : fichier converti par l'application " + Res.data[1]["Software"] + ".");
      } else {
        ControlRIS(Res.data[1], Infos.IdUser, false,"").then((InfosRIS) => {
          setResults(Res.data[1]);
          setErrorMessage("");
          setSuccessMessage(true);
          handleGestionRIS(InfosRIS.data);
        });
      }
    });
  };

  const handleGestionRIS = (InfosRIS) => {
    if (Boolean(InfosRIS.Blocage)) {
      if (Boolean(InfosRIS.Blocage.CotisAssi) === true) {
        Object.entries(InfosRIS.Blocage["CotisAssi"]).map((data, index) => {
          var valeurInitiale = 0;
          var TrimestreRetenu = Math.min(
            4,
            data[1].reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante.Trimestres, valeurInitiale)
          );
          // console.log('TRIM',TrimestreRetenu)
          InfosRIS.Blocage["CotisAssi"][data[0]].push({ TrimestreRetenu: TrimestreRetenu });
        });
      }
      setInfosBlocageRIS(InfosRIS.Blocage);
    }
  };

  const handleCorrectionBlocageRIS = (TypeBlocage, Annee, IndexArray, event) => {
    console.log(InfosBlocageRIS);
    let InfosBlocage = { ...InfosBlocageRIS };
    if (TypeBlocage === "CotisAssi") {
      InfosBlocage[TypeBlocage][Annee][IndexArray]["Trimestres"] = Number(event.target.value);

      InfosBlocage["CotisAssi"][Annee].pop();
      var valeurInitiale = 0;
      var TrimestreRetenu = Math.min(
        4,
        InfosBlocage["CotisAssi"][Annee].reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante.Trimestres, valeurInitiale)
      );

      InfosBlocage["CotisAssi"][Annee].push({ TrimestreRetenu: TrimestreRetenu });
    } else if (TypeBlocage === "CarriereRSICommercant") {
      if (IndexArray === "RSIChoiceAll") {
        Object.keys(InfosBlocage["CarriereRSICommercant"]).map(function (key) {
          InfosBlocage["CarriereRSICommercant"][key]["Nature"] = event.target.value;
        });
      } else {
        InfosBlocage[TypeBlocage][Annee]["Nature"] = event.target.value;
      }
    } else if (TypeBlocage === "CarriereFonctionPublique") {
      InfosBlocage[TypeBlocage][IndexArray] = event.target.value;
    } else if (TypeBlocage === "AbsencePointRG") {
      InfosBlocage[TypeBlocage] = event.target.value;
    }
    console.log("IFNFO", InfosBlocage);
    setInfosBlocageRIS(InfosBlocage);
  };

  const ValidationRIS = (ClientConjoint) => {
    ControlRIS(Results, context.state.IdUser, InfosBlocageRIS,context.state['DateDepartRetraiteAffichage'+ClientConjoint]).then((InfosRIS) => {
      context.handleAddRIS(InfosRIS.data, ClientConjoint);
      handleResetRISProvider();
    });
  };
  // Render component
  return (
    <RISContext.Provider
      value={{
        InfosRIS: Results.RISInfos,
        InfosBlocageRIS: InfosBlocageRIS,
        ErrorMessage: ErrorMessage,
        SuccessMessage: SuccessMessage,
        handleUploadRIS: handleUploadRIS,
        handleGestionRIS: handleGestionRIS,
        handleCorrectionBlocageRIS: handleCorrectionBlocageRIS,
        ValidationRIS: ValidationRIS,
      }}
    >
      {props.children}
    </RISContext.Provider>
  );
};
export default RISProvider;
