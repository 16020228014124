import "./CalculatriceAgircArrco.css";
import { GetCalculCalculatriceAgircArrco, GetSimulateurData } from "components/app/Api/ApiSimulateur";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { useContext, useEffect, useState } from "react";
import { FaArrowAltCircleDown, FaCogs, FaPrint, FaTimesCircle } from "react-icons/fa";
import _ from "lodash";
import { applyFormat } from "components/ui/ui-helpers";
import Modal from "components/ui/modal/modal";
import { SyntheseCaculatriceAgircArrco } from "components/app/Api/Rapport/ApiRapport";

const CalculatriceAgircArrco = () => {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [tabTotal, setTabTotal] = useState({ ptsArrco: 0, ptsAgircTB: 0, ptsAgircTC: 0, ptsAgircArrco: 0 });
  useEffect(() => {
    if (Object.keys(context.state.CalculatriceTableauAgircArrco).length === 0) {
      GetSimulateurData(context.state, "GetTableauCalculatriceAgircArrco").then((res) => {
        context.handleValueChange("CalculatriceTableauAgircArrco", res.detail);
      });
    }
  }, []);

  const handleChangeValue = (Annee, Property, Value) => {
    let List = _.cloneDeep(context.state.CalculatriceTableauAgircArrco);
    List[Annee][Property] = Value;
    context.handleValueChangeAsync("CalculatriceTableauAgircArrco", List).then(() => {
      handleCalcul(List);
    });
  };
  const handleExpandValue = (AnneeMin, Property) => {
    let List = _.cloneDeep(context.state.CalculatriceTableauAgircArrco);

    Object.keys(context.state.CalculatriceTableauAgircArrco)?.forEach((Annee) => {
      if (Number(Annee) > AnneeMin) {
        if (Property === "Salaire") {
          let Salaire = List[AnneeMin][Property];
          if (Number(AnneeMin) < 2002 && Number(Annee) >= 2002) {
            Salaire = Number(List[AnneeMin][Property] / 6.55957).toFixed(0);
          }
          List[Annee][Property] = Salaire;
        } else {
          List[Annee][Property] = List[AnneeMin][Property];
        }
      }
    });
    context.handleValueChangeAsync("CalculatriceTableauAgircArrco", List).then(() => {
      handleCalcul(List);
    });
  };
  const handleCalcul = (CalculatriceTableauAgircArrco) => {
    GetCalculCalculatriceAgircArrco(CalculatriceTableauAgircArrco, "CalculTableauCalculatriceAgircArrco").then((res) => {
      if (typeof res === "object") {
        setTabTotal(res.total)
        context.handleValueChange("CalculatriceTableauAgircArrco", res.detail);
      }
    });
  };
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox>
              <Column fill>
                {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") && (
                  <Row bpad>
                    <DropDown
                      value={context.state.SwitchClient}
                      onChange={context.handleSwitchClient}
                      label="Etude réalisée pour"
                      options={[
                        { value: "client", label: context.state.SwitchPrenom },
                        { value: "conjoint", label: context.state.SwitchPrenomConjoint },
                      ]}
                      xlarge
                    />
                  </Row>
                )}
                <Row>
                  <Column fill>
                    <Row>
                      <h1>Calculatrice AGIRC ARRCO</h1>
                      <Column style={{ marginTop: "-8px" }}>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            setmodalOpen(true);
                          }}
                        >
                          <FaCogs />
                        </Button>
                      </Column>
                      <Column style={{ marginTop: "-8px" }}>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            SyntheseCaculatriceAgircArrco(context.state,tabTotal);
                          }}
                        >
                          <FaPrint />
                        </Button>
                      </Column>
                    </Row>
                    <Row center></Row>
                    <Row tpad>
                      <table className="TableauCalculatriceAgircArrco">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>Année</th>
                            <th style={{ width: "150px", textAlign: "center" }}>Statut</th>
                            <th style={{ width: "120px", textAlign: "center" }}>Salaire</th>
                            <th style={{ width: "120px", textAlign: "center" }}>Tx d'activité</th>
                            <th style={{ textAlign: "center" }}>ARRCO</th>
                            <th style={{ textAlign: "center" }}>AGIRC TB</th>
                            <th style={{ textAlign: "center" }}>AGIRC TC</th>
                            <th style={{ textAlign: "center" }}>AGIRC-ARRCO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(context.state.CalculatriceTableauAgircArrco)?.map((Annee) => {
                            return (
                              <tr key={"Calc" + Annee}>
                                <td style={{ textAlign: "center" }}>{Annee}</td>
                                <td style={{ width: "150px", textAlign: "center" }}>
                                  <Row wrap>
                                    <Column style={{ padding: 0 }}>
                                      <DropDown
                                        style={{ textAlign: "center" }}
                                        value={context.state.CalculatriceTableauAgircArrco[Annee]["Statut"]}
                                        onChange={(e) => {
                                          handleChangeValue(Annee, "Statut", e.target.value);
                                        }}
                                        options={[
                                          { value: "cadre", label: "Cadre" },
                                          { value: "nonCadre", label: "Non cadre" },
                                        ]}
                                        small
                                        iconAfter={
                                          <Button tabIndex="-1" icon>
                                            <FaArrowAltCircleDown />
                                          </Button>
                                        }
                                      />
                                    </Column>
                                    <Column style={{ padding: 0 }}>
                                      <Button style={{ paddingTop: "5px" }} tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "Statut")}>
                                        <FaArrowAltCircleDown />
                                      </Button>
                                    </Column>
                                  </Row>
                                </td>
                                <td style={{ width: "120px", textAlign: "center" }}>
                                  <TextField
                                    small
                                    value={context.state.CalculatriceTableauAgircArrco[Annee]["Salaire"]}
                                    dataType="uint"
                                    format={{ thousands: " ", unit: Number(Annee) < 2002 ? " FRF" : " €" }}
                                    onChange={(e) => {
                                      handleChangeValue(Annee, "Salaire", e.target.value);
                                    }}
                                    iconAfter={
                                      <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "Salaire")}>
                                        <FaArrowAltCircleDown />
                                      </Button>
                                    }
                                  />
                                </td>
                                <td style={{ width: "120px", textAlign: "center" }}>
                                  <TextField
                                    small
                                    value={context.state.CalculatriceTableauAgircArrco[Annee]["TauxActivite"]}
                                    dataType="ufloat"
                                    format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                                    onChange={(e) => {
                                      handleChangeValue(Annee, "TauxActivite", e.target.value);
                                    }}
                                    iconAfter={
                                      <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "TauxActivite")}>
                                        <FaArrowAltCircleDown />
                                      </Button>
                                    }
                                  />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {applyFormat(context.state.CalculatriceTableauAgircArrco[Annee]["PtsArrco"], "ufloat", { thousands: " ", decimal: ",", precision: 2 })}{" "}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {applyFormat(context.state.CalculatriceTableauAgircArrco[Annee]["PtsAgircTB"], "ufloat", { thousands: " ", decimal: ",", precision: 2 })}{" "}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {applyFormat(context.state.CalculatriceTableauAgircArrco[Annee]["PtsAgircTC"], "ufloat", { thousands: " ", decimal: ",", precision: 2 })}{" "}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {applyFormat(context.state.CalculatriceTableauAgircArrco[Annee]["PtsAgircArrco"], "ufloat", { thousands: " ", decimal: ",", precision: 2 })}{" "}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <th style={{ textAlign: "center" }}>Total</th>
                            <th style={{ width: "150px", textAlign: "center" }}></th>
                            <th style={{ width: "120px", textAlign: "center" }}></th>
                            <th style={{ width: "120px", textAlign: "center" }}></th>
                            <th style={{ textAlign: "center" }}> {applyFormat(tabTotal.ptsArrco, "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</th>
                            <th style={{ textAlign: "center" }}>{applyFormat(tabTotal.ptsAgircTB, "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</th>
                            <th style={{ textAlign: "center" }}>{applyFormat(tabTotal.ptsAgircTC, "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</th>
                            <th style={{ textAlign: "center" }}>{applyFormat(tabTotal.ptsAgircArrco, "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</th>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
      <Modal
        width="80%"
        height="80%"
        title="Paramétrage"
        icon={<FaCogs />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <table className="TableauCalculatriceAgircArrco">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Année</th>
              <th style={{ width: "120px", textAlign: "center" }}>TX ARRCO T1</th>
              <th style={{ width: "120px", textAlign: "center" }}>TX ARRCO T2</th>
              <th style={{ width: "120px", textAlign: "center" }}>TX AGIRC TB</th>
              <th style={{ width: "120px", textAlign: "center" }}>TX AGIRC TC</th>
              <th style={{ width: "120px", textAlign: "center" }}>SAL REF ARRCO</th>
              <th style={{ width: "120px", textAlign: "center" }}>SAL REF AGIRC</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(context.state.CalculatriceTableauAgircArrco)?.map((Annee) => {
              return (
                <tr key={"Calc" + Annee}>
                  <td style={{ textAlign: "center" }}>{Annee}</td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    <TextField
                      small
                      value={context.state.CalculatriceTableauAgircArrco[Annee]["TauxArrcoT1"] * 100}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      onChange={(e) => {
                        handleChangeValue(Annee, "TauxArrcoT1", Number(Number(e.target.value) / 100).toFixed(4));
                      }}
                      // iconAfter={
                      //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "TauxActivite")}>
                      //     <FaArrowAltCircleDown />
                      //   </Button>
                      // }
                    />
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    <TextField
                      small
                      value={context.state.CalculatriceTableauAgircArrco[Annee]["TauxArrcoT2"] * 100}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      onChange={(e) => {
                        handleChangeValue(Annee, "TauxArrcoT2", Number(Number(e.target.value) / 100).toFixed(4));
                      }}
                      // iconAfter={
                      //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "TauxActivite")}>
                      //     <FaArrowAltCircleDown />
                      //   </Button>
                      // }
                    />
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    {Number(Annee) <= 2018 && (
                      <TextField
                        small
                        value={context.state.CalculatriceTableauAgircArrco[Annee]["TauxAgircTB"] * 100}
                        dataType="ufloat"
                        format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                        onChange={(e) => {
                          handleChangeValue(Annee, "TauxAgircTB", Number(Number(e.target.value) / 100).toFixed(4));
                        }}
                        // iconAfter={
                        //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "TauxActivite")}>
                        //     <FaArrowAltCircleDown />
                        //   </Button>
                        // }
                      />
                    )}
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    {Number(Annee) >= 1988 && Number(Annee) <= 2018 && (
                      <TextField
                        small
                        value={context.state.CalculatriceTableauAgircArrco[Annee]["TauxAgircTC"] * 100}
                        dataType="ufloat"
                        format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                        onChange={(e) => {
                          handleChangeValue(Annee, "TauxAgircTC", Number(Number(e.target.value) / 100).toFixed(4));
                        }}
                        // iconAfter={
                        //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "TauxActivite")}>
                        //     <FaArrowAltCircleDown />
                        //   </Button>
                        // }
                      />
                    )}
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    <TextField
                      small
                      value={context.state.CalculatriceTableauAgircArrco[Annee]["SalRefArrco"]}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 4, decimal: ",", unit: Number(Annee) < 2002 ? " FRF" : " €" }}
                      onChange={(e) => {
                        handleChangeValue(Annee, "SalRefArrco", e.target.value);
                      }}
                      // iconAfter={
                      //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "Salaire")}>
                      //     <FaArrowAltCircleDown />
                      //   </Button>
                      // }
                    />
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    {Number(Annee) <= 2018 && (
                      <TextField
                        small
                        value={context.state.CalculatriceTableauAgircArrco[Annee]["SalRefAgirc"]}
                        dataType="ufloat"
                        format={{ thousands: " ", precision: 4, decimal: ",", unit: Number(Annee) < 2002 ? " FRF" : " €" }}
                        onChange={(e) => {
                          handleChangeValue(Annee, "SalRefAgirc", e.target.value);
                        }}
                        // iconAfter={
                        //   <Button tabIndex="-1" icon onClick={() => handleExpandValue(Annee, "Salaire")}>
                        //     <FaArrowAltCircleDown />
                        //   </Button>
                        // }
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default CalculatriceAgircArrco;
