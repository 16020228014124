import { useCallback, useContext, useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DeclarationPER from "components/app/Simulateurs/Declaration PER/DeclarationPER";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";

const DeclarationPERPage = () => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;

        let Resultat = {
          RemunerationImposable: context.state.DeclaPERRemunerationImposable,
          RegimeFiscal: context.state.DeclaPERRegimeFiscal,
          AbondementPERCO: context.state.DeclaPERAbondementPERCO,
          RetraiteMadelin: context.state.DeclaPERRetraiteMadelin,
          PrevoyanceMadelin: context.state.DeclaPERPrevoyanceMadelin,
          Retraite83: context.state.DeclaPERRetraite83,
          Retraite163: context.state.DeclaPERRetraite163,
          AssietteCalcul: context.state.DeclaPERAssietteDisponible,
          AssietteDisponible: context.state.DeclaPERResultat?.MontantAssietteDisponible,
          AssietteDisponibleT1: context.state.DeclaPERResultat?.MontantAssietteDisponibleT1,
          AssietteDisponibleT2: context.state.DeclaPERResultat?.MontantAssietteDisponibleT2,
          DisponibleT1: context.state.DeclaPERResultat?.MontantDisponibleT1,
          DisponibleT2: context.state.DeclaPERResultat?.MontantDisponibleT2,
          MontantADeclarer: context.state.DeclaPERResultat?.MontantADeclarer,
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handleMenu = () => {
    let menu = [
      {
        isTitle: true,
        title: "",
      },
      {
        // label: "Dossier",
        // icon: <FaHome id="DossierAuditCompletPage" />,
        content: <DeclarationPER />,
      },
    ];

    return menu;
  };
  return (
    <Pager
      pages={handleMenu()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default DeclarationPERPage;
