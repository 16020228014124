import { useState, useEffect} from "react";
import { FaCogs, FaFileImport } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import LectureRIS from "components/app/AuditComplet/Retraite/RIS/LectureRIS";
import PrecisionsRIS from "components/app/AuditComplet/Retraite/RIS/PrecisionsRIS";
import RISProvider, { RISContext } from "components/app/AuditComplet/Retraite/RIS/RISProvider";

const AjoutRISPageModal = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleMenu = (RISCtx) => {
    let menu = [
      {
        isTitle: true,
        title: "Relevé de carrière",
        subTitle: "Paramétrage de l'import",
      },
      {
        label: "Lecture du relevé",
        icon: <FaFileImport />,
        content: <LectureRIS Outil="" ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Précisions",
        icon: <FaCogs />,
        content: <PrecisionsRIS Outil="" ClientConjoint={props.ClientConjoint} />,
      },
    ];
    if (Object.entries(RISCtx.InfosBlocageRIS).length === 0) {
      menu = menu.filter((m) => m.label !== "Précisions");
    }
    return menu;
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);
  return (
    <RISProvider visible={props.visible}>
      <RISContext.Consumer>
        {(RISCtx) => (
          <>
          <Pager
            withMenu
            validateDisabled={currentPage!== handleMenu(RISCtx).length-1}
            pages={handleMenu(RISCtx)}
            page={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
            navButtons={{
              cancel: {
                onClick: () => {
                  props.onClose();
                },
              },
              previous: {},
              next: {},
              validate: {
                onClick: (evt) => {
                  props.onClose();
                  RISCtx.ValidationRIS(props.ClientConjoint);
                },
              },
            }}
            />
            </>
        )}

      </RISContext.Consumer>
    </RISProvider>
  );
};

export default AjoutRISPageModal;
