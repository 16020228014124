import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamCarpv from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpv";
import ParamCavec from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavec";
import ParamLpaPageModal from "pages/audit complet/situation professionnelle/modules/parametre lpa modal";


function DonneesAdministratives(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <Row>
          <Column fill>

      
        <CardBox>
          <Column fill>
            <Row>
              <h1>Rémunération de {context.state.Prenom}</h1>
            </Row>
            <Row>
              <Column>
                <DropDown label="Caisse d'affiliation" value={context.state.Caisse} options={caisse} onChange={context.handleChoixCaisse} normal />
              </Column>
            </Row>

            {context.state.Caisse === "Carpv" && <ParamCarpv Outil="" ClientConjoint="" />}
            {context.state.Caisse === "Cavec" && <ParamCavec Outil="" ClientConjoint="" />}
            {context.state.Caisse === "Cnbf" && (
              <>
                <Row tpad>
                  <Column>
                    <ParamLpaPageModal Outil="" ClientConjoint="" />
                  </Column>
                </Row>
              </>
            )}
            <Row tpad>
              <Column>
                <TextField
                  label="REVENUS ANNUELS NETS"
                  value={context.state.Remuneration}
                  onChange={context.handleInputChange("Remuneration")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
        </Column>
        </Row>
      </Column>
      
    </>
  );
}

export default DonneesAdministratives;
