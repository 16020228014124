import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

const CreditReduction = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th colSpan={3}>Dons et personne à domicile</th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="Dons à des organismes d'aide aux personnes en difficulté" VarName1={Outil + "IRDonPersonne"} VarName2="empty" />
                <LigneImpotDetaille Libelle="Dons versés à des associations d'utilité publiques" VarName1={Outil + "IRDonAssociation"} VarName2="empty" />
                <LigneImpotDetaille Libelle="Emploi d'une personne à domicile" VarName1={Outil + "IREmploiDomicile"} VarName2="empty" />
                <tr className="light-title">
                  <td>Frais de gardes des enfants de moins de 6 ans</td>
                  <td><div className="normal text-center">Charge exclusive</div></td>
                  <td><div className="normal text-center">Charge partagée</div> </td>
                </tr>
                <tr>
                  <td>
                    1 <sup>ier</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantExclusive1"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantExclusive1"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantPartagee1"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantPartagee1"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    2 <sup>ième</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantExclusive2"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantExclusive2"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantPartagee2"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantPartagee2"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    3 <sup>ième</sup> enfant
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantExclusive3"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantExclusive3"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                  <td>
                    <TextField
                      value={context.state[Outil + "IRFraisGardeEnfantPartagee3"]}
                      onChange={context.handleInputChange([Outil + "IRFraisGardeEnfantPartagee3"])}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr className="light-title">
                  <td>Nombre d'enfants poursuivant des études</td>
                  <td><div className="normal text-center">Charge exclusive</div></td>
                  <td><div className="normal text-center">Charge partagée</div> </td>
                </tr>
                <LigneImpotDetaille Libelle="Collège" VarName1={Outil + "IREtudeEnfantCollegeExclusive"} VarName2={Outil + "IREtudeEnfantCollegePartagee"} NotEuro="Ok" />
                <LigneImpotDetaille Libelle="Lycée" VarName1={Outil + "IREtudeEnfantLyceeExclusive"} VarName2={Outil + "IREtudeEnfantLyceePartagee"} NotEuro="Ok" />
                <LigneImpotDetaille Libelle="Etudes supérieures" VarName1={Outil + "IREtudeEnfantFacExclusive"} VarName2={Outil + "IREtudeEnfantFacPartagee"} NotEuro="Ok" />
                <LigneImpotDetaille Libelle="Réduction et crédits d'impôts divers" VarName1={Outil + "ReductionImpot"} VarName2="empty" />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default CreditReduction;
