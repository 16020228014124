import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Hover from "components/ui/controls/hover/hover";
import { FaEdit, FaInfo } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import StrategiePERDisponible163 from "./Strategie/Disponible163";
import StrategiePERDisponibleMadelin from "./Strategie/DisponibleMadelin";
import { TabTauxConversion } from "components/app/AuditComplet/Retraite/Parametrage/ContratRetraite/TabTauxconversion";
import { GetCalculStrategiePER } from "components/app/Api/ApiSimulateur";

function StrategiePERStrategie(props) {
  const context = useContext(AppContext);
  const [openModal163, setOpenModal163] = useState(false);
  const [openModalMadelin, setOpenModalMadelin] = useState(false);

  useEffect(() => {
    if (context.state.StrategiePERContratSetTauxConversion === false) {
      if (context.state["DateNaissance"].slice(-4) in TabTauxConversion) {
        if (Math.min(70, context.state.StrategiePERAgeDepartRetraite) in TabTauxConversion[context.state["DateNaissance"].slice(-4)]) {
          context.handleValueChange(
            "StrategiePERContratTauxConversion",
            TabTauxConversion[context.state["DateNaissance"].slice(-4)][Math.min(70, Number(context.state.StrategiePERAgeDepartRetraite))]
          );
        } else {
          context.handleValueChange("StrategiePERContratTauxConversion", 0.0);
        }
      } else {
        context.handleValueChange("StrategiePERContratTauxConversion", 0.0);
      }
    }
  }, []);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <h1>Configuration de la stratégie</h1>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Choix de la stratégie"
                  value={context.state.StrategiePERChoixStrategie}
                  options={[
                    {
                      value: "rentevscapital",
                      label: "Comparer rente et capital",
                    },
                    {
                      value: "prestation",
                      label: "Fixer la prestation souhaitée",
                    },
                    // {
                    //   value: "Assimilé salarié",
                    //   label: "Assimilé salarié",
                    // },
                  ]}
                  onChange={context.handleInputChange("StrategiePERChoixStrategie")}
                  normal
                />
              </Column>
              {/* <Column style={{ paddingTop: "41px" }}>
                <Hover icon={<FaInfo />}>
                  {" "}
                  Le logiciel prévoit la possibilité de racheter des trimestres au titre : <br />
                  <br />- Des années incomplètes. <br />
                  <br />- Des années d’études supérieures (attention, le logiciel ne prévoit pas la réduction tarifaire en cas de rachat d’années d’études intervenant dans les 10 ans
                  suivants les études). Nous vous rappelons que la demande de rachat s’effectue auprès du premier régime auquel l’assuré a été affilié après ses études.
                  <br />
                  <br />
                  Le rachat en option 1 influe sur le taux de pension et permet donc de réduire ou d’annuler la décote. Le rachat en option 2 influe sur le taux et la durée d'assurance
                  retenue pour le calcul de la retraite du régime unifié.
                </Hover>
              </Column> */}
            </Row>
            {context.state.StrategiePERChoixStrategie === "prestation" && (
              <>
                <Row>
                  <Column>
                    <TextField
                      label="Perte de gain à la retraite"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={Number((props?.Results.enActivite?.remNetteApresImpot - props?.Results.ro?.retraiteNetteApresImpot) / 12).toFixed(0)}
                      disabled
                      normal
                      dataType="int"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Taux de remplacement"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={Number((props?.Results.ro?.retraiteNetteApresImpot / props?.Results.enActivite?.remNetteApresImpot)*100).toFixed(0)}
                      disabled
                      normal
                      dataType="int"
                      format={{ thousands: " ", unit: "%" }}
                    />
                  </Column>
                  <Column style={{ paddingTop: "41px" }}>
                    <Hover icon={<FaInfo />}>La perte de gain est estimée nette d'impôt et de prélèvements sociaux.</Hover>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <DropDown
                      label="Choix de la sortie"
                      value={context.state.StrategiePERChoixSortie}
                      options={[
                        {
                          value: "rente",
                          label: "Rente",
                        },
                        {
                          value: "capitalFractionne",
                          label: "Capital fractionné",
                        },
                      ]}
                      onChange={context.handleInputChange("StrategiePERChoixSortie")}
                      normal
                    />
                  </Column>
                  <Column >
                    <TextField
                      label="Revenu net mensuel supplémentaire"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERRevenuNetComplementaire}
                      onChange={context.handleInputChange("StrategiePERRevenuNetComplementaire")}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </Column>
                  <Column style={{ paddingTop: "41px" }}>
                    <Hover icon={<FaInfo />}>Le montant doit être indiqué net d'impôt et de prélèvements sociaux.</Hover>
                  </Column>
                </Row>
              </>
            )}
            <Row>
              <Column>
                <TextField
                  label="Espérance de vie"
                  value={context.state.StrategiePEREsperanceVie}
                  onChange={context.handleInputChange("StrategiePEREsperanceVie")}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " ans" }}
                />
              </Column>
            </Row>
            <Row tpad>
              <h1>Cadre fiscal des versements</h1>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Cadre fiscal"
                  value={context.state.StrategiePERCadreFiscal}
                  options={
                    context.state.Statut === "Assimilé salarié"
                      ? [
                          {
                            value: "163",
                            label: "163 quatervicies",
                          },
                        ]
                      : [
                          {
                            value: "163",
                            label: "163 quatervicies",
                          },
                          {
                            value: "Madelin",
                            label: "Madelin",
                          },
                        ]
                  }
                  onChange={context.handleInputChange("StrategiePERCadreFiscal")}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Disponible fiscal"
                  value={context.state.StrategiePERDisponibleFiscal}
                  onChange={context.handleInputChange("StrategiePERDisponibleFiscal")}
                  disabled
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              <Column>
                <Button
                  style={{ paddingTop: "37px" }}
                  icon
                  onClick={() => {
                    context.state.StrategiePERCadreFiscal === "163" ? setOpenModal163(true) : setOpenModalMadelin(true);
                  }}
                >
                  <FaEdit />
                </Button>
              </Column>
            </Row>

            {context.state.StrategiePERCadreFiscal === "Madelin" && (
              <Row>
                <Column>
                  <DropDown
                    label="Déduction de la cotisation"
                    value={context.state.StrategiePERDeductionCotisation}
                    options={[
                      {
                        value: "IR",
                        label: "Impôt sur le revenu",
                      },
                      {
                        value: "IS",
                        label: "Impôt sur les sociétés",
                      },
                    ]}
                    onChange={context.handleInputChange("StrategiePERDeductionCotisation")}
                    normal
                  />
                </Column>
                {context.state.StrategiePERDeductionCotisation === "IS" && (
                  <Column>
                    <TextField
                      label="Résultat avant IS"
                      value={context.state.StrategiePERRai}
                      onChange={context.handleInputChange("StrategiePERRai")}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </Column>
                )}
              </Row>
            )}
          </Column>
        </CardBox>
      </Column>

      <StrategiePERDisponible163
        visible={openModal163}
        CalculDisponible={props?.CalculDisponible}
        Disponible163Calcule={props?.Disponible163Calcule}
        onClose={() => {
          setOpenModal163(false);
        }}
      />
      <StrategiePERDisponibleMadelin
        visible={openModalMadelin}
        CalculDisponible={props?.CalculDisponible}
        onClose={() => {
          setOpenModalMadelin(false);
        }}
      />
    </>
  );
}

export default StrategiePERStrategie;
