import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const situationPro = [
  {
    value: "Tns",
    label: "Chef d'entreprise",
  },
  {
    value: "SalarieCadre",
    label: "Salarié cadre",
  },
  {
    value: "SalarieNonCadre",
    label: "Salarié non cadre",
  },
  // {
  //   value: "ECSalarie",
  //   label: "Expert comptable salarié",
  // },
  // {
  //   value: "AvocatSalarie",
  //   label: "Avocat salarié",
  // },
  // {
  //   value: "Fonctionnaire",
  //   label: "Fonctionnaire",
  // },
];
function SituationPro(props) {
  const context = useContext(AppContext);


  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <blockquote>
                Sélectionnez une situation professionnelle dans la liste ci-dessous. Dans cet onglet, le début de la période saisie ne peut être inférieur au 1er janvier de l'année en cours.
                La carrière passée devra être saisie dans l'onglet "Retraite" du menu principal.
              </blockquote>
            </Row>
            <Row tpad>
              <Column>
                <DropDown
                  label="SITUATION PROFESSIONNELLE"
                  value={context.state.SituationPro}
                  options={situationPro}
                  onChange={(e) => {
                    context.handleChoixStatut("SituationPro",props.ClientConjoint, e);
                  }}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField label="Début de période" value={context.state.DebutPeriodeCarriereActuelle} dataType="date" disabled normal />
              </Column>
              <Column lpad>
                <DropDown
                  label="TERME RETENU"
                  value={context.state.FinCarriereActuelle}
                  options={[
                    {
                      value: "DepartRetraite",
                      label: "Départ à la retraite",
                    },
                    {
                      value: "Personnalise",
                      label: "Personnalisé",
                    },
                  ]}
                  onChange={context.handleFinCarriereActuelle(props.ClientConjoint)}
                  normal
                />
              </Column>
              <Column lpad>
                <TextField
                  label="Fin de période"
                  value={context.state.FinPeriodeCarriereActuelle}
                  dataType="date"
                  disabled={context.state.FinCarriereActuelle === "DepartRetraite" ? true : false}
                  onChange={context.handleInputChange("FinPeriodeCarriereActuelle")}
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
      {/* 
      <TextField
        value={context.state.DateNaissance}
        onKeyUp={context.handleInputChange("DateNaissance")}
        fullWidth
        margin="normal"
        label="Fin de période"
        InputLabelProps={{ shrink: true }}
        size="small"
        InputProps={{
          inputComponent: DateFormatCustom,
          inputProps: {},
        }}
      /> */}
    </>
  );
}

export default SituationPro;
