import { useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './button.css';

/**
 * Button component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Button component.
 */
 const Button = (props) => {
    
    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'tool', 'icon', 'disabled', 'ghost'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId())

    // Render component
    return (
        <button
            id={id}
            className={classFromProperties(props, 'button', Alignments, Paddings, Sizes, ['tool', 'icon', 'disabled', 'ghost'])}
            {...getOptionalProps(props, Excluded)}
        >
            {props.children}
        </button>
    );
}

export default Button;
