import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const ParamCnbf = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Ancienneté"
            value={context.state[props.Outil + "AncienneteCnbf" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "AncienneteCnbf" + props.ClientConjoint)}
            options={[
              {
                value: "1",
                label: "1"
              },
              {
                value: "2",
                label: "2"
              },
              {
                value: "3",
                label: "3"
              },
              {
                value: "4",
                label: "4"
              },
              {
                value: "5",
                label: "5"
              },
              {
                value: "6",
                label: "6 et +"
              },
            
            ]}
            normal
          />
        </Column>
        <Column hpad>
          <DropDown
            label="Classe retraite"
            value={context.state[props.Outil + "ClasseRetraiteCnbf" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCnbf" + props.ClientConjoint)}
            options={[
              {
                value: "C1",
                label: "C1"
              },
              {
                value: "C2",
                label: "C2"
              },
              {
                value: "C3",
                label: "C3"
              },
              {
                value: "C3+",
                label: "C3+"
              },
              
            ]}
            normal
          />
        </Column>
      </Row>
    </>
  );
};
export default ParamCnbf;


