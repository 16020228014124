import { useState, useContext, useEffect } from "react";
import { FaInfo, FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";
import Hover from "components/ui/controls/hover/hover";

const RachatTrimestrePage = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalAlertTauxPlein, setModalAlertTauxPlein] = useState(false);
  const [modalAlertDepassementTrimestre, setModalAlertDepassementTrimestre] = useState(false);
  const ClientConjoint = props.ClientConjoint;
  const Prenom = context.state["Prenom" + ClientConjoint];

  useEffect(() => {
    // ResultatTNS.GetResultBilanRetraite(context.state,"");
    // if(context.state.CarriereConjoint.length>0){
    //   ResultatTNS.GetResultBilanRetraite(context.state,"Conjoint");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClientConjoint]);
  useEffect(() => {
    const totalTrim = Number(context.state["RachatTrimRU" + ClientConjoint]) + Number(context.state["RachatTrimCNAVPL" + ClientConjoint]);
    if (totalTrim > 12) {
      setModalAlertDepassementTrimestre(true);
      context.handleValueChange("RachatTrimRU" + ClientConjoint, 0);
      context.handleValueChange("RachatTrimCNAVPL" + ClientConjoint, 0);
    }
  }, [context.state["RachatTrimRU" + ClientConjoint], context.state["RachatTrimCNAVPL" + ClientConjoint]]);

  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox fill>
              <Column fill>
                <Row>
                  <h1>Rachat de trimestres</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left " style={{ color: "black" }} value={"Régimes"} normal />
                  </Column>
                  <Column rpad>
                    <TextField disabled className="invisibleRachatTrimestre" style={{ color: "black" }} value={"Nombre"} normal />
                  </Column>
                  <Column lpad>
                    <TextField disabled className="invisibleRachatTrimestre" style={{ color: "black" }} value={"Type"} normal />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black" }} value={"Régime unifié"} normal />
                  </Column>
                  <Column rpad>
                    <TextField
                      disabled={
                        context.state["Carriere" + ClientConjoint].filter(
                          (Carriere) =>
                            (Carriere.Nature === "RsiCommercant" ||
                              Carriere.Nature === "RsiArtisan" ||
                              Carriere.Nature === "ActiviteSalarie" ||
                              Carriere.Nature === "SalarieNonCadre" ||
                              Carriere.Nature === "SalarieCadre" ||
                              Carriere.Nature === "SalarieNonCadreCFE" ||
                              Carriere.Nature === "SalarieCadreCFE" ||
                              Carriere.Nature === "SalarieNonCadreAgricole" ||
                              Carriere.Nature === "SalarieCadreAgricole") &&
                            Carriere.id !== "CarriereActuelle"
                        ).length > 0
                          ? false
                          : true
                      }
                      value={context.state["RachatTrimRU" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatTrimRU" + ClientConjoint)}
                      dataType="uint"
                      normal
                    />
                  </Column>
                  <Column hpad>
                    <DropDown
                      value={context.state["RachatOptionRU" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatOptionRU" + ClientConjoint)}
                      options={[
                        { value: "option1", label: "Option 1" },
                        { value: "option2", label: "Option 2" },
                      ]}
                      normal
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>
                      {" "}
                      Le logiciel prévoit la possibilité de racheter des trimestres au titre : <br />
                      <br />- Des années incomplètes. <br />
                      <br />- Des années d’études supérieures (attention, le logiciel ne prévoit pas la réduction tarifaire en cas de rachat d’années d’études intervenant dans les 10 ans
                      suivants les études). Nous vous rappelons que la demande de rachat s’effectue auprès du premier régime auquel l’assuré a été affilié après ses études.
                      <br />
                      <br />
                      Le rachat en option 1 influe sur le taux de pension et permet donc de réduire ou d’annuler la décote. Le rachat en option 2 influe sur le taux et la durée d'assurance
                      retenue pour le calcul de la retraite du régime unifié.
                    </Hover>
                  </Column>
                </Row>
                <Row tpad bpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black" }} value={"CNAVPL"} normal />
                  </Column>
                  <Column rpad>
                    <TextField
                      disabled={
                        context.state["Carriere" + ClientConjoint].filter(
                          (Carriere) =>
                            (Carriere.Nature === "Carcdsf" ||
                              Carriere.Nature === "Carcdsf2" ||
                              Carriere.Nature === "Carmf" ||
                              Carriere.Nature === "Carpimko" ||
                              Carriere.Nature === "Carpv" ||
                              Carriere.Nature === "Cavec" ||
                              Carriere.Nature === "Crn" ||
                              Carriere.Nature === "Cavamac" ||
                              Carriere.Nature === "Cavp" ||
                              Carriere.Nature === "Cavom" ||
                              Carriere.Nature === "MicroCipav" ||
                              Carriere.Nature === "Cipav") &&
                            Carriere.id !== "CarriereActuelle"
                        ).length > 0
                          ? false
                          : true
                      }
                      value={context.state["RachatTrimCNAVPL" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatTrimCNAVPL" + ClientConjoint)}
                      dataType="uint"
                      normal
                    />
                  </Column>
                  <Column hpad>
                    <DropDown
                      value={context.state["RachatOptionCNAVPL" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatOptionCNAVPL" + ClientConjoint)}
                      options={[
                        { value: "option1", label: "Trimestres" },
                        { value: "option2", label: "Trimestres + points" },
                      ]}
                      normal
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>
                      Le logiciel prévoit la possibilité de racheter des trimestres au titre : <br />
                      <br />- Des années incomplètes. <br />
                      <br />- Des années d’études supérieures (attention, le logiciel ne prévoit pas la réduction tarifaire en cas de rachat d’années d’études intervenant dans les 10 ans
                      suivants les études). Nous vous rappelons que la demande de rachat s’effectue auprès du premier régime auquel l’assuré a été affilié après ses études.
                      <br /> <br />
                      Le professionnel libéral a la possibilité de racheter des trimestres et des points au régime de base de la CNAVPL. Le rachat d’un trimestre permet d’acquérir des points
                      supplémentaires selon le montant de rémunération déclaré.
                    </Hover>
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black" }} value={"Revenus moyens"} normal />
                  </Column>
                  <Column rpad>
                    <TextField
                      value={context.state["RachatRevenus" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatRevenus" + ClientConjoint)}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>
                      Indiquez le montant des revenus professionnels des 3 années qui précèdent la demande pour déterminer le cout du rachat. Montant des salaires et le cas échéant des
                      revenus d'activité non salariée soumis à cotisations de sécurité sociale, non limités au plafond. Les autres revenus n'ont pas à être déclarés.
                    </Hover>
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black" }} value={"TMI en activité"} normal />
                  </Column>
                  <Column rpad>
                    <DropDown
                      value={context.state["RachatTMI" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatTMI" + ClientConjoint)}
                      options={[
                        { value: 0, label: "0%" },
                        { value: 11, label: "11%" },
                        { value: 30, label: "30%" },
                        { value: 41, label: "41%" },
                        { value: 45, label: "45%" },
                      ]}
                      normal
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>Le montant du rachat est fiscalement déductible. Indiquez la TMI pour déterminer l’économie d’impôt réalisée grâce au rachat.</Hover>
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black" }} value={"TMI à la retraite"} normal />
                  </Column>
                  <Column rpad>
                    <DropDown
                      value={context.state["RachatTMIRetraite" + ClientConjoint]}
                      onChange={context.handleInputChange("RachatTMIRetraite" + ClientConjoint)}
                      options={[
                        { value: 0, label: "0%" },
                        { value: 11, label: "11%" },
                        { value: 30, label: "30%" },
                        { value: 41, label: "41%" },
                        { value: 45, label: "45%" },
                      ]}
                      normal
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>
                      Fixer une TMI à la retraite permet de tenir compte de l'impôt supplémentaire liée à l'augmentation de la pension de retraite. L'étude du retour sur investissement de
                      l'opération sera donc plus précise.
                    </Hover>
                  </Column>
                </Row>
                <Row tpad>
                  <Column tpad fill center>
                    <Button
                      disabled={context.state["RachatRevenus" + ClientConjoint].toString() === "0" ? true : false}
                      onClick={() => {
                        ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalTrimestre >=
                        ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.Infos?.DureeReference
                          ? setModalAlertTauxPlein(true)
                          : ResultatTNS.GetResultRachatTrimestre(context.state, context.state["CarriereSalaireReference" + ClientConjoint], ClientConjoint);
                      }}
                    >
                      <FaPrint />
                      <span>Imprimer</span>
                    </Button>
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
      <Modal
        width="50%"
        height="20%"
        title=" "
        visible={modalAlertTauxPlein}
        onClose={() => {
          setModalAlertTauxPlein(false);
        }}
      >
        <Row fill center>
          <Column fill center>
            <h3>Il n'est pas possible de racheter des trimestres dans le cadre d'un départ au taux plein.</h3>
          </Column>
        </Row>
      </Modal>

      <Modal
        width="50%"
        height="20%"
        title=" "
        visible={modalAlertDepassementTrimestre}
        onClose={() => {
          setModalAlertDepassementTrimestre(false);
        }}
      >
        <Row fill center>
          <Column fill center>
            <h3>Il n'est pas possible de racheter plus de 12 trimestres.</h3>
          </Column>
        </Row>
      </Modal>
    </>
  );
};

export default RachatTrimestrePage;
