import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointCnbf = (props) => {
  const { resultRetraite, ClientConjoint } = props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };

  return (
    <>
         {context.state['Carriere'+ClientConjoint].filter((Carriere) => (Carriere.Nature === "Cnbf" || Carriere.Nature==="CnbfSalarie" )).length > 0 && (
        <tr>
          <td>CNBF</td>
          <td>
            <TextField
              value={context.state["TableauPoints" + ClientConjoint].RetraiteCompCnbf}
              onChange={(e)=>{context.handlePointRetraite(ClientConjoint,"RetraiteCompCnbf",e)}}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
          <td>
            <DropDown
              value={context.state["TableauPoints" + ClientConjoint].AnneeFinCnbfComp}
              onChange={(e)=>{
                context.handlePointRetraite(ClientConjoint,"AnneeFinCnbfComp",e)
                props.CalculRetraite();
              }}
              options={[
                { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
              ]}
              small
            />
          </td>
          <td>
            <TextField
              value={resultRetraite?.InfosRetraite?.Cnbf?.General?.PointCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Cnbf?.General?.PointCalcule}
              disabled={true}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
        </tr>
      )}
    </>
  );
};
export default PointCnbf;
