import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { useContext } from "react";

const DetailCotisationsCarcdsf = (props) => {
  const { DetailCotisations, DetailCSG, TotalChargesSociales } = props;
  const context = useContext(AppContext);
  let Periode = [];
  if (props.ClientConjoint === "Conjoint" && context.state["PeriodeActuelle" + props.ClientConjoint].length === 0) {
    Periode = context.state["PeriodeActuelle"][0];
  } else {
    Periode = context.state["PeriodeActuelle" + props.ClientConjoint][0];
  }
  if (Periode === undefined) return;
  return (
    <>
      <table className="cotisations">
        <thead>
          <tr className="dark-title">
            <th></th>
            <th>Assiette</th>
            <th>Taux</th>
            <th>Cotisation</th>
            <th>Part CPAM</th>
            <th>Montant dû</th>
          </tr>
        </thead>
        <tbody>
          <tr className="light-title">
            <td colSpan={6}>Cotisations de {props.Prenom}</td>
          </tr>
          {Periode["ConventionneCarcdsf" + props.ClientConjoint] === "non" && (
            <tr>
              <td>Assurance maladie (non conventionné)</td>
              <td>{DetailCotisations?.assiette_assurance_maladie}</td>
              <td>{DetailCotisations?.taux_assurance_maladie}</td>
              <td>{DetailCotisations?.assurance_maladie}</td>
              <td>-</td>
              <td>{DetailCotisations?.assurance_maladie}</td>
            </tr>
          )}
          {Periode["ConventionneCarcdsf" + props.ClientConjoint] === "oui" && (
            <>
              <tr>
                <td>Assurance maladie (hors dépassement)</td>
                <td>{DetailCotisations?.assiette_assurance_maladie}</td>
                <td>{DetailCotisations?.taux_assurance_maladie}</td>
                <td>{DetailCotisations?.assurance_maladie}</td>
                <td>{DetailCotisations?.assurance_maladie_Cpam}</td>
                <td>{DetailCotisations?.assurance_maladie_restant}</td>
              </tr>
              {DetailCotisations?.assurance_maladie_depassement_honoraire !== "0 €" && (
                <tr>
                  <td>Assurance maladie (en dépassement)</td>
                  <td>{DetailCotisations?.assiette_maladie_depassement_honoraire}</td>
                  <td>{DetailCotisations?.taux_MaladieDh}</td>
                  <td>{DetailCotisations?.assurance_maladie_depassement_honoraire}</td>
                  <td>-</td>
                  <td>{DetailCotisations?.assurance_maladie_depassement_honoraire}</td>
                </tr>
              )}
            </>
          )}
          <tr>
            <td>Allocations familiales</td>
            <td>{DetailCotisations?.assiette_allocation_familiale}</td>
            <td>{DetailCotisations?.taux_allocation_familiale}</td>
            <td>{DetailCotisations?.allocation_familiale}</td>
            <td>-</td>
            <td>{DetailCotisations?.allocation_familiale}</td>
          </tr>
          <tr>
            <td>Indemnités journalières</td>
            <td>{DetailCotisations?.assiette_ij_cpam}</td>
            <td>{DetailCotisations?.taux_ij_cpam}</td>
            <td>{DetailCotisations?.ij_cpam}</td>
            <td>-</td>
            <td>{DetailCotisations?.ij_cpam}</td>
          </tr>
          <tr>
            <td>Invalidité / Décès</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.prevoyance}</td>
            <td>-</td>
            <td>{DetailCotisations?.prevoyance}</td>
          </tr>
          <tr>
            <td>Retraite de base tranche 1</td>
            <td>{DetailCotisations?.assiette_retraite_de_base1}</td>
            <td>{DetailCotisations?.taux_retraite_de_base1}</td>
            <td>{DetailCotisations?.retraite_de_base1}</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_de_base1}</td>
          </tr>
          {DetailCotisations?.retraite_de_base2 !== "0 €" && (
            <tr>
              <td>Retraite de base tranche 2</td>
              <td>{DetailCotisations?.assiette_retraite_de_base2}</td>
              <td>{DetailCotisations?.taux_retraite_de_base2}</td>
              <td>{DetailCotisations?.retraite_de_base2}</td>
              <td>-</td>
              <td>{DetailCotisations?.retraite_de_base2}</td>
            </tr>
          )}
          <tr>
            <td>Retraite complémentaire forfaitaire</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_complementaire_forfaitaire}</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_complementaire_forfaitaire}</td>
          </tr>
          <tr>
            <td>Retraite complémentaire proportionnelle</td>
            <td>{DetailCotisations?.assiette_retraite_complementaire_proportionnelle}</td>
            <td>{DetailCotisations?.taux_RcPp}</td>
            <td>{DetailCotisations?.retraite_complementaire_proportionnelle}</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_complementaire_proportionnelle}</td>
          </tr>
          {Periode["ConventionneCarcdsf" + props.ClientConjoint] === "oui" && (
            <>
              <tr>
                <td>Retraite complémentaire forfaitaire PCV</td>
                <td>-</td>
                <td>-</td>
                <td>{DetailCotisations?.pcv_forfaitaire}</td>
                <td>{DetailCotisations?.pcv_forfaitaire_Cpam}</td>
                <td>{DetailCotisations?.pcv_forfaitaire_restant}</td>
              </tr>
              {DetailCotisations?.pcv_proportionnelle_restant !== "0 €" && (
              <tr>
                <td>Retraite complémentaire proportionnelle PCV</td>
                <td>{DetailCotisations?.assiette_pcv_proportionnelle}</td>
                <td>{DetailCotisations?.taux_PcvPp}</td>
                <td>{DetailCotisations?.pcv_proportionnelle}</td>
                <td>{DetailCotisations?.pcv_proportionnelle_Cpam}</td>
                <td>{DetailCotisations?.pcv_proportionnelle_restant}</td>
              </tr>
              )}
            </>
          )}
          <tr className="light-title">
            <td colSpan={5}>
              Cotisations sociales obligatoires <span style={{ fontSize: "8pt" }}> (hors formation et CSG-CRDS)</span>{" "}
            </td>
            <td>{applyFormat(DetailCSG?.ChargesSocialesObligatoire, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>Formation professionnelle</td>
            <td>{DetailCotisations?.assiette_formation_professionnelle}</td>
            <td>{DetailCotisations?.taux_formation_professionnelle}</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
            <td>-</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
          </tr>
          <tr>
            <td>Contribution URPS</td>
            <td>{DetailCotisations?.assiette_contribution_urps}</td>
            <td>{DetailCotisations?.taux_Urps}</td>
            <td>{DetailCotisations?.contribution_urps}</td>
            <td>-</td>
            <td>{DetailCotisations?.contribution_urps}</td>
          </tr>
          <tr>
            <td>CSG déductible</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>-</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>CSG non déductible + CRDS</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGNonDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>-</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          {Number(DetailCSG?.csg_primes) > 0 && (
            <tr>
              <td>CSG non déductible + CRDS sur épargne salariale</td>
              <td>{applyFormat(DetailCSG?.assiette_csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>{applyFormat(DetailCSG?.TauxCSG, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>-</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          )}
          <tr className="light-title">
            <td colSpan={5}>Total des cotisations sociales </td>
            <td>{applyFormat(TotalChargesSociales, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DetailCotisationsCarcdsf;
