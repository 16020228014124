import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";



const ParamCarmf = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Conventionné"
            value={context.state[props.Outil + "ConventionneCarmf" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ConventionneCarmf" + props.ClientConjoint)}
            options={[
              {
                value: "non",
                label: "Non",
              },
              {
                value: "Secteur1",
                label: "Secteur 1",
              },
              {
                value: "Secteur2",
                label: "Secteur 2",
              },
            ]}
            normal
          />
        </Column>
        {context.state[props.Outil + "ConventionneCarmf" + props.ClientConjoint] !== "non" && (
        <Column hpad>
          <TextField
            value={context.state[props.Outil + "TauxCpam" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "TauxCpam" + props.ClientConjoint)}
            label="Taux de prise en charge CPAM"
            dataType="ufloat"
            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
            normal
          />
        </Column>
         )}
      </Row>
      {context.state[props.Outil + "ConventionneCarmf"+ props.ClientConjoint] === "Secteur2" && (
        <Row tpad>
          <Column>
            <DropDown
              label="Option SSI secteur 2"
              value={context.state[props.Outil + "OptionRsi" + props.ClientConjoint]}
              onChange={context.handleInputChange(props.Outil + "OptionRsi" + props.ClientConjoint)}
              options={[
                {
                  value: "oui",
                  label: "Oui",
                },
                {
                  value: "non",
                  label: "Non",
                },
              ]}
              normal
            />
          </Column>
        </Row>
      )}
    </>
  );
};

export default ParamCarmf;
