import { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight, FaCheck, FaTimes } from "react-icons/fa";
import Column from "../../layout/column/column";
import Row from "../../layout/row/row";
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from "../../ui-helpers";
import Button from "../button/button";
import MenuItem from "../menu-item/menu-item";
import MenuTitle from "../menu-title/menu-title";
import Menu from "../menu/menu";
import "./pager.css";

/**
 * Generates a page component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Pager = (props) => {
  // Excluded properties for automatic properties propagation
  const Excluded = ["id", "withMenu", "onPageChange", "navButtons", "pages", "customButtons", "disabled", "hidden", "validateDisabled"].concat(Alignments, Paddings, Sizes);

  // Initialize states
  const [id] = useState(props?.id ? props.id : getUniqId());
  const [page, setPage] = useState(props?.page || 0);
  const [pages, setPages] = useState(props?.pages || []);
  const [validateDisabled, setValidateDisabled] = useState(props?.validateDisabled || false);

  /**
   * Creates left menu component.
   * @returns Left menu component.
   */
  const createMenu = () => {
    // Check menu
    if (!props?.withMenu) return <></>;

    // Check pages
    const pages = props?.pages || [];
    if (!pages.length) return <></>;
    // Generate menu
    return (
      <Menu>
        {pages.map((pageItem, index) => {
          return pageItem.isTitle ? (
            <MenuTitle key={`TITLE${index}`} title={pageItem.title || ""} subTitle={pageItem.subTitle || ""} disabled={pageItem.disabled} hidden={pageItem.hidden} />
          ) : (
            pageItem !== false && (
              <MenuItem
                key={`PAGE${index}`}
                disabled={pageItem.disabled}
                hidden={pageItem.hidden}
                selected={index === page}
                onClick={() => {
                  props?.onPageChange?.(index);
                  setPage(index);
                }}
              >
                {pageItem.icon}
                <span>{pageItem.label}</span>
              </MenuItem>
            )
          );
        })}
      </Menu>
    );
  };

  /**
   * Goes to previous page.
   * @param {ClickEvent} evt Click event.
   * @param {Function} handler Handler associated with previous button.
   */
  const gotoPrevious = (evt, handler) => {
    // Get the previous page
    let previousPage = page;
    for (let i = page - 1; i >= 0; i--) {
      const pageItem = pages[i];
      if (!pageItem.isTitle && !pageItem.disabled && !pageItem.hidden) {
        previousPage = i;
        break;
      }
    }

    // Check if we can go to next page
    if (page !== previousPage) {
      props?.onPageChange?.(previousPage);
      setPage(previousPage);
      handler?.(evt);
    }
  };

  /**
   * Goes to next page.
   * @param {ClickEvent} evt Click event.
   * @param {Function} handler Handler associated with next button.
   */
  const gotoNext = (evt, handler) => {
    // Get next page
    let nextPage = page;
    for (let i = page + 1; i < pages.length; i++) {
      const pageItem = pages[i];
      if (!pageItem.isTitle && !pageItem.disabled && !pageItem.hidden) {
        nextPage = i;
        break;
      }
    }

    // Check if we can go to next page
    if (page !== nextPage) {
      props?.onPageChange?.(nextPage);
      setPage(nextPage);
      handler?.(evt);
    }
  };

  /**
   * Creates a buttpon.
   * @param {Object} button Button parameters.
   * @param {boolean} disabled Defines whether button is disabled.
   * @param {boolean} iconBefore Defines whether button icon is before label.
   * @param {Component} defaultIcon Default icon if none given in parameters.
   * @param {String} defaultLabel Default label if none given in parameters.
   * @param {Function} clickWrapper Optional click handler.
   * @returns Generated button.
   */
  const createButton = (button, disabled, iconBefore, defaultIcon, defaultLabel, clickWrapper = null) => {
    return (
      <Button
        onClick={
          clickWrapper
            ? (evt) => {
                clickWrapper(evt, button.onClick);
              }
            : button.onClick
        }
        disabled={disabled}
      >
        {iconBefore ? button.icon ? button.icon : defaultIcon : <></>}
        <span>{button.label ? button.label : defaultLabel}</span>
        {!iconBefore ? button.icon ? button.icon : defaultIcon : <></>}
      </Button>
    );
  };

  /**
   * Creates the buttons bar.
   * @returns Buttons bar component.
   */
  const createButtons = () => {
    // Get buttons
    const buttons = props?.navButtons || {};
    if (!props?.customButtons && !Object.keys(buttons).length) return <></>;

    // Check if previous is possible
    let previousEnabled = false;
    for (let i = page - 1; i >= 0; i--) {
      const pageItem = pages[i];
      if (!pageItem.isTitle && !pageItem.disabled && !pageItem.hidden) {
        previousEnabled = true;
        break;
      }
    }

    // Check if next is possible
    let nextEnabled = false;
    for (let i = page + 1; i < pages.length; i++) {
      const pageItem = pages[i];
      if (!pageItem.isTitle && !pageItem.disabled && !pageItem.hidden) {
        nextEnabled = true;
        break;
      }
    }

    return (
      <Row className="pager-buttons">
        <Column className="pager-buttons-left">
          <Row fill>
            {buttons["cancel"] ? createButton(buttons["cancel"], false, true, <FaTimes />, "Annuler") : <></>}
            {buttons["previous"] ? createButton(buttons["previous"], !previousEnabled, true, <FaArrowCircleLeft />, "Précédent", gotoPrevious) : <></>}
          </Row>
        </Column>
        <Column className="pager-buttons-center" fill center>
          {props?.customButtons}
        </Column>
        <Column className="pager-buttons-right">
          <Row fill>
            {buttons["next"] ? createButton(buttons["next"], !nextEnabled, false, <FaArrowCircleRight />, "Suivant", gotoNext) : <></>}
            {buttons["validate"] ? createButton(buttons["validate"], validateDisabled, false, <FaCheck id={id + "_validate"} />, "Valider") : <></>}
          </Row>
        </Column>
      </Row>
    );
  };

  // On page property change
  useEffect(() => {
    setPage(props?.page || 0);
  }, [props?.page]);

  // On pages change
  useEffect(() => {
    setPages(props?.pages || []);
    if (page >= props.pages.length) setPage(props.pages.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.pages]);

  // On validate disabled change
  useEffect(() => {
    setValidateDisabled(props?.validateDisabled || false);
  }, [props?.validateDisabled]);

  // Render component
  return (
    <Column fill id={id} className={classFromProperties(props, "pager", ["disabled"])} {...getOptionalProps(props, Excluded)}>
      <Row fill style={{ overflow: "hidden" }}>
        {createMenu()}
        <Column className="pager-content" fill scroll>
          {pages[page].content}
        </Column>
      </Row>
      {createButtons()}
    </Column>
  );
};

export default Pager;
