import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import RemExpressContratFacProModal from "pages/audit rémunération/contratfacultatifpro pager";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe } from "react-icons/fa";

function RemExpressRemunerationTns(props) {
  const { Prenom, ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalContrat, setModalContrat] = useState(false);


  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="resultatRemExpress">
        <tbody>
          <tr>
            <td className="libelle">Budget rémunération pour {Prenom}</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state.RemExpressCoutRemuneration}
                onChange={(e) => {
                  context.handleCalculRemExpress("cout_remuneration","RemExpressCoutRemuneration", ResultatTNS, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Cotisations obligatoires et facultatives</td>
            <td className="nouvelle pr4">
              <Button
                className="editIconOptiRem"
                onClick={() => {
                  setModalContrat(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit id="contratFacProAuditRem" />
              </Button>
              {applyFormat(context.state.RemExpressCotisations, "uint", { thousands: " ", decimal: ",", unit: " €" })}
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération nette</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state.RemExpressRemNette}
                onChange={(e) => {
                  context.handleCalculRemExpress("remuneration_nette", "RemExpressRemNette" , ResultatTNS, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération imposable</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state.RemExpressRemImposable}
                onChange={(e) => {
                  context.handleCalculRemExpress("brut_imposable", "RemExpressRemImposable" , ResultatTNS, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaGlobe />}
        visible={modalContrat}
        onClose={() => {
          context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "",  ResultatTNS, "");
          setModalContrat(false);
        }}
      >
        <RemExpressContratFacProModal
          Outil=""
          ClientConjoint={''}
          visible={modalContrat}
          onClose={() => {
            context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "",  ResultatTNS, "");
            setModalContrat(false);
          }}
        />
      </Modal>
    </CardBox>
  );
}
export default RemExpressRemunerationTns;
