import { useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Scrolls, Sizes } from '../../ui-helpers';
import './menu.css';

const Menu = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ["onChange", "disabled"].concat(Alignments, Paddings, Sizes, Scrolls);

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId())

    return (
        <div
            id={id}
            className={classFromProperties(props, 'menu', Alignments, Paddings, Sizes, Scrolls, ['disabled'] )}
            {...getOptionalProps(props, Excluded)}
        >
            {props?.children}
        </div>
    )
}

export default Menu;