import { useState } from "react";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaCogs } from "react-icons/fa";
import ParamFicheDePaie from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamFicheDePaie";
import ParamLpa from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamLpa";

function ParamLpaPageModal(props) {
  const [modalOpen, setmodalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setmodalOpen(true)}>
        <FaCogs />
        <span>Paramétrage de la LPA ou AON</span>
      </Button>
      <Modal
        width="80%"
        height="80%"
        title="Paramétrage de la LPA ou AON"
        icon={<FaCogs />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ParamLpa
        Outil={props.Outil}
        ClientConjoint={props.ClientConjoint}
        onClose={() => {
            setmodalOpen(false);
          }}/>
        
      </Modal>
    </>
  );
}

export default ParamLpaPageModal;
