import ContratRetraitePager from "components/app/AuditComplet/Retraite/Parametrage/ContratRetraite/ContratRetraitePager";
import { AppContext } from "components/app/RemExprertProvider";
import Modal from "components/ui/modal/modal";
import { useContext } from "react";
import { FaGlobe } from "react-icons/fa";

function ContratRetraitePageModal(props) {
  const context = useContext(AppContext);
  return (
    <>
      <Modal
         hideClose={true}
        width="80%"
        height="80%"
        title= {context.state.TypeSimulation !== "Audit retraite"?"Ajout d'un contrat de retraite existant":"Ajout d'un contrat de retraite"}
        icon={<FaGlobe />}
        visible={props?.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <ContratRetraitePager
     Statut={props.Statut}
           AccessRemExpress={props.AccessRemExpress}
          visible={props?.visible}
          Outil={props.Outil}
          ClientConjoint={props.ClientConjoint}
          CliConj={props.CliConj}
          PayeurPrime={props.PayeurPrime}
          onClose={() => {
            props?.onClose?.();
          }}
        />
      </Modal>
    </>
  );
}

export default ContratRetraitePageModal;
