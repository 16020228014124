import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync } from "react-icons/fa";
import { CalculResultatEntreprise } from "components/app/Api/Remuneration/ApiRemuneration";

function ModalParamDividendes(props) {
  const context = useContext(AppContext);
  useEffect(() => {
    if (context.state[props.Outil + "SetSeuilAssuj"] === false) {
      CalculResultatEntreprise(context.state, props.Outil).then((ResultatEntreprise) => {
        context.handleValueChange(props.Outil+"SeuilAssuj", ResultatEntreprise.SeuilAssuj);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state[props.Outil + "SetSeuilAssuj"]]);

  useEffect(() => {
    if (context.state[props.Outil + "SetSeuilAssujConjoint"] === false) {
      CalculResultatEntreprise(context.state, props.Outil).then((ResultatEntreprise) => {
        context.handleValueChange(props.Outil+"SeuilAssujConjoint", ResultatEntreprise.SeuilAssujConjoint);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state[props.Outil + "SetSeuilAssujConjoint"]]);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <h1>Détention capitalistique</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Capital social"
                  value={context.state[props.Outil + "CapitalSocial"]}
                  onChange={context.handleInputChange(props.Outil + "CapitalSocial")}
                  onBlur={(e) => {
                    context.handleCalculResultatEntreprise(props.Outil, props.Outil + "CapitalSocial", e);
                  }}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label={"Détention capitalistique " + context.state.Prenom}
                  value={context.state[props.Outil + "PartSoc"]}
                  onBlur={(e) => context.handleCalculResultatEntreprise(props.Outil, props.Outil + "PartSoc", e)}
                  normal
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                />
              </Column>
              {context.state.InclureConjoint && (
                <Column lpad>
                  <TextField
                    label={"Détention capitalistique " + context.state.PrenomConjoint}
                    value={context.state[props.Outil + "PartSocConj"]}
                    onBlur={(e) => context.handleCalculResultatEntreprise(props.Outil, props.Outil + "PartSocConj", e)}
                    disabled={context.state[props.Outil + "PartSoc"] === 100}
                    normal
                    dataType="ufloat"
                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                  />
                </Column>
              )}
            </Row>
            <Row tpad>
              <h1>Résultat distribué</h1>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  value={context.state[props.Outil + "Reserve"]}
                  label="Mise en réserve"
                  normal
                  onChange={context.handleInputChange(props.Outil + "Reserve")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
            </Row>
            <Row tpad>
              <h1>Régime social des dividendes</h1>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  iconBefore={
                    <Button
                      tabIndex="-1"
                      icon
                      onClick={() => {
                        context.handleValueChange(props.Outil + "SetSeuilAssuj", false);
                      }}
                    >
                      {context.state[props.Outil + "SetSeuilAssuj"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                    </Button>
                  }
                  label={"Seuil d'assujetissement pour " + context.state.Prenom}
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state[props.Outil + "SeuilAssuj"]}
                  onChange={(e) => {
                    context.handleValueChange(props.Outil + "SetSeuilAssuj", true);
                    context.handleValueChange(props.Outil + "SeuilAssuj", e.target.value);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
            {props.Outil === "OptiRem" && context.state.InclureConjoint && (
              <Row>
                <Column>
                  <TextField
                    iconBefore={
                      <Button
                        tabIndex="-1"
                        icon
                        onClick={() => {
                          context.handleValueChange(props.Outil + "SetSeuilAssujConjoint", false);
                        }}
                      >
                        {context.state[props.Outil + "SetSeuilAssujConjoint"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                      </Button>
                    }
                    label={"Seuil d'assujetissement pour " + context.state.PrenomConjoint}
                    labelStyle={{ whiteSpace: "nowrap" }}
                    value={context.state[props.Outil + "SeuilAssujConjoint"]}
                    onChange={context.handleInputChange(props.Outil + "SeuilAssujConjoint")}
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                    normal
                  />
                </Column>
              </Row>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ModalParamDividendes;
