import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function DecesCondPart(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            { context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel" ? (
              <>
                <Row>
                  <Column>
                    <TextField
                      label="Capital décès toutes causes"
                      value={context.state.DecesTouteCause}
                      onKeyUp={context.handleMontantToTaux("DecesTouteCause")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Capital décès accidentel"
                      value={context.state.DecesAccidentel}
                      onKeyUp={context.handleMontantToTaux("DecesAccidentel")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Double effet"
                      value={context.state.DecesDoubleEffet}
                      onChange={context.handleInputChange("DecesDoubleEffet")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Frais d'obsèques"
                      value={context.state.FraisObseque}
                      onChange={context.handleInputChange("FraisObseque")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Rente conjoint annuelle"
                      value={context.state.RenteConjoint}
                      onKeyUp={context.handleMontantToTaux("RenteConjoint")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <tr>
                        <th>
                          <TextField className="invisible" value={"Age de début"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Age de fin"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Rente éducation annuelle"} normal />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut1}
                            onChange={context.handleInputChange("DecesAgeDebut1")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin1}
                            onChange={context.handleInputChange("DecesAgeFin1")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.RenteEducation0_11}
                            onChange={context.handleInputChange("RenteEducation0_11")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut2}
                            onChange={context.handleInputChange("DecesAgeDebut2")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin2}
                            onChange={context.handleInputChange("DecesAgeFin2")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.RenteEducation12_17}
                            onChange={context.handleInputChange("RenteEducation12_17")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut3}
                            onChange={context.handleInputChange("DecesAgeDebut3")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin3}
                            onChange={context.handleInputChange("DecesAgeFin3")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.RenteEducation18_25}
                            onChange={context.handleInputChange("RenteEducation18_25")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <h1>Capital</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Assiette de calcul"
                      value={context.state.BaseDecesComplementaireSalarie}
                      onChange={context.handleInputChange("BaseDecesComplementaireSalarie")}
                      options={[
                        { value: "SalaireBrut", label: "Salaire brut" },
                        { value: "SalaireNet", label: "Salaire net" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Capital décès toutes causes"
                      value={context.state.PourcentageDecesTouteCause}
                      onChange={context.handleInputChange("PourcentageDecesTouteCause")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Capital décès accidentel"
                      value={context.state.PourcentageDecesAccidentel}
                      onChange={context.handleInputChange("PourcentageDecesAccidentel")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Double effet"
                      value={context.state.DecesDoubleEffet}
                      onChange={context.handleInputChange("DecesDoubleEffet")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Frais d'obsèques"
                      value={context.state.FraisObseque}
                      onChange={context.handleInputChange("FraisObseque")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <h1>Rente conjoint</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Assiette de calcul"
                      value={context.state.BaseRenteConjointComplementaireSalarie}
                      onChange={context.handleInputChange("BaseRenteConjointComplementaireSalarie")}
                      options={[
                        { value: "SalaireBrut", label: "Salaire brut" },
                        { value: "SalaireNet", label: "Salaire net" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Taux"
                      value={context.state.PourcentageRenteConjoint}
                      onChange={context.handleInputChange("PourcentageRenteConjoint")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>

                <Row tpad>
                  <h1>Rente éducation</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Assiette de calcul"
                      value={context.state.BaseRenteEducationComplementaireSalarie}
                      onChange={context.handleInputChange("BaseRenteEducationComplementaireSalarie")}
                      options={[
                        { value: "SalaireBrut", label: "Salaire brut" },
                        { value: "SalaireNet", label: "Salaire net" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <tr>
                        <th>
                          <TextField className="invisible" value={"Age de début"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Age de fin"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Rente éducation annuelle"} normal />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut1}
                            onChange={context.handleInputChange("DecesAgeDebut1")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin1}
                            onChange={context.handleInputChange("DecesAgeFin1")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.PourcentageRenteEducation0_11}
                            onChange={context.handleInputChange("PourcentageRenteEducation0_11")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            normal
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut2}
                            onChange={context.handleInputChange("DecesAgeDebut2")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin2}
                            onChange={context.handleInputChange("DecesAgeFin2")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.PourcentageRenteEducation12_17}
                            onChange={context.handleInputChange("PourcentageRenteEducation12_17")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            normal
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.DecesAgeDebut3}
                            onChange={context.handleInputChange("DecesAgeDebut3")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.DecesAgeFin3}
                            onChange={context.handleInputChange("DecesAgeFin3")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " ans" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.PourcentageRenteEducation18_25}
                            onChange={context.handleInputChange("PourcentageRenteEducation18_25")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            normal
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default DecesCondPart;
