import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync } from "react-icons/fa";

const TauxRemplacement = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <TextField
                      iconBefore={
                        <Button
                          icon
                          onClick={(e) => {
                            context.handleCarriereSalaireReferenceSaisi("CarriereSalaireReferenceSaisi" + ClientConjoint, ClientConjoint, "");
                          }}
                        >
                          {!context.state["CarriereSalaireReferenceSaisi" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                label="Revenus nets de l'année en cours"
                value={context.state["CarriereSalaireReference" + ClientConjoint]}
                onBlur={(e) => {
                  context.handleCarriereSalaireReferenceSaisi("CarriereSalaireReferenceSaisi" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                large
              />
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default TauxRemplacement;
