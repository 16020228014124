import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const VersementPee = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  


  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Row>
              <Column>
                <TextField
                  label="Intéressement"
                  value={context.state.InteressementContratRetraite}
                  onChange={context.handleInputChange("InteressementContratRetraite")}
                  
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
              <Column hpad>
              <TextField
                  label="Participation"
                  value={context.state.ParticipationContratRetraite}
                  onChange={context.handleInputChange("ParticipationContratRetraite")}
                  
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Abondement"
                  value={context.state.AbondementContratRetraite}
                  onChange={context.handleInputChange("AbondementContratRetraite")}
                  
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
              <Column hpad>
              <TextField
                  label="Versement volontaire"
                  value={context.state.VolontaireContratRetraite}
                  onChange={context.handleInputChange("VolontaireContratRetraite")}
                  
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
              <DropDown
                  label="CSG-CRDS précomptées"
                  value={context.state.CSGPERCOContratRetraite}
                  onChange={context.handleInputChange("CSGPERCOContratRetraite")}
                  options={[
                    { value: "non", label: "Non" },
                    { value: "oui", label: "Oui" },
                  ]}
                  normal
                />
              </Column>
            </Row>
           
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default VersementPee;
