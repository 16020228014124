import { useState } from "react";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaCogs } from "react-icons/fa";
import ContributionSpecifique from "components/app/AuditComplet/SituationPro/ContributionSpecifique";

function ContributionSpecifiquePageModal(props) {
  const [modalOpen, setmodalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setmodalOpen(true)}>
        <FaCogs />
        <span>Ajouter une contribution spécifique</span>
      </Button>
      <Modal
        width="80%"
        height="80%"
        title="Contribution spécifique"
        icon={<FaCogs />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ContributionSpecifique
        ClientConjoint={props.ClientConjoint}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ContributionSpecifiquePageModal;
