import CardBox from "components/ui/controls/card-box/card-box";
import { applyFormat } from "components/ui/ui-helpers";

const RemunerationTNS = (props) => {
    let {Resultat,Prenom}=props;
  return (
    <>
      <CardBox tpad bpad>
        <table className="resultat">
          <tbody>
            <tr>
              <td>Budget rémunération {Prenom}</td>
              <td>{applyFormat(Resultat.CoutRemuneration, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Cotisations obligatoires et facultatives</td>
              <td>{applyFormat(Number(Resultat.TotalChargesSociales)+Number(Resultat.CotisationsFacultativesEntreprise), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Rémunération nette</td>
              <td>{applyFormat(Resultat.RemunerationNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Rémunération imposable</td>
              <td>{applyFormat(Resultat.BrutImposable, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          </tbody>
        </table>
      </CardBox>
    </>
  );
};

export default RemunerationTNS;
