import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import StateBox from "components/ui/controls/state-box/state-box";
import Hover from "components/ui/controls/hover/hover";
import { FaInfo } from "react-icons/fa";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { GetCalculRetraiteNetImposable, GetCalculStrategiePER } from "components/app/Api/ApiSimulateur";
import ImageRetraite from "./Image retraite.png";

function StrategiePERSituationPro(props) {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);

  const CalculNetImposable = (BrutImposable) => {
    ResultatTNS.GetImpot("", context.state, BrutImposable, 0, 0).then((resImpot) => {
      context.handleValueChange("StrategiePERRevenuNetActivite", resImpot.BaseImposable);
      context.handleValueChange("StrategiePERRemuImposable", BrutImposable);
    });
  };

  const RetaiteRONette = (Value) => {
    context.handleValueChange("StrategiePERRetaiteRONette", Value);
    GetCalculRetraiteNetImposable(context.state, Value, "StrategiePERCalculRevenusNetRetraite").then((result) => {
      context.handleValueChange("StrategiePERRevenuNetRetraite", result);
    });
  };
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <h1>En activité</h1>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Statut social"
                  value={context.state.Statut}
                  options={[
                    {
                      value: "TNS Article 62",
                      label: "TNS article 62",
                    },
                    {
                      value: "TNS individuel",
                      label: "TNS Individuel",
                    },
                    {
                      value: "Assimilé salarié",
                      label: "Assimilé salarié",
                    },
                  ]}
                  onChange={context.handleInputChange("Statut")}
                  normal
                />
              </Column>
              <Column>
                {context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel" ? (
                  <DropDown label="Caisse d'affiliation" value={context.state.StrategiePERCaisse} options={caisse} onChange={context.handleInputChange("StrategiePERCaisse")} normal />
                ) : (
                  <DropDown
                    label="Caisse d'affiliation"
                    value={"Regime"}
                    options={[
                      {
                        value: "Regime",
                        label: "Régime général",
                      },
                    ]}
                    normal
                  />
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Rémunération annuelle imposable "
                  value={context.state.StrategiePERRemuImposable}
                  onChange={(e) => {
                    CalculNetImposable(e.target.value);
                  }}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              {context.state.StrategiePERCaisse === "Cavp" && (
                <Column>
                  <DropDown
                    label="Classe retraite"
                    value={context.state["StrategiePERClasseRetraiteCavp"]}
                    onChange={context.handleInputChange("StrategiePERClasseRetraiteCavp")}
                    options={[
                      {
                        value: "classe3",
                        label: "3",
                      },
                      {
                        value: "classe4",
                        label: "4",
                      },
                      {
                        value: "classe5",
                        label: "5",
                      },
                      {
                        value: "classe6",
                        label: "6",
                      },
                      {
                        value: "classe7",
                        label: "7",
                      },
                      {
                        value: "classe8",
                        label: "8",
                      },
                      {
                        value: "classe9",
                        label: "9",
                      },
                      {
                        value: "classe10",
                        label: "10",
                      },
                      {
                        value: "classe11",
                        label: "11",
                      },
                      {
                        value: "classe12",
                        label: "12",
                      },
                      {
                        value: "classe13",
                        label: "13",
                      },
                    ]}
                    normal
                  />
                </Column>
              )}
            </Row>

            <Row tpad>
              <h1>A la retraite : Pension de retraite des régimes obligatoires</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Age de départ"
                  value={context.state.StrategiePERAgeDepartRetraite}
                  onChange={context.handleInputChange("StrategiePERAgeDepartRetraite")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ paddingTop: "43px" }}>ans et</Column>
              <Column>
                <TextField
                  label="&nbsp;"
                  value={context.state.StrategiePERAMoisDepartRetraite}
                  onChange={context.handleInputChange("StrategiePERAMoisDepartRetraite")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ paddingTop: "43px" }}>mois</Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  label="Pension brute annuelle"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERRetaiteROBrute}
                  onChange={context.handleInputChange("StrategiePERRetaiteROBrute")}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              <Column>
                <TextField
                  label="Pension nette annuelle"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERRetaiteRONette}
                  onChange={(e) => {
                    RetaiteRONette(e.target.value);
                  }}
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
              <Column style={{ paddingTop: "41px" }}>
                <Hover style={{ width: "560px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                  Vous pouvez récupérer ces montants dans l'étude retraite Word du simulateur "Audit retraite" : annexe "détails des pensions". <br />
                  <img style={{ height: "210px", paddingTop: "25px", borderRadius: "4px" }} src={ImageRetraite} alt="..." />
                </Hover>
              </Column>
            </Row>
            <Row tpad></Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default StrategiePERSituationPro;
