import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointAgircArrco = (props) => {
  const { resultRetraite, ClientConjoint } = props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };

  return (
    <>
      {context.state["Carriere" + ClientConjoint].filter(
        (Carriere) =>
          (Carriere.Nature === "ActiviteSalarie" ||
            Carriere.Nature === "SalarieNonCadre" ||
            Carriere.Nature === "SalarieCadre" ||
            Carriere.Nature === "SalarieCadreCFE" ||
            Carriere.Nature === "SalarieCadreAgricole" ||
            Carriere.Nature === "CavecSalarie") 
      ).length > 0 && (
        <tr>
          <td>AGIRC-ARRCO</td>
          <td>
            <TextField
              value={context.state["TableauPoints" + ClientConjoint].RetraiteCompAgircArrco}
              onChange={(e) => {
                context.handlePointRetraite(ClientConjoint, "RetraiteCompAgircArrco", e);
              }}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
          <td>
            <DropDown
              value={context.state["TableauPoints" + ClientConjoint].AnneeFinAgircArrco}
              onChange={(e) => {
                context.handlePointRetraite(ClientConjoint, "AnneeFinAgircArrco", e);
                props.CalculRetraite();
              }}
              options={[
                { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
              ]}
              small
            />
          </td>
          <td>
            <TextField
              value={resultRetraite?.InfosRetraite?.Salarie?.General?.AgircArrcoCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Salarie?.General?.AgircArrcoCalcule}
              disabled={true}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
        </tr>
      )}
    </>
  );
};
export default PointAgircArrco;
