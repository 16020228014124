import { useEffect, useState } from "react";
import Row from "../../layout/row/row";
import { Alignments, applyFormat, classFromProperties, getOptionalProps, getUniqId, NumericDecimals, NumericSigned, NumericTypes, Paddings, removeFormat, Sizes, splitDate } from "../../ui-helpers";
import { FaEye } from 'react-icons/fa';
import './text-field.css';
import Button from "../button/button";

/**
 * Generates a text input component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const TextField = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['dataType', 'label', 'labelStyle', 'value', 'format', 'password', 'onChange', 'onKeyDown', 'onPaste', 'onFocus', 'onBlur', 'style', 'textAlign', 'iconBefore', 'iconAfter', 'multiline', 'disabled', 'readOnly'].concat(Alignments, Paddings, Sizes);

    // Data type getter
    const getDataType = () => (!props.dataType && props?.format && props.format.dataType) ? props.format.dataType : props?.dataType || 'string';

    // Check alignment
    let alignment = NumericTypes.includes(getDataType()) ? 'right' : getDataType() === 'date' ? 'center' : 'left';
    if (props?.textAlign)
        alignment = props.textAlign;

    // Set style
    const style = props?.style || {};
    if (!style.textAlign)
        style.textAlign = alignment;

    //#region Format handling

    /**
     * Validate an input character.
     * @param {HTMLInputElement} el Input element.
     * @param {string} value Value without format.
     * @param {CharacterData} chr Character to validate.
     * @returns True if character is allowed, False otherwise.
     */
    const validateChar = (el, value, chr) => {

        // Check empty character
        if (!chr) return false;

        // Always prevent \ " < >
        if (chr === '\\' || chr === '"') return false;

        // Assert format
        if (getDataType() === 'string') return true;

        // Check type
        const ascii = chr.charCodeAt(0);
        if (NumericTypes.includes(getDataType())) {
            // Check numeric value
            const unselected = value.substring(0, el.selectionStart) + value.substring(el.selectionEnd + 1);
            if (chr === 'BACK') return true;
            if (ascii >= 48 && ascii <= 57) return true;
            if (NumericDecimals.includes(getDataType()) && (chr === (props.format?.decimal || '.') || ascii === 46) && unselected.indexOf(props.format?.decimal || '.') === -1 && unselected.indexOf('.') === -1) return true;
            if (ascii === 45 && value.indexOf('-') === -1 && !el.selectionStart && NumericSigned.includes(getDataType())) return true;
        }
        else if (getDataType() === 'date') {
            // Check date value
            const actualValue = el.value;
            if (chr === 'BACK') {
                if ((el.selectionStart !== el.selectionEnd) || (el.selectionStart !== actualValue.length)) return true;
                if (actualValue.substr(actualValue.length - 1, 1) !== '/') return true;
                el.setSelectionRange(Math.max(0, actualValue.length - 2), actualValue.length, 'backward');
            }
            if (ascii >= 48 && ascii <= 57) return true;
            const slashes = actualValue.split('/').length - 1;
            if (chr === '/' && slashes < 2 && actualValue.substr(actualValue.length - 1, 1) !== '/') return true;
        }

        // Character not accepted
        return false;
    }

    //#endregion

    //#region Keyboard events

    /**
     * Handles a key down event.
     * @param {KeyboardEvent} evt Keyboard event.
     * @returns False if event is cancelled.
     */
    const handleKeyDown = (evt) => {

        // Initialize result
        let valid = false;

        // Get value
        const el = evt.target;
        const value = removeFormat(el.value, getDataType(), props?.format);

        // Check enter key
        if (evt.key === 'Enter' && el.nodeName === 'INPUT') {

            // Get inputs
            const focusable = Array.prototype.slice.call(document.querySelectorAll('a[href], button:not([tabindex="-1"]), input, textarea, select, details, [tabindex]:not([tabindex="-1"])'));
            if (focusable.length) {
                let index = focusable.indexOf(el);
                if (index !== -1) {
                    index = index === focusable.length - 1 ? 0 : index + 1;
                    const nextInput = focusable[index];
                    nextInput?.focus();
                }
            }
        }
        // Always authorize CTRL+A CTRL+C CTRL+X and CTRL+V
        else if (((evt.key === 'a') || (evt.key === 'c') || (evt.key === 'x') || (evt.key === 'v')) && evt.ctrlKey) {
            valid = true;
        }
        // Always authorize arrows and control characters
        else if (evt.which < 47 && evt.key !== 'Backspace') {
            valid = true;
        }
        else {
            // Validate character
            valid = validateChar(el, value, evt.key === 'Backspace' ? 'BACK' : evt.key);
        }

        // Check if key must be cancelled
        if (!valid) {
            evt.preventDefault();
            return false;
        }

        // Call user defined handler
        return props?.onKeyDown?.(evt, value);
    }

    //#endregion

    //#region Input events

    /**
     * Handles an input change event.
     * @param {ChangeEvent} evt Change event.
     * @returns False if event is cancelled.
     */
    const handleChange = (evt) => {

        // Update value
        setValue(removeFormat(evt.target.value));
    }

    //#endregion

    //#region Focus events

    /**
     * Handles a focus event.
     * @param {FocusEvent} evt Focus event.
     * @returns False if event is cancelled.
     */
    const handleFocus = (evt) => {

        // Set focus state
        setIsFocused(true);

        // Remove format
        evt.target.value = removeFormat(value, getDataType(), props?.format);
        if (!props?.readOnly && !props?.disabled)
            // setTimeout(() => { evt.target.select() }, 10);
            evt.target.select()
        else
            evt.target.setSelectionRange(0, 0);

        // Call user defined handler
        return props?.onFocus?.(evt);
    }

    /**
     * Handles a blur event.
     * @param {BlurEvent} evt Blur event.
     * @returns False if event is cancelled.
     */
    const handleBlur = (evt) => {

        // Set blur state
        setIsFocused(false);

        // Apply format
        const dataType = getDataType();
        let unformatted = removeFormat(value, dataType, props?.format);

        // Check date
        if (dataType === 'date')
            unformatted = splitDate(unformatted).replace(/\//g, '');

        // Call user defined change handler
        evt.target.value = unformatted === '' && NumericTypes.includes(dataType) ? '0' : unformatted;
        const unformattedProp = removeFormat(sanitizeValue(props?.value), dataType, props?.format);
        if (unformatted !== unformattedProp) props?.onChange?.(evt, unformatted);

        // Call user defined blur handler
        const result = props?.onBlur?.(evt, unformatted);
        evt.target.value = applyFormat(unformatted, dataType, props?.format);
        return result;
    }

    //#endregion

    //#region Clipboard events

    /**
     * Handles a paste action.
     * @param {ClipboardEvent} evt Clipboard event.
     * @returns Always false to cancel event.
     */
    const handlePaste = (evt) => {

        // Get type informations
        const el = evt.target;
        const actualValue = el.value;
        const unformatted = removeFormat(actualValue, getDataType(), props?.format);

        // Get clipboard value
        const pasted = evt.clipboardData.getData('text');
        let result = actualValue.substr(0, el.selectionStart);
        const suffix = actualValue.substr(el.selectionEnd);
        for (let i = 0; i < pasted.length; i++) {
            let chr = pasted.substring(i, i + 1);
            if (validateChar(el, unformatted, chr))
                result += chr;
        }
        result += suffix;

        // Set value
        //el.value = result;
        setValue(result);

        // Cancel paste
        evt.preventDefault();
        return false;
    }

    //#endregion

    /**
     * Generates input element.
     * @returns Input element.
     */
    const generateInput = () => <input
        id={id}
        onChange={handleChange}
        type={props?.password ? 'password' : 'text'}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={style}
        value={value}
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...getOptionalProps(props, Excluded)}
    />

    /**
     * Generates text area element.
     * @returns Textarea element.
     */
    const generateTextArea = () => <textarea
        id={id}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={style}
        value={value}
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...getOptionalProps(props, Excluded)}
    >
    </textarea>

    /**
     * Toggles plain text view for password field.
     */
    const togglePassword = () => {
        const el = document.getElementById(id);
        el.setAttribute('type', el.getAttribute('type') === 'password' ? 'text' : 'password');
    }

    /**
     * Generates input field.
     * @returns Generated input component
     */
    const generateField = () => {

        // Define input div class name
        let className = 'input-field';
        if (props?.iconBefore) className += ' with-icon-left';
        if (props?.iconAfter) className += ' with-icon-right';

        // Return row with icons
        return (
            <Row>
                {props?.iconBefore ?
                    <div className="icon icon-left" tabIndex="-1">
                        {props.iconBefore}
                    </div>
                    : <></>
                }
                <div className={className} tabIndex="-1">
                    {props?.multiline ? generateTextArea() : generateInput()}
                </div>
                {props?.password
                    ? <div className="icon icon-right" tabIndex="-1">
                        <Button icon tabIndex="-1" onClick={togglePassword}>
                            <FaEye />
                        </Button>
                    </div>
                    : props?.iconAfter
                        ? <div className="icon icon-right" tabIndex="-1">
                            {props.iconAfter}
                        </div>
                        : <></>
                }
            </Row>
        )
    }

    /**
     * Ensures value string even if undefined.
     * @param {string} value Value to sanitize.
     * @returns Sanitized value.
     */
    const sanitizeValue = (value) => value !== undefined &&  value !== null ? value.toString() : '';

    // Initialize states
    const [id] = useState(props?.id ? props.id : getUniqId());
    const [value, setValue] = useState(getDataType() !== 'date' ? sanitizeValue(props?.value) : applyFormat(sanitizeValue(props?.value), getDataType(), props?.format));
    const [isFocused, setIsFocused] = useState(false);
    const [isDisabled, setDisabled] = useState(props?.disabled !== undefined ? props?.disabled : false);
    const [isReadOnly, setReadOnly] = useState(props?.readOnly !== undefined ? props?.readOnly : false);

    /**
     * On value property change.
     */
    useEffect(() => {
        const strValue = sanitizeValue(props?.value);
        const dataType = getDataType();
        const useFormat = isFocused && dataType !== 'date' ? true : false;
        setValue(useFormat ? strValue : applyFormat(strValue, dataType, props?.format));
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setReadOnly(props?.readOnly !== undefined ? props?.readOnly : false);
        // eslint-disable-next-line
    }, [props?.value, getDataType(), props?.format, props?.disabled, props?.readOnly, isFocused, isDisabled, isReadOnly])

    // Generate component
    return (
        <div
            className={classFromProperties(
                props,
                'text-field' +
                (isDisabled ? ' disabled' : '') +
                (isReadOnly ? ' readOnly' : ''),
                Alignments,
                Paddings,
                Sizes,
                ['hidden'])}
            tabIndex="-1"
        >
            {props?.label ? <label tabIndex="-1" htmlFor={id} style={props?.labelStyle}>{props.label}</label> : <></>}
            {generateField()}
        </div>
    )
}

export default TextField;