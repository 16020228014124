import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync } from "react-icons/fa";
import CheckBox from "components/ui/controls/check-box/check-box";

function RevenusRetraiteCell(props) {
  const {  Annee, Revenus, Trimestre, CheckboxTrimestre, CheckboxDeplafondPass } = props;
  const context = useContext(AppContext);
  let Today = new Date();
  return (
    <>
      <tr>
        <td>{Annee}</td>
       <td>
          {Annee < 2002 && (
            <TextField
              value={Revenus}
              onChange={(e) => {
                props.onModifyRevenus(Annee, e);
                props.CalculTrimestre(Annee, e);
              }}
              dataType="uint"
              format={{ thousands: " ", unit: " FRF" }}
              normal
            />
          )}
          {/* {Annee >= 2002 && Annee < Today.getFullYear() && ( */}
          {Annee >= 2002 && Annee < 2019 && (
            <TextField
              value={Revenus}
              onChange={(e) => {
                props.onModifyRevenus(Annee, e);
                props.CalculTrimestre(Annee, e);
              }}
              dataType="uint"
              format={{ thousands: " ", unit: " €" }}
              normal
            />
          )}
          {/* {Annee >= Today.getFullYear() && ( */}
          {Annee >= 2019 && (
            <TextField
              disabled={context.state.IdPeriodeRetraite === "CarriereActuelle" ? true : false}
              value={Revenus}
              onKeyUp={(e) => {
                props.onModifyRevenus(Annee, e);
              }}
              onBlur={() => {
                props.CalculTrimestre();
              }}
              dataType="uint"
              format={{ thousands: " ", unit: " €" }}
              normal
            />
          )}
        </td>
         {props.DisplayTrimestre === true && (
          <>
            <td>
              <TextField
                iconBefore={
                  <Button
                    tabIndex="-1"
                    icon
                    onClick={(e) => {
                      props.onCheck(Annee, false, "CheckboxTrimestre", props.DetailRetraiteConcerne);
                      props.CalculTrimestre(Annee, context.state[props.DetailRetraiteConcerne][Annee]["Salaire"]);
                    }}
                  >
                    {!CheckboxTrimestre ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                disabled={Annee>=(Today.getFullYear()) &&  context.state.RetraiteActivite!=="Msa"}
                value={Trimestre}
                onChange={(e) => {
                  props.onCheck(Annee, true, "CheckboxTrimestre", props.DetailRetraiteConcerne);
                  props.onModifyTrimestre(Annee, e.target.value, props.DetailRetraiteConcerne);
                }}
                dataType="uint"
                normal
              />
            </td>
          </>
        )}
       {props.DisplayDeplafondPass === true &&
          (Annee < 2005 ? (
            <td style={{ textAlign: "center" }}>
              <CheckBox
                // style={{ textAlign: "center" }}
                checked={true}
                unchecked={false}
                value={CheckboxDeplafondPass}
                onChange={(e) => {
                  props.onCheck(Annee, e.target.checked, "CheckboxDeplafondPass", props.DetailRetraiteConcerne);
                }}
                
              />
            </td>
          ) : (
            <td></td>
          ))} 
      </tr>
    </>
  );
}

export default RevenusRetraiteCell;
