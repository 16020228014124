import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointRafp = (props) => {
  const {resultRetraite,ClientConjoint}=props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };

  return (
    <>
                 {context.state['Carriere'+ClientConjoint].filter((Carriere) => Carriere.Nature === "FonctionPublique" ).length > 0 && (
                    <tr>
                      <td>RAFP</td>
                      <td>
                        <TextField
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteCompFonctionPublique}
                          onChange={(e)=>{context.handlePointRetraite(ClientConjoint,"RetraiteCompFonctionPublique",e)}}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          small
                        />
                      </td>
                      <td>
                        <DropDown
                          value={context.state["TableauPoints"+ClientConjoint].AnneeFinFonctionPubliqueComp}
                          onChange={(e)=>{
                            context.handlePointRetraite(ClientConjoint,"AnneeFinFonctionPubliqueComp",e)
                            props.CalculRetraite();
                          }}
                          options={[
                            { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                            { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
                          ]}
                          small
                        />
                      </td>
                      <td>
                        <TextField
                          value={resultRetraite?.InfosRetraite?.Rafp?.General?.PointCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Rafp?.General?.PointCalcule}
                          disabled={true}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          small
                        />
                      </td>
                    </tr>
                  )}
    </>
  );
};
export default PointRafp;
