import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PlusMoinsValue=(props)=> {
  const { Outil } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>Paramètres des plus et moins values mobilières</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="Montant des plus values" VarName1={Outil + "IRDirigeantPlusValue"} VarName2="" />
                <LigneImpotDetaille Libelle="Montant des moins values imputables " VarName1={Outil + "IRDirigeantMoinsValue"} VarName2="" />
                <LigneImpotDetaille Libelle="Montant de l'abattement pour durée de détention" VarName1={Outil + "IRDirigeantAbattementDureeDetention"} VarName2="" />
                <tr>
                  <td>Abattement spécifique pour départ en retraite</td>
                  <td>
                    <DropDown
                      value={context.state[Outil + "IRDirigeantAbattementDepartRetraite"]}
                      onChange={context.handleInputChange(Outil + "IRDirigeantAbattementDepartRetraite")}
                      options={[
                        { value: "Non", label: "Non" },
                        { value: "Oui", label: "Oui" },
                      ]}
                      normal
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default PlusMoinsValue;
