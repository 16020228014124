import { useState, useEffect, useContext } from "react";
import {  FaFileSignature } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import AjoutContratRetrPersoPageModalOptiRem from "./ajout contrat retraite perso modal optirem";

const ContratFacPersoModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    setCurrentPage(0);
  }, [props.visible]);
  return (
    <>
      <Pager
        withMenu
        pages={[
          {
            label: "Contrats",
            icon: <FaFileSignature />,
            content: context.state.OptiRemListeContrat!==undefined && context.state.OptiRemListeContratConjoint!==undefined && <AjoutContratRetrPersoPageModalOptiRem Outil="OptiRem" ClientConjoint={props.ClientConjoint} AccessRemExpress={false}/>,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ContratFacPersoModal;
