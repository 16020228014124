import { useState, useContext } from "react";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { AppContext } from "components/app/RemExprertProvider";
import SituationPro from "components/app/AuditRemuneration/SituationPro";

const SituationProRemExpressPage = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Situation professionnelle pour {context.state.Prenom}</h1>
                </Row>
                <SituationPro/>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default SituationProRemExpressPage;
