import { useState, useContext, useEffect } from "react";
import { FaGlobe, FaInfo, FaPlusCircle, FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";
import Hover from "components/ui/controls/hover/hover";
import ParamStrategiePERModal from "./parametrage strategie PER";
import { GetCalculStrategiePER } from "components/app/Api/ApiSimulateur";
import _ from "lodash";
import { SyntheseStrategiePER } from "components/app/Api/Rapport/ApiRapport";
import { applyFormat } from "components/ui/ui-helpers";
import EffortEparne from "./graphEffortEpargne";

const StrategiePER = (props) => {
  let { clientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalStrategie, setModalStrategie] = useState(false);
  const [results, setResults] = useState({});
  const [disponible163Calcule, setDisponible163Calcule] = useState(0);
  const dt = new Date();
  const yearM1 = dt.getFullYear() - 1;
  const yearM2 = dt.getFullYear() - 2;
  const yearM3 = dt.getFullYear() - 3;
  const year = dt.getFullYear();

  const CalculDisponible = () => {
    if (context.state.StrategiePERCadreFiscal === "Madelin") {
      GetCalculStrategiePER(context.state, "", "StrategiePERCalculDisponibleMadelin").then((DisponibleMadelin) => {
        context.handleValueChange("StrategiePERDisponibleFiscal", DisponibleMadelin);
      });
    } else {
      GetCalculStrategiePER(context.state, "", "StrategiePERCalculDisponiblePER163").then((Disponible163) => {
        let Values = _.cloneDeep(context.state.StrategiePERDisponibleTabPlafond163);

        if ((year in Values)) {
        Values[year]["Client"] = Disponible163.Dirigeant;
        setDisponible163Calcule(Disponible163.Dirigeant);
        context.handleValueChange("StrategiePERDisponibleTabPlafond163", Values);
        context.handleValueChange("StrategiePERDisponibleFiscal", Disponible163.Total);
        }
      });
    }
  };


  const CalculCapital = () => {
    GetCalculStrategiePER(context.state, "", "StrategiePERCalculStrategie").then((results) => {
      setResults(results);
      context.handleValueChange("StrategiePERResultat", results);
      
    });
  };

  useEffect(() => {
    let InitValues = _.cloneDeep(context.state.StrategiePERDisponibleTabPlafond163);
    if (!(year in InitValues)) {
      InitValues[year] = { Client: 0, SetClient: false, Conjoint: 0, SetConjoint: true };
    }
    if (!(yearM1 in InitValues)) {
      InitValues[yearM1] = { Client: 0, Conjoint: 0 };
    }
    if (!(yearM2 in InitValues)) {
      InitValues[yearM2] = { Client: 0, Conjoint: 0 };
    }
    if (!(yearM3 in InitValues)) {
      InitValues[yearM3] = { Client: 0, Conjoint: 0 };
    }
    context.handleValueChange("StrategiePERDisponibleTabPlafond163", InitValues);
    CalculCapital();
  }, []);

  useEffect(() => {
    CalculDisponible();
  }, [context.state.StrategiePERCadreFiscal, context.state.StrategiePERRevenuNetActivite, context.state.StrategiePERContratVersementInitial]);
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Stratégie PER {context.state.Prenom}</h1>
                </Row>
                <Row center>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={() => {
                        // setClientConjoint("");
                        setModalStrategie(true);
                      }}
                    >
                      <span>Ajouter une stratégie</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>
                  <Button
                    onClick={() => {
                      SyntheseStrategiePER(context.state, results).then((res) => {});
                    }}
                  >
                    <span>
                      {" "}
                      <FaPrint />
                      Imprimer
                    </span>
                  </Button>
                </Row>
                {/* <Row tpad>RESULTAT</Row>
                <Row>
                  <table>
                    <tr>
                      <th>Annee</th>
                      <th>Cotisation brute</th>
                      <th>Frais</th>
                      <th>Cotisation nette</th>
                      <th>Produit</th>
                      <th>Epargne</th>
                    </tr>
                    
                    {typeof results==="object"  && "strategie"in results &&  Object.keys(results?.strategie).map(Annee=>{
                      {console.log(Annee)}
                      return(
                      <tr>
                        <td>{Annee}</td>
                        <td>{results?.strategie[Annee]['CotisationBrute']}</td>
                        <td>{results?.strategie[Annee]['Frais']}</td>
                        <td>{results?.strategie[Annee]['CotisationNette']}</td>
                        <td>{results?.strategie[Annee]['Produit']}</td>
                        <td>{results?.strategie[Annee]['CapitalRetraite']}</td>
                      </tr>
                      )
                    })}
                  </table>
                </Row> */}
                {/* <Row tpad>
                  <h1>RESULTAT RENTE</h1>
                </Row>
                <Row>
                  <table>
                    <tr>
                      <th>Annee</th>
                      <th>Rente brute</th>
                      <th>Frais d'arrérages</th>
                      <th>Rente nette de frais</th>
                      <th>Prel Soc</th>
                      <th>Rente Nette</th>
                      <th>Assiette impot sans C</th>
                      <th>Impot sans C</th>
                      <th>Assiette impot</th>
                      <th>Impot</th>
                      <th>Impot sup</th>
                      <th>Rente nette nette</th>
                    </tr>

                    {typeof results === "object" &&
                      "sortie" in results &&
                      Object.keys(results?.sortie?.cumulRente).map((Annee) => {
                        return (
                          Annee !== "total" && (
                            <tr>
                              <td>{Annee}</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["renteBrute"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["fraisArrerage"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["renteApresFraisArrerage"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["prelSoc"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["renteNette"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(context.state.StrategiePERRevenuNetRetraite, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.retaiteSansContrat["impot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["assietteImpot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["impot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["impotSup"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulRente[Annee]["renteNetteApresImpot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                            </tr>
                          )
                        );
                      })}
                  </table>
                </Row>
                <Row tpad>
                  <h1>RESULTAT CAPITAL</h1>
                </Row>
                <Row>
                  <table>
                    <tr>
                      <th>Annee</th>
                      <th>Capital restant</th>
                      <th>Capital</th>
                      <th>Capital brut produit</th>
                      <th>Prel soc produit</th>
                      <th>PFU produit</th>
                      <th>Capital net produit (A)</th>
                      <th>Capital brut versement (B)</th>
                      <th>Assiette impot sans C</th>
                      <th>Impot sans C</th>
                      <th>Assiette impot</th>
                      <th>Impot</th>
                      <th>Impot sup (C)</th>
                      <th>Capital net net (A+B-C)</th>
                    </tr>

                    {typeof results === "object" &&
                      "sortie" in results &&
                      Object.keys(results?.sortie?.cumulCapital).map((Annee) => {
                        return (
                          Annee !== "total" && (
                            <tr>
                              <td>{Annee}</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["capitalRestant"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["capitalBrut"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td style={{ borderLeft: "1px solid black" }}>
                                {applyFormat(results?.sortie?.cumulCapital[Annee]["capitalBrutPartProduit"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €
                              </td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["prelSoc"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["pfuProduit"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td style={{ borderRight: "1px solid black" }}>
                                {applyFormat(results?.sortie?.cumulCapital[Annee]["capitalNetPartProduit"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €
                              </td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["capitalBrutPartVersement"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(context.state.StrategiePERRevenuNetRetraite, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.retaiteSansContrat["impot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["assietteImpot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["impot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["impotSup"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                              <td>{applyFormat(results?.sortie?.cumulCapital[Annee]["capitalNetApresImpot"], "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                            </tr>
                          )
                        );
                      })}
                  </table>
                </Row> */}

                <Row tpad>
                  <h1>EFFORT REEL D'EPARGNE</h1>
                </Row>
                <Row>
                  <EffortEparne data={results?.enActivite?.graphEffortEpargne} />
                </Row>
                <Row tpad>
                  <h1>COMPARATIF RENTE ET CAPITAL</h1>
                </Row>
                <Row tpad>
                  <table style={{ width: "700px" }}>
                    <tr>
                      <th></th>
                      <th style={{ textAlign: "center" }}>Sortie en rente</th>
                      <th style={{ textAlign: "center" }}>Sortie en capital</th>
                    </tr>
                    <tr>
                      <td>Régimes obligatoires</td>
                      <td style={{ textAlign: "center" }}>{applyFormat(results?.ro?.retraiteNetteApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                      <td style={{ textAlign: "center" }}>{applyFormat(results?.ro?.retraiteNetteApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                    </tr>
                    <tr>
                      <td>PER individuel</td>
                      <td style={{ textAlign: "center" }}>{applyFormat(results?.sortie?.rente?.renteNetteApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                      <td style={{ textAlign: "center" }}>{applyFormat(results?.sortie?.capital?.capitalNetApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €</td>
                    </tr>
                    <tr>
                      <td>Retraite nette totale annuelle </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(results?.ro?.retraiteNetteApresImpot + results?.sortie?.rente?.renteNetteApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(results?.ro?.retraiteNetteApresImpot + results?.sortie?.capital?.capitalNetApresImpot, "ufloat", { thousands: " ", decimal: ",", precision: 0 })} €
                      </td>
                    </tr>
                    <tr>
                      <td>Retraite nette totale mensuelle </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(results?.ro?.retraiteNetteApresImpot / 12 + results?.sortie?.rente?.renteNetteApresImpot / 12, "ufloat", { thousands: " ", decimal: ",", precision: 0 })}{" "}
                        €
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(results?.ro?.retraiteNetteApresImpot / 12 + results?.sortie?.capital?.capitalNetApresImpot / 12, "ufloat", {
                          thousands: " ",
                          decimal: ",",
                          precision: 0,
                        })}{" "}
                        €
                      </td>
                    </tr>
                    <tr>
                      <td>Taux de remplacement </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(
                          results?.enActivite?.remNetteApresImpot === 0
                            ? 0
                            : ((results.ro?.retraiteNetteApresImpot + results.sortie?.rente?.renteNetteApresImpot) / results?.enActivite?.remNetteApresImpot) * 100,
                          "ufloat",
                          { thousands: " ", decimal: ",", precision: 0 }
                        )}
                        %
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {applyFormat(
                          results?.enActivite?.remNetteApresImpot === 0
                            ? 0
                            : ((results.ro?.retraiteNetteApresImpot + results.sortie?.capital?.capitalNetApresImpot) / results?.enActivite?.remNetteApresImpot) * 100,
                          "ufloat",
                          { thousands: " ", decimal: ",", precision: 0 }
                        )}
                        %
                      </td>
                    </tr>
                  </table>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>

      <Modal
        width="80%"
        height="80%"
        title="Stratégie PER"
        icon={<FaGlobe />}
        visible={modalStrategie}
        onClose={() => {
          setModalStrategie(false);
        }}
      >
        <ParamStrategiePERModal
          Results={results}
          ClientConjoint={clientConjoint}
          CalculDisponible={CalculDisponible}
          Disponible163Calcule={disponible163Calcule}
          CalculCapital={CalculCapital}
          visible={modalStrategie}
          onClose={() => {
            setModalStrategie(false);
          }}
        />
      </Modal>
    </>
  );
};

export default StrategiePER;
