import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ContratPrecoModal from "./ContratPrecoModal";
import DropDown from "components/ui/controls/drop-down/drop-down";

function ContratPreco(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column tpad fill>
        <CardBox>
          <Column fill>
            <Row>
              <h1>Proposition n° {props.Propal}</h1>
            </Row>
            <ContratPrecoModal Outil={props.Outil} AccessRemExpress={"AccessRemExpress" in props ? true : false} ClientConjoint={props.ClientConjoint} />
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratPreco;
