import { createContext, useEffect, useState } from "react";

/**
 * Modal context.
 */
export const ModalContext = createContext();

/**
 * Generates a modal context provider component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const ModalProvider = (props) => {

    // Initialize states
    const [visible, setVisible] = useState(props?.visible || false);

    /**
     * Hides modal.
     */
    const hide = () => {
        if (visible) props?.onClose?.();
        setVisible(false);
    }

    /**
     * Shows modal.
     */
    const show = () => {
        setVisible(true);
    }

    // On visible property change
    useEffect(() => {
        setVisible(props?.visible || false);
    },[props?.visible])

    // Render component
    return (
        <ModalContext.Provider value={{
            visible: visible,
            hide: hide,
            show: show
        }}
        >
            {props.children}
        </ModalContext.Provider>
    )
}
export default ModalProvider;