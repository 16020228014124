import React from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";

const DetailSAMCRPCEN = ({ InfosRAM }) => {
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <table>
              <thead>
                <tr>
                  <th className="text-center">Année</th>
                  <th className="text-center">Revenus</th>
                  <th className="text-center">Revenus (€)</th>
                  <th className="text-center">PASS</th>
                  <th className="text-center">
                    Revenus <br />
                    pris en <br />
                    compte
                  </th>
                  <th className="text-center">
                    Coefficient de <br />
                    revalorisation
                  </th>
                  <th className="text-center">
                    Revenus <br />
                    plafonnés <br />
                    revalorisés
                  </th>
                  <th className="text-center">
                    Meilleurs <br />
                    revenus
                  </th>
                </tr>
              </thead>
              {Boolean(InfosRAM.Crpcen) && (
                <tbody>
                  {Object.entries(InfosRAM.Crpcen.General.DetailSAMCrpcen).map((data, index) => {
                    if (data[1].SalaireRetenu > 0) {
                      return (
                        <tr key={"DetailRAM" + index}>
                          <td className="text-center">{data[1].Annee}</td>
                          <td className="text-center">
                            {data[1].Annee < 2002
                              ? applyFormat(Math.max(0, data[1].Salaire), "uint", { thousands: " ", decimal: ",", unit: " FRF" })
                              : applyFormat(Math.max(0, data[1].Salaire), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                          </td>
                          <td className="text-center">{applyFormat(Math.max(0, data[1].SalaireEuro), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                          <td className="text-center">{applyFormat(Math.max(0, data[1].Pass), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                          <td className="text-center">
                            {data[1].Annee < 2002
                              ? applyFormat(Math.max(0, Number(data[1].SalaireRetenu / Number(6.55957)).toFixed(0)), "uint", { thousands: " ", decimal: ",", unit: " €" })
                              : applyFormat(Math.max(0, data[1].SalaireRetenu), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                          </td>
                          <td className="text-center">{applyFormat(Math.max(0, data[1].Coeff), "ufloat", { thousands: " ", decimal: ",", precision: 3 })} </td>
                          <td className="text-center">{applyFormat(Math.max(0, data[1].SalaireRevalorise), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                          <td className="text-center">
                            {Boolean(data[1].MeilleurSalaire) ? applyFormat(Math.max(0, data[1].MeilleurSalaire), "uint", { thousands: " ", decimal: ",", unit: " €" }) : ""}
                          </td>
                        </tr>
                      );
                    } else {
                      return "";
                    }
                  })}
                  <tr className="light-title">
                    <td colSpan="7">Revenu annuel moyen (RAM) :</td>
                    <td className="text-center">{applyFormat(Math.max(0, InfosRAM.Crpcen.General.SAM), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailSAMCRPCEN;

//    <FormatLignePension
//   key={index++}
//   caisse={"RSI Commerçant"}
//   points={data[1]["General"]["TotalPoint"]}
//   valeurPoint={data[1]["General"]["ValeurPoint"]}
//   pensionTheo={data[1]["General"]["PensionTheorique"]}
//   coeff={data[1]["General"]["Coefficient"]}
//   majoEnfant={"-"}
//   pension={data[1]["General"]["Pension"]}
// />
