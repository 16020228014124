import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const ParamMsa = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="ATEXA"
            value={context.state[props.Outil + "Atexa" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "Atexa" + props.ClientConjoint)}
            options={[
              {
                value: "A",
                label: "A"
              },
              {
                value: "B",
                label: "B"
              },
              {
                value: "C",
                label: "C"
              },
              {
                value: "D",
                label: "D"
              },
              {
                value: "E",
                label: "E"
              }
            ]}
            normal
          />
        </Column>
       
      </Row>
    </>
  );
};
export default ParamMsa;


