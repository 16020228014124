import { useCallback, useContext, useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import CalculatriceAgircArrco from "components/app/Simulateurs/Calculatrice AGIRC ARRCO/CalculatriceAgircArrco";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";
import { AppContext } from "components/app/RemExprertProvider";

const CalculatriceAgircArrcoPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(AppContext);

  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;
        let Resultat = context.state.CalculatriceTableauAgircArrco;
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handleMenu=()=>{
    let menu=[
      {
        isTitle: true,
        title: "",
      },
      {
        label: "Dossier",
        icon: <FaHome id="DossierAuditCompletPage" />,
        content: <CalculatriceAgircArrco />
      },
    ]

    return menu;
  }
  return (
    <Pager
      pages={handleMenu()}
      page={currentPage}
      onPageChange={(page)=>{setCurrentPage(page)}}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default CalculatriceAgircArrcoPage;
