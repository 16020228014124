import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaCircle, FaEdit, FaGlobe, FaIdBadge, FaMedrt, FaPlusCircle, FaRedoAlt, FaTrashAlt, FaUmbrella } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import AuditCompletPage from "pages/audit complet";
import Dialogs from "components/ui/dialogs/dialogs";
import { AuthContext } from "components/auth/auth-provider";
import AuditCompletLogo from "./images/Audit complet.JPG";
import AuditRetaiteLogo from "./images/Audit retraite.JPG";
import AuditPrevoyanceLogo from "./images/Audit prevoyance.JPG";
import AuditRemunerationLogo from "./images/Audit remuneration.JPG";
import CalculatriceAgircArrcoLogo from "./images/Caculatrice AGIRC ARRCO.JPG";
import DeclarationPERLogo from "./images/Declaration PER.JPG";
import CorrectionPERLogo from "./images/Correction PER.JPG";
import StrategiePERLogo from "./images/Strategie PER.JPG";

const ListeAudits = (props) => {
  const context = useContext(AppContext);
  const [modalMakoFormation, setmodalMakoFormation] = useState(false);
  const authContext = useContext(AuthContext);
  const [etude, setEtude] = useState("");
  useEffect(() => {
    context.handleListeDossier();
  }, []);

  return (
    <>
      <Column fill>
        {props.AccesEtude === "" && (
          <>
            {" "}
            <CardBox>
              <Column fill>
                <Row>
                  <h1>Pack essentiel</h1>
                </Row>
                <Row>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        props.SetAccessEtude("Audit complet");
                        setEtude("audit complet");
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={AuditCompletLogo} alt="..." />
                      {/* <span>Audit complet</span> */}
                    </Button>
                  </Column>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        props.SetAccessEtude("Audit retraite");
                        setEtude("audit de retraite");
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={AuditRetaiteLogo} alt="..." />
                      {/* <span>Audit de retraite</span> */}
                    </Button>
                  </Column>
                    <Column style={{ width: "170px", marginRight: "32px" }}>
                      <Button
                        style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                        onClick={() => {
                          props.SetAccessEtude("Audit prévoyance");
                          setEtude("audit de prévoyance");
                        }}
                      >
                        <img style={{ height: "120px", borderRadius: "4px" }} src={AuditPrevoyanceLogo} alt="..." />
                        {/* <span>Audit de prévoyance</span> */}
                      </Button>
                    </Column>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        props.SetAccessEtude("Audit rémunération");
                        setEtude("audit de rémunération");
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={AuditRemunerationLogo} alt="..." />
                      {/* <span>Audit de rémunération</span> */}
                    </Button>
                  </Column>
                </Row>
              </Column>
            </CardBox>
            <CardBox tpad>
              <Column fill>
                <Row>
                  <h1>Pack Expert</h1>
                </Row>
                <Row>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        if (authContext.utilisateur_reel?.AccesMakoFormation === true) {
                          props.SetAccessEtude("Calculatrice AGIRC ARRCO");
                          setEtude("calculatrice AGIRC ARRCO");
                        } else {
                          setmodalMakoFormation(true);
                        }
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={CalculatriceAgircArrcoLogo} alt="..." />
                      {/* <span>Calculatrice AGIRC ARRCO</span> */}
                    </Button>
                  </Column>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        if (authContext.utilisateur_reel?.AccesMakoFormation === true) {
                          props.SetAccessEtude("Déclaration PER");
                          setEtude("déclaration PER");
                        } else {
                          setmodalMakoFormation(true);
                        }
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={DeclarationPERLogo} alt="..." />
                      {/* <span>Déclaration PER</span> */}
                    </Button>
                  </Column>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        if (authContext.utilisateur_reel?.AccesMakoFormation === true) {
                          props.SetAccessEtude("Correction plafond PER");
                          setEtude("correction plafond PER");
                        } else {
                          setmodalMakoFormation(true);
                        }
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={CorrectionPERLogo} alt="..." />
                      {/* <span>Correction plafond PER</span> */}
                    </Button>
                  </Column>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        if (authContext.utilisateur_reel?.AccesMakoFormation === true) {
                          props.SetAccessEtude("Stratégie PER");
                          setEtude("stratégie PER");
                        } else {
                          setmodalMakoFormation(true);
                        }
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={StrategiePERLogo} alt="..." />
                      {/* <span>Correction plafond PER</span> */}
                    </Button>
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </>
        )}
        {props.AccesEtude !== "" && (
          <CardBox tpad>
            <Column fill>
              <Row tpad>
                <h1>{etude}</h1>
              </Row>
              <Row>
                <Column style={{ width: "350px" }}>
                  <Button
                    onClick={() => {
                      context.handleNewSimulation(props.AccesEtude);
                    }}
                  >
                    <span>Ajouter une nouvelle étude</span>
                    <FaPlusCircle />
                  </Button>
                </Column>
              </Row>
              <Row tpad>
                <h1>LES ÉTUDES RÉALISÉES</h1>
              </Row>
              <Row tpad>
                <Column scroll fill>
                  <table>
                    <thead>
                      <tr>
                        <th>Type d'audit</th>
                        <th>Titre</th>
                        <th>Date de modification</th>
                        <th>Heure de modification</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.state.ListeDossier?.map((Infos, index) => {
                        return (
                          Infos.TypeSimulation === props.AccesEtude && (
                            <tr key={"DOSSIER" + index}>
                              <td>{Infos.TypeSimulation}</td>
                              <td>{Infos.InfosDossier.LibelleDossier}</td>
                              <td>{Infos.DateAdd}</td>
                              <td>{Infos.HeureAdd}</td>
                              <td>
                                <>
                                  <Button
                                    onClick={() => {
                                      context.handleOpenDossier(Infos.IdDossier);
                                    }}
                                    icon
                                    style={{ display: "inline-block" }}
                                  >
                                    <FaEdit />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      Dialogs.confirm("Vous êtes sur le point de supprimer ce dossier.Voulez-vous continuer ?", "Suppression du dossier", () => {
                                        context.handleDeleteDossier(Infos.IdDossier);
                                      });
                                    }}
                                    icon
                                    style={{ display: "inline-block" }}
                                  >
                                    <FaTrashAlt className="delete" />
                                  </Button>
                                </>
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </Column>
              </Row>
            </Column>
          </CardBox>
        )}
        <Modal
          width="80%"
          height="40%"
          title="Pack formation"
          visible={modalMakoFormation}
          onClose={() => {
            setmodalMakoFormation(false);
          }}
        >
          <Column fill>
            <Row fill>
              <Column fill center style={{ color: "black", textAlign: "justify", marginTop: "32px" }}>
                <br />
                L'accès au pack formation est reservé aux utilisateurs ayant souscrit à l'option.
                <br />
                Pour plus d'informations, vous vous invitions à nous contacter à l'adresse suivante : contact@mako-remexpert.com
                <br />
                {/* <a href="mailto:contact@mako-remexpert.com">contact@mako-remexpert.com</a> */}
                <br />
                Le pack formation comprend les services ci dessous : <br />
                <br />- Un guide complet sur la protection sociale: 150 pages de détails pédagogiques sous forme de fiches sur la retraite et la prévoyance du chef d’entreprise.
                <br />- Des newsletters sur l'environnement sociale et fiscale du chef d'entreprise.
                <br />- Une base de contrats de prévoyance TNS intégrée sur votre espace MAKO (une trentaine de contrats du marché sont analysés sur différents critères : délai d’attente,
                exclusions, garanties, conditions de prise en charges des affections dorsales et psy, barème d’invalidité…).
                <br />- Une calculette de points AGIRC ARRCO permettant de contrôler les points acquis.
                <br />- Deux outils d’aide à la détermination et la déclaration du plafond PER dans le cadre de l’article 163 quatervicies du CGI : correction des anciens plafonds, calcul du
                plafond 2024.
                <br />- Un outil d’aide à la vente du PER : mise en évidence de l’efficacité du dispositif : raisonnement net de fiscalité et de PS à l’entrée et à la sortie.
                <br />
                <br />
              </Column>
            </Row>
          </Column>
        </Modal>
      </Column>
    </>
  );
};

export default ListeAudits;
