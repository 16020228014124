import React, { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Row from "components/ui/layout/row/row";
import ModalPeriodeRetraite from "./ModalPeriodeRetraite";

const Periode = (props) => {
  const context = useContext(AppContext);

  useEffect(()=>{
    if(props.visible===true){
      // context.handleCreationRevenusRetraite("RetraiteDebutActivite",context.state.RetraiteDebutActivite)
    }
  },[context.state.RetraiteActivite,props.visible])

 
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <TextField
                  label="A partir du"
                  disabled={context.state.RISAuto === "RISAuto" ? true : false}
                  value={context.state.RetraiteDebutActivite}
                  // onBlur={context.handleCreationRevenusRetraite("RetraiteDebutActivite")}
                  onBlur={(evt,unformatted) => { context.handleCreationRevenusRetraite("RetraiteDebutActivite",unformatted) }}
                  dataType='date'
                />
              </Column>
              <Column>
                <DropDown
                label='&nbsp;'
                  value={context.state.CheckboxDepartRetraite}
                  disabled={context.state.RISAuto === "RISAuto" ? true : false}
                  onChange={context.handleCheckboxDepartRetraite(props.ClientConjoint)}
                  options={[
                    { value: true, label: "Départ en retraite" },
                    { value: false, label: "Personnalisé" },
                  ]}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Jusqu'au"
                  disabled={context.state.RISAuto === "RISAuto" || context.state.CheckboxDepartRetraite === true ? true : false}
                  value={context.state.RetraiteFinActivite}
                  onBlur={(evt,unformatted) => { context.handleCreationRevenusRetraite("RetraiteFinActivite",unformatted) }}
                  dataType='date'
                />
              </Column>
            </Row>
              <ModalPeriodeRetraite visible={context.state.OpenAlertDialogPeriodeRetraite} onClose={context.handleDialogClose('OpenAlertDialogPeriodeRetraite')} />
            <Row>
              <Column>
              
              <DropDown
                label='Activité'
                  value={context.state.RetraiteActivite}
                  disabled={
                    context.state.RISAuto === "RISAuto" &&
                    context.state.RetraiteActivite !== "Maladie" &&
                    context.state.RetraiteActivite !== "Maternite" &&
                    context.state.RetraiteActivite !== "Invalidite"
                      ? true
                      : false
                  }
                  onChange={context.handleSelectActiviteRetraite("RetraiteActivite")}
                  options={context.state.ListeActiviteRetraite}
                  xlarge
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default Periode;
