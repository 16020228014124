import { createContext, useContext, useEffect, useState } from "react";
import Dialogs from "../ui/dialogs/dialogs";
import Subordinates from "../ui/login/subordinates";
import Modal from "../ui/modal/modal";
import { FaBuilding, FaShieldAlt, FaUser } from "react-icons/fa";
import Companies from "../ui/login/companies";
import Password from "../ui/login/password";
import Api from "../../api/api";
import Login from "../ui/login/login";
import queryString from "query-string";
import { AppContext } from "components/app/RemExprertProvider";
/**
 * Modal context.
 */
export const AuthContext = createContext();

/**
 * Generates an authentication context provider component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const AuthProvider = (props) => {
  const queryString2 = window.location.search;
  const urlParams = new URLSearchParams(queryString2);
  const isSofraco = urlParams.has("sofracoToken");
  const isLyaProtect = urlParams.has("lyaToken");
  // console.log('URLPARAM',urlParams,urlParams.has('auto'))

  // Initialize states
  const [authApiUrl] = useState(props.authApiUrl);
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [association, setAssociation] = useState(null);
  const [relation, setRelation] = useState(null);
  const [settings, setSettings] = useState(null);
  const [auto] = useState(urlParams.has("auto"));
  const [step, setStep] = useState(auto ? 8 : isSofraco ? 10 : isLyaProtect ? 11 : 0);
  const [ok, setOk] = useState(false);
  const [lastResponse, setLastResponse] = useState({});
  const [logoutMessage, setLogoutMessage] = useState(null);
  const context = useContext(AppContext);

  useEffect(() => {
    if (!logoutMessage) return;

    Dialogs.alert(logoutMessage, "Déconnexion", () => {
      setLogoutMessage(null);
    });
  }, [logoutMessage]);

  const handleResponse = (response) => {
    setLastResponse({ ...response });
    switch (response.code) {
      case 0:
        // Authentication done.
        setAuthUser(response.logged_user);
        setUser(response.actual_user);
        setCompany(response.company);
        setAssociation(response.association);
        setRelation(response.relation);
        setSettings(response.settings);
        setOk(true);
        setStep(-1);
        break;
      case 1:
        setStep(99);
        Dialogs.alert(response.message, "Modification prise en compte", () => {
          setStep(0);
        });
        break;
      case 2:
      case 3:
        setStep(99);
        Dialogs.alert(response.message, "Identification impossible", () => {
          setStep(0);
        });
        break;
      case 99:
        setStep(99);
        Dialogs.alert(response.message, "Identification impossible", () => {
          setStep(0);
        });
        break;
      default:
        // Check temporary password
        if (response.temporary) {
          setStep(9);
        } else {
          switch (response.code) {
            case 4:
              setStep(4);
              break;
            case 5:
              setStep(5);
              break;
            default:
              throw new Error(`Etape d'identification inconnue ${response.code}`);
          }
        }
        break;
    }
  };

  const handleLogin = (login, password) => {
    Api.put(authApiUrl, { step: 0, login: login, password: password })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        Dialogs.error(error.message, "Identification impossible");
      });
  };

  const handleAutoLogin = () => {
    Api.put(authApiUrl, { step: 8, auto: urlParams.get("auto") })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        Dialogs.error(error.message, "Identification automatique impossible");
      });
  };
  const handleAutoLoginSofraco = () => {
    Api.put(authApiUrl, { step: 10, sofracoToken: urlParams.get("sofracoToken") })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        Dialogs.error(error.message, "Identification automatique impossible");
      });
  };
  const handleAutoLoginLyaProtect = () => {
    Api.put(authApiUrl, { step: 11, lyaToken: urlParams.get("lyaToken") })
      .then((response) => {
        handleResponse(response.data);
        setTimeout(() => {
          if (response.data?.idClient !== "") {
            context.handleDisplaySimulation(Number(response.data?.idClient));
          }
        }, "500");
      })
      .catch((error) => {
        console.log(error);
        Dialogs.error(error.message, "Identification automatique impossible");
      });
  };

  const handleSubordinateSelect = (subordinateId) => {
    Api.put(authApiUrl, { step: 2, subordinateId: subordinateId })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        setStep(0);
        Dialogs.error(error.message, "Identification impossible");
      });
  };

  const handleCompanySelect = (companyId) => {
    Api.put(authApiUrl, { step: 3, companyId: companyId })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        setStep(0);
        Dialogs.error(error.message, "Identification impossible");
      });
  };

  const handleSavePassword = (password) => {
    Api.put(authApiUrl, { step: 9, userId: lastResponse.logged_user.id, password: password, signature: lastResponse.signature })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        setStep(0);
        Dialogs.error(error.message, "Identification impossible");
      });
  };

  const handleResetPassword = (login) => {
    Api.put(authApiUrl, { step: 1, login: login })
      .then((response) => {
        handleResponse(response.data);
      })
      .catch((error) => {
        setStep(0);
        Dialogs.error(error.message, "Réinitialisation du mot de passe impossible");
      });
  };

  const getContent = (currentStep) => {
    switch (currentStep) {
      case -1:
        return props?.children;
      case 4:
        return (
          <Modal width="60%" height="60%" title="Choix de l'utilisateur" icon={<FaUser />} visible={step === 4} hideClose={true}>
            <Subordinates root={lastResponse.root} onSelect={handleSubordinateSelect} onCancel={() => setStep(0)} />
          </Modal>
        );
      case 5:
        return (
          <Modal width="80%" height="80%" title="Choix du cabinet" icon={<FaBuilding />} visible={step === 5} hideClose={true}>
            <Companies list={lastResponse.companies} onSelect={handleCompanySelect} onCancel={() => setStep(0)} />
          </Modal>
        );
      case 8:
        handleAutoLogin();
        return <></>;
      case 9:
        return (
          <Modal width="400px" height="600px" title="Définir un mot de passe" icon={<FaShieldAlt />} visible={step === 9} hideClose={true}>
            <Password onValidate={handleSavePassword} />
          </Modal>
        );
      case 10:
        handleAutoLoginSofraco();
        return <></>;
      case 11:
        handleAutoLoginLyaProtect();
        return <></>;
      case 99:
        return <></>;
      default:
        return (
          <Modal width="350px" height="350px" title="Identification" icon={<FaShieldAlt />} visible={step < 4} hideClose={true}>
            <Login onValidate={handleLogin} onForgotten={handleResetPassword} />
          </Modal>
        );
    }
  };

  /**
   * Disconnects current user.
   */
  const disconnect = (message = null) => {
    // Clear context
    setStep(0);
    setOk(false);
    setAuthUser(null);
    setUser(null);
    setCompany(null);
    setAssociation(null);
    setLogoutMessage(message);
    // Release server side session
    Api.delete(authApiUrl);
  };

  /**
   * Updates a property.
   * @param {string} name Property name.
   * @param {any} value Property value.
   */
  const updateSettings = (name, value) => {
    const newSettings = { ...settings };
    newSettings[name] = value;
    setSettings(newSettings);
  };

  /**
   * Update settings.
   * @param {Function} success Success callback.
   * @param {Function} failure Failure callback.
   */
  const saveSettings = (success, failure) => {
    // Create form data
    const formData = new FormData();
    formData.append("couleurs", settings.couleurs);
    formData.append("mentions", settings.mentions);
    formData.append("icone", settings.icone);
    formData.append("logo", settings.logo);
    if (settings.fichier_icone) formData.append("fichier_icone", settings.fichier_icone);
    if (settings.fichier_logo) formData.append("fichier_logo", settings.fichier_logo);

    Api.submit(authApiUrl, formData)
      .then((response) => {
        setSettings(response.data);
        success?.();
      })
      .catch((error) => {
        if (error.response.statusText === "Format d'image non reconnu") Dialogs.error("Format d'image non reconnu, utilisez une image PNG ou JPG.", "Une erreur est survenue", failure);
        else Dialogs.error("Impossible de sauvegarder vos préférences pour le moment.", "Une erreur est survenue", failure);
      });
  };

  // Render component
  return (
    <AuthContext.Provider
      value={{
        step,
        ok,
        utilisateur_identifie: authUser,
        utilisateur_reel: user,
        cabinet: company,
        groupement: association,
        relation: relation,
        preferences: settings,
        deconnecter: disconnect,
        modifierPreferences: updateSettings,
        sauverPreferences: saveSettings,
      }}
    >
      {getContent(step)}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
