import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

const tauxPS = [
  {
    value: 0,
    label: "Taux zéro",
  },
  {
    value: 4.3,
    label: "Taux réduit",
  },
  {
    value: 7.4,
    label: "Taux médian",
  },
  {
    value: 9.1,
    label: "Taux normal",
  },
];
const regimeAM = [
  {
    value: "RG",
    label: "Régime général",
  },
  {
    value: "RAMG",
    label: "Régime local Alsace Moselle agricole",
  },
  {
    value: "RAM",
    label: "Régime local Alsace Moselle",
  },
];
const PrelevementsSociaux = (props) => {
  let {ClientConjoint}=props;
  const context = useContext(AppContext);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <DropDown label="Taux de prélèvements sociaux" value={context.state["TauxPS"+ClientConjoint]} onChange={context.handleInputChange("TauxPS"+ClientConjoint)} options={tauxPS} large />
            </Row>
            <Row tpad>
              <DropDown label="Régime d'assurance maladie" value={context.state["RegimeAM"+ClientConjoint]} onChange={context.handleInputChange("RegimeAM"+ClientConjoint)} options={regimeAM} large />
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default PrelevementsSociaux;
