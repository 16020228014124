import React from "react";
import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function ContratRetraite(props) {
  const context = useContext(AppContext);
  const { AffichageCDS, Outil } = props;
  useEffect(() => {
    if (props.Statut !== "TNS Article 62" && props.Statut !== "TNS individuel") {
      context.handleValueChange("FiscaliteContratPer", "163");
    }
    if (context.state.TypeSimulation !== "Audit retraite" && props.PayeurPrime === "Perso") {
      context.handleValueChange("FiscaliteContratPer", "163");
      }
  }, []);

  useEffect(() => {
    if (context.state.FiscaliteContratRetraite === "Madelin") context.handleValueChange("SortieContratRetraite", "RenteSimple");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.FiscaliteContratRetraite]);

  const handleMenu = () => {
    let menu = [
      { value: "Per", label: "PER individuel" },
      { value: "Madelin", label: "Retraite Madelin" },
      { value: "Perp", label: "PERP" },
      { value: "Pero", label: "PER Obligatoire" },
      { value: "Article83", label: "Article 83" },
      { value: "Article62", label: "Assurance vie" },
      { value: "PEE", label: "PEE" },
      { value: "PERCO", label: "PERECO" },
    ];
    // if (props.PayeurPrime === "Entreprise") {
    if (context.state.TypeSimulation !== "Audit retraite") {
      if (props.PayeurPrime === "Entreprise") {
        if (props.Statut === "TNS Article 62" || props.Statut === "TNS individuel") {
          menu = [
            { value: "Per", label: "PER individuel" },
            { value: "Madelin", label: "Retraite Madelin" },
            { value: "Perp", label: "PERP" },
            { value: "Article62", label: "Assurance vie" },
            { value: "PEE", label: "PEE" },
            { value: "PERCO", label: "PERECO" },
          ];
        } else {
          menu = [
            { value: "Per", label: "PER individuel" },
            { value: "Perp", label: "PERP" },
            { value: "Pero", label: "PER Obligatoire" },
            { value: "Article83", label: "Article 83" },
            { value: "Article62", label: "Assurance vie" },
            { value: "PEE", label: "PEE" },
            { value: "PERCO", label: "PERECO" },
          ];
        }
      }else{
      // context.handleValueChange("FiscaliteContratPer", "163");
      if (props.Statut === "TNS Article 62" || props.Statut === "TNS individuel") {
        menu = [
          { value: "Per", label: "PER individuel" },
          { value: "Perp", label: "PERP" },
          { value: "Article62", label: "Assurance vie" },
          { value: "PEE", label: "PEE" },
          { value: "PERCO", label: "PERECO" },
        ];
      } else {
        menu = [
          { value: "Per", label: "PER individuel" },
          { value: "Perp", label: "PERP" },
          { value: "Pero", label: "PER Obligatoire" },
          { value: "Article83", label: "Article 83" },
          { value: "Article62", label: "Assurance vie" },
          { value: "PEE", label: "PEE" },
          { value: "PERCO", label: "PERECO" },
        ];
      }
      }
    }
    // }
    //BLOCAGE 2 CONTRATS 83
    if (
      context.state.FiscaliteContratRetraite === "Per" &&
      context.state[props.Outil + "ListeContrat" + props.ClientConjoint].filter((Contrat) => Contrat.Fiscalite === "Article83" || Contrat.Fiscalite === "Pero").length > 0 &&
      context.state.TypeSimulation !== "Audit retraite"
    ) {
      menu = menu.filter((k) => k.value !== "Article83");
      menu = menu.filter((k) => k.value !== "Pero");
    }
    return menu;
  };
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <DropDown
                  label="Produits d'épargne retraite"
                  value={context.state.FiscaliteContratRetraite}
                  onChange={context.handleChangeFiscalite("FiscaliteContratRetraite")}
                  options={handleMenu()}
                  normal
                />
              </Column>
              {context.state.FiscaliteContratRetraite === "Per" && (props.Statut === "TNS Article 62" || props.Statut === "TNS individuel") && (
                <Column>
                  <DropDown
                    label="Fiscalité"
                    value={context.state.FiscaliteContratPer}
                    onChange={context.handleInputChange("FiscaliteContratPer")}
                    disabled={context.state.TypeSimulation !== "Audit retraite" && props.PayeurPrime === "Perso"}
                    options={[
                      { value: "Madelin", label: "Madelin" },
                      { value: "163", label: "163 quatervicies" },
                    ]}
                    normal
                  />
                </Column>
              )}
            </Row>
            {props.AccessRemExpress === false && (
              <Row tpad>
                <Column>
                  <TextField label="Libellé" value={context.state.CompagnieContratRetraite} onChange={context.handleInputChange("CompagnieContratRetraite")} normal />
                </Column>
                <Column>
                  <DropDown
                    label="Statut"
                    value={context.state.StatutContratRetraite}
                    onChange={context.handleMisEnReductionContrat}
                    options={[
                      { value: "En cours", label: "En cours" },
                      { value: "Mis en réduction", label: "Mis en réduction" },
                    ]}
                    normal
                  />
                </Column>
              </Row>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratRetraite;
