import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import ContexteFiscalPageModal from "pages/audit complet/contexte fiscal/contexte fiscal pager";
import ContratFacPersoModal from "pages/audit complet/simulation/contrat facultatif perso";
import RemExpressContratFacPersoModal from "pages/audit rémunération/contrat facultatif perso";
import React, { useContext, useState } from "react";
import { FaEdit, FaGlobe } from "react-icons/fa";

function RemExpressRevenus(props) {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalContexteFiscalOpen, setmodalContexteFiscalOpen] = useState(false);
  const [modalContratFacPerso, setmodalContratFacPerso] = useState(false);
  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="resultatRemExpress">
        <tbody>
          <tr>
            <td className="libelle">Revenus d'activités perçus</td>
            <td className="nouvelle pr4">{applyFormat(context.state.RemExpressRevPercus, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>

            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Autres revenus du foyer</td>
            <td className="nouvelle pr4">{applyFormat(context.state.RemExpressRevenusFoyer, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Impôt sur le revenu</td>
            <td className="nouvelle pr4">
              <Button
                className="editIconOptiRem"
                onClick={() => {
                  setmodalContexteFiscalOpen(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
              {applyFormat(context.state.RemExpressIRTotal, "uint", { thousands: " ", decimal: ",", unit: " €" })}
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Cotisations facultatives personnelles</td>
            <td className="nouvelle pr4">
            <Button
                className="editIconOptiRem"
                onClick={() => {
                  setmodalContratFacPerso(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit id="contratFacPersoAuditRem" />
              </Button>
              {applyFormat(Number(context.state.RemExpressCotFacPerso), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="extra">&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <Modal
        width="80%"
        height="80%"
        title="Contexte fiscal"
        icon={<FaGlobe />}
        visible={modalContexteFiscalOpen}
        onClose={() => {
          context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "",  ResultatTNS, "");
          setmodalContexteFiscalOpen(false);
        }}
      >
        <ContexteFiscalPageModal
          Outil=""
          visible={modalContexteFiscalOpen}
          onClose={() => {
            context.handleCalculRemExpress(context.state.RemExpressTypeCalcul,"", ResultatTNS, "");
            setmodalContexteFiscalOpen(false);
          }}
        />
      </Modal>
      <Modal
        width="80%"
        height="80%"
        title="Paramètres"
        icon={<FaGlobe />}
        visible={modalContratFacPerso}
        onClose={() => {
          context.handleCalculRemExpress(context.state.RemExpressTypeCalcul, "",  ResultatTNS, "");
          setmodalContratFacPerso(false);
        }}
      >
        <RemExpressContratFacPersoModal
        ClientConjoint={""}
          Outil=""
          visible={modalContratFacPerso}
          onClose={() => {
            context.handleCalculRemExpress(context.state.RemExpressTypeCalcul,"", ResultatTNS, "");
            setmodalContratFacPerso(false);
          }}
        />
      </Modal>
    </CardBox>
  );
}
export default RemExpressRevenus;
