import { useContext, useState } from 'react';
import { FaRegWindowMaximize, FaTimes } from 'react-icons/fa';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../ui-helpers';
import Row from '../layout/row/row';
import Column from '../layout/column/column';
import './modal.css';
import { ModalContext } from './modal-provider';

/**
 * Inner context modal content component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Modal component.
 */
const ModalInner = (props) => {

    // Get modal context
    const context = useContext(ModalContext);

    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'title', 'icon', 'hideClose'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId());

    // Define style
    const style = props?.style || {};

    /**
     * Handles a close event.
     * @param {ClickEvent} evt Click event.
     * @param {Function} handler Custom close handler.
     */
    const closeWrapper = (handler) => {
        context.hide();
        handler?.();
    }

    // Get properties
    let {width, height} = props;
    if (!style.width) style.width = width || '80%';
    if (!style.height) style.height = height || '80%';

    // Render component
    return (
        <div id={'MBG_' + id} className={classFromProperties(props, 'modal-bg' + (!context.visible ? ' hidden' : ''), Alignments, Paddings, Sizes)}>
            <div
                id={id}
                className='modal'
                style={style}
                {...getOptionalProps(props, Excluded)}
            >
                <Row className="modal-header">
                    <Column className="modal-icon">
                        {props?.icon}
                    </Column>
                    <Column fill>
                        <span>{props?.title }</span>
                    </Column>
                    {!props?.hideClose
                        ?   <Column className="modal-close">
                                <FaTimes onClick={() => { closeWrapper(props?.onClose)}} />
                            </Column>
                        :   <></>
                    }
                </Row>
                <Row className="modal-content" fill>
                    {props.children}                        
                </Row>
            </div>
        </div>
    )
}
export default ModalInner;