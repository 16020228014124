import { Alignments, classFromProperties, getOptionalProps } from '../../ui-helpers';
import './content.css';

/**
 * Layout content component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Row component.
 */
 const Content = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = [].concat(Alignments);

    // Generate component
    return (
        <div 
            className={classFromProperties(props, 'content', Alignments)}
            {...getOptionalProps(props, Excluded)}
        >
            {props.children}
        </div>
    );
}
export default Content;