import { Alignments, classFromProperties, getOptionalProps, Paddings, Scrolls } from '../../ui-helpers';
import './row.css';

/**
 * Row component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Row component.
 */
 const Row = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = [].concat(Alignments, Paddings, Scrolls);

    // Generate component
    return (
        <div 
            className={classFromProperties(props, 'row', Alignments, Paddings, Scrolls)}
            {...getOptionalProps(props, Excluded)}
        >
            {props.children}
        </div>
    );
}

export default Row;