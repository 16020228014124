export class SchedulerEntry {
    constructor(delay, validator, action, attempts = 10) {
        this.delay = delay;
        this.validator = validator;
        this.action = action;
        this.attempts = attempts;
    }
}

export class Scheduler {

    stack = [];
    index = -1;
    delay = 0;
    attempts = 0;
    handle = -1;
    running = false;
    timeResolution = 10;

    constructor(onSuccess, onError) {
        this.onSuccess = onSuccess;
        this.onError = onError;
    }

    push(entry) {
        this.stack.push(entry);
    }

    stop() {
        if (this.handle !== -1)
            clearInterval(this.handle);
        this.handle = -1;
    }

    start() {
        if (this.handle !== -1) {
            console.warn("Scheduler already running");
            return;
        }
        this.handle = setInterval(() => {
            if (!this.running) {
                // Run new entry
                this.index++;
                if ( this.index === this.stack.length) {
                    this.onSuccess?.();
                    this.stop();
                }
                else {
                    this.delay = this.timeResolution;
                    this.attempts = 0;
                    this.running = true;
                }
            }
            else {
                try {
                    this.delay += this.timeResolution;
                    if ( this.delay >=  this.stack[ this.index].delay) {
                        this.delay = 0;

                        // Check validator
                        if (!this.stack[this.index].validator()) {
                            this.attempts++;
                            if (this.attempts >= this.stack[this.index].attempts)
                                throw new Error(`Reached max attempts on scheduler entry #${this.index}`);
                        }
                        else {
                            this.stack[this.index].action();
                            this.running = false;
                        }
                    }
                } catch (err) {
                    this.stop();
                    this.onError?.(err);
                }
            }
        }, this.timeResolution);
    }
}