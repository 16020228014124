import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const ParamCarpv = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Classe prévoyance"
            value={context.state[props.Outil + "ClassePrevoyanceCarpv" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ClassePrevoyanceCarpv" + props.ClientConjoint)}
            options={[
              {
                value: "minimum",
                label: "Minimum",
              },
              {
                value: "medium",
                label: "Médium",
              },
              {
                value: "maximum",
                label: "Maximum",
              },
            ]}
            normal
          />
        </Column>
        {context.state.TypeSimulation !== "Audit prévoyance" && (
          <Column hpad>
            <DropDown
              label="Classe retraite"
              value={context.state[props.Outil + "ClasseRetraiteCarpv" + props.ClientConjoint]}
              onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCarpv" + props.ClientConjoint)}
              options={[
                {
                  value: "NC",
                  label: "NC",
                },
                {
                  value: "SS1",
                  label: "SS1",
                },
                {
                  value: "SS2",
                  label: "SS2",
                },
                {
                  value: "S1",
                  label: "S1",
                },
                {
                  value: "S2",
                  label: "S2",
                },
                {
                  value: "A",
                  label: "A",
                },
                {
                  value: "B",
                  label: "B",
                },
                {
                  value: "C",
                  label: "C",
                },
                {
                  value: "D",
                  label: "D",
                },
              ]}
              normal
            />
          </Column>
        )}
      </Row>
    </>
  );
};
export default ParamCarpv;
