import { createRoot } from "react-dom/client";
import { FaCheck, FaQuestionCircle, FaTimes, FaTimesCircle } from "react-icons/fa";
import Button from "../controls/button/button";
import Column from "../layout/column/column";
import Dialog from "./dialog";

/**
 * Dialog boxes manager.
 */
class Dialogs {

    static createRoot(component) {
        // Create values div
        const bg = document.createElement('div');
        bg.classList.add('dialog-bg');

        // Create react root
        const bgRoot = createRoot(bg);

        // Add options
        bgRoot.render(component);

        // Add values to body
        document.body.querySelector('.App').append(bg);

        // Return background component
        return bg;
    }

    static alert(message, title, onOk) {
        const bg = Dialogs.createRoot(
            <Dialog
                title={title}
                message={message}
                buttons={
                    <Column>
                        <Button small onClick={() => {
                            bg.remove();
                            onOk?.();
                        }}>
                            Ok
                        </Button>
                    </Column>
                }
            />)
    }

    static confirm(message, title, onYes, onNo) {
        const bg = Dialogs.createRoot(
            <Dialog
                icon={<FaQuestionCircle />}
                title={title}
                message={message}
                buttons={
                    <>
                        <Column>
                            <Button small onClick={() => {
                                bg.remove();
                                onYes?.();
                            }}>
                                <FaCheck />
                                Oui
                            </Button>
                        </Column>
                        <Column lpad>
                            <Button small onClick={() => {
                                bg.remove();
                                onNo?.();
                            }}>
                                <FaTimes />
                                Non
                            </Button>
                        </Column>
                    </>
                }
            />)
    }

    static error(message, title, onOk) {
        const bg = Dialogs.createRoot(
            <Dialog
                icon={<FaTimesCircle />}
                title={title}
                message={message}
                buttons={
                    <Column>
                        <Button small onClick={() => {
                            bg.remove();
                            onOk?.();
                        }}>
                            Ok
                        </Button>
                    </Column>
                }
            />)
    }
}
export default Dialogs; 