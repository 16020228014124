import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    headerContainer: {
        marginTop: 12
    },
    reportTitle: {
        fontSize:9,
        fontFamily: 'Helvetica-Oblique'
    },
  });



  const InvoiceInfosMako = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>MAKO LOGICIELS</Text>
        <Text style={styles.reportTitle}>439A LA BASTIDE NEUVE</Text>
        <Text style={styles.reportTitle}>84240 ANSOUIS</Text>
    </View>
  );
  
  export default InvoiceInfosMako