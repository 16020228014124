import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Pager from "components/ui/controls/pager/pager";
import ContributionSpecifiquePageModal from "pages/audit complet/situation professionnelle/modules/contribution specifique modal";
import Button from "components/ui/controls/button/button";
import { FaTrashAlt } from "react-icons/fa";
import { applyFormat } from "components/ui/ui-helpers";

function ParamLpa(props) {
  const context = useContext(AppContext);

   const Tabij1 = [
 
    {
      value: 7,
      label: "7 jours",
    },
    {
      value: 15,
      label: "15 jours",
    },
    {
      value: 30,
      label: "30 jours",
    },
    {
      value: 60,
      label: "60 jours",
    },
  ];
  return (
    <>
      <>
        <Pager
          pages={[
            {
              content: (
                <Column pad fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <h1>INDEMNITÉS JOURNALIÈRES</h1>
                      </Row>
                      <Row tpad>
                        <Column>
                          <table className="mono">
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }}>Montant</th>
                                <th style={{ textAlign: "center" }}>Début de versement</th>
                                <th style={{ textAlign: "center" }}>Fin de versement</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <TextField
                                    value={context.state[props.Outil + "LpaMontantIncap" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "LpaMontantIncap" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit:  " €", decimal: "," }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                                <td>
                                <DropDown  style={{ textAlign: "center" }} value={context.state[props.Outil + "LpaFranchiseIncapDebut" + props.ClientConjoint]} onChange={context.handleInputChange(props.Outil + "LpaFranchiseIncapDebut" + props.ClientConjoint)} options={Tabij1} normal />
                                </td>
                                <td>
                                  <TextField
                                  disabled={true}
                                    value={context.state[props.Outil + "LpaFranchiseIncapFin" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "LpaFranchiseIncapFin" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit: " jours", decimal: "," }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Column>
                      </Row>
                      <Row>
                        <h1>INVALIDITÉ</h1>
                      </Row>
                      <Row tpad>
                        <Column>
                          <TextField
                            label="Invalidité partielle"
                            value={context.state[props.Outil + "LpaInvalPartielle" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "LpaInvalPartielle" + props.ClientConjoint)}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: " €", decimal: "," }}
                            normal
                          />
                        </Column>
                        <Column hpad>
                          <TextField
                            label="Invalidité totale"
                            value={context.state[props.Outil + "LpaInvalTotale" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "LpaInvalTotale" + props.ClientConjoint)}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: " €", decimal: "," }}
                            normal
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: () => {
                props.onClose();
              },
            },
          }}
        />
      </>
    </>
  );
}

export default ParamLpa;
