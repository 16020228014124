import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

const Conjoint = (props) => {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <DropDown
                  label="Civilité du conjoint"
                  value={context.state.CiviliteConjoint}
                  options={[
                    { value: "Monsieur", label: "Monsieur" },
                    { value: "Madame", label: "Madame" },
                  ]}
                  onChange={context.handleInputChange("CiviliteConjoint")}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField label="Prénom du conjoint" value={context.state.PrenomConjoint} onChange={context.handleInputChange("PrenomConjoint")} normal />
              </Column>
              <Column hpad>
                <TextField label="Nom du conjoint" value={context.state.NomConjoint} onChange={context.handleInputChange("NomConjoint")} normal />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  label="Date de naissance du conjoint"
                  onKeyUp={(e) => {
                    props.CheckLURA(e);
                  }}
                  value={context.state.DateNaissanceConjoint}
                  onChange={context.handleInputChange("DateNaissanceConjoint")}
                  dataType="date"
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default Conjoint;
