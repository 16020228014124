import Modal from "components/ui/modal/modal";
import { FaHospitalSymbol } from "react-icons/fa";
import ContratSantePager from "components/app/AuditComplet/Prevoyance/ContratSante/ContratSantePager";
import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";

const ContratSantePageModal = (props) => {
  const context = useContext(AppContext);

  return (
    <>
      <Modal
       hideClose={true}
        width="80%"
        height="80%"
        title="Ajout d'un contrat de santé existant"
        icon={<FaHospitalSymbol />}
        visible={props?.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <ContratSantePager
        AccessRemExpress={props.AccessRemExpress}
          visible={props?.visible}
          Outil={props.Outil}
          Statut={props.Statut}
          PayeurPrime={props.PayeurPrime}
          ClientConjoint={props.ClientConjoint}
          onClose={() => {
            props?.onClose?.();
          }}
        />
      </Modal>
    </>
  );
};

export default ContratSantePageModal;
