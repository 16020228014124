import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Pager from "components/ui/controls/pager/pager";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CheckBox from "components/ui/controls/check-box/check-box";
import StateBox from "components/ui/controls/state-box/state-box";

function ContratPrecoSelect(props) {
  const context = useContext(AppContext);

  const [typeContrat, setTypeContrat] = useState("retraite");
  const handleClick = (evt) => {
    setTypeContrat(evt.target.value);
  };
  useEffect(() => {
    if (context.state.TypeSimulation === "Audit prévoyance") {
      setTypeContrat("prevoyance");
    } else {
      setTypeContrat("retraite");
    }
  }, [props.visible]);
  return (
    <>
      <Pager
        pages={[
          {
            content: (
              <Column center fill pad>
                <CardBox>
                  <Column>
                    { context.state[props.Outil+"ContratEnregistre" + props.ClientConjoint].length < 3 && (
                          <Row tpad>
                            <StateBox label="Contrat de prévoyance" inline checked="prevoyance" value={typeContrat === "prevoyance"} onClick={handleClick} />
                          </Row>
                        )
                    }
                    { context.state[props.Outil+"ContratFraisPro" + props.ClientConjoint].length === 0 && (
                      <Row tpad>
                        <StateBox label="Contrat de frais professionnels" inline checked="fraisPro" value={typeContrat === "fraisPro"} onClick={handleClick} />
                      </Row>
                    )}
                  </Column>
                </CardBox>
              </Column>
            ),
          },
        ]}
        page={0}
        navButtons={{
          validate: {
            onClick: () => {
              // context.handleAddCotisationManuelle("");
              props.onClose(typeContrat);
            },
          },
        }}
      />
    </>
  );
}

export default ContratPrecoSelect;
