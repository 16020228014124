import { useEffect, useState } from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './check-box.css';

/**
 * Check box component.
 * @param {ReactDOM.props} props Component properties
 * @returns Generated component.
 */
const CheckBox = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'label', 'labelStyle', 'value', 'checked', 'unchecked', 'onClick', 'onChange', 'inline', 'before', 'disabled', 'readOnly'].concat(Alignments, Paddings, Sizes);

    // Define checked values
    let checkedValue = props?.checked === undefined ? true : props?.checked;
    let uncheckedValue = props?.unchecked === undefined ? false : props?.unchecked;

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId());
    const [ value, setValue ] = useState(props?.value);
    const [ isDisabled, setDisabled ] = useState(props?.disabled !== undefined ? props?.disabled : false);
    const [ isReadOnly, setReadOnly ] = useState(props?.readOnly !== undefined ? props?.readOnly : false);
    
    /**
     * Handles check box click.
     * @param {ClickEvent} evt Click event.
     */
    const handleClick = (evt) => {
        // Check readonly
        if (props?.readOnly) return;

        // Update value
        const newValue = value === checkedValue ? uncheckedValue : checkedValue;
        setValue(newValue);
        evt.target.checked = newValue === checkedValue;
        evt.target.value = newValue;
        props?.onChange?.(evt, newValue);
    }

    /**
     * Handles value change from properties.
     */
    useEffect(() => {
        setValue(props?.value);
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setReadOnly(props?.readOnly !== undefined ? props?.readOnly : false);
    }, [props?.value, props?.disabled, props?.readOnly]);

    // Render component
    return (
        <div 
            id={id}
            className={classFromProperties(
                props,
                'check-box' +
                    (isDisabled ? ' disabled' : '') +
                    (isReadOnly ? ' readOnly' : ''),
                Alignments,
                Paddings,
                Sizes,
                ['inline', 'before', 'checked', 'unchecked']
            )}
            onClick={handleClick}
            {...getOptionalProps(props, Excluded)}
            tabIndex='-1'
        >
            {props?.label ? <label style={props?.labelStyle}>{props.label}</label> : <></>}
            <div className='icon'>
                {value === checkedValue
                    ? <FaCheckSquare/>
                    : <FaRegSquare/>
                }
            </div>
        </div>
    )
}

export default CheckBox;