import { useState, useContext, useEffect } from "react";
import {    FaFilePowerpoint,  FaFileWord,  FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { AppContext } from "components/app/RemExprertProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { AuditPrevoyancepptx, AuditPrevoyanceWord } from "components/app/Api/Rapport/ApiRapport";
import { Menu, MenuItem } from "@mui/material";

const ResultatPagePrevoyance = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ClientConjoint = props.ClientConjoint;
  const [preco, setPreco] = useState("PrevTNSPreco1");
  useEffect(() => {
    ResultatTNS.GetResultPrevTns(context.state);
    
    
    // document.getElementById("GraphIJ").innerHTML = ResultatTNS.state?.PrevTNS?.ImgGraphIncap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleMenuPreco = () => {
    let menu = [];

    if (context.state.PrevTNSPreco1ContratEnregistre.length > 0 || context.state.PrevTNSPreco1ContratFraisPro.length > 0) {
      menu = [...menu, { value: "PrevTNSPreco1", label: "Proposition 1" }];
    }
    if (context.state.PrevTNSPreco2ContratEnregistre.length > 0 || context.state.PrevTNSPreco2ContratFraisPro.length > 0) {
      menu = [...menu, { value: "PrevTNSPreco2", label: "Proposition 2" }];
    }
    if (context.state.PrevTNSPreco3ContratEnregistre.length > 0 || context.state.PrevTNSPreco3ContratFraisPro.length > 0) {
      menu = [...menu, { value: "PrevTNSPreco3", label: "Proposition 3" }];
    }
    return menu;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Column fill>
        <CardBox style={{ width: "730px", padding: 0 }}>
          <Row tpad bpad rpad>
            <Column lpad fill>
            <Button onClick={handleClick}>
                <FaPrint />
                <span>Imprimer</span>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    AuditPrevoyancepptx(context.state, ResultatTNS.state,preco).then((res) => {});
                    handleClose();
                  }}
                  disableRipple
                >
                  <FaFilePowerpoint style={{ color: "#cc4424", marginRight: "10px" }} sy />
                  <div style={{ paddingTop: "4px" }}> Etude simplifiée </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    AuditPrevoyanceWord(context.state, ResultatTNS.state, preco).then((res) => {});
                    handleClose();
                  }}
                  disableRipple
                >
                  <FaFileWord style={{ color: "#2c5698", marginRight: "10px" }} sy />
                  <div style={{ paddingTop: "4px" }}> Etude complète </div>
                </MenuItem>
              </Menu>

            </Column>
            {(context.state.PrevTNSPreco1ContratEnregistre.length > 0 ||
              context.state.PrevTNSPreco1ContratFraisPro.length > 0 ||
              context.state.PrevTNSPreco2ContratEnregistre.length > 0 ||
              context.state.PrevTNSPreco2ContratFraisPro.length > 0 ||
              context.state.PrevTNSPreco3ContratEnregistre.length > 0 ||
              context.state.PrevTNSPreco3ContratFraisPro.length > 0) && (
              <Column lpad>
                <table>
                    <tr>
                      <td>Préconisation</td>
                      <td>
                        <DropDown value={preco} options={handleMenuPreco()} onChange={(e) => setPreco(e.target.value)} normal />
                      </td>
                    </tr>
                </table>
              </Column>
            )}
          </Row>
        </CardBox>
      </Column>
    </>
  );
};

export default ResultatPagePrevoyance;
