import { FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import DropDown from "components/ui/controls/drop-down/drop-down";

const CorrrectionPERAnnee = (props) => {
  const { Year, TabCorrectionPER, ClientConjoint } = props;

  return (
    <>
      <Modal
        width="750px"
        height="60%"
        title=" Correction du disponible PER 163 quatervicies"
        icon={<FaGlobe />}
        visible={props.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <table className="declaPER">
                      <tbody>
                        <tr>
                          <td>Rémunération imposable {Year - 1}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERRemunerationImposable" + ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERRemunerationImposable" + ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Régime fiscal</td>
                          <td>
                            <DropDown
                              value={TabCorrectionPER[Year]?.['DeclaPERRegimeFiscal'+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERRegimeFiscal"+ ClientConjoint, e.target.value);
                              }}
                              options={[
                                { value: "tns", label: "Revenus des gérants (art 62 du CGI)" },
                                { value: "bic", label: "BIC/BNC" },
                                { value: "salaire", label: "Traitement et salaires" },
                              ]}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Abondement PERCO {Year - 1}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERAbondementPERCO"+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERAbondementPERCO"+ ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cotisations retraite Madelin {Year - 1}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERRetraiteMadelin"+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERRetraiteMadelin"+ ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cotisations prévoyance et santé Madelin {Year - 1}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERPrevoyanceMadelin"+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERPrevoyanceMadelin"+ ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cotisations retraite 83 {Year - 1}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERRetraite83"+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERRetraite83"+ ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cotisations PER 163 quatervicies {Year}</td>
                          <td>
                            <TextField
                              value={TabCorrectionPER[Year]?.["DeclaPERRetraite163"+ ClientConjoint]}
                              onChange={(e) => {
                                props.ChangeValue(Year, "DeclaPERRetraite163"+ ClientConjoint, e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                              normal
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                props?.onClose?.();
                props?.CalculCorrectionPER(Year,ClientConjoint);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default CorrrectionPERAnnee;
