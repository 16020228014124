import { useContext, useState, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaFileSignature, FaSun } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import ContratFraisPro from "../ContratFraisPro/ContratFraisPro";
import PrestationFraisPro from "../ContratFraisPro/PrestationFraisPro";

const ContratFraisProPager = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const handleMenu = () => {
    let menu = [
      {
        label: "Contrat",
        icon: <FaFileSignature />,
        content: <ContratFraisPro AccessRemExpress={props.AccessRemExpress} />,
      },
      {
        label: "Prestations",
        icon: <FaSun />,
        content: <PrestationFraisPro />,
      },
    ];
    if (props.AccessRemExpress === true) {
      menu = menu.filter((k) => k.label !== "Prestations");
    }
    return menu;
  };
  return (
    <>
      <Pager
        id="contratFacFraisPro"
        withMenu
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              props?.onClose?.();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddContratFraisPro(context.state.IdContratPrevoyance, props.Outil, props.ClientConjoint);
              props?.onClose?.();
            },
          },
        }}
      />
    </>
  );
};

export default ContratFraisProPager;
