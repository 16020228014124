import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaEuroSign, FaFileAlt, FaIdBadge, FaMoneyBillWave, FaUniversity } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import ContratsFacultatif from "components/app/AuditComplet/SituationPro/ContratsFacultatif";
import _ from "lodash";

const ContratFacProModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [contratsRetrActuelle, setContratsRetrActuelle] = useState([]);
  const [contratsPrevActuelle, setContratsPrevActuelle] = useState([]);
  const [contratsSanteActuelle, setContratsSanteActuelle] = useState([]);
  const [contratsFraisProActuelle, setContratsFraisProActuelle] = useState([]);
  const [contratsRetrActuelleConjoint, setContratsRetrActuelleConjoint] = useState([]);
  const [contratsPrevActuelleConjoint, setContratsPrevActuelleConjoint] = useState([]);
  const [contratsSanteActuelleConjoint, setContratsSanteActuelleConjoint] = useState([]);
  const [contratsFraisProActuelleConjoint, setContratsFraisProActuelleConjoint] = useState([]);


  //On garde les contrats qui on été saisie en cas d'annulation de la saisie de la periode.
  useEffect(() => {
    if (props.visible === true) {
      // if (context.state.ListeContrat.length > 0) {
        setContratsRetrActuelle(_.cloneDeep(context.state.OptiRemListeContrat));
        setContratsPrevActuelle(_.cloneDeep(context.state.OptiRemContratEnregistre));
        setContratsSanteActuelle(_.cloneDeep(context.state.OptiRemContratEnregistreSante));
        setContratsFraisProActuelle(_.cloneDeep(context.state.OptiRemContratFraisPro));
        setContratsRetrActuelleConjoint(_.cloneDeep(context.state.OptiRemListeContratConjoint));
        setContratsPrevActuelleConjoint(_.cloneDeep(context.state.OptiRemContratEnregistreConjoint));
        setContratsSanteActuelleConjoint(_.cloneDeep(context.state.OptiRemContratEnregistreSanteConjoint));
        setContratsFraisProActuelleConjoint(_.cloneDeep(context.state.OptiRemContratFraisProConjoint));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

const handleStatut = () => {
  let Statut = "TNS Article 62";

    if (props.ClientConjoint === "" && context.state.OptiRemPeriodeActuelle?.length > 0) {
      Statut = context.state.OptiRemPeriodeActuelle[0].Statut;
    }
    if (props.ClientConjoint === "Conjoint") {
      if (context.state.OptiRemPeriodeActuelleConjoint?.length > 0) {
        Statut = context.state.OptiRemPeriodeActuelleConjoint[0].Statut;
      } else {
        Statut = context.state.OptiRemPeriodeActuelle[0].StatutConjoint;
      }
    }
  return Statut;
};
  return (
    <>
      <Pager
        withMenu
        pages={[
          {
            label: "Contrats facultatifs",
            icon: <FaFileAlt />,
            content: context.state.OptiRemListeContrat!==undefined && context.state.OptiRemListeContratConjoint!==undefined && <ContratsFacultatif Statut={handleStatut()} Outil="OptiRem" ClientConjoint={props.ClientConjoint} />,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              context.handleValueChange("OptiRemListeContrat", contratsRetrActuelle);
              context.handleValueChange("OptiRemContratEnregistre", contratsPrevActuelle);
              context.handleValueChange("OptiRemContratEnregistreSante", contratsSanteActuelle);
              context.handleValueChange("OptiRemContratFraisPro", contratsFraisProActuelle);
              context.handleValueChange("OptiRemListeContratConjoint", contratsRetrActuelleConjoint);
              context.handleValueChange("OptiRemContratEnregistreConjoint", contratsPrevActuelleConjoint);
              context.handleValueChange("OptiRemContratEnregistreSanteConjoint", contratsSanteActuelleConjoint);
              context.handleValueChange("OptiRemContratFraisProConjoint", contratsFraisProActuelleConjoint);
              context.handleResetPeriodeActuelle();
              props.onClose();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ContratFacProModal;
