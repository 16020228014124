import xhook from 'xhook';
import './loader.css';

class Loader {

    counter = 0;
    handleShow = -1;
    handleHide = -1;
    delayShow = 250;
    delayHide = 100;

    on() {
        this.counter++;

        // Check if we must start show timer
        if (this.counter === 1) {
            // Check if a hide timer is running
            if (this.handleHide !== -1) {
                clearTimeout(this.handleHide);
                this.handleHide = -1;
            }

            // Start show timer
            this.handleShow = setTimeout(() => {
                document.querySelector('.loader-bg').classList.remove('hidden');
                this.handleShow = -1;
            }, this.delayShow)
        }
    }

    off() {
        this.counter--;

        // Check if we must start hide timer
        if (this.counter === 0) {
            // Check if a show timer is running
            if (this.handleShow !== -1) {
                clearTimeout(this.handleShow);
                this.handleShow = -1;
            }

            // Start show timer
            this.handleHide = setTimeout(() => {
                document.querySelector('.loader-bg').classList.add('hidden');
                this.handleHide = -1;
            }, this.delayHide)
        }
    }

    constructor() {

        // Create Background
        const bg = document.createElement('div');
        bg.classList.add('loader-bg');
        bg.classList.add('hidden');

        // Create frame
        const frame = document.createElement('div');
        frame.classList.add('loader');
        bg.appendChild(frame);

        // Create loader picture
        const img = document.createElement('img');
        img.src = '/img/loader.svg';
        img.alt = '...';
        frame.appendChild(img);

        // Create paragraph
        const p = document.createElement('p');
        p.innerHTML = 'Chargement en cours';
        frame.appendChild(p);

        // Add background to body
        document.body.appendChild(bg);

        // Add XHR hooks
        xhook.before(function (request) {
            this.on();
        }.bind(this))
        xhook.after(function (request, response) {
            this.off();
        }.bind(this))
    }
}
export default new Loader();