import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { FaArrowAltCircleRight, FaEdit, FaGlobe, FaIdBadge, FaPlusCircle, FaSearch, FaTrashAlt } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import ClientPageModal from "pages/extranet/client/client pager";
import Dialogs from "components/ui/dialogs/dialogs";
import TextField from "components/ui/controls/text-field/text-field";
import { User } from "../Api/ApiUser";
import ImportClientSofracoPage from "pages/extranet/client/import client sofraco pager";
import { AuthContext } from "components/auth/auth-provider";

const ListeClients = (props) => {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalCrm, setmodalCrm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const authContext = useContext(AuthContext);
  useEffect(() => {
    console.log('EFFECT')
    context.handleListeClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row center>
              <Column>
                <Button
                  onClick={() => {
                    context.handleNewClient();
                    setmodalOpen(true);
                  }}
                >
                  <span>Nouveau client</span>
                  <FaPlusCircle />
                </Button>
              </Column>
            </Row>
          </Column>
        </CardBox>
        <CardBox style={{ backgroundColor: "var(--app-selected-bg-color)", color: "white" }} tpad>
          <Column fill>
            <Row>
              <Column style={{ padding: 0 }}>
                <TextField
                  value={searchText}
                  iconBefore={<FaSearch style={{ color: "var(--menu-icon-color)" }} />}
                  large
                  placeholder="Rechercher un client"
                  onKeyUp={(e) => {
                    setSearchText(e.target.value);
                    context.handleSearchClient(e);
                  }}
                />
              </Column>
              {authContext.utilisateur_reel?.abonnement === 3 && (
                <Column style={{ paddingTop: 0, paddingBottom: 0, marginTop: "-4px" }}>
                  <Button
                    onClick={() => {
                      setmodalCrm(true);
                    }}
                  >
                    <span>Connexion CRM</span>
                    <FaPlusCircle />
                  </Button>
                </Column>
              )}
            </Row>
          </Column>
        </CardBox>
        <CardBox tpad>
          <Column fill>
            <Row tpad>
              <Column scroll fill>
                <table>
                  <thead>
                    <tr>
                      <th>Civilité</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.ListeClient?.map((Infos, index) => {
                      return (
                        <tr key={"CLIENT" + index}>
                          <td>{Infos.Civilite}</td>
                          <td>{Infos.Nom}</td>
                          <td>{Infos.Prenom}</td>
                          <td>
                            <Button
                              className="ghost"
                              onClick={() => {
                                context.handleDisplaySimulation(Infos.IdClient);
                              }}
                            >
                              <span>Audits</span>
                              <FaArrowAltCircleRight />
                            </Button>
                          </td>
                          <td>
                            <>
                              <Button
                                onClick={() => {
                                  context.handleDisplayClient(Infos.IdClient);
                                  setmodalOpen(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                onClick={() => {
                                  Dialogs.confirm(
                                    "Vous êtes sur le point de supprimer la fiche de " + Infos.Civilite + " " + Infos.Nom + ".Voulez-vous continuer ?",
                                    "Suppression du client",
                                    () => {
                                      context.handleSuppressionClient(Infos.IdClient);
                                    }
                                  );
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaTrashAlt className="delete" />
                              </Button>
                            </>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
      <Modal
        width="80%"
        height="80%"
        title="Fiche client"
        icon={<FaGlobe />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ClientPageModal
          visible={modalOpen}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
      <Modal
        width="40%"
        height="40%"
        title="Fiche client"
        icon={<FaGlobe />}
        visible={modalCrm}
        onClose={() => {
          setmodalCrm(false);
        }}
      >
        <ImportClientSofracoPage
          visible={modalOpen}
          onClose={() => {
            setmodalCrm(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ListeClients;
