import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Pager from "components/ui/controls/pager/pager";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe, FaMoneyBillWave } from "react-icons/fa";
import ModalParamDividendes from "./ModalParamDividendes";

function OptimisationResAvtIS(props) {
  const { Periode, ClientConjoint } = props;
  let CliConjRai = (ClientConjoint === ""  ||ClientConjoint === "ConjointAssocie" )? "" : "Conjoint";
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalResultatEntreprise, setmodalResultatEntreprise] = useState(false);
  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="simulation">
        <tbody>
          <tr>
            <td className="libelle">Résultat avant IS</td>
            <td className="origine">{applyFormat(Periode?.ResAvtIs, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state["OptiRemResAvtIs" + ClientConjoint]}
                onChange={(e) => {
                  context.handleCalculOptiRem("res_avant_is", "OptiRemResAvtIs" + ClientConjoint, ClientConjoint, ResultatTNS, false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemResAvtIs" + ClientConjoint]) - Number(Periode?.ResAvtIs))}</td>
          </tr>
          <tr>
            <td className="libelle">Impôt sur les sociétés</td>
            <td className="origine">{applyFormat(Periode?.ImpotSoc, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle pr4">
              <Button
                className="editIconOptiRem"
                onClick={() => {
                  setmodalResultatEntreprise(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
              {applyFormat(context.state["OptiRemImpotSoc" + ClientConjoint], "uint", { thousands: " ", decimal: ",", unit: " €" })}
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemImpotSoc" + ClientConjoint]) - Number(Periode?.ImpotSoc))}</td>
          </tr>
          <tr>
            <td className="libelle">Mise en réserve{Number(context.state["OptiRemDivAutres" + ClientConjoint]) !== 0 ? " / div. autres associés" : ""}</td>
            <td className="origine">{applyFormat(Number(Periode?.Reserve) + Number(Periode?.DividendeBrutAutres), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle pr4">
              {applyFormat(Number(context.state["OptiRemReserve" + ClientConjoint]) + Number(context.state["OptiRemDivAutres" + ClientConjoint]), "uint", {
                thousands: " ",
                decimal: ",",
                unit: " €",
              })}
            </td>
            <td className="extra">&nbsp;</td>

            <td className="ecart">
              {ApplySignedFormat(
                Number(context.state["OptiRemReserve" + ClientConjoint]) +
                  Number(context.state["OptiRemDivAutres" + ClientConjoint]) -
                  (Number(Periode?.Reserve) + Number(Periode?.DividendeBrutAutres))
              )}
            </td>
          </tr>
          <tr>
            <td className="libelle">Dividendes bruts</td>
            <td className="origine">{applyFormat(Periode?.DividendeBrut, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state["OptiRemDividendeBrut" + ClientConjoint]}
                onChange={(e) => {
                  context.handleCalculOptiRem("dividende_brut", "OptiRemDividendeBrut" + ClientConjoint, ClientConjoint, ResultatTNS, false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemDividendeBrut" + ClientConjoint]) - Number(Periode?.DividendeBrut))}</td>
          </tr>
        </tbody>
      </table>

      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaGlobe />}
        visible={modalResultatEntreprise}
        onClose={() => { 
          context.handleCalculOptiRem("res_avant_is","OptiRemResAvtIs" + CliConjRai, CliConjRai, ResultatTNS,false, context.state["OptiRemResAvtIs" + CliConjRai]);
          setmodalResultatEntreprise(false);
        }}
      > 
        <Pager
          withMenu
          pages={[
            {
              label: "Dividendes",
              icon: <FaMoneyBillWave />,
              content: <ModalParamDividendes Outil={"OptiRem"} ClientConjoint={ClientConjoint} Periode={Periode} />,
            },
          ]}
          page={0}
          navButtons={{
            // cancel: {
            //   onClick: (evt) => {
            //     setmodalResultatEntreprise(false);
            //   },
            // },
            // previous: {},
            // next: {},
            validate: {
              onClick: (evt) => {
                context.handleCalculOptiRem("res_avant_is","OptiRemResAvtIs" + CliConjRai, CliConjRai, ResultatTNS,false, context.state["OptiRemResAvtIs" + CliConjRai]);
                setmodalResultatEntreprise(false);
              },
            },
          }}
        />
      </Modal>
    </CardBox>
  );
}
export default OptimisationResAvtIS;
