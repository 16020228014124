import { useEffect, useState } from "react";
import { FaUserShield } from "react-icons/fa";
import Button from "../controls/button/button";
import Column from "../layout/column/column";
import Row from "../layout/row/row";
import './login.css';
import Dialogs from "../dialogs/dialogs";
import TextField from "../controls/text-field/text-field";

/**
 * Login component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Login = (props) => {

    // Initialize states
    const [login, setLogin] = useState(props?.login ? props.login : '');
    const [password, setPassword] = useState(props?.password ? props.password : '');

    /**
     * Validate handler.
     */
    const validate = () => {
        const loginEl = document.getElementById('login');
        const passEl = document.getElementById('password');
        if (loginEl.value === '') {
            Dialogs.error("Merci de saisir votre identifiant de connexion.", "Identification interrompue");
            document.getElementById('login').focus();
        }
        else if (passEl.value === '') {
            Dialogs.error("Merci de saisir votre mot de passe.", "Identification interrompue");
            document.getElementById('password').focus();
        }
        else {
            props?.onValidate?.(loginEl.value, passEl.value);
        }
    }

    /**
     * Forgotten password handler.
     */
    const forgotten = (evt) => {
        const loginEl = document.getElementById('login');
        if (loginEl.value === '') {
            Dialogs.error("Merci d'indiquer votre identifiant de connexion.", "Réinitialisation interrompue");
            document.getElementById('login').focus();
        }
        else {
            props?.onForgotten?.(loginEl.value);
        }
        evt.preventDefault();
        return true;
    }

    useEffect(() => {
        // document.getElementById('login').focus();
      
    }, []);

    return (
        <Column id="loginForm" className="login" fill centerX centerY>
            <Row>
                <Column>
                    <TextField
                        id='login'
                        label='Identifiant'
                        dataType='string'
                        value={login}
                        placeholder="identifiant"
                        onChange={(evt) => setLogin(evt.target.value)}
                        large
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <TextField
                        id='password'
                        label='Mot de passe'
                        dataType='string'
                        value={password}
                        placeholder="Mot de passe"
                        onChange={(evt) => setPassword(evt.target.value)}
                        password
                        large
                    />
                </Column>
            </Row>
            <Row tpad>
                <Column>
                    <Button
                        onClick={validate}
                        large
                    >
                        Identification
                        <FaUserShield />
                    </Button>
                </Column>
            </Row>
            <Row tpad>
                </Row>
            <Row tpad>
                <Column>
                    <a href="/" onClick={forgotten}>Mot de passe oublié ?</a>
                </Column>
            </Row>
        </Column >
    )
}
export default Login;