import { applyFormat } from "components/ui/ui-helpers";

const DetailCotisationsMsa = (props) => {
  const { DetailCotisations, DetailCSG, TotalChargesSociales } = props;

  return (
    <>
      <table className="cotisations">
        <thead>
          <tr className="dark-title">
            <th></th>
            <th>Assiette</th>
            <th>Taux</th>
            <th>Montant dû</th>
          </tr>
        </thead>
        <tbody>
          <tr className="light-title">
            <td colSpan={4}>Cotisations de {props.Prenom}</td>
          </tr>
          <tr>
            <td>AMEXA</td>
            <td>{DetailCotisations?.assiette_assurance_maladie}</td>
            <td>{DetailCotisations?.taux_assurance_maladie}</td>
            <td>{DetailCotisations?.amexa}</td>
          </tr>
          <tr>
            <td>Allocations familiales</td>
            <td>{DetailCotisations?.assiette}</td>
            <td>{DetailCotisations?.taux_allocation_familiale}</td>
            <td>{DetailCotisations?.allocation_familiale}</td>
          </tr>
          <tr>
            <td>Indemnités journalières AMEXA</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.ij_amexa}</td>
          </tr>
          <tr>
            <td>Invalidité décès ATEXA</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.atexa}</td>
          </tr>
          <tr>
            <td>Retraite de base AVI</td>
            <td>{DetailCotisations?.assiette_avi}</td>
            <td>{DetailCotisations?.taux_avi}</td>
            <td>{DetailCotisations?.avi}</td>
          </tr>
          <tr>
            <td>Retraite de base AVA plafonnée</td>
            <td>{DetailCotisations?.assiette_ava_plafonnee}</td>
            <td>{DetailCotisations?.taux_ava_plafonne}</td>
            <td>{DetailCotisations?.ava_plafonne}</td>
          </tr>
          <tr>
            <td>Retraite de base AVA déplafonnée</td>
            <td>{DetailCotisations?.assiette_ava_deplafonnee}</td>
            <td>{DetailCotisations?.taux_ava_deplafonne}</td>
            <td>{DetailCotisations?.ava_deplafonne}</td>
          </tr>
          <tr>
            <td>Retraite complémentaire</td>
            <td>{DetailCotisations?.assiette_retraite_complementaire}</td>
            <td>{DetailCotisations?.taux_retraite_complementaire}</td>
            <td>{DetailCotisations?.retraite_complementaire}</td>
          </tr>

          <tr className="light-title">
            <td colSpan={3}>
              Cotisations sociales obligatoires <span style={{ fontSize: "8pt" }}> (hors formation et CSG-CRDS)</span>{" "}
            </td>
            <td>{applyFormat(DetailCSG?.ChargesSocialesObligatoire, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>Formation professionnelle</td>
            <td>{DetailCotisations?.assiette_formation_professionnelle}</td>
            <td>{DetailCotisations?.taux_formation_professionnelle}</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
          </tr>
          <tr>
            <td>CSG déductible</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>CSG non déductible + CRDS</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGNonDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          {Number(DetailCSG?.csg_primes) > 0 && (
            <tr>
              <td>CSG non déductible + CRDS sur épargne salariale</td>
              <td>{applyFormat(DetailCSG?.assiette_csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>{applyFormat(DetailCSG?.TauxCSG, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          )}
          <tr className="light-title">
            <td colSpan={3}>Total des cotisations sociales </td>
            <td>{applyFormat(TotalChargesSociales, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DetailCotisationsMsa;
