import { useCallback, useContext, useEffect, useState } from "react";
import { FaHome, FaIdBadge, FaTable, FaUmbrella } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DossierPage from "pages/audit complet/dossier/dossier";
import ContratsFacultatif from "components/app/AuditComplet/SituationPro/ContratsFacultatif";
import DonneesAdministratives from "components/app/AuditPrevoyance/DonneesAdministatives";
import ContratsPreco from "components/app/AuditPrevoyance/PrevoyancePreco/ContratsPreco";
import ResultatPagePrevoyance from "./resultatPrevoyance";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";

const AuditPrevPage = () => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [currentPage, setCurrentPage] = useState(1);

  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;
        let Resultat = {
          DonneesContrat: {
            ContratsExistants: {
              Prevoyance: context.state.ContratEnregistre,
              FraisPro: context.state.ContratFraisPro,
            },
            ContratsPreconises: {
              Preco1: {
                Prevoyance: context.state.PrevTNSPreco1ContratEnregistre,
                FraisPro: context.state.PrevTNSPreco1ContratFraisPro,
              },
              Preco2: {
                Prevoyance: context.state.PrevTNSPreco2ContratEnregistre,
                FraisPro: context.state.PrevTNSPreco2ContratFraisPro,
              },
              Preco3: {
                Prevoyance: context.state.PrevTNSPreco3ContratEnregistre,
                FraisPro: context.state.PrevTNSPreco3ContratFraisPro,
              },
            },
          },
          ResultatsContrat:{
            ContratsExistants:ResultatTNS.state.PrevTNS,
            ContratsPreco1:ResultatTNS.state.PrevTNSPreco1,
            ContratsPreco2:ResultatTNS.state.PrevTNSPreco2,
            ContratsPreco3:ResultatTNS.state.PrevTNSPreco3,
          }
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state,ResultatTNS.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handlePages = () => {
    let pages = [];
    if (context.state.ApiUser === true) {
      pages = [
        {
          isTitle: true,
          title: "Audit de prévoyance",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Données professionnelles",
          icon: <FaIdBadge />,
          content: <DonneesAdministratives />,
        },
        {
          label: "Contrats existants",
          icon: <FaUmbrella id="contratExistants" />,
          content: <ContratsFacultatif Statut={"TNS Article 62"} Outil="" ClientConjoint="" />,
        },
        {
          label: "Contrats préconisés",
          icon: <FaUmbrella id="contratsPreco" />,
          content: <ContratsPreco Outil="" ClientConjoint="" />,
          // content: <RemExpressSimulationPage />
        },
        {
          label: "Résultat",
          icon: <FaTable id="resultatPrevoyance" />,
          content: <ResultatPagePrevoyance />,
        },
      ];
    } else {
      pages = [
        {
          isTitle: true,
          title: "Audit de prévoyance",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Dossier",
          icon: <FaHome id="DossierAuditPrevoyancePage" />,
          content: <DossierPage />,
        },
        {
          label: "Données professionnelles",
          icon: <FaIdBadge />,
          content: <DonneesAdministratives />,
        },
        {
          label: "Contrats existants",
          icon: <FaUmbrella id="contratExistants" />,
          content: <ContratsFacultatif Statut={"TNS Article 62"} Outil="" ClientConjoint="" />,
        },
        {
          label: "Contrats préconisés",
          icon: <FaUmbrella id="contratsPreco" />,
          content: <ContratsPreco Outil="" ClientConjoint="" />,
          // content: <RemExpressSimulationPage />
        },
        {
          label: "Résultat",
          icon: <FaTable id="resultatPrevoyance" />,
          content: <ResultatPagePrevoyance />,
        },
      ];
    }
    return pages;
  };
  return (
    <Pager
      withMenu
      pages={handlePages()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default AuditPrevPage;
