import { useContext, useEffect, useState } from "react";
import { AppUIContext } from "../../../../providers/app-ui-context";
import Column from "../column/column";
import Row from "../row/row";
import UserPanel from "../user-panel/user-panel";
import "./header.css";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import { FaSave } from "react-icons/fa";
import Modal from "components/ui/modal/modal";
import TextField from "components/ui/controls/text-field/text-field";
import { API_URL } from "paths";
import axios from "axios";

/**
 * Header bar component.
 * @returns Header bar component.
 */
const Header = (props) => {
  // Initialize states
  const [userId, setUserId] = useState(props?.userId || 0);
  const [gender, setGender] = useState(props?.gender || 0);
  const [firstName, setFirstName] = useState(props?.firstName || "");
  const [lastName, setLastName] = useState(props?.lastName || "");
  const [subUserId, setSubUserId] = useState(props?.subUserId || 0);
  const [subGender, setSubGender] = useState(props?.subGender || 0);
  const [subFirstName, setSubFirstName] = useState(props?.subFirstName || "");
  const [subLastName, setSubLastName] = useState(props?.subLastName || "");
  const [company, setCompany] = useState(props?.company || "");
  const [association, setAssociation] = useState(props?.association || "");
  const [modalOpen, setmodalOpen] = useState(false);

  const [usersMakoFormation, setUsersMakoFormation] = useState([]);
  const [modalMakoFormation, setmodalMakoFormation] = useState(false);
  const context = useContext(AppContext);
  // On user change
  useEffect(() => {
    setUserId(props?.userId || 0);
    setGender(props?.gender || 0);
    setFirstName(props?.firstName || 0);
    setLastName(props?.lastName || 0);
  }, [props?.userId, props?.gender, props?.firstName, props?.lastName]);

  // On sub user change
  useEffect(() => {
    setSubUserId(props?.subUserId || 0);
    setSubGender(props?.subGender || 0);
    setSubFirstName(props?.subFirstName || 0);
    setSubLastName(props?.subLastName || 0);
  }, [props?.subUserId, props?.subGender, props?.subFirstName, props?.subLastName]);

  // On company change
  useEffect(() => {
    setCompany(props?.company);
  }, [props?.company]);

  // On association change
  useEffect(() => {
    setAssociation(props?.association);
  }, [props?.association]);
  useEffect(() => {
    context.handleValueChange("IdUser", props?.authInfos?.utilisateur_reel.id);
    context.handleValueChange("Conseiller", props?.authInfos?.utilisateur_reel.prenom + " " + props?.authInfos?.utilisateur_reel.nom);
    context.handleValueChange("IdCabinet", props?.authInfos?.cabinet.id);
    context.handleValueChange("MentionLegale", props?.authInfos?.preferences.mentions);
    context.handleValueChange("CodesCourtier", props?.authInfos?.relation.codes === "" ? [] : JSON.parse(props?.authInfos?.relation.codes));
    context.handleValueChange("Color1", props?.authInfos?.preferences.couleurs[0]);
    context.handleValueChange("Color2", props?.authInfos?.preferences.couleurs[1]);
    context.handleValueChange("LogoName", props?.authInfos?.preferences.logo);
    context.handleValueChange("Partner", "");
  }, [JSON.stringify(props?.authInfos)]);

  const GetUserList = () => {
    var formData = new FormData();
    formData.append("CRUD", "GetUsersToMakoFormation");

    return axios({
      method: "post",
      url: `${API_URL}/ApiMako/V3/User/CopyContratPrevToUser.php`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      withCredentials: true,
    })
      .then((ListeUsers) => {
        return ListeUsers.data;
      })
      .catch(function (error) {
        if (parseInt(error.response.request.status) === 401) {
          var event = new CustomEvent("session-expired", {
            detail: {
              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
            },
          });
          window.dispatchEvent(event);
        } 
      });
  };
  useEffect(() => {
    GetUserList().then((Users) => {
      setUsersMakoFormation(Users);
    });
  }, []);

  return (
    <Row className={"header"} centerY>
      <Column fill className="brand">
        <AppUIContext.Consumer>
          {(ui) => (
            <>
              <div className="brand-name">{ui.name}</div>
              <div className="brand-slogan">{ui.slogan}</div>
            </>
          )}
        </AppUIContext.Consumer>
      </Column>
      {/* {usersMakoFormation.filter((user) => user.id === userId).length === 0 && (
        <>
          <Column
            style={{ fontWeight: "bold", textDecoration: "underline", cursor: "Pointer" }}
            onClick={() => {
              setmodalMakoFormation(true);
            }}
          >
            <span>Nouveau: Guide de la protection sociale (en option)</span>
          </Column>
          <Modal
            width="80%"
            height="35%"
            title="Guide de la protection sociale"
            visible={modalMakoFormation}
            onClose={() => {
              setmodalMakoFormation(false);
            }}
          >
            <Column fill>
              <Row fill>
                <Column fill center style={{ color: "black", textAlign: "justify", marginTop: "32px" }}>
                  <br />
                  Un nouveau guide complet sur la protection sociale du chef d’entreprise est disponible ! <br />
                  150 pages de détails pédagogiques sous forme de fiches sur la retraite et la prévoyance du chef d’entreprise.
                  <br />
                  <br />
                  <br />
                  En cas de souscription, 4 simulateurs sont offerts :
                  <br />
                  <br />- Un comparateur de contrat de prévoyance TNS ( une trentaine de contrats du marché sont analysés sur différents critères : délai d’attente, exclusions, garanties,
                  conditions de prise en charges des affections dorsales et psy, barème d’invalidité….
                  <br />- Une calculette de points AGIRC ARRCO permettant de contrôler les points acquis sur la passé par vos clients
                  <br />- Un outil d’aide à la détermination du plafond PER dans le cadre de l’article 163 quatervicies du CGI : correction des anciens plafonds, calcul du plafonds 2023…
                  <br />- Un outil d’aide à la vente du PER : mise en évidence de l’efficacité du dispositif : raisonnement net de fiscalité et de PS à l’entrée et à la sortie
                  <br />
                  <br />
                  Si vous souhaitez souscrire à l’offre ou en savoir plus, n’hésitez pas à nous contacter à l'adresse suivante :
                  <a href="mailto:mako-formation@outlook.fr">mako-formation@outlook.fr</a>
                </Column>
              </Row>
            </Column>
          </Modal>
        </>
      )} */}
      {context.state.TypeSimulation !== "" && context.state.AdminAccess !== true && (
        <Column style={{ paddingBottom: "0" }}>
          <Button
            className=" user-panel user-panel-disconnect"
            icon
            onClick={() => {
              setmodalOpen(true);
            }}
          >
            <FaSave title="Sauvegarder" id="saveModal" />
          </Button>
          <Row style={{ fontSize: "8pt", marginTop: "4px" }}>Sauvegarder</Row>
        </Column>
      )}
      <Modal
        width="450px"
        height="320px"
        title="Sauvegarde du dossier"
        icon={<FaSave />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <Column fill>
          <Row fill center>
            <Column fill center>
              <TextField
                labelStyle={{ color: "var(--app-fg-color)" }}
                label={"Titre de l'audit pour " + context.state.Prenom}
                value={context.state.LibelleDossier}
                onChange={context.handleInputChange("LibelleDossier")}
                style={{ width: "370px" }}
              />
            </Column>
          </Row>
          <Row className="pager-buttons">
            <Column fill>
              <Button
                onClick={() => {
                  // context.handleNewSimulation("Audit prévoyance");
                  if (context.state.IdDossier === "") {
                    context.handleSaveAsDossier(context.state.TypeSimulation);
                  } else {
                    context.handleSaveDossier();
                  }
                  setmodalOpen(false);
                }}
              >
                <span id="saveDossier">Enregistrer</span>
              </Button>
            </Column>
            <Column fill>
              <Button
                onClick={() => {
                  context.handleSaveAsDossier(context.state.TypeSimulation);
                  // context.handleNewSimulation("Audit prévoyance");
                  setmodalOpen(false);
                }}
              >
                <span>Enregistrer sous</span>
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>
      <Column>
        <UserPanel
          userId={userId}
          gender={gender}
          firstName={firstName}
          lastName={lastName}
          subUserId={subUserId}
          subGender={subGender}
          subFirstName={subFirstName}
          subLastName={subLastName}
          company={company}
          association={association}
          onDisconnect={props?.onDisconnect}
          disconnectDisabled={false}
        />
      </Column>
    </Row>
  );
};

export default Header;
