import React, { useContext, useEffect } from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { AppContext } from "components/app/RemExprertProvider";

const DetailDisponibleSalarie = ({ GraphDisponible, GraphPerp }) => {
  console.log(GraphDisponible);
  const context = useContext(AppContext);
  useEffect(() => {
    if (GraphDisponible !== undefined) {
      document.getElementById("GraphSocialRetraite83").innerHTML = GraphDisponible.GraphiqueSocial83.GraphRetraite;
      document.getElementById("GraphSocialPrev83").innerHTML = GraphDisponible.GraphiqueSocial83.GraphPrevSante;
      document.getElementById("GraphFiscalRetraite83").innerHTML = GraphDisponible.GraphiqueFiscal83.GraphRetraite;
      document.getElementById("GraphFiscalPrev83").innerHTML = GraphDisponible.GraphiqueFiscal83.GraphPrevSante;
    }
    if (GraphPerp !== undefined) {
      document.getElementById("GraphDispoPerpSAL").innerHTML = GraphPerp;
    }
  }, []);

  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <h1>Disponibles sociaux article 83</h1>
          </Row>
          <Row tpad>
            <Column lpad hpad id="GraphSocialRetraite83"></Column>
            <Column hpad id="GraphSocialPrev83"></Column>
            <Column style={{ alignSelf: "center", marginLeft: "90px" }}>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color1 }}></Column>
                <Column>Disponible social</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#405060" }}></Column>
                <Column>Dépassement</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color2 }}></Column>
                <Column>Cotisations patronales</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#d3d3d3" }}></Column>
                <Column> Disponible restant</Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column fill>
              <Row>
                <h1>Disponibles fiscaux article 83</h1>
              </Row>
            </Column>
          </Row>
          <Row tpad>
            <Column lpad hpad id="GraphFiscalRetraite83"></Column>
            <Column hpad id="GraphFiscalPrev83"></Column>
            <Column style={{ alignSelf: "center", marginLeft: "90px" }}>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color1 }}></Column>
                <Column>Disponible fiscal</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#405060" }}></Column>
                <Column>Dépassement</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color2 }}></Column>
                <Column>Cotisations versées</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#d3d3d3" }}></Column>
                <Column> Disponible restant</Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column fill>
              <Row>
                <h1>Disponible épargne retraite 163 quatervicies</h1>
              </Row>
            </Column>
          </Row>
          <Row tpad>
            <Column lpad hpad id="GraphDispoPerpSAL"></Column>
            <Column style={{ alignSelf: "center", marginLeft: "90px" }}>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color1 }}></Column>
                <Column>Disponible fiscal</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#405060" }}></Column>
                <Column>Dépassement</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color2 }}></Column>
                <Column>Versements effectués</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#d3d3d3" }}></Column>
                <Column> Disponible restant</Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailDisponibleSalarie;
