import React from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { useContext } from "react";
import TextField from "components/ui/controls/text-field/text-field";

function LigneImpotDetaille(props) {
  const context = useContext(AppContext);

  return (
    <>
      <tr>
        <td>{props.Libelle}</td>
        <td>
          {props.NotEuro ? (
            <TextField value={context.state[props.VarName1]} onChange={context.handleInputChange([props.VarName1])}  dataType="uint" format={{ thousands: " "}} normal />
          ) : (
            <TextField value={context.state[props.VarName1]} onChange={context.handleInputChange([props.VarName1])} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
          )}
        </td>
        {props.VarName2 !== "" &&
          (props.VarName2 === "empty" ? (
            <td></td>
          ) : (
            <td>
              {props.NotEuro ? (
                <TextField value={context.state[props.VarName2]} onChange={context.handleInputChange([props.VarName2])}  dataType="uint" format={{ thousands: " " }}normal />
              ) : (
                <TextField value={context.state[props.VarName2]} onChange={context.handleInputChange([props.VarName2])} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
              )}
            </td>
          ))}
      </tr>
    </>
  );
}
export default LigneImpotDetaille;
