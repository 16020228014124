import { useContext,  useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import { FaPlusCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import Row from "components/ui/layout/row/row";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import ContratRetraitePageModal from "../situation professionnelle/modules/contrat retraite modal";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";

const AjoutContratRetrPersoPageModalOptiRem = (props) => {
  const { ClientConjoint, Outil } = props;
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalOpenConjoint, setmodalOpenConjoint] = useState(false);
  const annee = new Date().getFullYear();
  const StatutConjoint = context.state.PeriodeActuelle[0]?.StatutConjoint;
  const CorrespondanceContrat = {
    Madelin: "Madelin",
    Perp: "PERP",
    Per: "PER",
    Article62: "Assurance vie",
    Article83: "Article 83",
    Pero: "PER obligatoire",
    PEE: "PEE",
    PERCO: "PERECO",
  };

  const handleStatut = () => {
    let Statut = "TNS Article 62";
  
      if (props.ClientConjoint === "" && context.state.OptiRemPeriodeActuelle?.length > 0) {
        Statut = context.state.OptiRemPeriodeActuelle[0].Statut;
      }
      if (props.ClientConjoint === "Conjoint") {
        if (context.state.OptiRemPeriodeActuelleConjoint?.length > 0) {
          Statut = context.state.OptiRemPeriodeActuelleConjoint[0].Statut;
        } else {
          Statut = context.state.OptiRemPeriodeActuelle[0].StatutConjoint;
        }
      }
    return Statut;
  };
  // useEffect(() => {
  //   // if(modalOpen===true){
  //   //   console.log(context.state.Id)
  //   // context.handleResetContratRetraite();
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalOpen]);
  return (
    <>
      <Column fill>
        {context.state.TypeSimulation === "Audit complet" && (
          <Column>
            <Row>
              <blockquote>
                Le tableau ci dessous reprend les contrats d'épargne retraite qui ont été souscrits au niveau de l'entreprise étudiée. Vous avez par ailleurs la possibilité d'ajouter des
                contrats d'épargne retraite souscrits dans le cadre d'une autre entreprise ou à titre personnel.
              </blockquote>
            </Row>
          </Column>
        )}
        <CardBox>
          <Column fill>
            <Row>
              <h1>CONTRATS RETRAITE DE {context.state["Prenom" + ClientConjoint]}</h1>
            </Row>
            <Row>
              <Column style={{ paddingLeft: 0 }}>
                <Button onClick={() => setmodalOpen(true)}>
                  {context.state.TypeSimulation === "Audit complet" && <span>Ajouter un contrat de retraite existant pour {context.state["Prenom" + ClientConjoint]}</span>}
                  {context.state.TypeSimulation === "Audit retraite" && <span>Ajouter un contrat de retraite pour {context.state["Prenom" + ClientConjoint]}</span>}
                  <FaPlusCircle />
                </Button>
              </Column>
            </Row>
            <ContratRetraitePageModal
              AccessRemExpress={props.AccessRemExpress}
              Statut={handleStatut()}
              Outil={props.Outil}
              visible={modalOpen}
              PayeurPrime="Perso"
              ClientConjoint={props.ClientConjoint}
              CliConj={props.ClientConjoint}
              onClose={() => {
                setmodalOpen(false);
              }}
            />
            <Row tpad>
              <table>
                <thead>
                  <tr>
                    <th>Nature du contrat</th>
                    <th>Régime</th>
                    <th>Cotisation</th>
                    {props.AccessRemExpress === false && <th>Libellé</th>}
                    {props.AccessRemExpress === true && <th>Payeur de prime</th>}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {context.state[props.Outil + "ListeContrat" + props.ClientConjoint].map((Infos, Index) => (
                    <tr key={Index}>
                      <td>Retraite</td>
                      <td>{CorrespondanceContrat[Infos.Fiscalite]}</td>
                      <td>
                        {applyFormat(typeof Infos.Cotisation === "object" ? (annee in Infos.Cotisation ? Infos.Cotisation[annee].Versement : 0) : Infos.Cotisation, "uint", {
                          thousands: " ",
                          decimal: ",",
                          unit: " €",
                        })}
                      </td>
                      {props.AccessRemExpress === false && <td>{Infos.Compagnie}</td>}
                      {props.AccessRemExpress === true && <td>{Infos.PayeurPrimeRetraite === "Perso" ? "Personnel" : "Entreprise"} </td>}
                      <td>
                        {Infos.PayeurPrimeRetraite === "Perso" && (
                          <>
                            <Button
                              onClick={() => {
                                context.handleModifyDeleteContratRetraite(Infos.id, "Modifier", props.Outil, ClientConjoint);
                                setmodalOpen(true);
                              }}
                              icon
                              style={{ display: "inline-block" }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              onClick={() => {
                                Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                                  context.handleModifyDeleteContratRetraite(Infos.id, "Supprimer", Outil, ClientConjoint);
                                });
                              }}
                              icon
                              style={{ display: "inline-block" }}
                            >
                              <FaTrashAlt className="delete" />
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
          </Column>
        </CardBox>

        {context.state.InclureConjoint && StatutConjoint !== "Aucun" && (
          <CardBox>
            <Column fill>
              <Row tpad style={{ marginTop: "90px" }}>
                <h1>CONTRATS RETRAITE DE {context.state["PrenomConjoint"]}</h1>
              </Row>
              <Row>
                <Column style={{ paddingLeft: 0 }}>
                  <Button onClick={() => setmodalOpenConjoint(true)}>
                    {context.state.TypeSimulation === "Audit complet" && <span>Ajouter un contrat de retraite existant pour {context.state["PrenomConjoint"]}</span>}
                    {context.state.TypeSimulation === "Audit retraite" && <span>Ajouter un contrat de retraite pour {context.state["PrenomConjoint"]}</span>}
                    <FaPlusCircle />
                  </Button>
                </Column>
              </Row>
              <ContratRetraitePageModal
                AccessRemExpress={props.AccessRemExpress}
                Outil={props.Outil}
                Statut={context.state.OptiRemPeriodeActuelle[0]?.StatutConjoint}
                visible={modalOpenConjoint}
                PayeurPrime="Perso"
                ClientConjoint="Conjoint"
                CliConj="ConjointAssocie"
                onClose={() => {
                  setmodalOpenConjoint(false);
                }}
              />
              <Row tpad>
                <table>
                  <thead>
                    <tr>
                      <th>Nature du contrat</th>
                      <th>Régime</th>
                      <th>Cotisation</th>
                      {props.AccessRemExpress === false && <th>Libellé</th>}
                      {props.AccessRemExpress === true && <th>Payeur de prime</th>}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state[props.Outil + "ListeContratConjoint"].map((Infos, Index) => (
                      <tr key={Index}>
                        <td>Retraite</td>
                        <td>{CorrespondanceContrat[Infos.Fiscalite]}</td>
                        <td>
                          {applyFormat(typeof Infos.Cotisation === "object" ? (annee in Infos.Cotisation ? Infos.Cotisation[annee].Versement : 0) : Infos.Cotisation, "uint", {
                            thousands: " ",
                            decimal: ",",
                            unit: " €",
                          })}
                        </td>
                        {props.AccessRemExpress === false && <td>{Infos.Compagnie}</td>}
                        {props.AccessRemExpress === true && <td>{Infos.PayeurPrimeRetraite === "Perso" ? "Personnel" : "Entreprise"} </td>}
                        <td>
                          {Infos.PayeurPrimeRetraite === "Perso" && (
                            <>
                              <Button
                                onClick={() => {
                                  context.handleModifyDeleteContratRetraite(Infos.id, "Modifier", props.Outil, ClientConjoint);
                                  setmodalOpenConjoint(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                onClick={() => {
                                  Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                                    context.handleModifyDeleteContratRetraite(Infos.id, "Supprimer", Outil, ClientConjoint);
                                  });
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaTrashAlt className="delete" />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
        )}
      </Column>
    </>
  );
};

export default AjoutContratRetrPersoPageModalOptiRem;
