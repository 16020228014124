import { useContext, useEffect, useState } from "react";
import { AppUIContext } from "../../../../providers/app-ui-context";
import Column from "../column/column";
import Row from "../row/row";
import UserPanel from "../user-panel/user-panel";
import "./header.css";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import { FaSave } from "react-icons/fa";
import Modal from "components/ui/modal/modal";
import TextField from "components/ui/controls/text-field/text-field";
import { SaveAsDossierPartner, SaveDossierPartner, SaveDossierPartnerPrevTns, SaveDossierPartnerRemExpress } from "components/app/Api/ApiPartner";

/**
 * Header bar component.
 * @returns Header bar component.
 */
const HeaderMahaka = (props) => {
  // Initialize states
  const [userId, setUserId] = useState(props?.userId || 0);
  const [gender, setGender] = useState(props?.gender || 0);
  const [firstName, setFirstName] = useState(props?.firstName || "");
  const [lastName, setLastName] = useState(props?.lastName || "");
  const [subUserId, setSubUserId] = useState(props?.subUserId || 0);
  const [subGender, setSubGender] = useState(props?.subGender || 0);
  const [subFirstName, setSubFirstName] = useState(props?.subFirstName || "");
  const [subLastName, setSubLastName] = useState(props?.subLastName || "");
  const [company, setCompany] = useState(props?.company || "");
  const [association, setAssociation] = useState(props?.association || "");
  const [modalOpen, setmodalOpen] = useState(false);
  const context = useContext(AppContext);
  // On user change
  useEffect(() => {
    setUserId(props?.userId || 0);
    setGender(props?.gender || 0);
    setFirstName(props?.firstName || 0);
    setLastName(props?.lastName || 0);
  }, [props?.userId, props?.gender, props?.firstName, props?.lastName]);

  // On sub user change
  useEffect(() => {
    setSubUserId(props?.subUserId || 0);
    setSubGender(props?.subGender || 0);
    setSubFirstName(props?.subFirstName || 0);
    setSubLastName(props?.subLastName || 0);
  }, [props?.subUserId, props?.subGender, props?.subFirstName, props?.subLastName]);

  // On company change
  useEffect(() => {
    setCompany(props?.company);
  }, [props?.company]);

  // On association change
  useEffect(() => {
    setAssociation(props?.association);
  }, [props?.association]);
  useEffect(() => {
    context.handleValueChange("IdUser", props?.authInfos?.utilisateur_reel.id);
    context.handleValueChange("Conseiller", props?.authInfos?.utilisateur_reel.prenom + " " + props?.authInfos?.utilisateur_reel.nom);
    context.handleValueChange("IdCabinet", props?.authInfos?.cabinet.id);
    context.handleValueChange("MentionLegale", props?.authInfos?.preferences.mentions);
    context.handleValueChange("Color1", props?.authInfos?.preferences.couleurs[0]);
    context.handleValueChange("Color2", props?.authInfos?.preferences.couleurs[1]);
    context.handleValueChange("LogoName", props?.authInfos?.preferences.logo);
    context.handleValueChange("Partner", props.Params?.Partner);
    context.handleValueChange("Civilite", props.InfosClient?.Civilite);
    context.handleValueChange("CiviliteConjoint", props.InfosClient?.CiviliteConjoint);
    context.handleValueChange("DateNaissance", props.InfosClient?.DateNaissance);
    context.handleValueChange("DateNaissanceConjoint", props.InfosClient?.DateNaissanceConjoint);
    context.handleValueChange("Enfant", props.InfosClient?.Enfant);
    context.handleValueChange("EnfantACharge", props.InfosClient?.EnfantACharge);
    context.handleValueChange("EnfantAChargeExclusive", props.InfosClient?.EnfantAChargeExclusive);
    context.handleValueChange("EnfantAChargePartagee", props.InfosClient?.EnfantAChargePartagee);
    context.handleValueChange("TableauEnfant", props.InfosClient?.TableauEnfant);
    context.handleValueChange("Isole",Number(props.InfosClient?.Isole)===1?true:false);
    context.handleValueChange("Nom", props.InfosClient?.Nom);
    context.handleValueChange("NomConjoint", props.InfosClient?.NomConjoint);
    context.handleValueChange("Prenom", props.InfosClient?.Prenom);
    context.handleValueChange("PrenomConjoint", props.InfosClient?.PrenomConjoint);
    context.handleValueChange("StatutMarital", props.InfosClient?.StatutMarital);
    context.handleValueChange("PartnerIdConseiller", props.InfosClient?.PartnerIdConseiller);
    context.handleValueChange("PartnerIdClient", props.InfosClient?.PartnerIdClient);
    context.handleValueChange("PartnerIdDossier", props.InfosClient?.PartnerIdDossier);
    context.handleValueChange("PartnerQid", props.InfosClient?.PartnerQid);
  }, [JSON.stringify(props?.authInfos)]);

  useEffect(() => {

  }, []);
  return (
    <Row className={"header"} centerY>
      <Column fill className="brand">
        <AppUIContext.Consumer>
          {(ui) => (
            <>
              <div className="brand-name">{ui.name}</div>
              <div className="brand-slogan">{ui.slogan}</div>
            </>
          )}
        </AppUIContext.Consumer>
      </Column>

      <Column style={{ paddingBottom: "0" }}>
        <Button
          className=" user-panel user-panel-disconnect"
          icon
          onClick={() => {
            setmodalOpen(true);
          }}
        >
          <FaSave title="Sauvegarder" id="saveModal" />
        </Button>
        <Row style={{ fontSize: "8pt", marginTop: "4px" }}>Sauvegarder</Row>
      </Column>
      <Modal
        width="450px"
        height="320px"
        title="Sauvegarde du dossier"
        icon={<FaSave />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        {props.Params?.Simulation === "BilanRetraite" && (
          <Column fill>
            <Row fill center>
              <Column fill center>
                <TextField
                  labelStyle={{ color: "var(--app-fg-color)" }}
                  label={"Titre de l'audit"}
                  value={context.state.LibelleDossier}
                  onChange={context.handleInputChange("LibelleDossier")}
                  style={{ width: "370px" }}
                />
              </Column>
            </Row>
            <Row className="pager-buttons">
              <Column fill>
                <Button
                  onClick={() => {
                    // context.handleNewSimulation("Audit prévoyance");
                    if (context.state.IdDossier === "" || context.state.IdDossier === "0") {
                      context.handleSaveAsDossierPartner();
                    
                    } else {
                      SaveDossierPartner(context.state);
                    }
                    setmodalOpen(false);
                  }}
                >
                  <span id="saveDossier">Enregistrer</span>
                </Button>
              </Column>
              <Column fill>
                <Button
                  onClick={() => {
                    context.handleSaveAsDossierPartner();
                    // context.handleNewSimulation("Audit prévoyance");
                    setmodalOpen(false);
                  }}
                >
                  <span>Enregistrer sous</span>
                </Button>
              </Column>
            </Row>
          </Column>
        )}
        {props.Params?.Simulation === "RemExpress" && (
          <Column fill>
            <Row fill center>
              <Column fill center>
                <TextField
                  labelStyle={{ color: "var(--app-fg-color)" }}
                  label={"Titre de l'audit"}
                  value={context.state.LibelleDossier}
                  onChange={context.handleInputChange("LibelleDossier")}
                  style={{ width: "370px" }}
                />
              </Column>
            </Row>
            <Row className="pager-buttons">
              <Column fill>
                <Button
                  onClick={() => {
                    // context.handleNewSimulation("Audit prévoyance");
                    if (context.state.IdDossier === ""|| context.state.IdDossier === "0") {
                      context.handleSaveAsDossierPartnerRemExpress()
                    
                    } else {
                      SaveDossierPartnerRemExpress(context.state)
                    }
                    setmodalOpen(false);
                  }}
                >
                  <span id="saveDossier">Enregistrer</span>
                </Button>
              </Column>
              <Column fill>
                <Button
                  onClick={() => {
                    context.handleSaveAsDossierPartnerRemExpress()
                    // context.handleNewSimulation("Audit prévoyance");
                    setmodalOpen(false);
                  }}
                >
                  <span>Enregistrer sous</span>
                </Button>
              </Column>
            </Row>
          </Column>
        )}
        {props.Params?.Simulation === "PrevTns" && (
          <Column fill>
            <Row fill center>
              <Column fill center>
                <TextField
                  labelStyle={{ color: "var(--app-fg-color)" }}
                  label={"Titre de l'audit"}
                  value={context.state.LibelleDossier}
                  onChange={context.handleInputChange("LibelleDossier")}
                  style={{ width: "370px" }}
                />
              </Column>
            </Row>
            <Row className="pager-buttons">
              <Column fill>
                <Button
                  onClick={() => {
                    // context.handleNewSimulation("Audit prévoyance");
                    if (context.state.IdDossier === ""|| context.state.IdDossier === "0") {
                      context.handleSaveAsDossierPartnerPrevTns()
                    
                    } else {
                      SaveDossierPartnerPrevTns(context.state)
                    }
                    setmodalOpen(false);
                  }}
                >
                  <span id="saveDossier">Enregistrer</span>
                </Button>
              </Column>
              <Column fill>
                <Button
                  onClick={() => {
                    context.handleSaveAsDossierPartnerRemExpress()
                    // context.handleNewSimulation("Audit prévoyance");
                    setmodalOpen(false);
                  }}
                >
                  <span>Enregistrer sous</span>
                </Button>
              </Column>
            </Row>
          </Column>
        )}
      </Modal>
    </Row>
  );
};

export default HeaderMahaka;
