import React, { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";

const PointCavpCapi = (props) => {
  const { resultRetraite, ClientConjoint } = props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };

  useEffect(()=>{
  props.CalculRetraite();
  },[context.state["TableauPoints" + ClientConjoint].RetraiteCompCavpCapi])
  return (
    <>
      {context.state['Carriere'+ClientConjoint].filter((Carriere) => Carriere.Nature === "Cavp" ).length > 0 && (
        <table >
          <thead>
            <tr>
              <th>Régime</th>
              <th>Epargne acquise</th>
              <th>Date d'acquisition</th>
              <th>Somme des cotisations</th>
              <th>Epargne au terme</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CAVP par capitalisation</td>
              <td>
                <TextField
                  value={context.state["TableauPoints" + ClientConjoint].RetraiteCompCavpCapi}
                  onChange={(e) => {
                    context.handlePointRetraite(ClientConjoint, "RetraiteCompCavpCapi", e);
                  }}
                  dataType="uint"
                  format={{ thousands: " ", decimal: "," , unit:' €'}}
                  small
                />
              </td>
              <td>31/12/{InitialAnneeAcquisition() + 1}</td>
              <td>
              <TextField
                   value={resultRetraite?.InfosRetraite?.Cavp?.General?.CapiCapitalCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Cavp?.General?.CapiCapitalCalcule}
                   disabled={true}
                   dataType="uint"
                   format={{ thousands: " ", decimal: ",", unit:' €' }}
                   small
                />
              </td>
              <td>
                <TextField
                   value={resultRetraite?.InfosRetraite?.Cavp?.General?.CapiCapitalCalcule === undefined ? 0 :Number(context.state["TableauPoints" + ClientConjoint].RetraiteCompCavpCapi) +Number(resultRetraite?.InfosRetraite?.Cavp?.General?.CapiCapitalCalcule)}
                   disabled={true}
                   dataType="uint"
                   format={{ thousands: " ", decimal: ",", unit:' €' }}
                   small
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};
export default PointCavpCapi;
