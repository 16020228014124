import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#f4f5f5'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#4285F4',
        backgroundColor: '#4285F4',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 30,
        color:"white",
        flexGrow: 1,
        fontFamily: 'Helvetica-Bold',
        fontSize:10,
    },
    description: {
        paddingTop:4,
        width: '55%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginLeft:10,
    },
    qty: {
        paddingTop:4,
        width: '17%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign:"center",
    },
    rate: {
        paddingTop:4,
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign:"center",
    },
    amount: {
        paddingTop:4,
        width: '15%',
        textAlign:"center",
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.qty}>Prix unitaire</Text>
        <Text style={styles.rate}>Quantité</Text>
        <Text style={styles.amount}>Montant HT</Text>
    </View>
  );
  
  export default InvoiceTableHeader