import React, { useEffect } from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

const ForfaitSocial = (props) => {
  const context = useContext(AppContext);
  const {Outil}=props;


  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Row>
              <Column>
              <DropDown
                  label="Intéressement"
                  value={context.state[Outil+"ForfaitSocialInteressement"]}
                  onChange={context.handleInputChange(Outil+"ForfaitSocialInteressement")}
                  options={[
                    { value: 0, label: "0%" },
                    { value: 20, label: "20%" },
                  ]}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
              <DropDown
                  label="Participation"
                  value={context.state[Outil+"ForfaitSocialParticipation"]}
                  onChange={context.handleInputChange(Outil+"ForfaitSocialParticipation")}
                  options={[
                    { value: 0, label: "0%" },
                    { value: 20, label: "20%" },
                  ]}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
              <DropDown
                  label="Abondement PEE"
                  value={context.state[Outil+"ForfaitSocialAbondementPee"]}
                  onChange={context.handleInputChange(Outil+"ForfaitSocialAbondementPee")}
                  options={[
                    { value: 0, label: "0%" },
                    { value: 20, label: "20%" },
                  ]}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
              <DropDown
                  label="Abondement PERECO"
                  value={context.state[Outil+"ForfaitSocialAbondementPerco"]}
                  onChange={context.handleInputChange(Outil+"ForfaitSocialAbondementPerco")}
                  options={[
                    { value: 0, label: "0%" },
                    { value: 16, label: "16%" },
                    { value: 20, label: "20%" },
                  ]}
                  normal
                />
              </Column>
            </Row>
           
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default ForfaitSocial;
