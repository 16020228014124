import { useState, useContext } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { AppContext } from "components/app/RemExprertProvider";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { User } from "components/app/Api/ApiUser";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import { List, ListItem, ListItemText } from "@mui/material";

const ImportClientSofracoPage = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(AppContext);
  const [disableValidate, setDisableValidate] = useState(true);

  const [LoginSofraco, setLoginSofraco] = useState("");
  const [MdpSofraco, setMdpSofraco] = useState("");
  const [Token, setToken] = useState("");
  const [ListeProspect, setListeProspect] = useState([]);

  const GetToken = () => {
    User({ LoginSofraco: LoginSofraco, MdpSofraco: MdpSofraco }, "GetSofracoInfos").then((Infos) => {
      setToken(Infos.Token);
      setListeProspect(Infos.Prospects);
      // context.handleValueChange("SofracoToken", Infos.Token);
      // context.handleValueChange("ListeProspect", Infos.Prospects);
    });
  };

  return (
    <>
      <Column center fill>
        <CardBox>
          {Token === "" && (
            <Column fill>
              <Row>
                <Column>
                  <TextField value={LoginSofraco} large placeholder="Nom d'utilisateur ou adresse email" onChange={(e) => setLoginSofraco(e.target.value)} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <TextField value={MdpSofraco} large placeholder="Mot de passe" onChange={(e) => setMdpSofraco(e.target.value)} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Button
                    large
                    onClick={() => {
                      GetToken();
                    }}
                  >
                    <span>CONNEXION</span>
                    <FaPlusCircle />
                  </Button>
                </Column>
              </Row>
            </Column>
          )}
          {Token !== "" && (
            <CardBox  tpad className="blockRoundedForce" style={{minWidth:"250px"}}>
              <Column center fill>
            <List dense={true}>
                {ListeProspect.map(prospect => {
                  return (
              <ListItem style={{cursor:"pointer",paddingBottom:"8px"}}
              onClick={() => {
                context.HandleImportClient(prospect.id,Token,prospect);
              }}>
                <ListItemText  primary={`${prospect.lastName} ${prospect.firstName}`}
                />
                  <FaPlusCircle style={{paddingLeft:"4px"}} />
                  
              </ListItem>
                  )
                })}
            </List>
            </Column>
            </CardBox >
            // <Column fill>
            //   <Row>
            //     <Column>
            //       <TextField value={LoginSofraco} large placeholder="Nom d'utilisateur ou adresse email" onChange={(e) => setLoginSofraco(e.target.value)} />
            //     </Column>
            //   </Row>
            //   <Row>
            //     <Column>
            //       <TextField value={MdpSofraco} large placeholder="Mot de passe" onChange={(e) => setMdpSofraco(e.target.value)} />
            //     </Column>
            //   </Row>
            //   <Row>
            //     <Column>
            //       <Button
            //         large
            //         onClick={() => {
            //           GetToken();
            //         }}
            //       >
            //         <span>CONNEXION</span>
            //         <FaPlusCircle />
            //       </Button>
            //     </Column>
            //   </Row>
            // </Column>
          )}
        </CardBox>
      </Column>
    </>
  );
};

export default ImportClientSofracoPage;
