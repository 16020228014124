import { useState, useEffect, useContext } from "react";
import { FaBaby, FaGlobe, FaHeart, FaIdBadge } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import Client from "components/app/Extranet/Client/Client";
import { AppContext } from "components/app/RemExprertProvider";
import Conjoint from "components/app/Extranet/Client/Conjoint";
import Enfants from "components/app/Extranet/Client/Enfants";
import Modal from "components/ui/modal/modal";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";

const ClientPageModal = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(AppContext);
  const [disableValidate, setDisableValidate] = useState(true);
  let [openModal, setOpenModal] = useState(false);
  let [openModalDateNaissance, setOpenModalDateNaissance] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);
  const handleMenu = () => {
    let menu = [
      {
        isTitle: true,
        title: "Client",
        subTitle: "Informations sur votre client",
      },
      {
        label: "Identité",
        icon: <FaIdBadge />,
        content: <Client CheckLURA={ handleCheckLURA} />,
      },
      {
        label: "Conjoint",
        icon: <FaHeart />,
        content: <Conjoint CheckLURA={ handleCheckLURA} />,
      },
      {
        label: "Enfants",
        icon: <FaBaby />,
        content: <Enfants />,
      },
    ];
    if (context.state.Nom === "") {
      menu = menu.filter((k) => k.label !== "Conjoint");
      menu = menu.filter((k) => k.label !== "Enfant");
    }
    if (context.state.StatutMarital !== "marie" && context.state.StatutMarital !== "pacse") {
      menu = menu.filter((k) => k.label !== "Conjoint");
    }
    return menu;
  };

  const handleDateNaissance = () => {
    let NaissanceClient = context.state.DateNaissance;
    let NaissanceConjoint = context.state.DateNaissanceConjoint;
    if (NaissanceClient.length === 8 && NaissanceConjoint.length === 8) {
      setDisableValidate(false);
      for (let i = 0; i <= context.state.TableauEnfant.length - 1; i++) {
        if (context.state.TableauEnfant[i]["DateNaissance"].length < 8) {
          setDisableValidate(true);
          break;
        }
      }
    } else {
      setDisableValidate(true);
    }
    const AnneeeNaissanceClient = Number(NaissanceClient.slice(-4));
    if(AnneeeNaissanceClient>new Date().getFullYear()){
      setOpenModalDateNaissance(true);
    context.handleValueChange('DateNaissance','01011970')
    }
    const AnneeeNaissanceConjoint = Number(NaissanceConjoint.slice(-4));
    if(AnneeeNaissanceConjoint>new Date().getFullYear()){
      setOpenModalDateNaissance(true);
    context.handleValueChange('DateNaissanceConjoint','01011970')
    }
  };

  const handleCheckLURA = (event) => {
    const Annee = Number(event.target.value.slice(-4));
    if (event.target.value.length===8 && Annee < 1953 ) {
      setOpenModal(true);
    }
  };
  useEffect(() => {
    handleDateNaissance();
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.DateNaissance, context.state.DateNaissanceConjoint, JSON.stringify(context.state.TableauEnfant)]);

  useEffect(() => {
    if (context.state.Nom !== "" && context.state.Prenom !== "") {
      setDisableValidate(false);
    } else {
      setDisableValidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.Nom, context.state.Prenom]);
  return (
    <>
      <Pager
        withMenu
        validateDisabled={disableValidate}
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: () => {
              // context.handleNewClient();
              props.onClose();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleCreateUpdateClient();
              props.onClose();
            },
          },
        }}
      />
      <Modal
        width="50%"
        height="25%"
        title="AVERTISSEMENT"
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill style={{justifyContent: "center"}}>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column style={{textAlign:"justify"}}> 
                          Nous informons que le logiciel ne tient pas compte des anciennes règles relatives aux régimes dits alignés et applique le dispositif LURA. Le calcul de la pension
                          de retraite peut donc, en fonction de la situation, comporter une marge d'erreur.
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
      <Modal
        width="50%"
        height="25%"
        title="AVERTISSEMENT"
        icon={<FaGlobe />}
        visible={openModalDateNaissance}
        onClose={() => {
          setOpenModalDateNaissance(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill style={{justifyContent: "center"}}>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column style={{textAlign:"justify"}}> 
                        La date de naissance ne peut pas être supérieure à la date d'aujourd'hui.
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModalDateNaissance(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default ClientPageModal;
