import { useEffect, useState } from "react";
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from "../../ui-helpers";
import './hover.css';

/**
 * Generates a hover responsive icon.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Hover = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['icon', 'visible', 'onMouseEnter', 'onMouseLeave', 'disabled'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [id] = useState(props?.id ? props.id : getUniqId());
    const [visible, setVisible] = useState(props?.visible || false);
    const [disabled, setDisabled] = useState(props?.disabled || false);

    /**
     * Mouse enter handler.
     */
    const handleMouseEnter = () => {
        
        // Get icon bounding box
        const icon = document.getElementById(id);
        const bbox = icon.getBoundingClientRect();

        // Get position
        const left = bbox.left + bbox.width;
        const top = bbox.top + bbox.height;

        // Get content
        const content = document.getElementById(`${id}_content`);
        content.style.left = `${left}px`;
        content.style.top = `${top}px`;

        if (!disabled)
            setVisible(true);
    }

    /**
     * Mouse leave handler.
     */
    const handleMouseLeave = () => {
        if (!disabled)
            setVisible(false);
    }

    /**
     * On visible property change.
     */
    useEffect(() => {
        setVisible(props?.visible || false);
    }, [props?.visible]);

    /**
     * Onn disabled property change.
     */
    useEffect(() => {
        setDisabled(props?.disabled || false);
    }, [props?.disabled]);

    // Generate component
    return (
        <>
            <div id={id} className="hover-icon" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {props?.icon}
            </div>
            <div
                id={`${id}_content`}
                className={classFromProperties(
                    props,
                    'hover-content' + (disabled ? ' disabled' : '') + (visible ? '' : ' hidden'),
                    Alignments,
                    Paddings,
                    Sizes
                )}
                {...getOptionalProps(props, Excluded)}
            >
                {props?.children}
            </div>
        </>
    )
}

export default Hover;

export const Hover2 = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['icon', 'visible', 'onMouseEnter', 'onMouseLeave', 'disabled'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [id] = useState(props?.id ? props.id : getUniqId());
    const [visible, setVisible] = useState(props?.visible || false);
    const [disabled, setDisabled] = useState(props?.disabled || false);

    /**
     * Mouse enter handler.
     */
    const handleMouseEnter = () => {
        
        // Get icon bounding box
        const icon = document.getElementById(id);
        const bbox = icon.getBoundingClientRect();

        // Get position
        const left = bbox.left + bbox.width;
        const top = bbox.top + bbox.height;

        // Get content
        const content = document.getElementById(`${id}_content`);
        content.style.left = `${left}px`;
        content.style.top = `${top}px`;

        if (!disabled)
            setVisible(true);
    }

    /**
     * Mouse leave handler.
     */
    const handleMouseLeave = () => {
        if (!disabled)
            setVisible(false);
    }

    /**
     * On visible property change.
     */
    useEffect(() => {
        setVisible(props?.visible || false);
    }, [props?.visible]);

    /**
     * Onn disabled property change.
     */
    useEffect(() => {
        setDisabled(props?.disabled || false);
    }, [props?.disabled]);

    // Generate component
    return (
        <>
            {/* height: 44px;
    margin: 0;
    font-size: 0.875rem;
    box-shadow: 1px 1px 2px rgba(0,0,0,.5);
    border-radius: 50%;
    background-color: var(--app-selected-bg-color); */}
            <div id={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {props?.icon}
            </div>
            <div
                id={`${id}_content`}
                className={classFromProperties(
                    props,
                    'hover-content' + (disabled ? ' disabled' : '') + (visible ? '' : ' hidden'),
                    Alignments,
                    Paddings,
                    Sizes
                )}
                {...getOptionalProps(props, Excluded)}
            >
                {props?.children}
            </div>
        </>
    )
}
