import { useState, useEffect, useContext } from "react";
import { FaCogs, FaIdBadge, FaMoneyBillWave } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import Periode from "components/app/AuditComplet/Retraite/Periode/Periode";
import Parametres from "components/app/AuditComplet/Retraite/Periode/Parametres";
import RevenusRetraite from "components/app/AuditComplet/Retraite/Periode/RevenusRetraite";

const AjoutPeriodePageModal = (props) => {
  const [disableValidate, setDisableValidate] = useState(false);
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handleMenu = () => {
    if (context.state.IdPeriodeRetraite === "CarriereActuelle") {
      let menu = [
        {
          isTitle: true,
          title: "Période",
          subTitle: "Période professionnelle",
        },
        {
          label: "Revenus",
          icon: <FaMoneyBillWave />,
          content: <RevenusRetraite Outil="" />,
        },
      ];
      return menu;
    }

    let menu = [
      {
        isTitle: true,
        title: "Période",
        subTitle: "Période professionnelle",
      },
      {
        label: "Période",
        icon: <FaIdBadge />,
        content: <Periode Outil="" ClientConjoint={props.ClientConjoint} visible={props.visible} />,
      },
      {
        label: "Revenus",
        icon: <FaMoneyBillWave />,
        content: <RevenusRetraite Outil="" />,
      },
      {
        label: "Paramètres",
        icon: <FaCogs />,
        content: <Parametres Outil="" ClientConjoint={props.ClientConjoint} />,
      },
    ];
    if (
      context.state.RetraiteActivite === "Chomage" ||
      context.state.RetraiteActivite === "Maladie" ||
      context.state.RetraiteActivite === "Maternite" ||
      context.state.RetraiteActivite === "Invalidite" ||
      context.state.RetraiteActivite === "TrimSpecifique" ||
      context.state.RetraiteActivite === "Militaire"
    ) {
      menu = menu.filter((k) => k.label !== "Revenus");
    }

    if (
      context.state.RetraiteActivite === "RsiArtisan" ||
      context.state.RetraiteActivite === "Crpcen" ||
      context.state.RetraiteActivite === "CnbfSalarie" ||
      context.state.RetraiteActivite === "Msa" ||
      context.state.RetraiteActivite === "Cavom" ||
      context.state.RetraiteActivite === "MicroRSI" ||
      context.state.RetraiteActivite === "MicroBIC" ||
      context.state.RetraiteActivite === "MicroBNC" ||
      context.state.RetraiteActivite === "MicroCipav" ||
      context.state.RetraiteActivite === "Maternite" ||
      context.state.RetraiteActivite === "Avpf" ||
      context.state.RetraiteActivite === "Militaire"
    ) {
      menu = menu.filter((k) => k.label !== "Paramètres");
    }

    if (context.state.RetraiteActivite === "Maladie" || context.state.RetraiteActivite === "Invalidite") {
      let FinPeriode = context.state.RetraiteFinActivite;
      if (FinPeriode.length === 8) {
        let AnneeFinPeriode = FinPeriode.slice(4);
        if (AnneeFinPeriode < Number(context.state["TableauPoints" + props.ClientConjoint].AnneeFinAgircArrco + 1)) {
          menu = menu.filter((k) => k.label !== "Paramètres");
        }
      }
    }
    return menu;
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);

  const handleEndGreaterBegin = () => {
    let DebutPeriode = context.state.RetraiteDebutActivite;
    let FinPeriode = context.state.RetraiteFinActivite;
    if (DebutPeriode.length === 8 && FinPeriode.length === 8) {
      let AnneeDebutPeriode = DebutPeriode.slice(4);
      let MoisDebutPeriode = DebutPeriode.slice(2, 4) - 1;
      let JourDebutPeriode = DebutPeriode.slice(0, 2);

      let DateDebutPeriode = new Date();
      DateDebutPeriode.setFullYear(AnneeDebutPeriode, MoisDebutPeriode, JourDebutPeriode);

      let DateFinPeriode = new Date();
      DateFinPeriode.setFullYear(FinPeriode.slice(4), FinPeriode.slice(2, 4) - 1, FinPeriode.slice(0, 2));

      DateDebutPeriode.setHours(0, 0, 0, 0);
      DateFinPeriode.setHours(24, 0, 0, 0);

      if (DateFinPeriode.getTime() <= DateDebutPeriode.getTime()) {
        setDisableValidate(true);
      } else {
        setDisableValidate(false);
      }
    } else {
      setDisableValidate(true);
    }
  };
  useEffect(() => {
    handleEndGreaterBegin();
    handleMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.RetraiteDebutActivite, context.state.RetraiteFinActivite, context.state.CheckboxDepartRetraite]);

  return (
    <>
      <Pager
        withMenu
        validateDisabled={disableValidate}
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {
          //   onClick: () => {
          //     props.onClose();
          //   },
          // },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
              context.handleAddPeriodeRetraite(context.state.IdPeriodeRetraite, props.Outil, props.ClientConjoint);
            },
          },
        }}
      />
    </>
  );
};

export default AjoutPeriodePageModal;
