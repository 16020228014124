import { useState, useEffect, useContext } from "react";
import {FaFileSignature } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import AjoutContratRetrPersoPageModal from "pages/audit complet/retraite/ajout contrat retraite perso modal";
import { AppContext } from "components/app/RemExprertProvider";

const RemExpressContratFacPersoModal = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const context = useContext(AppContext);
  useEffect(() => {
    setCurrentPage(0);
  }, [props.visible]);

  const handleStatut = () => {
    let Statut = "TNS Article 62";

    if (props.Outil==="OptiRem") {
      if (props.ClientConjoint === "" && context.state.OptiRemPeriodeActuelle?.length > 0) {
        Statut = context.state.OptiRemPeriodeActuelle[0].Statut;
      }
    }else{
      Statut=context.state.Statut;
    }
    return Statut;
  };

  return (
    <>
      <Pager
      id="contratFacPersoAuditRemPager"
        withMenu
        pages={[
          {
            label: "Contrats",
            icon: <FaFileSignature />,
            content: <AjoutContratRetrPersoPageModal Outil={props.Outil} Statut={handleStatut()}  AccessRemExpress={true} ClientConjoint={""}/>,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default RemExpressContratFacPersoModal;
