import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamCarpv from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpv";
import ParamCavec from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavec";
import ParamCnbf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCnbf";
import ParamLpaPageModal from "pages/audit complet/situation professionnelle/modules/parametre lpa modal";
import ContratPreco from "./ContratPreco";
import Button from "components/ui/controls/button/button";
import { User } from "components/app/Api/ApiUser";
import Modal from "components/ui/modal/modal";
import { FaPlusCircle, FaUmbrella } from "react-icons/fa";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { AuthContext } from "components/auth/auth-provider";

function ContratsPreco(props) {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [idTarifcation, setIdTarification] = useState("");
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const authContext = useContext(AuthContext);
  useEffect(() => {
    ResultatTNS.GetResultPrevTns(context.state);
  }, context.state.TypeSimulation);


  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <Column fill>
              <CardBox tpad>
                <Column fill>
                  <Row>
                    <blockquote>
                      Dans cet onglet, vous avez la possibilité de réaliser jusqu'à 3 propositions différentes, matérialisées par les 3 blocs ci dessous. Dans chacune des propositions, vous
                      pouvez ajouter jusqu'à 3 contrats.
                    </blockquote>
                  </Row>
                  <Row>
                    <DropDown
                      tpad
                      label={"Nombre de propositions"}
                      value={context.state.AffichContratPreco}
                      onChange={context.handlePropals}
                      options={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                      ]}
                      normal
                    />
                  </Row>
                </Column>
              </CardBox>
            </Column>
            {context.state.AffichContratPreco >= 1 && <ContratPreco Propal={1} Outil={"PrevTNSPreco1"} ClientConjoint="" />}
            {context.state.AffichContratPreco >= 2 && <ContratPreco Propal={2} Outil={"PrevTNSPreco2"} ClientConjoint="" />}
            {context.state.AffichContratPreco >= 3 && <ContratPreco Propal={3} Outil={"PrevTNSPreco3"} ClientConjoint="" />}
          </Column>
        </Row>
      </Column>
    </>
  );
}

export default ContratsPreco;
