import React, { useState, useContext, useEffect } from "react";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CardBox from "components/ui/controls/card-box/card-box";
import { AppContext } from "../RemExprertProvider";
import { SaveCodesCourtier, User } from "../Api/ApiUser";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import { AuthContext } from "components/auth/auth-provider";
import { FaDownload } from "react-icons/fa";
import { ApiUrl } from "../Api/ApiData";
import { saveAs } from "file-saver";
import axios from "axios";
import Modal from "components/ui/modal/modal";
const APIURL = ApiUrl;

export default function Formation() {
  const context = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const [modalMakoFormation, setmodalMakoFormation] = useState(false);

  const DownloadDocument = (Data, CRUD) => {
    var formData = new FormData();
    formData.append("Data", JSON.stringify(Data));
    formData.append("CRUD", CRUD);

    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    // ES6
    axios(`${APIURL}User/User.php`, {
      method: "post",
      data: formData,
      withCredentials: true,
      responseType: "blob",
      
      // headers: {
      //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      // }
    }).then((response) => {
      // create file link in browser's memory
      console.log(response.data)
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", Data.name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  };

  const updateLastTimeVisited=()=>{
    User({},"UpdateLastTimeVisited");
  }

  useEffect(() => {
       updateLastTimeVisited()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Column fill>
      <Row>
        <Column fill>
          <CardBox bpad style={{ maxWidth: "800px" }}>
            <Column fill>
              <Row>
                <h1>Guides</h1>
              </Row>
              <Row tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>Date</th>
                      <th style={{ width: "500px" }}>Libellé</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {authContext.utilisateur_reel?.documents?.guides?.map((Infos, Index) => {
                      return (
                        <tr key={Index}>
                          <td>{Infos.date}</td>
                          <td>{Infos.name} {Infos.isNew===true && <div className="badge"></div>}</td>
                          <td>
                            {authContext.utilisateur_reel?.AccesMakoFormation === true && (
                              <Button
                                onClick={() => {
                                  DownloadDocument(
                                    {
                                      IdUser: context.state.IdUser,
                                      name: Infos.name,
                                      type: "Guides",
                                    },
                                    "DownloadDocument"
                                  );
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                            {authContext.utilisateur_reel?.AccesMakoFormation === false && (
                              <Button
                                onClick={() => {
                                  setmodalMakoFormation(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
          <CardBox tpad bpad style={{ maxWidth: "800px" }}>
            <Column fill>
              <Row>
                <h1>Newsletter</h1>
              </Row>
              <Row tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>Date</th>
                      <th style={{ width: "500px" }}>Libellé</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {authContext.utilisateur_reel?.documents?.newsletter?.map((Infos, Index) => {
                      return (
                        <tr key={Index}>
                          <td>{Infos.date}</td>
                          <td>{Infos.name} {Infos.isNew===true && <div className="badge"></div>}</td>
                          <td>
                            {authContext.utilisateur_reel?.AccesMakoFormation === true && (
                              <Button
                                onClick={() => {
                                  DownloadDocument(
                                    {
                                      IdUser: context.state.IdUser,
                                      name: Infos.name,
                                      type: "NewsLetter",
                                    },
                                    "DownloadDocument"
                                  );
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                            {authContext.utilisateur_reel?.AccesMakoFormation === false && (
                              <Button
                                onClick={() => {
                                  setmodalMakoFormation(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
          <CardBox tpad bpad style={{ maxWidth: "800px" }}>
            <Column fill>
              <Row>
                <h1>Supports de formation</h1>
              </Row>
              <Row tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>Date</th>
                      <th style={{ width: "500px" }}>Libellé</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {authContext.utilisateur_reel?.documents?.supports?.map((Infos, Index) => {
                      return (
                      
                        <tr key={Index}>
                          <td>{Infos.date}</td>
                          <td>{Infos.name} {Infos.isNew===true && <div className="badge"></div>}</td>
                          <td>
                            {authContext.utilisateur_reel?.AccesMakoFormation === true && (
                              <Button
                                onClick={() => {
                                  DownloadDocument(
                                    {
                                      IdUser: context.state.IdUser,
                                      name: Infos.name,
                                      type: "Supports",
                                    },
                                    "DownloadDocument"
                                  );
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                            {authContext.utilisateur_reel?.AccesMakoFormation === false && (
                              <Button
                                onClick={() => {
                                  setmodalMakoFormation(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaDownload />
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
          <Modal
            width="80%"
            height="40%"
            title="Pack formation"
            visible={modalMakoFormation}
            onClose={() => {
              setmodalMakoFormation(false);
            }}
          >
            <Column fill>
              <Row fill>
                <Column fill center style={{ color: "black", textAlign: "justify", marginTop: "32px" }}>
                  <br />
                  L'accès au pack formation est reservé aux utilisateurs ayant souscrit à l'option.
                  <br />
                  Pour plus d'informations, vous vous invitions à nous contacter à l'adresse suivante : contact@mako-remexpert.com
                  <br />
                  {/* <a href="mailto:contact@mako-remexpert.com">contact@mako-remexpert.com</a> */}
                  <br />
                  Le pack formation comprend les services ci dessous : <br />
                  <br />- Un guide complet sur la protection sociale: 150 pages de détails pédagogiques sous forme de fiches sur la retraite et la prévoyance du chef d’entreprise.
                  <br />- Des newsletters sur l'environnement sociale et fiscale du chef d'entreprise.
                  <br />- Une base de contrats de prévoyance TNS intégrée sur votre espace MAKO (une trentaine de contrats du marché sont analysés sur différents critères : délai d’attente,
                  exclusions, garanties, conditions de prise en charges des affections dorsales et psy, barème d’invalidité…).
                  <br />- Une calculette de points AGIRC ARRCO permettant de contrôler les points acquis.
                  <br />- Deux outils d’aide à la détermination et la déclaration du plafond PER dans le cadre de l’article 163 quatervicies du CGI : correction des anciens plafonds, calcul
                  du plafonds 2024.
                  <br />- Un outil d’aide à la vente du PER : mise en évidence de l’efficacité du dispositif : raisonnement net de fiscalité et de PS à l’entrée et à la sortie.
                  <br />
                  <br />
                </Column>
              </Row>
            </Column>
          </Modal>
        </Column>
      </Row>
    </Column>
  );
}
