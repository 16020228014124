/**
 * API url.
 * @var string
 */
export const API_URL =  process.env.REACT_APP_API_URL;
/**
 * files url.
 * @var string
 */
export const FILES_URL = process.env.REACT_APP_FILES_URL;

