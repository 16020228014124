import axios from "axios";
import { RemoveUnecessaryData, ApiUrl } from "./ApiData";
import Api from "../../../api/api";
const APIURL = ApiUrl;

export function User(InfosUser, CRUD) {
  var formData = new FormData();

  formData.append("Data", JSON.stringify(InfosUser));
  formData.append("CRUD", CRUD);
  if ("Logo" in InfosUser) {
    formData.append("Logo", InfosUser.Logo);
  }
  return axios({
    method: "post",
    url: `${APIURL}User/User.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeClient) => {
      return ListeClient.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function SaveCodesCourtier(relation, codesCourtier) {
  relation.codes = JSON.stringify(codesCourtier);
  return Api.put("relations", relation).then((res) => {
    return true;
  });
}

export function Client(Data, CRUD) {
  var formData = new FormData();

  const InfosClient = {
    IdUser: Data.IdUser,
    IdClient: Data.IdClient,
    IdCabinet: Data.IdCabinet,
    Civilite: Data.Civilite,
    Nom: Data.Nom,
    Prenom: Data.Prenom,
    DateNaissance: Data.DateNaissance,
    CiviliteConjoint: Data.CiviliteConjoint,
    NomConjoint: Data.NomConjoint,
    PrenomConjoint: Data.PrenomConjoint,
    DateNaissanceConjoint: Data.DateNaissanceConjoint,
    StatutMarital: Data.StatutMarital,
    Enfant: Data.Enfant,
    Isole: Data.Isole,
    TableauEnfant: Data.TableauEnfant,
    IdProspectSofraco: Data.IdProspectSofraco,
    SofracoToken: Data.SofracoToken,
    SofracoProspect: Data.SofracoProspect,
    SofracoIdCabinet: Data.SofracoIdCabinet,
  };

  formData.append("Data", JSON.stringify(InfosClient));
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}User/Client.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeClient) => {
      return ListeClient.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}
export function InfosEntreprise(Siret, CRUD) {
  var formData = new FormData();

  const InfosEntreprise = {
    Siret: Siret,
  };

  formData.append("Data", JSON.stringify(InfosEntreprise));
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}User/InfosEntreprise.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }).then((ListeClient) => {
    return ListeClient.data;
  });
}

export function Dossier(Data, Method, TypeSimulation, IdDossier, CoutEntrepriseRemExpress, DividendeBrutRemExpress) {
  var formData = new FormData();
  let CleanData = RemoveUnecessaryData(Data);
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Method", Method);
  formData.append("TypeSimulation", TypeSimulation);
  formData.append("IdDossier", IdDossier);
  formData.append("CoutEntrepriseRemExpress", CoutEntrepriseRemExpress);
  formData.append("DividendeBrutRemExpress", DividendeBrutRemExpress);
  return axios({
    method: "post",
    url: `${APIURL}User/Dossier.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeDossier) => {
      return ListeDossier.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR CHARGEMENT DOSSIER",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de contacter le support.");
      }
    });
}

export function ContratPrev(Data, CRUD) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();

  formData.append("Data", JSON.stringify(CleanData));
  formData.append("CRUD", CRUD);

  
  return axios({
    method: "post",
    url: `${APIURL}User/User.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ListeDossier) => {
      return ListeDossier.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } 
    });

}
