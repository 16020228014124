import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import AjoutContratFacultatifPageModal from "pages/audit complet/situation professionnelle/modules/ajout contrat facultatif modal";

function ContratsFacultatif(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column pad fill>
        <CardBox>
          <Column fill>
            {context.state.TypeSimulation === "Audit prévoyance" ? (
              <Row>
                <h1>CONTRATS SOUSCRITS POUR {context.state.Prenom}</h1>
              </Row>
            ) : (
              <>
                <Row>
                  {context.state.TypeSimulation === "Audit rémunération" ? (
                    <blockquote>
                      Dans cet onglet, vous devez uniquement ajouter les contrats <b>souscrits par l'entreprise étudiée</b> au profit du dirigeant.
                    </blockquote>
                  ) : (
                    <blockquote>
                      Dans cet onglet, vous devez uniquement ajouter les contrats <b>souscrits par l'entreprise étudiée</b> au profit du dirigeant et éventuellement de son conjoint. Les
                      contrats d'épargne retraite souscrits à titre personnel doivent être saisis dans l'onglet retraite.
                    </blockquote>
                  )}
                </Row>
                <Row tpad>
                  {context.state.TypeSimulation === "Audit rémunération" ? (
                    <h1>CONTRATS SOUSCRITS PAR L'ENTREPRISe</h1>
                  ) : (
                    <h1>
                      CONTRATS SOUSCRITS PAR {context.state.IdentiteEntreprise} POUR {context.state['Prenom'+props.ClientConjoint]}
                    </h1>
                  )}
                </Row>
              </>
            )}
            <AjoutContratFacultatifPageModal Outil={props.Outil} AccessRemExpress={"AccessRemExpress" in props ? true : false} Statut={props.Statut} ClientConjoint={props.ClientConjoint} CliConj={props.ClientConjoint} />
            {context.state.InclureConjoint && context.state.StatutConjoint !== "Aucun" && (
              <>
                <Row tpad style={{ marginTop: "90px" }}>
                  <h1>
                    CONTRATS SOUSCRITS PAR {context.state.IdentiteEntreprise} POUR {context.state.PrenomConjoint}
                  </h1>
                </Row>

                <AjoutContratFacultatifPageModal Outil={props.Outil} AccessRemExpress={false} Statut={context.state.StatutConjoint} ClientConjoint="Conjoint" CliConj="ConjointAssocie" />
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratsFacultatif;
