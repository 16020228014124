import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

function PrestationFraisPro() {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <TextField
                  label="Indemnités journalières"
                  value={context.state.FGPIJ}
                  onChange={context.handleInputChange("FGPIJ")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  label="Franchise maladie"
                  value={context.state.FGPFranchiseMaladie}
                  onChange={context.handleInputChange("FGPFranchiseMaladie")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " jours" }}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Franchise accident"
                  value={context.state.FGPFranchiseAccident}
                  onChange={context.handleInputChange("FGPFranchiseAccident")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " jours" }}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Franchise hospitalisation"
                  value={context.state.FGPFranchiseHospitalisation}
                  onChange={context.handleInputChange("FGPFranchiseHospitalisation")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " jours" }}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  label="Durée de versement"
                  value={context.state.FGPDureeVersement}
                  onChange={context.handleInputChange("FGPDureeVersement")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " jours" }}
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default PrestationFraisPro;
