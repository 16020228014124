import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";


const ParamCarcdsf = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Conventionné"
            value={context.state[props.Outil + "ConventionneCarcdsf" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil+"ConventionneCarcdsf"+ props.ClientConjoint)}
            options={[
              {
                value: "oui",
                label: "Oui",
              },
              {
                value: "non",
                label: "Non",
              },
            ]}
            normal
          />
        </Column>
        {context.state[props.Outil + "ConventionneCarcdsf" + props.ClientConjoint] === "oui" && (
        <Column hpad>
          <TextField
            value={context.state[props.Outil + "TauxCpam" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "TauxCpam" + props.ClientConjoint)}
            label="Taux de prise en charge CPAM"
            dataType="ufloat"
            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
            normal
          />
        </Column>
         )}
      </Row>
    </>
  );
};

export default ParamCarcdsf;
