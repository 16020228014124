import { useEffect, useState } from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './state-box.css';

/**
 * State box component.
 * @param {ReactDOM.props} props Component properties
 * @returns Generated component.
 */
const StateBox = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'label', 'labelStyle', 'onClick', 'inline', 'before', 'checked', 'disabled', 'readOnly'].concat(Alignments, Paddings, Sizes);

    // Define checked values
    const checkedValue = props?.checked === undefined ? true : props?.checked;

    // Initialize states
    const [ id ] = useState(props?.id ? props.id : getUniqId())
    const [ checked, setChecked ] = useState(props?.value)
    const [ isDisabled, setDisabled ] = useState(props?.disabled !== undefined ? props?.disabled : false);
    const [ isReadOnly, setReadOnly ] = useState(props?.readOnly !== undefined ? props?.readOnly : false);
    
    /**
     * Handles check box click.
     * @param {ClickEvent} evt Click event.
     */
    const handleClick = (evt) => {
        // Check readonly
        if (props?.readOnly) return;

        // Update value
        evt.target.checked = checkedValue;
        evt.target.value = checkedValue;
        props?.onClick?.(evt);
    }

    /**
     * Handles value change from properties.
     */
    useEffect(() => {
        setChecked(props?.value);
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setReadOnly(props?.readOnly !== undefined ? props?.readOnly : false);
    }, [props?.value, props?.disabled, props?.readOnly])

    // Render component
    return (
        <div 
            id={id}
            className={classFromProperties(
                props,
                'state-box' +
                    (isDisabled ? ' disabled' : '') +
                    (isReadOnly ? ' readOnly' : ''),
                Alignments,
                Paddings,
                Sizes,
                ['inline', 'before', 'checked']
            )}
            onClick={handleClick}
            {...getOptionalProps(props, Excluded)}
        >
            {props?.label ? <label style={props?.labelStyle}>{props.label}</label> : <></>}
            <div className='icon'>
                {checked
                    ? <FaCheckSquare/>
                    : <FaRegSquare/>
                }
            </div>
        </div>
    )
}

export default StateBox;