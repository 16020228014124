import { AppUIContext } from '../../../../providers/app-ui-context';
import Column from '../column/column';
import Row from '../row/row';
import './footer.css';

/**
 * Footer bar component.
 * @returns Footer bar component.
 */
const Footer = () => {

    return (
        <AppUIContext.Consumer>
            {(ui) =>
                <Row className={'footer'}>
                    <Column className="footer-status fill">
                        <span>{ui.status}</span>
                    </Column>
                    <Column className="footer-version">
                        <span>Version {ui.version}</span>
                    </Column>
                </Row>
            }
        </AppUIContext.Consumer>
    )
}

export default Footer;
