import { useState, useContext, useEffect } from "react";
import {  FaGlobe,  FaPlusCircle } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import ContexteFiscalPageModal from "./contexte fiscal pager";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";

const ContexteFiscalePage = (props) => {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  useEffect(() => {
    const PeriodeClient = context.state["PeriodeActuelle"][0];
    const PeriodeConjoint = context.state["PeriodeActuelleConjoint"][0];

    let BrutImposableClient = 0;
    let BrutImposableConjoint = 0;
    if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
      BrutImposableClient += Number(ResultatTNS.state.RemunerationTNS.BrutImposable);
    } else {
      BrutImposableClient += Number(ResultatSalarie.state["RemunerationSalarie"].BrutImposable);
    }

    if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
      BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
    } else {
      BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
    }

    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
      } else {
        BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
      }
    }
    if (PeriodeClient !== undefined) {
      let BrutImposableClient = 0;
      let BrutImposableConjoint = 0;
      if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
        BrutImposableClient += Number(ResultatTNS.state.RemunerationTNS.BrutImposable);
      } else {
        BrutImposableClient += Number(ResultatSalarie.state["RemunerationSalarie"].BrutImposable);
      }

      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
      } else {
        BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
      }

      if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun") {
        if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
          BrutImposableConjoint += Number(ResultatTNS.state.RemunerationTNSConjoint.BrutImposable);
        } else {
          BrutImposableConjoint += Number(ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable);
        }
      }
      if (PeriodeClient !== undefined) {
        const DividendeClient = PeriodeClient && PeriodeClient.DividendeBrut ? PeriodeClient.DividendeBrut : 0;
        const DividendeConjoint = PeriodeConjoint && PeriodeConjoint.DividendeBrut ? PeriodeConjoint.DividendeBrut : 0;
        ResultatTNS.GetImpot(props.Outil, context.state, BrutImposableClient, BrutImposableConjoint, Number(DividendeClient) + Number(DividendeConjoint));
      }
    }
  }, [
    ResultatTNS.state["RemunerationTNS"].BrutImposable,
    ResultatTNS.state.RemunerationTNSConjoint.BrutImposable,
    ResultatSalarie.state["RemunerationSalarie"].BrutImposable,
    ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable,
    modalOpen
  ]);

  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox>
              <Column fill>
                <Row>
                  <h1>Contexte fiscal</h1>
                </Row> 
                <Row center>
                <Column style={{paddingLeft:0}}>
                    <Button
                      onClick={() => {
                        setmodalOpen(true);
                      }}
                    >
                      <span>Ajouter un contexte fiscal</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>
                </Row>
                <Row tpad>
                  <table>
                    <thead>
                      <tr>
                        <th>Type d'impôt</th>
                        <th style={{ textAlign: "center" }}>Assiette</th>
                        <th style={{ textAlign: "right" }}>Montant à payer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ResultatTNS.state.DetailImpot.BaseImposable > 0 && (
                        <tr>
                          <td>Impôt lié au barème progressif</td>
                          <td style={{ textAlign: "center" }}>{applyFormat(ResultatTNS.state.DetailImpot.BaseImposable, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.IRapresQF, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      {ResultatTNS.state.DetailImpot.PFU > 0 && (
                        <tr>
                          <td>PFU</td>
                          <td style={{ textAlign: "center" }}>
                            {applyFormat(ResultatTNS.state.DetailImpot.AssietteTotaleIR.RevenuSoumisPFU, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                          </td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.PFU, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      {ResultatTNS.state.DetailImpot.ContribHR > 0 && (
                        <tr>
                          <td>CEHR</td>
                          <td style={{ textAlign: "center" }}>{applyFormat(ResultatTNS.state.DetailImpot.AssietteTotaleIR?.CEHR, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.ContribHR, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      {ResultatTNS.state.DetailImpot.PensionCapital > 0 && (
                        <tr>
                          <td>Pensions de retraite en capital taxables à 7,5%</td>
                          <td style={{ textAlign: "center" }}>
                            {applyFormat(ResultatTNS.state.DetailImpot.AssietteImpot.Foyer.PensionCapital, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                          </td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.PensionCapital, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      {Number(ResultatTNS.state.DetailImpot.ReductionImpot) + Number(ResultatTNS.state.DetailImpot.ReductionVeuf) > 0 && (
                        <tr>
                          <td>Réductions et crédits d’impôt</td>
                          <td style={{ textAlign: "center" }}>-</td>
                          <td style={{ textAlign: "right" }}>
                            - {applyFormat(Number(ResultatTNS.state.DetailImpot.ReductionImpot) + Number(ResultatTNS.state.DetailImpot.ReductionVeuf), "uint", {
                              thousands: " ",
                              decimal: ",",
                              unit: " €",
                            })}
                          </td>
                        </tr>
                      )}
                      {ResultatTNS.state.DetailImpot.Decote > 0 && (
                        <tr>
                          <td>Décote</td>
                          <td style={{ textAlign: "center" }}>-</td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.Decote, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      {ResultatTNS.state.DetailImpot.PrelevementsSociaux > 0 && (
                        <tr>
                          <td>Prélèvements sociaux</td>
                          <td style={{ textAlign: "center" }}>
                            {applyFormat(
                              ResultatTNS.state.DetailImpot.AssietteTotaleIR?.DividendeActivitePrelevementSociaux +
                                ResultatTNS.state.DetailImpot.AssietteTotaleIR?.RevenuSoumisPrelevementSociaux,
                              "uint",
                              { thousands: " ", decimal: ",", unit: " €" }
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.PrelevementsSociaux, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={2}>Total</td>
                        <td style={{ textAlign: "right" }}>{applyFormat(ResultatTNS.state.DetailImpot.IRTotal, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
      <Modal
        width="80%"
        height="80%"
        title="Contexte fiscal"
        icon={<FaGlobe />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ContexteFiscalPageModal
          Outil=""
          visible={modalOpen}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ContexteFiscalePage;
