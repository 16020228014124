import { getOptionalProps } from '../ui-helpers';
import ModalProvider from './modal-provider';
import ModalInner from './modal-inner';

/**
 * Modal component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Modal component.
 */
const Modal = (props) => {

    // Render component
    return (
        <ModalProvider onClose={props?.onClose} visible={props?.visible}>
            <ModalInner {...getOptionalProps(props, ['onClose', 'visible']) }>
                {props.children}
            </ModalInner>
        </ModalProvider>
    )
}

export default Modal;