import React, { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";

const DetailInvaliditeTNS = ({ Outil, GraphInval, TabInval, Affichage }) => {

  useEffect(() => {
    document.getElementById("GraphInval").innerHTML = GraphInval;
  }, []);
  const context = useContext(AppContext);
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <h1>Prestations d'invalidité</h1>
          </Row>
          <Row tpad bpad>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">Régime obligatoire</th>
                  <th colSpan={context.state[Outil + "ContratEnregistre"].length} className="text-center">
                    Régime(s) facultatif(s)
                  </th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>15%</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[0]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[0]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, TabInval[0]["Régime obligatoire"] + TabInval[0]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>33%</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[1]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[1]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, TabInval[1]["Régime obligatoire"] + TabInval[1]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>66%</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[2]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[2]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, TabInval[2]["Régime obligatoire"] + TabInval[2]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>100%</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[3]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[3]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, TabInval[3]["Régime obligatoire"] + TabInval[3]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>100% + majo. tierce pers.</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[4]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabInval[4]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, TabInval[4]["Régime obligatoire"] + TabInval[4]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row tpad bpad>
            <h1>Graphique de l'invalidité</h1>
          </Row>
          <Row tpad style={{with:'500px',height:"500px"}} id="GraphInval">
           
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailInvaliditeTNS;
