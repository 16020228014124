import './classes/loader/loader';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "App";
import AppDev from "AppDev";
import "components/app/styles/styles.css"
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import RemExpertProvider from "./components/app/RemExprertProvider";
import ResultatRemExpertTNSProvider from "components/app/Api/ResultatRemExpertTNSProvider";
import ResultatRemExpertSalarieProvider from "components/app/Api/ResultatRemExpertSalarieProvider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppApiUserPLPSOFT from "AppApiUserPLPSOFT";
import AppApiUserMAHAKA from 'AppApiUserMAHAKA';
import AppApiUserKWIPER from 'AppApiUserKWIPER';
import IndexError from 'index_error';
import AppApiUserEXCELIANCES from 'AppApiUserEXCELIANCES';


const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/ApiUser/PlpSoft",
    element: <AppApiUserPLPSOFT theme="PLPSoft" />
  },
  {
    path: "/ApiUser/Mahaka",
    element: <AppApiUserMAHAKA theme="default" />
  },
  {
    path: "/ApiUser/Kwiper",
    element: <AppApiUserKWIPER theme="Kwiper" />
  },
  {
    path: "/ApiUser/Exceliances",
    element: <AppApiUserEXCELIANCES theme="Exceliances" />
  },
  {
    path: "/",
    element: <App theme="default" />,
    // errorElement: <IndexError/>
  },
]);

root.render(
  // <React.StrictMode>
  <RemExpertProvider>
    <ResultatRemExpertTNSProvider>
      <ResultatRemExpertSalarieProvider>
        <RouterProvider router={router} />

        {/* <AppDev theme="default"/> */}
      </ResultatRemExpertSalarieProvider>
    </ResultatRemExpertTNSProvider>
  </RemExpertProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
