import { useState, useEffect} from "react";
import { FaBuilding, FaCogs, FaCoins, FaFileInvoiceDollar, FaFunnelDollar, FaIdBadge, FaIndustry, FaMoneyBillWave, FaReceipt, FaSun, FaWallet } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import RevenusPro from "components/app/AuditComplet/ContexteFiscal/RevenusPro";
import BicBncAvantAbattement from "components/app/AuditComplet/ContexteFiscal/BicBncAvantAbattement";
import RevenusFoncier from "components/app/AuditComplet/ContexteFiscal/RevenusFoncier";
import PensionRetraiteRente from "components/app/AuditComplet/ContexteFiscal/PensionRetraiteRente";
import RenteViagere from "components/app/AuditComplet/ContexteFiscal/RenteViagere";
import CapitauxMobiliers from "components/app/AuditComplet/ContexteFiscal/CapitauxMobiliers";
import PlusMoinsValue from "components/app/AuditComplet/ContexteFiscal/PlusMoinsValue";
import Divers from "components/app/AuditComplet/ContexteFiscal/Divers";
import Charges from "components/app/AuditComplet/ContexteFiscal/Charges";
import CreditReduction from "components/app/AuditComplet/ContexteFiscal/CreditReduction";
import DeductionEpargne from "components/app/AuditComplet/ContexteFiscal/DeductionEpargne";

const ContexteFiscalPageModal = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);


  return (
    <>
      <Pager
        withMenu
        pages={[
          {
            isTitle: true,
            title: "Impôt sur le revenu",
            subTitle: "Paramètrage de l'impôt",
          },
          {
            label: "Revenus professionnels",
            icon: <FaMoneyBillWave />,
            content: <RevenusPro Outil={props.Outil} visible={props.visible} />,
          },
          {
            label: "BNC / BIC avant abattement",
            icon: <FaIdBadge />,
            content: <BicBncAvantAbattement Outil={props.Outil} />,
          },
          {
            label: "Revenus fonciers",
            icon: <FaBuilding />,
            content: <RevenusFoncier Outil={props.Outil} />,
          },
          {
            label: "Pensions, retraites, rentes",
            icon: <FaSun />,
            content: <PensionRetraiteRente Outil={props.Outil} />,
          },
          {
            label: "Rente viagère à titre onéreux",
            icon: <FaWallet />,
            content: <RenteViagere Outil={props.Outil} />,
          },
          {
            label: "Valeurs et capitaux mobiliers",
            icon: <FaIndustry />,
            content: <CapitauxMobiliers Outil={props.Outil}/>,
          },
          {
            label: "+ / - values sur valeurs mobilières",
            icon: <FaCoins />,
            content: <PlusMoinsValue Outil={props.Outil} />,
          },
          {
            label: "Divers",
            icon: <FaCogs />,
            content: <Divers Outil={props.Outil} />,
          },
          {
            label: "Déduction épargne retraite",
            icon: <FaReceipt />,
            content: <DeductionEpargne Outil={props.Outil} />,
          },
          {
            label: "Charges",
            icon: <FaFileInvoiceDollar />,
            content: <Charges Outil={props.Outil} />,
          },
          {
            label: "Crédits et réductions d'impôt",
            icon: <FaFunnelDollar />,
            content: <CreditReduction Outil={props.Outil} />,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ContexteFiscalPageModal;
