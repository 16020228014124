import React, { useState } from "react";

/**
 * Application UI context.
 */
export const AppUIContext = React.createContext();

/**
 * Generates an UI context provider.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
export const AppUIContextProvider = (props) => {

    // Initialize states
    const ui = {};
    [ui.theme, ui.setTheme] = useState('default');
    [ui.name, ui.setName] = useState(props?.name || 'MAKO');
    [ui.slogan, ui.setSlogan] = useState(props?.slogan || 'Logiciels');
    [ui.version, ui.setVersion] = useState(props?.version || '1.0.0');
    [ui.logo, ui.setLogo] = useState('/img/logo.svg');
    [ui.toolbar, ui.setToolbar] = useState([]);
    [ui.status, ui.setStatus] = useState(props?.status || 'Bienvenue dans votre application !');
    [ui.menu, ui.setMenu] = useState({
        'extranet': { visible: true, enabled: true },
        'audits': { visible: true, enabled: false }
    });

    // Render component
    return (
        <AppUIContext.Provider value={ui}>
            {props.children}
        </AppUIContext.Provider>
    )
}
