import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

function ContratSante(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
          {props.AccessRemExpress === false && (
            <Row>
              <Column>
                <TextField label="Libellé" value={context.state.LibelleContratSante} onChange={context.handleInputChange("LibelleContratSante")} normal />
              </Column>
            </Row>
          )}
            {props.Statut === "TNS Article 62" || props.Statut  === "TNS individuel" ? (
              <>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Cotisation Madelin annuelle"
                      value={context.state.SanteMadelin}
                      onChange={context.handleInputChange("SanteMadelin")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Cotisation non Madelin annuelle"
                      value={context.state.SanteNonMadelin}
                      onChange={context.handleInputChange("SanteNonMadelin")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
              </>
            ) : (
              <Row tpad>
                <Column>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Salarié</th>
                        <th>Employeur</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Frais de santé sur le PASS</td>
                        <td>
                          <TextField
                            value={context.state.Taux83Sante.taux_sante_salarie83}
                            onChange={(e) => context.handleObjectChange("Taux83Sante", "taux_sante_salarie83", e)}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            large
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.Taux83Sante.taux_sante_patronale83}
                            onChange={(e) => context.handleObjectChange("Taux83Sante", "taux_sante_patronale83", e)}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            large
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Column>
              </Row>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default ContratSante;
