import { FaCalculator, FaGlobe, FaSync } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import _ from "lodash";
import Button from "components/ui/controls/button/button";

const StrategiePERDisponible163 = (props) => {
  const { Year, ClientConjoint } = props;
  const context = useContext(AppContext);
  const dt = new Date();
  const yearM1 = dt.getFullYear() - 1;
  const yearM2 = dt.getFullYear() - 2;
  const yearM3 = dt.getFullYear() - 3;
  const year = dt.getFullYear();

  console.log(props)
  const handleChangeValue = (Year, Property, Value) => {
    let Values = _.cloneDeep(context.state.StrategiePERDisponibleTabPlafond163);
    if (Property === "SetClient" && Value === false) {
      Values[Year]["Client"] = props?.Disponible163Calcule;
    } 
    Values[Year][Property] = Value;

    if (Year === year && (Property === "Client" || Property === "Conjoint")) {
      Values[Year]["Set" + Property] = true;
    }
    context.handleValueChange("StrategiePERDisponibleTabPlafond163", Values);
  };

  const handleMutuPlafond = (value) => {
    context.handleValueChange("StrategiePERDisponiblMutuPlafond", value);
    // handleCalculPlafondDeduction(tabCorrectionPER, value);
  };
  // const handleCalculPlafondDeduction = (Values, MutuPlafond) => {
  //   let PlafondClient =
  //     Number(Values[year]["DispoPERCorrige"]) + Number(Values[yearM1]["DispoPERCorrige"]) + Number(Values[yearM2]["DispoPERCorrige"]) + Number(Values[yearM3]["DispoPERCorrige"]);
  //   let PlafondConjoint =
  //     Number(Values[year]["DispoPERCorrigeConjoint"]) +
  //     Number(Values[yearM1]["DispoPERCorrigeConjoint"]) +
  //     Number(Values[yearM2]["DispoPERCorrigeConjoint"]) +
  //     Number(Values[yearM3]["DispoPERCorrigeConjoint"]);
  //   if (MutuPlafond === "oui") {
  //     PlafondClient += PlafondConjoint;
  //     PlafondConjoint = 0;
  //   }
  //   setPlafondClient(PlafondClient);
  //   setPlafondConjoint(PlafondConjoint);
  //   context.handleValueChange("CorrectionPER", Values);
  // };

  return (
    <>
      <Modal
        width="750px"
        height="60%"
        title=" Disponible fiscal 163 quatervicies"
        icon={<FaGlobe />}
        visible={props.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <table className="correctPER">
                      <tbody>
                        <tr>
                          <td></td>
                          <td style={{ paddingLeft: "70px" }}>Client</td>
                          <td style={{ paddingLeft: "70px" }}>Conjoint</td>
                        </tr>
                        <tr>
                          <td>Plafond non utilisé pour les revenus de {yearM3}</td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM3]?.Client}
                              onChange={(e) => {
                                handleChangeValue(yearM3, "Client", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM3]?.Conjoint}
                              onChange={(e) => {
                                handleChangeValue(yearM3, "Conjoint", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Plafond non utilisé pour les revenus de {yearM2}</td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM2]?.Client}
                              onChange={(e) => {
                                handleChangeValue(yearM2, "Client", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM2]?.Conjoint}
                              onChange={(e) => {
                                handleChangeValue(yearM2, "Conjoint", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Plafond non utilisé pour les revenus de {yearM1}</td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM1]?.Client}
                              onChange={(e) => {
                                handleChangeValue(yearM1, "Client", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[yearM1]?.Conjoint}
                              onChange={(e) => {
                                handleChangeValue(yearM1, "Conjoint", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Plafond calculé sur les revenus de {yearM1}</td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              iconBefore={
                                <Button tabIndex="-1" icon onClick={() => handleChangeValue(year, "SetClient", false)}>
                                  {context.state["StrategiePERDisponibleTabPlafond163"][year]?.SetClient === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                                </Button>
                              }
                              value={context.state.StrategiePERDisponibleTabPlafond163[year]?.Client}
                              onChange={(e) => {
                                handleChangeValue(year, "Client", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                          <td>
                            <TextField
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponibleTabPlafond163[year]?.Conjoint}
                              onChange={(e) => {
                                handleChangeValue(year, "Conjoint", e.target.value);
                              }}
                              dataType="uint"
                              format={{ thousands: " ", unit: " €" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Mutualisation des plafonds</td>
                          <td>
                            <DropDown
                              className="CorrecPERInput"
                              value={context.state.StrategiePERDisponiblMutuPlafond}
                              onChange={(e) => {
                                handleMutuPlafond(e.target.value);
                              }}
                              options={[
                                { value: "Non", label: "Non" },
                                { value: "Oui", label: "Oui" },
                              ]}
                            />
                          </td>
                          <td></td>
                        </tr>
                        {/* <tr>
                    <td>Plafond de déduction {year}</td>
                    <td>
                      <TextField value={plafondClient} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                      <TextField value={0} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                    </td>
                    <td>
                      <TextField value={plafondConjoint} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                      <TextField value={0} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                    </td>
                  </tr> */}
                      </tbody>
                    </table>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                props?.onClose?.();
                props?.CalculDisponible();
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default StrategiePERDisponible163;
