import {  useState } from "react";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaCogs } from "react-icons/fa";
import ParamResultat from "components/app/AuditComplet/SituationPro/ParamResultat";

function ParamResultatEntrePrisePageModal(props) {
  const [modalOpen, setmodalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setmodalOpen(true)}>
        <FaCogs />
        <span> Paramétrage du résultat</span>
      </Button>
      <Modal
        width="50%"
        height="80%"
        title="Paramétrage du résultat de l'entreprise"
        icon={<FaCogs />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ParamResultat
        Prenom={props.Prenom}
        PrenomConjoint={props.PrenomConjoint}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ParamResultatEntrePrisePageModal;
