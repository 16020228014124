import { useState } from "react";
import { FaShieldAlt } from "react-icons/fa";
import Button from "../controls/button/button";
import TextField from "../controls/text-field/text-field";
import Column from "../layout/column/column";
import Row from "../layout/row/row";
import { strengthScore } from "../ui-helpers";
import './password.css';

/**
 * Password change component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Password = (props) => {

    // Initialize states
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [valid, setValid] = useState(false);

    /**
     * Checks whether password is valid.
     * @returns True if password is valid, False otherwise.
     */
    const isValid = () => {

        // Get elements
        const strength = document.getElementById('strength');
        const passEl = document.getElementById('new-password');
        const confirmEl = document.getElementById('password-confirm');
        const password = passEl.value;
        const confirm = confirmEl.value;

        // Check score
        const score = strengthScore(password);
        if (score > 80) {
            strength.classList.remove('critical');
            strength.classList.remove('warning');
            strength.classList.add('ok');
            strength.innerText = 'Mot de passe sécurisé';
        }
        else if (score > 60) {
            strength.classList.remove('critical');
            strength.classList.add('warning');
            strength.classList.remove('ok');
            strength.innerText = 'Mot de passe acceptable';
        }
        else {
            strength.classList.add('critical');
            strength.classList.remove('warning');
            strength.classList.remove('ok');
            strength.innerText = 'Mot de passe trop faible';
        }

        // Return result
        return password === confirm && score > 60;
    }

    /**
     * Validate handler.
     */
    const validate = () => {
        const passEl = document.getElementById('new-password');
        props?.onValidate?.(passEl.value);
    }

    const handlePasswordChange = (value) => {
        setPassword(value);
        setValid(isValid());
    }

    const handleConfirmChange = (value) => {
        setConfirm(value);
        setValid(isValid());
    }

    return (
        <Column className="password-reset" fill centerX centerY>
            <Row>
                <Column>
                    <blockquote>
                        Vous devez définir votre mot de passe. Nous vous recommandons qu'il contienne au moins:<br />
                        <ul>
                            <li>12 caractères</li>
                            <li>une lettre en minuscule</li>
                            <li>une lettre en majuscule</li>
                            <li>un chiffre</li>
                            <li>un caractère de ponctuation</li>
                        </ul>
                    </blockquote>
                </Column>
            </Row>
            <Row>
                <Column>
                    <TextField
                        id='new-password'
                        label='Mot de passe'
                        dataType='string'
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(evt) => { handlePasswordChange(evt.target.value) }}
                        onKeyUp={(evt) => { handlePasswordChange(evt.target.value) }}
                        password
                        large
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <div id="strength" className="critical">Mot de passe trop faible</div>
                </Column>
            </Row>
            <Row>
                <Column>
                    <TextField
                        id='password-confirm'
                        label='Confirmation'
                        dataType='string'
                        placeholder="Confirmation"
                        value={confirm}
                        onChange={(evt) => { handleConfirmChange(evt.target.value) }}
                        onKeyUp={(evt) => { handleConfirmChange(evt.target.value) }}
                        password
                        large
                    />
                </Column>
            </Row>
            <Row tpad>
                <Column>
                    <Button
                        onClick={validate}
                        disabled={!valid}
                        large
                    >
                        Enregistrer
                        <FaShieldAlt />
                    </Button>
                </Column>
            </Row>
        </Column>
    )
}
export default Password;