import Modal from "components/ui/modal/modal";
import { FaGlobe } from "react-icons/fa";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Pager from "components/ui/controls/pager/pager";

function ModalPeriodeRetraite(props) {
  return (
    <>
      <Modal
        width="40%"
        height="20%"
        title="Incohérence des dates"
        icon={<FaGlobe />}
        visible={props.visible}
        onClose={() => {
          props.onClose();
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>La date de fin de période ne peut pas être inferieure à la date de début période.</Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                props?.onClose?.();
              },
            },
          }}
        />
      </Modal>
    </>
  );
}

export default ModalPeriodeRetraite;
