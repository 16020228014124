import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaInfo, FaSync } from "react-icons/fa";
import Hover from "components/ui/controls/hover/hover";
import { TabTauxConversion } from "components/app/AuditComplet/Retraite/Parametrage/ContratRetraite/TabTauxconversion";
import { GetCalculStrategiePER } from "components/app/Api/ApiSimulateur";
import _ from "lodash";

function StrategiePERContrat(props) {
  const context = useContext(AppContext);
  const [results, setResults] = useState({});
  useEffect(() => {
    if (context.state.StrategiePERContratSetTauxConversion === false) {
      if (context.state["DateNaissance"].slice(-4) in TabTauxConversion) {
        if (Math.min(70, context.state.StrategiePERAgeDepartRetraite) in TabTauxConversion[context.state["DateNaissance"].slice(-4)]) {
          context.handleValueChange(
            "StrategiePERContratTauxConversion",
            TabTauxConversion[context.state["DateNaissance"].slice(-4)][Math.min(70, Number(context.state.StrategiePERAgeDepartRetraite))]
          );
        } else {
          context.handleValueChange("StrategiePERContratTauxConversion", 0.0);
        }
      } else {
        context.handleValueChange("StrategiePERContratTauxConversion", 0.0);
      }
    }
  }, [context.state.StrategiePERContratSetTauxConversion]);

  const handleGestion = (value) => {
    context.handleValueChange("StrategiePERContratGestion", value);
    let taux = 0;
    if (value === "Gestion libre") {
      taux = 3;
    } else if (value === "Pilotage automatique prudent") {
      taux = 1.5;
    } else if (value === "Pilotage automatique équilibré") {
      taux = 2.5;
    } else if (value === "Pilotage automatique dynamique") {
      taux = 5;
    }
    CalculCapital("StrategiePERContratRendementMoyen", taux);
  };

  const CalculCapital = (name, value) => {
    context.handleValueChange(name, value);
    let values = _.cloneDeep(context.state);
    values[name] = value;
    GetCalculStrategiePER(values, "", "StrategiePERCalculStrategie").then((results) => {
      setResults(results);
    });
  };

  useEffect(() => {
    GetCalculStrategiePER(context.state, "", "StrategiePERCalculStrategie").then((results) => {
      setResults(results);
    });
  }, []);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row tpad>
              <h1>Paramétrage du contrat</h1>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Libellé"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERContratLibelle}
                  onChange={context.handleInputChange("StrategiePERContratLibelle")}
                  normal
                />
              </Column>
            </Row>
            {context.state.StrategiePERChoixStrategie !== "prestation" && (
              <Row>
                <Column>
                  <TextField
                    label="Versement initial"
                    labelStyle={{ whiteSpace: "nowrap" }}
                    value={context.state.StrategiePERContratVersementInitial}
                    onChange={(e) => {
                      CalculCapital("StrategiePERContratVersementInitial", e.target.value);
                    }}
                    normal
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                  />
                </Column>
                <Column>
                  <TextField
                    label="Versements programmés"
                    labelStyle={{ whiteSpace: "nowrap" }}
                    value={context.state.StrategiePERContratVersementProgramme}
                    onChange={(e) => {
                      CalculCapital("StrategiePERContratVersementProgramme", e.target.value);
                    }}
                    normal
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                  />
                </Column>
              </Row>
            )}
            <Row>
              {/* {context.state.StrategiePERChoixStrategie !== "prestation" && ( */}
              <Column>
                <TextField
                  label="Taux d’indexation"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERContratTauxIndexation}
                  onChange={(e) => {
                    CalculCapital("StrategiePERContratTauxIndexation", e.target.value);
                  }}
                  normal
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                />
              </Column>
              {/* )} */}
              <Column>
                <TextField
                  label="Frais sur versements"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERContratFraisVersement}
                  onChange={(e) => {
                    CalculCapital("StrategiePERContratFraisVersement", e.target.value);
                  }}
                  normal
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Gestion "
                  value={context.state.StrategiePERContratGestion}
                  onChange={(e) => handleGestion(e.target.value)}
                  onBlur={props?.CalculCapital}
                  options={[
                    {
                      value: "Gestion libre",
                      label: "Gestion libre", //3%
                    },
                    {
                      value: "Pilotage automatique prudent",
                      label: "Pilotage automatique prudent", //1.5
                    },
                    {
                      value: "Pilotage automatique équilibré",
                      label: "Pilotage automatique équilibré", //2.5
                    },
                    {
                      value: "Pilotage automatique dynamique",
                      label: "Pilotage automatique dynamique", //5
                    },
                  ]}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Rendement net moyen"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.StrategiePERContratRendementMoyen}
                  onChange={(e) => {
                    CalculCapital("StrategiePERContratRendementMoyen", e.target.value);
                  }}
                  normal
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                />
              </Column>
            </Row>
            <Row>
              {context.state.StrategiePERChoixStrategie === "prestation" && (
                <Column>
                  <TextField
                    label="Cotisation annuelle à investir"
                    labelStyle={{ whiteSpace: "nowrap" }}
                    value={results.strategie === undefined ? 0 : Number(results.versements?.versementIntial).toFixed(0)}
                    disabled
                    normal
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                  />
                </Column>
              )}
              <Column>
                <TextField
                  label="Epargne brute au terme"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={results.strategie === undefined ? 0 : Number(results.strategie?.Total?.CapitalRetraite).toFixed(0)}
                  disabled
                  normal
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                />
              </Column>
            </Row>
            {(context.state.StrategiePERChoixStrategie === "rentevscapital" ||
              (context.state.StrategiePERChoixStrategie === "prestation" && context.state.StrategiePERChoixSortie === "rente")) && (
              <>
                <Row tpad>
                  <h1>Sortie en rente</h1>
                </Row>
                <Row>
                  <Column>
                    <DropDown
                      label="Choix de la rente"
                      value={context.state.StrategiePERContratChoixRente}
                      onChange={context.handleInputChange("StrategiePERContratChoixRente")}
                      options={[
                        {
                          value: "Rente simple",
                          label: "Rente simple",
                        },
                        {
                          value: "Rente avec réversion",
                          label: "Rente avec réversion",
                        },
                        {
                          value: "Rente avec annuités garanties",
                          label: "Rente avec annuités garanties",
                        },
                        {
                          value: "Rente par palier",
                          label: "Rente par palier",
                        },
                        {
                          value: "Rente avec option dépendance",
                          label: "Rente avec option dépendance",
                        },
                      ]}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      iconBefore={
                        <Button
                          tabIndex="-1"
                          icon
                          onClick={() => {
                            context.handleValueChange("StrategiePERContratSetTauxConversion", false);
                          }}
                        >
                          {context.state["StrategiePERContratSetTauxConversion"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      label="Taux de conversion"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERContratTauxConversion}
                      onChange={(e) => {
                        context.handleValueChange("StrategiePERContratSetTauxConversion", true);
                        CalculCapital("StrategiePERContratTauxConversion", e.target.value);
                      }}
                      normal
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    />
                  </Column>
                  <Column style={{ paddingTop: "40px" }} lpad>
                    <Hover style={{ width: "500px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                      Le logiciel détermine automatiquement le taux de conversion applicable en fonction de l'année de naissance de votre client et de son âge de départ en retraite. Le taux
                      est basé sur une table de mortalité équivalente à la TGF05. Pour plus d'informations sur la table utilisée, vous pouvez télécharger le document.
                    </Hover>
                  </Column>
                  <Column style={{ paddingTop: "40px" }} lpad>
                    <a href="/static/TGF05.pdf" target="_blank">
                      Table de mortalité TGF05
                    </a>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <TextField
                      label="Frais sur arrérages"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERContratFraisArrerage}
                      onChange={(e) => {
                        CalculCapital("StrategiePERContratFraisArrerage", e.target.value);
                      }}
                      normal
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Revalorisation de la rente"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERContratRevalorisationRente}
                      onChange={context.handleInputChange("StrategiePERContratRevalorisationRente")}
                      normal
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    />
                  </Column>
                </Row>
              </>
            )}
            {(context.state.StrategiePERChoixStrategie === "rentevscapital" ||
              (context.state.StrategiePERChoixStrategie === "prestation" && context.state.StrategiePERChoixSortie === "capitalFractionne")) && (
              <>
                <Row tpad>
                  <h1>Sortie en capital</h1>
                </Row>
                <Row>
                  <Column>
                    <TextField
                      label="Durée de fractionnement"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERContratDureeFractionnement}
                      onChange={(e) => {
                        CalculCapital("StrategiePERContratDureeFractionnement", e.target.value);
                      }}
                      normal
                      dataType="uint"
                      format={{ thousands: " ", unit: " ans" }}
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Revalorisation du capital"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.StrategiePERContratRevalorisationCapital}
                      onChange={context.handleInputChange("StrategiePERContratRevalorisationCapital")}
                      normal
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    />
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default StrategiePERContrat;
