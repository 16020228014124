import { useContext, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaFileAlt, FaPlusCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import ContratsFacultatifSelect from "components/app/AuditComplet/SituationPro/ContratsFacultatifSelect";
import ContratPrevoyancePageModal from "./contrat prevoyance modal";
import ContratSantePageModal from "./contrat sante modal";
import Row from "components/ui/layout/row/row";
import ContratFraisProPageModal from "./contrat frais pro modal";
import ContratRetraitePageModal from "./contrat retraite modal";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";

function AjoutContratFacultatifPageModal(props) {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalContratPrevOpen, setmodalContratPrevOpen] = useState(false);
  const [modalContratRetraiteOpen, setmodalContratRetraiteOpen] = useState(false);
  const [modalContratSanteOpen, setmodalContratSanteOpen] = useState(false);
  const [modalContratFraisProOpen, setmodalContratFraisProOpen] = useState(false);
  const annee = new Date().getFullYear();
  const CorrespondanceContrat = {
    Madelin: "Madelin",
    Perp: "PERP",
    Per: "PER",
    Article62: "Assurance vie",
    Article83: "Article 83",
    Pero: "PER obligatoire",
    PEE: "PEE",
    PERCO: "PERECO",
  };

  // useEffect(() => {
  //   if(modalOpen===true){
  //   context.handleResetPrevoyanceSettings();
  //   context.handleResetSanteSettings();
  //   context.handleResetContratRetraite();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalOpen]);
  return (
    <>
      <Row tpad>
        <Button onClick={() => setmodalOpen(true)}>
          <span>Ajouter un contrat</span>
          <FaPlusCircle />
        </Button>
      </Row>
      <Modal
        width="30%"
        height="50%"
        title="Ajouter un contrat"
        icon={<FaFileAlt />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ContratsFacultatifSelect
        Outil={props.Outil}
        Statut={props.Statut}
          visible={modalOpen}
          ClientConjoint={props.ClientConjoint}
          onClose={(typeContrat) => {
            setmodalOpen(false);
            if (typeContrat === "prevoyance") {
              setmodalContratPrevOpen(true);
            } else if (typeContrat === "retraite") {
              setmodalContratRetraiteOpen(true);
            } else if (typeContrat === "sante") {
              setmodalContratSanteOpen(true);
            } else if (typeContrat === "fraisPro") {
              setmodalContratFraisProOpen(true);
            }
          }}
        />
      </Modal>
      <ContratPrevoyancePageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratPrevOpen}
        Outil={props.Outil}
        PayeurPrime="Entreprise"
        Statut={props.Statut}
        ClientConjoint={props.ClientConjoint}
        CliConj={[props.CliConj]}
        onClose={() => {
          setmodalContratPrevOpen(false);
        }}
      />
      <ContratRetraitePageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratRetraiteOpen}
        Outil={props.Outil}
        Statut={props.Statut}
        PayeurPrime="Entreprise"
        ClientConjoint={props.ClientConjoint}
        CliConj={props.CliConj}
        onClose={() => {
          setmodalContratRetraiteOpen(false);
        }}
      />
      <ContratSantePageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratSanteOpen}
        Outil={props.Outil}
        PayeurPrime="Entreprise"
        Statut={props.Statut}
        ClientConjoint={props.ClientConjoint}
        onClose={() => {
          setmodalContratSanteOpen(false);
        }}
      />
      <ContratFraisProPageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratFraisProOpen}
        Outil={props.Outil}
        ClientConjoint={props.ClientConjoint}
        onClose={() => {
          setmodalContratFraisProOpen(false);
        }}
      />

      <Row tpad>
        <table>
          <thead>
            <tr>
              <th>Nature du contrat</th>
              {context.state.TypeSimulation !== "Audit prévoyance" && <th>Régime</th>}
              <th>Cotisation</th>
              {props.AccessRemExpress === false && <th>Libellé</th>}
              {props.AccessRemExpress === true && <th>Payeur de prime</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {context.state[props.Outil + "ListeContrat" + props.ClientConjoint].map(
              (Infos, Index) =>
                Infos.PayeurPrimeRetraite === "Entreprise" && (
                  <tr key={["ListeContrat" + props.ClientConjoint + Index]}>
                    <td>Retraite</td>
                    <td>{CorrespondanceContrat[Infos.Fiscalite]}</td>
                    <td>
                      {applyFormat(typeof Infos.Cotisation === "object" ? (annee in Infos.Cotisation ? Infos.Cotisation[annee].Versement : 0) : Infos.Cotisation, "uint", {
                        thousands: " ",
                        decimal: ",",
                        unit: " €",
                      })}
                    </td>
                    {props.AccessRemExpress === false && <td>{Infos.Compagnie}</td>}
                    {props.AccessRemExpress === true && <td>{Infos.PayeurPrimeRetraite === "Perso" ? "Personnel" : "Entreprise"} </td>}
                    <td>
                      <>
                        <Button
                          onClick={() => {
                            context.handleModifyDeleteContratRetraite(Infos.id, "Modifier", props.Outil, props.ClientConjoint);
                            setmodalContratRetraiteOpen(true);
                          }}
                          icon
                          style={{ display: "inline-block" }}
                        >
                          <FaEdit className="editContratFacPro"  id={"contratFac"+Infos.id} />
                        </Button>
                        <Button
                          onClick={() => {
                            Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                              context.handleModifyDeleteContratRetraite(Infos.id, "Supprimer", props.Outil, props.ClientConjoint);
                            });
                          }}
                          icon
                          style={{ display: "inline-block" }}
                        >
                          <FaTrashAlt className="delete" />
                        </Button>
                      </>
                    </td>
                  </tr>
                )
            )}
            {context.state[props.Outil + "ContratEnregistre" + props.ClientConjoint].map((Infos, Index) => (
              <tr key={["ContratEnregistre" + props.ClientConjoint + Index]}>
                <td>Prévoyance</td>
                {context.state.TypeSimulation !== "Audit prévoyance" && <td>Risques lourds</td>}
                <td>
                  {applyFormat(Number(Infos.PrevoyanceMadelin) + Number(Infos.PrevoyanceNonMadelin) + Number(Infos.Cotisation83), "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </td>
                {props.AccessRemExpress === false && <td>{Infos.LibelleContrat}</td>}
                {props.AccessRemExpress === true && <td>Entreprise </td>}
                <td>
                  {Infos.PayeurPrimePrevoyance === "Entreprise" && (
                    <>
                      <Button
                        onClick={() => {
                          context.handleModifyDeleteContratPrevoyance(Infos.id, "Modifier", "Prevoyance", props.Outil, props.ClientConjoint);
                          setmodalContratPrevOpen(true);
                        }}
                        icon
                        style={{ display: "inline-block" }}
                      >
                        <FaEdit className="editContratFacPro"   id={"contratFac"+Infos.id}/>
                      </Button>
                      <Button
                        onClick={() => {
                          Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                            context.handleModifyDeleteContratPrevoyance(Infos.id, "Supprimer", "Prevoyance", props.Outil, props.ClientConjoint);
                          });
                        }}
                        icon
                        style={{ display: "inline-block" }}
                      >
                        <FaTrashAlt className="delete" />
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
            {context.state[props.Outil + "ContratFraisPro" + props.ClientConjoint].map((Infos, Index) => (
              <tr key={["ContratFraisPro" + props.ClientConjoint + Index]}>
                <td>Frais professionnels</td>
                {context.state.TypeSimulation !== "Audit prévoyance" && <td>Risques lourds</td>}
                <td>
                  {applyFormat( Number(Infos.FGPMontant), "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </td>
                {props.AccessRemExpress === false && <td>{Infos.LibelleContrat}</td>}
                {props.AccessRemExpress === true && <td>Entreprise</td>}
                <td>
                  <Button
                    onClick={() => {
                      context.handleModifyDeleteContratPrevoyance(Infos.id, "Modifier", "Frais professionnels", props.Outil, props.ClientConjoint);
                      setmodalContratFraisProOpen(true);
                    }}
                    icon
                    style={{ display: "inline-block" }}
                  >
                    <FaEdit className="editContratFacPro"  id={"contratFac"+Infos.id} />
                  </Button>
                  <Button
                    onClick={() => {
                      Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                        context.handleModifyDeleteContratPrevoyance(Infos.id, "Supprimer", "Frais professionnels", props.Outil, props.ClientConjoint);
                      });
                    }}
                    icon
                    style={{ display: "inline-block" }}
                  >
                    <FaTrashAlt className="delete" />
                  </Button>
                </td>
              </tr>
            ))}
            {context.state[props.Outil + "ContratEnregistreSante" + props.ClientConjoint].map((Infos, Index) => (
              <tr key={["ContratEnregistreSante" + props.ClientConjoint + Index]}>
                <td>Santé</td>
                <td>Risques lourds</td>
                <td>
                  {applyFormat(Number(Infos.SanteMadelin) + Number(Infos.SanteNonMadelin) + Number(Infos.Cotisation83), "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </td>
                {props.AccessRemExpress === false && <td>{Infos.LibelleContratSante}</td>}
                {props.AccessRemExpress === true && <td>Entreprise</td>}
                <td>
                  {Infos.PayeurPrimeSante === "Entreprise" && (
                    <>
                      <Button
                        onClick={() => {
                          context.handleModifyDeleteContratSante(Infos.id, "Modifier", props.Outil, props.ClientConjoint);
                          setmodalContratSanteOpen(true);
                        }}
                        icon
                        style={{ display: "inline-block" }}
                      >
                        <FaEdit className="editContratFacPro"  id={"contratFac"+Infos.id}/>
                      </Button>
                      <Button
                        onClick={() => {
                          Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                            context.handleModifyDeleteContratSante(Infos.id, "Supprimer", props.Outil, props.ClientConjoint);
                          });
                        }}
                        icon
                        style={{ display: "inline-block" }}
                      >
                        <FaTrashAlt className="delete" />
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
    </>
  );
}

export default AjoutContratFacultatifPageModal;
