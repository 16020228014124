import React from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";

const DetailTrimestre = ({ InfosTrimestres }) => {
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
          <table>
        <thead>
          <tr>
            <th className="text-center">
              Année
            </th>
            <th className="text-center">
              Régime <br />
              unifié
            </th>
            <th className="text-center">
              Trimestres <br />
              assimilés
            </th>
            <th className="text-center">
              Cnavpl
            </th>
            <th className="text-center">
              Cnbf
            </th>
            <th className="text-center">
              Msa
            </th>
            <th className="text-center">
              Fonction
              <br /> publique
            </th>
            <th className="text-center">
              CRPCEN
              
            </th>
            <th className="text-center">
              Trimestres <br />
              retenus
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(InfosTrimestres.Trimestre).map((data, index) => {
            return (
              <tr key={"DetaiRAM" + index}>
                <td className="text-center">
                  {data[0]}
                </td>
                <td className="text-center">
                  {data[1]["RegimeUnifie"]["TrimestreRetenu"]>0 ? data[1]["RegimeUnifie"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["RegimeAssimile"]["TrimestreRetenu"]>0 ? data[1]["RegimeAssimile"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["RegimeCnavpl"]["TrimestreRetenu"]>0 ? data[1]["RegimeCnavpl"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["RegimeCnbf"]["TrimestreRetenu"]>0 ? data[1]["RegimeCnbf"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["RegimeMsa"]["TrimestreRetenu"]>0 ? data[1]["RegimeMsa"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["FonctionPublique"]["TrimestreRetenu"]>0 ? data[1]["FonctionPublique"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1]["Crpcen"]["TrimestreRetenu"]>0 ? data[1]["Crpcen"]["TrimestreRetenu"] : ""}
                </td>
                <td className="text-center">
                  {data[1].TrimestreCotisesToutRegime}
                </td>
              </tr>
            );
          })}
          <tr className="light-title">
            <td className="text-center">
              Total
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreRegimeUnifie}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreRegimeAssimile}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreRegimeCnavpl}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreRegimeCnbf}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreRegimeMsa}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreFonctionPublique}
            </td>
            <td className="text-center">
              {InfosTrimestres.TotalTrimestreCrpcen}
            </td>
            <td className="text-center">
              {Number(
                Number(InfosTrimestres["InfosRetraite"]["General"]["TotalTrimestre"]) -
                  Number(InfosTrimestres.TotalTrimestreMajorationEnfant) -
                  Number(InfosTrimestres.TrimestreMajorationAgeRegimeUnifie)
              )}
            </td>
          </tr>
        </tbody>
      </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailTrimestre;

//    <FormatLignePension
//   key={index++}
//   caisse={"RSI Commerçant"}
//   points={data[1]["General"]["TotalPoint"]}
//   valeurPoint={data[1]["General"]["ValeurPoint"]}
//   pensionTheo={data[1]["General"]["PensionTheorique"]}
//   coeff={data[1]["General"]["Coefficient"]}
//   majoEnfant={"-"}
//   pension={data[1]["General"]["Pension"]}
// />
