import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

const Divers=(props)=> {
  const { Outil } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>Divers</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <LigneImpotDetaille Libelle="Autres revenus nets imposables" VarName1={Outil + "AutresRevenus"} VarName2="" />
                <LigneImpotDetaille Libelle="Revenus soumis aux PS avec CSG déductible" VarName1={Outil + "IRDirigeantAutresRevenusSoumisPSAvecCSGDed"} VarName2="" />
                <LigneImpotDetaille Libelle="Revenus soumis aux PS sans CSG déductible" VarName1={Outil + "IRDirigeantAutresRevenusSoumisPSSansCSGDed"} VarName2="" />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default Divers;
