import { useContext, useState, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import {  FaFileSignature } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

import ContratSante from "../ContratSante/ContratSante";

const ContratSantePager = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    context.handleValueChange("PayeurPrimeSante", props.PayeurPrime);
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  return (
    <>
      <Pager
     id="contratFacSante"
        withMenu
        pages={[
     
          {
            label: "Contrat",
            icon: <FaFileSignature />,
            content: <ContratSante  AccessRemExpress={props.AccessRemExpress} Statut={props.Statut} ClientConjoint={props.ClientConjoint} Outil={props.Outil}  />,
          },
         
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              props?.onClose?.();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddContratSante(context.state.IdContratSante, props.Outil,props.ClientConjoint);
              props?.onClose?.();
            },
          },
        }}
      />
    </>
  );
};

export default ContratSantePager;
