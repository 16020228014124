import { useContext,useState } from "react";
import { FaCogs, FaHome, FaIdBadge, FaSun, FaTable, FaUmbrella, FaUniversity } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import Pager from "components/ui/controls/pager/pager";
import Row from "components/ui/layout/row/row";
import SituationProPage from "./situation professionnelle/situation professionnelle";
import { AppContext } from "components/app/RemExprertProvider";
import ContexteFiscalePage from "./contexte fiscal/contexte fiscal";
import AjoutContratPrevPersoPageModal from "./prévoyance/ajout contrat prev perso modal";
import RetraitePage from "./retraite/retraite";
import DossierPage from "./dossier/dossier";
import ResultatPage from "./résultat/resultat";
import SimulationPage from "./simulation/simulation";

const AuditCompletPage = () => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handleMenu=()=>{
    let menu=[
      {
        isTitle: true,
        title: "Audit complet",
        subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
      },
      {
        label: "Dossier",
        icon: <FaHome id="DossierAuditCompletPage" />,
        content: <DossierPage />
      },
      {
        label: "Situation professionnelle",
        icon: <FaIdBadge id="situationPro" />,
        content: <SituationProPage />,
      },
      // { LA PREVOYANCE PERSO DU TNS A DEJA ETE FAITE
      //   label: "Prévoyance",
      //   icon: <FaUmbrella />,

      //   content: <AjoutContratPrevPersoPageModal Outil="" />,
      //   disabled: false,
      // },
      {
        label: "Retraite",
        icon: <FaSun  id="retraite"  />,
        content: <RetraitePage/>
      },
      {
        label: "Contexte fiscal",
        icon: <FaUniversity />,
        content: <ContexteFiscalePage Outil="" />
      },
    ]

    if(context.state.PeriodeActuelle.length>0){
      menu=[...menu,...[ {
        label: "Résultat",
        icon: <FaTable id="resultat" />,
        content: <ResultatPage GoToSimulation={()=>setCurrentPage(6)} />
      },
      {
        label: "Simulation",
        hidden:true,
        icon: <FaCogs />,
        content: <SimulationPage />
      },]]
    }
    return menu;
  }
  return (
    <Pager
      withMenu
      pages={handleMenu()}
      page={currentPage}
      onPageChange={(page)=>{setCurrentPage(page)}}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default AuditCompletPage;
