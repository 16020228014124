import axios from "axios";
import { RemoveUnecessaryData, ApiUrl } from "./ApiData";
import { GetFormattedDate } from "./Retraite/ApiRetraite";
import { CalculRetraite } from "./Retraite/ApiRetraite";
const APIURL = ApiUrl;

export function OpenDossier(IdDossier) {
  var formData = new FormData();

  formData.append("IdDossier", IdDossier);

  return axios({
    method: "post",
    url: `${APIURL}Api/ReadDossier.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(ListeClient => {
    return ListeClient.data;
  });
}

export function SaveDossierPartner(Data) {
  let CleanData = RemoveUnecessaryData(Data);

  CalculRetraite(CleanData,"", CleanData.CarriereSalaireReference, "").then(ResultatRetraite => {
    let Res = ResultatRetraite.RetraiteChoixDepartRetraite;
    if (CleanData.Partner === "PLPSOFT") {
      let DonneesPlpSoft = {
        IdConseillerPlp: CleanData["IdConseillerPlp"],
        IdDossierPlp: CleanData["IdDossierPlp"],
        LibelleDossier: "",
        IdClientPlp: CleanData["IdClientPlp"],
        DateDepartRetraite: GetFormattedDate(new Date(Res.Infos.DateDepartRetraite)),
        AgeDepartRetraite: Res.Infos.AgeDepartRetraite,
        TotalTrimestre: Res.TotalTrimestre,
        TotalPensionBrute: Res.TotalPensionBrute,
        TotalReversionBrute: Res.TotalReversion,
        TotalPensionNette: Res.TotalPensionNette,
        TotalReversionNette: Res.TotalReversionNette,
        TotalRenteBrute: Res.TotalRente,
        TotalRenteNette: Res.TotalRenteNette,
        TotalCapitalBrut: Res.TotalCapitalBrut,
        TotalCapitalNette: Res.TotalCapital,
        TotalCapitalFractionneBrut: Res.TotalCapitalFractionneBrut,
        TotalCapitalFractionneNette: Res.TotalCapitalFractionne
      };
      return axios({
        method: "post",
        url: "https://haumeal.plpsoft.com/interfaces/mako/retour-bilan-retraite.php",
        // url: 'https://preprod-haumeal.plpsoft.com/interfaces/mako/retour-bilan-retraite.php',
        //  url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
        data: {
          DonneesPlpSoft: DonneesPlpSoft,
          DonneesMako: Data
        }
      });
    }
    if (CleanData.Partner === "MAHAKA") {
      let DonneesMahaka = {
        PartnerIdConseiller: CleanData["PartnerIdConseiller"],
        PartnerIdDossier: CleanData["PartnerIdDossier"],
        PartnerLibelleDossier: CleanData["LibelleDossier"],
        PartnerIdClient: CleanData["PartnerIdClient"],
        PartnerQid: CleanData["PartnerQid"]
        // DateDepartRetraite: GetFormattedDate(new Date(Res.Infos.DateDepartRetraite)),
        // AgeDepartRetraite: Res.Infos.AgeDepartRetraite,
        // TotalTrimestre: Res.TotalTrimestre,
        // TotalPensionBrute: Res.TotalPensionBrute,
        // TotalReversionBrute: Res.TotalReversion,
        // TotalPensionNette: Res.TotalPensionNette,
        // TotalReversionNette: Res.TotalReversionNette,
        // TotalRenteBrute: Res.TotalRente,
        // TotalRenteNette: Res.TotalRenteNette,
        // TotalCapitalBrut: Res.TotalCapitalBrut,
        // TotalCapitalNette: Res.TotalCapital,
        // TotalCapitalFractionneBrut: Res.TotalCapitalFractionneBrut,
        // TotalCapitalFractionneNette: Res.TotalCapitalFractionne
      };
      axios({
        method: "post",
        url: "https://api.mahaka.fr/api/Mako/Save",
        data: {
          DonneesMahaka: DonneesMahaka,
          DonneesMako: Data
        }
      }).catch(error => {
        console.log(error.response);
      });
    }
  });
}
export function SaveAsDossierPartner(Data) {
  let CleanData = RemoveUnecessaryData(Data);
  return new Promise((resolve, reject) => {
    CalculRetraite(CleanData,"", CleanData.CarriereSalaireReference, "").then(ResultatRetraite => {
      let Res = ResultatRetraite.RetraiteChoixDepartRetraite;

      if (CleanData.Partner === "PLPSOFT") {
        let DonneesPlpSoft = {
          IdConseillerPlp: CleanData["IdConseillerPlp"],
          IdDossierPlp: 0,
          LibelleDossier: CleanData["LibelleDossier"],
          IdClientPlp: CleanData["IdClientPlp"],
          DateDepartRetraite: GetFormattedDate(new Date(Res.Infos.DateDepartRetraite)),
          AgeDepartRetraite: Res.Infos.AgeDepartRetraite,
          TotalTrimestre: Res.TotalTrimestre,
          TotalPensionBrute: Res.TotalPensionBrute,
          TotalReversionBrute: Res.TotalReversion,
          TotalPensionNette: Res.TotalPensionNette,
          TotalReversionNette: Res.TotalReversionNette,
          TotalRenteBrute: Res.TotalRente,
          TotalRenteNette: Res.TotalRenteNette,
          TotalCapitalBrut: Res.TotalCapitalBrut,
          TotalCapitalNette: Res.TotalCapital,
          TotalCapitalFractionneBrut: Res.TotalCapitalFractionneBrut,
          TotalCapitalFractionneNette: Res.TotalCapitalFractionne
        };
        axios({
          method: "post",
          url: "https://haumeal.plpsoft.com/interfaces/mako/retour-bilan-retraite.php",
          // url: 'https://preprod-haumeal.plpsoft.com/interfaces/mako/retour-bilan-retraite.php',
          // url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
          data: {
            DonneesPlpSoft: DonneesPlpSoft,
            DonneesMako: Data
          }
        })
          .catch(error => {
            console.log(error.response);
          })
          .then(IdDossier => {
            resolve(IdDossier);
          });
      }
      if (CleanData.Partner === "MAHAKA") {
        let DonneesMahaka = {
          PartnerIdConseiller: CleanData["PartnerIdConseiller"],
          PartnerIdDossier: 0,
          PartnerLibelleDossier: CleanData["LibelleDossier"],
          PartnerIdClient: CleanData["PartnerIdClient"],
          PartnerQid: CleanData["PartnerQid"]
          // DateDepartRetraite: GetFormattedDate(new Date(Res.Infos.DateDepartRetraite)),
          // AgeDepartRetraite: Res.Infos.AgeDepartRetraite,
          // TotalTrimestre: Res.TotalTrimestre,
          // TotalPensionBrute: Res.TotalPensionBrute,
          // TotalReversionBrute: Res.TotalReversion,
          // TotalPensionNette: Res.TotalPensionNette,
          // TotalReversionNette: Res.TotalReversionNette,
          // TotalRenteBrute: Res.TotalRente,
          // TotalRenteNette: Res.TotalRenteNette,
          // TotalCapitalBrut: Res.TotalCapitalBrut,
          // TotalCapitalNette: Res.TotalCapital,
          // TotalCapitalFractionneBrut: Res.TotalCapitalFractionneBrut,
          // TotalCapitalFractionneNette: Res.TotalCapitalFractionne
        };
        axios({
          method: "post",
          url: "https://api.mahaka.fr/api/Mako/Save",
          data: {
            DonneesMahaka: DonneesMahaka,
            DonneesMako: Data
          }
        })
          .catch(error => {
            console.log(error.response);
          })
          .then(IdDossier => {
            resolve(IdDossier);
          });
      }
    });
  });
}
export function SaveDossierPartnerRemExpress(Data) {
  let CleanData = RemoveUnecessaryData(Data);

  if (CleanData.Partner === "PLPSOFT") {
    let DonneesPlpSoft = {
      IdConseillerPlp: CleanData["IdConseillerPlp"],
      IdDossierPlp: CleanData["IdDossierPlp"],
      LibelleDossier: "",
      IdClientPlp: CleanData["IdClientPlp"]
    };
    return axios({
      method: "post",
      url: "https://haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php",
      // url: 'https://preprod-haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php',
      //  url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
      data: {
        DonneesPlpSoft: DonneesPlpSoft,
        DonneesMako: Data
      }
    });
  }
  if (CleanData.Partner === "MAHAKA") {
    let DonneesMahaka = {
      PartnerIdConseiller: CleanData["PartnerIdConseiller"],
      PartnerIdDossier: CleanData["PartnerIdDossier"],
      PartnerLibelleDossier: CleanData["LibelleDossier"],
      PartnerIdClient: CleanData["PartnerIdClient"],
      PartnerQid: CleanData["PartnerQid"]
    };
    axios({
      method: "post",
      url: "https://api.mahaka.fr/api/Mako/Save",
      data: {
        DonneesMahaka: DonneesMahaka,
        DonneesMako: Data
      }
    }).catch(error => {
      console.log(error.response);
    });
  }
}
export function SaveAsDossierPartnerRemExpress(Data) {
  let CleanData = RemoveUnecessaryData(Data);

  if (CleanData.Partner === "PLPSOFT") {
    let DonneesPlpSoft = {
      IdConseillerPlp: CleanData["IdConseillerPlp"],
      IdDossierPlp: 0,
      LibelleDossier: CleanData["LibelleDossier"],
      IdClientPlp: CleanData["IdClientPlp"]
    };
    return axios({
      method: "post",
      url: "https://haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php",
      // url: 'https://preprod-haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php',
      // url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
      data: {
        DonneesPlpSoft: DonneesPlpSoft,
        DonneesMako: Data
      }
    })
      .catch(error => {
        console.log(error.response);
      })
      .then(IdDossier => {
        return IdDossier;
      });
  }
  if (CleanData.Partner === "MAHAKA") {
    let DonneesMahaka = {
      PartnerIdConseiller: CleanData["PartnerIdConseiller"],
      PartnerIdDossier: 0,
      PartnerLibelleDossier: CleanData["LibelleDossier"],
      PartnerIdClient: CleanData["PartnerIdClient"],
      PartnerQid: CleanData["PartnerQid"]
    };
    return axios({
      method: "post",
      url: "https://api.mahaka.fr/api/Mako/Save",
      data: {
        DonneesMahaka: DonneesMahaka,
        DonneesMako: Data
      }
    })
      .catch(error => {
        console.log(error.response);
      })
      .then(IdDossier => {
        return IdDossier;
      });
  }
}

export function SaveDossierPartnerPrevTns(Data, Resultat) {
  let CleanData = RemoveUnecessaryData(Data);

  // if (CleanData.Partner === "PLPSOFT") {
  //   let DonneesPlpSoft = {
  //     IdConseillerPlp: CleanData["IdConseillerPlp"],
  //     IdDossierPlp: CleanData["IdDossierPlp"],
  //     LibelleDossier: "",
  //     IdClientPlp: CleanData["IdClientPlp"]
  //   };
  //   return axios({
  //     method: "post",
  //     url: "https://haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php",
  //     // url: 'https://preprod.haumeal.com/interfaces/mako/retour-remexpress.php',
  //     //  url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
  //     data: {
  //       DonneesPlpSoft: DonneesPlpSoft,
  //       DonneesMako: Data
  //     }
  //   });
  // }
  if (CleanData.Partner === "MAHAKA") {
    let DonneesMahaka = {
      PartnerIdConseiller: CleanData["PartnerIdConseiller"],
      PartnerIdDossier: CleanData["PartnerIdDossier"],
      PartnerLibelleDossier: CleanData["LibelleDossier"],
      PartnerIdClient: CleanData["PartnerIdClient"],
      PartnerQid: CleanData["PartnerQid"]
    };
    axios({
      method: "post",
      url: "https://api.mahaka.fr/api/Mako/Save",
      data: {
        DonneesMahaka: DonneesMahaka,
        DonneesMako: Data
      }
    }).catch(error => {
      console.log(error.response);
    });
  }
}

export function SaveAsDossierPartnerPrevTns(Data, Resultat) {
  let CleanData = RemoveUnecessaryData(Data);

  // if (CleanData.Partner === "PLPSOFT") {
  //   let DonneesPlpSoft = {
  //     IdConseillerPlp: CleanData["IdConseillerPlp"],
  //     IdDossierPlp: 0,
  //     LibelleDossier: CleanData["LibelleDossier"],
  //     IdClientPlp: CleanData["IdClientPlp"]
  //   };
  //   axios({
  //     method: "post",
  //     url: "https://haumeal.plpsoft.com/interfaces/mako/retour-remexpress.php",
  //     // url: 'https://preprod.haumeal.com/interfaces/mako/retour-remexpress.php',
  //     // url: 'https://www.dev.mako-remexpert.com/ApiMako/V3/Api/Plp/test.php',
  //     data: {
  //       DonneesPlpSoft: DonneesPlpSoft,
  //       DonneesMako: Data
  //     }
  //     // headers: {
  //     //   'Access-Control-Allow-Origin': '*',
  //     //   // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
  //     // },
  //   }).catch(error => {
  //     console.log(error.response);
  //   });
  // }
  if (CleanData.Partner === "MAHAKA") {
    let DonneesMahaka = {
      PartnerIdConseiller: CleanData["PartnerIdConseiller"],
      PartnerIdDossier: 0,
      PartnerLibelleDossier: CleanData["LibelleDossier"],
      PartnerIdClient: CleanData["PartnerIdClient"],
      PartnerQid: CleanData["PartnerQid"]
    };
    return axios({
      method: "post",
      url: "https://api.mahaka.fr/api/Mako/Save",
      data: {
        DonneesMahaka: DonneesMahaka,
        DonneesMako: Data
      }
    })
      .catch(error => {
        console.log(error.response);
      })
      .then(IdDossier => {
        return IdDossier;
      });
  }
}
