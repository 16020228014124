import { useCallback, useContext, useEffect, useState } from "react";
import { FaCog, FaHome, FaSun, FaTable } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DossierPage from "pages/audit complet/dossier/dossier";
import RetraitePage from "pages/audit complet/retraite/retraite";
import ResultatPageRetraite from "./resultat/resultatRetraite";
import RachatTrimestrePage from "./resultat/rachatTrimestre";
import RetraiteProgressivePage from "./resultat/retraiteProgressive";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";

const AuditRetraitePage = () => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [currentPage, setCurrentPage] = useState(1);
  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;
        let Resultat = {
          RetraiteBrute:
            ResultatTNS.state.Retraite?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute === undefined ? 0 : ResultatTNS.state.Retraite?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute,
          RetraiteNette:
            ResultatTNS.state.Retraite?.InfosRetraiteChoixDepart?.TotalRetraiteNetteChoixDepartRetraite === undefined
              ? 0
              : ResultatTNS.state.Retraite?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute,
          RetraiteBruteConjoint:
            ResultatTNS.state.RetraiteConjoint?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute === undefined
              ? 0
              : ResultatTNS.state.RetraiteConjoint?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute,
          RetraiteNetteConjoint:
            ResultatTNS.state.RetraiteConjoint?.InfosRetraiteChoixDepart?.TotalRetraiteNetteChoixDepartRetraite === undefined
              ? 0
              : ResultatTNS.state.RetraiteConjoint?.InfosRetraiteChoixDepart?.TotalPensionRenteBrute,
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state, ResultatTNS.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handlePages = () => {
    let pages = [];
    if (context.state.ApiUser === true) {
      pages = [
        {
          isTitle: true,
          title: "Bilan retraite",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Carrière",
          icon: <FaSun id="carriere" />,
          content: <RetraitePage />,
        },
        {
          label: "Résultat " + context.state.Prenom,
          icon: <FaTable id="resultatRetraite" />,
          content: <ResultatPageRetraite ClientConjoint="" />,
        },
        {
          label: "Rachat de trimestre(s) " + context.state.Prenom,
          icon: <FaCog />,
          content: <RachatTrimestrePage ClientConjoint="" />,
        },
        {
          label: "Retraite progressive " + context.state.Prenom,
          icon: <FaCog />,
          content: <RetraiteProgressivePage ClientConjoint="" />,
        },
      ];
      if (context.state.InclureConjoint === true) {
        pages = [
          {
            isTitle: true,
            title: "Bilan retraite",
            subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
          },
          {
            label: "Carrière",
            icon: <FaSun />,
            content: <RetraitePage />,
          },
          {
            label: "Résultat " + context.state.Prenom,
            icon: <FaTable />,
            content: <ResultatPageRetraite ClientConjoint="" />,
          },
          {
            label: "Résultat " + context.state.PrenomConjoint,
            icon: <FaTable id="resultatRetraiteConjoint" />,
            content: <ResultatPageRetraite ClientConjoint="Conjoint" />,
          },
          {
            label: "Rachat de trimestre(s) " + context.state.Prenom,
            icon: <FaCog />,
            content: <RachatTrimestrePage ClientConjoint="" />,
          },
          {
            label: "Rachat de trimestre(s) " + context.state.PrenomConjoint,
            icon: <FaCog />,
            content: <RachatTrimestrePage ClientConjoint="Conjoint" />,
          },
          context.state.DisplayRetraiteProgressive === true && {
            label: "Retraite progressive " + context.state.Prenom,
            icon: <FaCog />,
            content: <RetraiteProgressivePage ClientConjoint="" />,
          },
          context.state.DisplayRetraiteProgressiveConjont === true && {
            label: "Retraite progressive " + context.state.PrenomConjoint,
            icon: <FaCog />,
            content: <RetraiteProgressivePage ClientConjoint="Conjoint" />,
          },
        ];
      }
    } else {
      pages = [
        {
          isTitle: true,
          title: "Bilan retraite",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Dossier",
          icon: <FaHome id="DossierAuditRetraitePage" />,
          content: <DossierPage />,
        },
        {
          label: "Carrière",
          icon: <FaSun id="carriere" />,
          content: <RetraitePage />,
        },
        {
          label: "Résultat " + context.state.Prenom,
          icon: <FaTable id="resultatRetraite" />,
          content: <ResultatPageRetraite ClientConjoint="" />,
        },
        {
          label: "Rachat de trimestre(s) " + context.state.Prenom,
          icon: <FaCog />,
          content: <RachatTrimestrePage ClientConjoint="" />,
        },
        context.state.DisplayRetraiteProgressive === true && {
          label: "Retraite progressive " + context.state.Prenom,
          icon: <FaCog />,
          content: <RetraiteProgressivePage ClientConjoint="" />,
        },
      ];
      if (context.state.InclureConjoint === true) {
        pages = [
          {
            isTitle: true,
            title: "Bilan retraite",
            subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
          },
          {
            label: "Dossier",
            icon: <FaHome id="DossierAuditRetraitePage" />,
            content: <DossierPage />,
          },
          {
            label: "Carrière",
            icon: <FaSun id="carriere" />,
            content: <RetraitePage />,
          },
          {
            label: "Résultat " + context.state.Prenom,
            icon: <FaTable id="resultatRetraite" />,
            content: <ResultatPageRetraite ClientConjoint="" />,
          },
          {
            label: "Résultat " + context.state.PrenomConjoint,
            icon: <FaTable />,
            content: <ResultatPageRetraite ClientConjoint="Conjoint" />,
          },
          {
            label: "Rachat de trimestre(s) " + context.state.Prenom,
            icon: <FaCog />,
            content: <RachatTrimestrePage ClientConjoint="" />,
          },
          {
            label: "Rachat de trimestre(s) " + context.state.PrenomConjoint,
            icon: <FaCog />,
            content: <RachatTrimestrePage ClientConjoint="Conjoint" />,
          },
          context.state.DisplayRetraiteProgressive === true && {
            label: "Retraite progressive " + context.state.Prenom,
            icon: <FaCog />,
            content: <RetraiteProgressivePage ClientConjoint="" />,
          },
          context.state.DisplayRetraiteProgressiveConjoint === true && {
            label: "Retraite progressive " + context.state.PrenomConjoint,
            icon: <FaCog />,
            content: <RetraiteProgressivePage ClientConjoint="Conjoint" />,
          },
        ];
      }
    }

    return pages;
  };

  return (
    <Pager
      withMenu
      pages={handlePages()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default AuditRetraitePage;
