import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CheckBox from "components/ui/controls/check-box/check-box";

const Enfants = () => {
  const context = useContext(AppContext);
  const chargeEnfant = [
    {
      value: "exclusive",
      label: "A charge exclusive",
    },
    {
      value: "partagee",
      label: "A charge partagée",
    },
    {
      value: "none",
      label: "Plus à charge",
    },
  ];

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <TextField label="Nombre d'enfants" value={context.state.Enfant} onChange={context.handleCreationTableauEnfant} dataType="uint" small />
            </Row>
            <Row tpad>
              {context.state.TableauEnfant.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Prénom</th>
                      <th scope="col">Date de naissance</th>
                      <th scope="col">Situation fiscale</th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.TableauEnfant.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            <TextField value={data.Prenom} onChange={(e) => context.handleModificationEnfant(index, "Prenom", e)} normal />
                          </td>
                          <td>
                            <TextField value={data.DateNaissance} onChange={(e) => context.handleModificationEnfant(index, "DateNaissance", e)} dataType="date" normal />
                          </td>
                          <td>
                            <DropDown
                              value={data.Charge}
                              options={chargeEnfant}
                              onChange={(e) => context.handleModificationEnfant(index, "Charge", e)}
                              normal
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default Enfants;
