import React from "react";
import { useContext } from "react";
// import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaDownload, FaSync, FaUpload } from "react-icons/fa";
import FileField from "components/ui/controls/file-field/file-field";
import { ModalContext2, RISContext } from "./RISProvider";
import { ModalContext } from "components/ui/modal/modal-provider";
import { AppContext } from "components/app/RemExprertProvider";
import queryString from "query-string";
import { AnalyseCarriereWord } from "components/app/Api/Rapport/ApiRapport";
import { Client } from "components/app/Api/ApiUser";
// import { ModalContext } from "components/ui/modal/modal-provider";

const LectureRIS = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  const RIS = useContext(RISContext);
  const Params = queryString.parse(window.location.search);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              {Params.Partner === "PLPSOFT" && (
                <blockquote>
                  Seul le relevé "tous régimes" directement téléchargé du site de l'assurance retraite à compter du 1er juillet 2021 doit être importé dans le logiciel (
                  <a target="_blank" href="https://www.plpsoft.com/espace-documentaire/tutoriels/haumeal/module_retraite/simulateur-bilan-retraite.html">
                    procédure
                  </a>
                  ). Nous vous avertissons que les scans ou les fichiers convertis par des applications (notamment sur Mac), ne fonctionneront pas. En dehors de ces cas et en cas de
                  difficulté, nous vous invitons à nous transférer le relevé par mail.
                </blockquote>
              )}
              {Params.Partner !== "PLPSOFT" && (
                <blockquote>
                  Seul le relevé "tous régimes" directement téléchargé du site de l'assurance retraite à compter du 1er juillet 2021 doit être importé dans le logiciel (
                  <a target="_blank" href=" https://api-v3.mako-box.com/ApiMako/V3/Documents/Procédure d'import.pdf">
                    procédure
                  </a>
                  ). Nous vous avertissons que les scans ou les fichiers convertis par des applications (notamment sur Mac), ne fonctionneront pas. En dehors de ces cas et en cas de
                  difficulté, nous vous invitons à nous transférer le relevé par mail.
                </blockquote>
              )}
            </Row>
            <Row tpad bpad>
              <FileField
                label={"Chargement fichier"}
                value=""
                onChange={RIS.handleUploadRIS(context.state)}
                // onChange={(evt) => {
                //   console.log(evt.target.files[0]);
                // }}
                normal
              >
                <span>Envoyer un fichier</span>
                <FaUpload />
              </FileField>
            </Row>
            {RIS.ErrorMessage !== "" && (
              <Row tpad>
                <blockquote style={{ borderLeft: "5px solid red" }}>{RIS.ErrorMessage}</blockquote>
              </Row>
            )}
            {RIS.SuccessMessage === true && (
              <>
              {console.log(RIS)}
                <Row tpad>
                  <blockquote style={{ borderLeft: "5px solid green" }}>
                    Votre rélévé individuel de situation a bien été importé.
                    <br />
                    Des précisions peuvent être apportées en cliquant dans l'onglet suivant.
                  </blockquote>
                </Row>
                <Row tpad>
                  <Button
                    onClick={() => {
                      AnalyseCarriereWord(context.state, RIS.InfosRIS,ClientConjoint).then((res) => {});
                    }}
                  >
                    <span>Editer l'analyse de la carrière pour {context.state['Prenom'+ClientConjoint]}</span>
                    <FaDownload />
                  </Button>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default LectureRIS;
