import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaFileAlt } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import ContratsFacultatif from "components/app/AuditComplet/SituationPro/ContratsFacultatif";
import _ from "lodash";

const RemExpressContratFacProModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [contratsRetrActuelle, setContratsRetrActuelle] = useState([]);
  const [contratsPrevActuelle, setContratsPrevActuelle] = useState([]);
  const [contratsSanteActuelle, setContratsSanteActuelle] = useState([]);
  const [contratsFraisProActuelle, setContratsFraisProActuelle] = useState([]);

  //On garde les contrats qui on été saisie en cas d'annulation de la saisie de la periode.
  useEffect(() => {
    if (props.visible === true) {
      // if (context.state.ListeContrat.length > 0) {
      setContratsRetrActuelle(_.cloneDeep(context.state.ListeContrat));
      setContratsPrevActuelle(_.cloneDeep(context.state.ContratEnregistre));
      setContratsSanteActuelle(_.cloneDeep(context.state.ContratEnregistreSante));
      setContratsFraisProActuelle(_.cloneDeep(context.state.ContratFraisPro));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const handleStatut = () => {
    let Statut = "TNS Article 62";

    if (props.Outil==="OptiRem") {
      if (props.ClientConjoint === "" && context.state.OptiRemPeriodeActuelle?.length > 0) {
        Statut = context.state.OptiRemPeriodeActuelle[0].Statut;
      }
    }else{
      Statut=context.state.Statut;
    }
    return Statut;
  };
  return (
    <>
      <Pager
      id='contratFacProAuditRemPager'
        withMenu
        pages={[
          {
            label: "Contrats facultatifs",
            icon: <FaFileAlt />,
            content: <ContratsFacultatif Statut={handleStatut()} Outil={props.Outil} AccessRemExpress={true} ClientConjoint={""} />,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              context.handleValueChange("ListeContrat", contratsRetrActuelle);
              context.handleValueChange("ContratEnregistre", contratsPrevActuelle);
              context.handleValueChange("ContratEnregistreSante", contratsSanteActuelle);
              context.handleValueChange("ContratFraisPro", contratsFraisProActuelle);
              props.onClose();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default RemExpressContratFacProModal;
