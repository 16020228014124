import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaEuroSign, FaFileAlt, FaIdBadge, FaMoneyBillWave, FaUniversity } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import SituationPro from "components/app/AuditComplet/SituationPro/SituationPro";
import Entreprise from "components/app/AuditComplet/SituationPro/Entreprise";
import Remuneration from "components/app/AuditComplet/SituationPro/Remuneration";
import Dividendes from "components/app/AuditComplet/SituationPro/Dividendes";
import ContratsFacultatif from "components/app/AuditComplet/SituationPro/ContratsFacultatif";
import _ from "lodash";

const SituationProPageModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [disableValidate, setDisableValidate] = useState(false);
  const [contratsRetrActuelle, setContratsRetrActuelle] = useState([]);
  const [contratsPrevActuelle, setContratsPrevActuelle] = useState([]);
  const [contratsSanteActuelle, setContratsSanteActuelle] = useState([]);
  const [contratsFraisProActuelle, setContratsFraisProActuelle] = useState([]);
  const [contratsRetrActuelleConjoint, setContratsRetrActuelleConjoint] = useState([]);
  const [contratsPrevActuelleConjoint, setContratsPrevActuelleConjoint] = useState([]);
  const [contratsSanteActuelleConjoint, setContratsSanteActuelleConjoint] = useState([]);
  const [contratsFraisProActuelleConjoint, setContratsFraisProActuelleConjoint] = useState([]);

  useEffect(() => {
    setCurrentPage(0);
    if (props.ClientConjoint === "Conjoint" && context.state.FinCarriereActuelle === "DepartRetraite") {
      context.handleValueChange("FinPeriodeCarriereActuelle", context.state.DateDepartRetraiteConjoint.replace(/\//g, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  //On garde les contrats qui on été saisie en cas d'annulation de la saisie de la periode.
  useEffect(() => {
    if (props.visible === true) {
      // if (context.state.ListeContrat.length > 0) {
        setContratsRetrActuelle(_.cloneDeep(context.state.ListeContrat));
        setContratsPrevActuelle(_.cloneDeep(context.state.ContratEnregistre));
        setContratsSanteActuelle(_.cloneDeep(context.state.ContratEnregistreSante));
        setContratsFraisProActuelle(_.cloneDeep(context.state.ContratFraisPro));
        setContratsRetrActuelleConjoint(_.cloneDeep(context.state.ListeContratConjoint));
        setContratsPrevActuelleConjoint(_.cloneDeep(context.state.ContratEnregistreConjoint));
        setContratsSanteActuelleConjoint(_.cloneDeep(context.state.ContratEnregistreSanteConjoint));
        setContratsFraisProActuelleConjoint(_.cloneDeep(context.state.ContratFraisProConjoint));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const handleEndGreaterBegin = () => {
    let DebutPeriode = context.state.DebutPeriodeCarriereActuelle;
    let FinPeriode = context.state.FinPeriodeCarriereActuelle;
    if (DebutPeriode.length === 8 && FinPeriode.length === 8) {
      let AnneeDebutPeriode = DebutPeriode.slice(4);
      let MoisDebutPeriode = DebutPeriode.slice(2, 4) - 1;
      let JourDebutPeriode = DebutPeriode.slice(0, 2);

      let DateDebutPeriode = new Date();
      DateDebutPeriode.setFullYear(AnneeDebutPeriode, MoisDebutPeriode, JourDebutPeriode);

      let DateFinPeriode = new Date();
      DateFinPeriode.setFullYear(FinPeriode.slice(4), FinPeriode.slice(2, 4) - 1, FinPeriode.slice(0, 2));

      DateDebutPeriode.setHours(0, 0, 0, 0);
      DateFinPeriode.setHours(24, 0, 0, 0);

      if (DateFinPeriode.getTime() <= DateDebutPeriode.getTime()) {
        setDisableValidate(true);
      }else{
        setDisableValidate(false);
      }
    }else{
      setDisableValidate(true);
    }
  };
  useEffect(() => {
    handleEndGreaterBegin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.DebutPeriodeCarriereActuelle, context.state.FinPeriodeCarriereActuelle,context.state.FinCarriereActuelle]);

  const handleMenu=()=>{
    let menu =[
      {
        label: "Situation professionnelle",
        icon: <FaIdBadge />,
        content: <SituationPro ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Entreprise",
        icon: <FaUniversity />,
        content: <Entreprise ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Rémunération",
        icon: <FaEuroSign />,
        content: <Remuneration ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Dividendes",
        icon: <FaMoneyBillWave />,
        content: <Dividendes Outil={""} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Contrats facultatifs",
        icon: <FaFileAlt id="contratsFacPro" />,
        content: <ContratsFacultatif  Statut={context.state.Statut} Outil="" ClientConjoint={props.ClientConjoint} />,
      },
    ];
    if(context.state.Statut==="TNS individuel"){
      menu = menu.filter((k) => k.label !== "Dividendes");
    }
    return menu
  }
  return (
    <>
      <Pager
      id="situationProPager"
        withMenu
        validateDisabled={disableValidate}
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              context.handleValueChange("ListeContrat", contratsRetrActuelle);
              context.handleValueChange("ContratEnregistre", contratsPrevActuelle);
              context.handleValueChange("ContratEnregistreSante", contratsSanteActuelle);
              context.handleValueChange("ContratFraisPro", contratsFraisProActuelle);
              context.handleValueChange("ListeContratConjoint", contratsRetrActuelleConjoint);
              context.handleValueChange("ContratEnregistreConjoint", contratsPrevActuelleConjoint);
              context.handleValueChange("ContratEnregistreSanteConjoint", contratsSanteActuelleConjoint);
              context.handleValueChange("ContratFraisProConjoint", contratsFraisProActuelleConjoint);
              context.handleResetPeriodeActuelle();
              props.onClose();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddPeriodeActuelle(context.state.IdPeriodeActuelle, "", props.ClientConjoint);
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default SituationProPageModal;
