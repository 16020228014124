import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Modal from "components/ui/modal/modal";
import { FaGlobe } from "react-icons/fa";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import Pager from "components/ui/controls/pager/pager";

function ModalEpgAcquise(props) {
  const context = useContext(AppContext);
  return (
    <>
      <Modal
        width="30%"
        height="50%"
        title="EPARGNE ACQUISE"
        icon={<FaGlobe />}
        visible={props.visible}
        onClose={() => {
          props.onClose();
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column>
                          <TextField
                            label="Part représentative des versements"
                            labelStyle={{ width: "260px" }}
                            value={context.state.EpargneAcquiseVersementContratRetraite}
                            onChange={context.handleInputChange("EpargneAcquiseVersementContratRetraite")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            label="Part représentative des produits"
                            labelStyle={{ width: "260px" }}
                            value={context.state.EpargneAcquiseProduitContratRetraite}
                            onChange={context.handleInputChange("EpargneAcquiseProduitContratRetraite")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                props?.onClose?.();
              },
            },
          }}
        />
      </Modal>
    </>
  );
}

export default ModalEpgAcquise;
