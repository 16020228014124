import { useCallback, useContext, useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import CorrectionPER from "components/app/Simulateurs/Correction PER/CorrectionPER";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";

const CorrectionPlafondPERPage = () => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(context.state.CorrectionPER);
  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;

        let Resultat = {
          DonneesSaisies: context.state.CorrectionPER,
          PlafondClient: context.state.CorrectionPERPlafondClient,
          PlafondConjoint: context.state.CorrectionPERPlafondConjoint,
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handleMenu = () => {
    let menu = [
      {
        isTitle: true,
        title: "",
      },
      {
        // label: "Dossier",
        // icon: <FaHome id="DossierAuditCompletPage" />,
        content: <CorrectionPER />,
      },
    ];

    return menu;
  };
  return (
    <Pager
      pages={handleMenu()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default CorrectionPlafondPERPage;
