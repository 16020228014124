import CardBox from "components/ui/controls/card-box/card-box";
import { applyFormat } from "components/ui/ui-helpers";

const RemunerationSalarie = (props) => {
    let {Resultat,Prenom}=props;

  return (
    <>
      <CardBox tpad bpad>
        <table className="resultat">
          <tbody>
            <tr>
              <td>Budget rémunération {Prenom}</td>
              <td>{applyFormat(Resultat.CoutRemuneration, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Cotisations patronales</td>
              <td>{applyFormat(Resultat.TotalChargesPatronale, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Salaire brut</td>
              <td>{applyFormat(Resultat.SalaireBrut, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Cotisations salariales</td>
              <td>{applyFormat(Resultat.TotalChargesSalariale, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Salaire net</td>
              <td>{applyFormat(Resultat.SalaireNet, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
            <tr>
              <td>Salaire imposable</td>
              <td>{applyFormat(Resultat.BrutImposable, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          </tbody>
        </table>
      </CardBox>
    </>
  );
};

export default RemunerationSalarie;
