import { createContext, useState } from "react";
import Dialogs from "../ui/dialogs/dialogs";
import Subordinates from "../ui/login/subordinates";
import Modal from "../ui/modal/modal";
import { FaBuilding, FaShieldAlt, FaUser } from "react-icons/fa";
import Companies from "../ui/login/companies";
import Password from "../ui/login/password";
import Api from "../../api/api";
import Login from "../ui/login/login";

/**
 * Modal context.
 */
export const AuthContextApi = createContext();

/**
 * Generates an authentication context provider component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const AuthProviderApi = (props) => {
  // Initialize states
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [association, setAssociation] = useState(null);
  const [relation, setRelation] = useState(null);
  const [settings, setSettings] = useState(null);

  // Render component
  return (
    <AuthContextApi.Provider
      value={{
        utilisateur_identifie: authUser,
        utilisateur_reel: user,
        cabinet: company,
        groupement: association,
        relation: relation,
        preferences: settings,
      }}
    >
      {props.children}
    </AuthContextApi.Provider>
  );
};
export default AuthProviderApi;
