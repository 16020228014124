import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { applyFormat } from 'components/ui/ui-helpers';

const borderColor = '#f4f5f5'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 8,
        fontStyle: 'bold',
    },
    row2: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 8,
        backgroundColor: '#4285F4',
        color:"white",
        fontFamily: 'Helvetica-Bold',
    },
    description: {
        width: '87%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '13%',
        textAlign: 'right',
        paddingRight: 8,
       
    },
  });


const InvoiceTableFooter = ({items}) => {
    const totalHT = items.map(item => item.total_pre_tax_amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)

    const TVA = items.map(item => item.total_tax_amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
        
    const totalTTC = items.map(item => item.total_amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
   
        return(
         <>  
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL HT</Text>
            <Text style={styles.total}>{ applyFormat(totalHT, "ufloat", { precision:2,thousands: " ", decimal: ",", unit: " €" })}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description}>TVA 20,00%</Text>
            <Text style={styles.total}>{applyFormat(TVA, "ufloat", { precision:2,thousands: " ", decimal: ",", unit: " €" })}</Text>
        </View>
        <View style={styles.row2}>
            <Text style={styles.description}>Total TTC</Text>
            <Text style={styles.total}>{ applyFormat(totalTTC, "ufloat", { precision:2,thousands: " ", decimal: ",", unit: " €" })}</Text>
        </View>

        </> 
    )
};
  
  export default InvoiceTableFooter