import { useContext, useEffect, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Modal from "components/ui/modal/modal";
import Button from "components/ui/controls/button/button";
import { FaFileAlt, FaPlusCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";
import ContratPrecoSelect from "./ContratPrecoSelect";
import ContratPrevoyancePageModal from "pages/audit complet/situation professionnelle/modules/contrat prevoyance modal";
import ContratFraisProPageModal from "pages/audit complet/situation professionnelle/modules/contrat frais pro modal";
import DropDown from "components/ui/controls/drop-down/drop-down";

function ContratPrecoModal(props) {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalContratPrevOpen, setmodalContratPrevOpen] = useState(false);
  const [modalContratFraisProOpen, setmodalContratFraisProOpen] = useState(false);

  useEffect(() => {
    // if(modalOpen===true)
    // {
    //   context.handleResetPrevoyanceSettings();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);
  return (
    <>
      <Row tpad>
        <Button onClick={() => setmodalOpen(true)}>
          <span>Ajouter un contrat</span>
          <FaPlusCircle />
        </Button>
      </Row>
      <Modal
        width="30%"
        height="50%"
        title="Ajouter un contrat"
        icon={<FaFileAlt />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ContratPrecoSelect
          visible={modalOpen}
          Outil={props.Outil}
          ClientConjoint={props.ClientConjoint}
          onClose={(typeContrat) => {
            setmodalOpen(false);
            if (typeContrat === "prevoyance") {
              setmodalContratPrevOpen(true);
            } else if (typeContrat === "fraisPro") {
              setmodalContratFraisProOpen(true);
            }
          }}
        />
      </Modal>
      <ContratPrevoyancePageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratPrevOpen}
        Statut={"TNS Article 62"}
        Outil={props.Outil}
        PayeurPrime="Entreprise"
        ClientConjoint={props.ClientConjoint}
        CliConj={props.ClientConjoint}
        onClose={() => {
          setmodalContratPrevOpen(false);
        }}
      />
      <ContratFraisProPageModal
        AccessRemExpress={props.AccessRemExpress}
        visible={modalContratFraisProOpen}
        Outil={props.Outil}
        ClientConjoint={props.ClientConjoint}
        onClose={() => {
          setmodalContratFraisProOpen(false);
        }}
      />
      {context.state.ContratEnregistre.length > 0 && context.state[props.Outil + "ContratEnregistre"].length === 0 && (
        <Row tpad>
          <table>
            <tbody>
            <tr>
              <td style={{ width: "350px" }}>Souhaitez vous conserver le(s) contrat(s) existant(s)</td>
              <td>
                <DropDown
                  value={"Non"}
                  onChange={ (e)=> e.target.value ==="Oui" && context.handlePrevTnsAddToPreco(props.Outil)}
                  options={[
                    { value: "Non", label: "Non" },
                    { value: "Oui", label: "Oui" },
                  ]}
                  normal
                />
              </td>
            </tr>
            </tbody>
          </table>
        </Row>
      )}
      <Row tpad>
        <table>
          <thead>
            <tr>
              <th>Nature du contrat</th>
              <th style={{minWidth:"100px"}}>Cotisation</th>
              <th style={{minWidth:"180px"}}>Libellé</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {context.state[props.Outil + "ContratEnregistre" + props.ClientConjoint].map((Infos, Index) => (
              <tr key={["ContratEnregistre" + props.ClientConjoint + Index]}>
                <td>Prévoyance</td>
                <td>
                  {applyFormat(Number(Infos.PrevoyanceMadelin) + Number(Infos.PrevoyanceNonMadelin), "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </td>
                <td>{Infos.LibelleContrat}</td>
                <td>
                  <>
                    <Button
                      onClick={() => {
                        context.handleModifyDeleteContratPrevoyance(Infos.id, "Modifier", "Prevoyance", props.Outil, props.ClientConjoint);
                        setmodalContratPrevOpen(true);
                      }}
                      icon
                      style={{ display: "inline-block" }}
                    >
                      <FaEdit className="editContratPreco" id={"contratFac"+Infos.id} />
                    </Button>
                    <Button
                      onClick={() => {
                        Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                          context.handleModifyDeleteContratPrevoyance(Infos.id, "Supprimer", "Prevoyance", props.Outil, props.ClientConjoint);
                        });
                      }}
                      icon
                      style={{ display: "inline-block" }}
                    >
                      <FaTrashAlt className="delete" />
                    </Button>
                  </>
                </td>
              </tr>
            ))}
            {context.state[props.Outil + "ContratFraisPro" + props.ClientConjoint].map((Infos, Index) => (
              <tr key={["ContratFraisPro" + props.ClientConjoint + Index]}>
                <td>Frais professionnels</td>
                <td>
                  {applyFormat( Number(Infos.FGPMontant), "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </td>
                <td>{Infos.LibelleContrat}</td>
                <td>
                  <Button
                    onClick={() => {
                      context.handleModifyDeleteContratPrevoyance(Infos.id, "Modifier", "Frais professionnels", props.Outil, props.ClientConjoint);
                      setmodalContratFraisProOpen(true);
                    }}
                    icon
                    style={{ display: "inline-block" }}
                  >
                    <FaEdit className="editContratPreco" id={"contratFac"+Infos.id} />
                  </Button>
                  <Button
                    onClick={() => {
                      Dialogs.confirm("Vous êtes sur le point de supprimer le contrat.\nVoulez-vous continuer ?", "Suppression du contrat", () => {
                        context.handleModifyDeleteContratPrevoyance(Infos.id, "Supprimer", "Frais professionnels", props.Outil, props.ClientConjoint);
                      });
                    }}
                    icon
                    style={{ display: "inline-block" }}
                  >
                    <FaTrashAlt className="delete" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
    </>
  );
}

export default ContratPrecoModal;
