import { useEffect, useState } from 'react';
import { Alignments, classFromProperties, getOptionalProps, Paddings, Sizes } from '../../ui-helpers';
import './menu-title.css';

/**
 * Generates a menu title component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const MenuTitle = (props) => {
    
    // Excluded properties for automatic properties propagation
    const Excluded = ['title', 'subTitle', 'hidden', 'disabled'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [ isDisabled, setIsDisabled ] = useState(props?.isDisabled !== undefined ? props?.disabled : false);
    const [ isHidden, setIsHidden ] = useState(props?.hidden !== undefined ? props?.hidden : false);

    /**
     * On property change.
     */
     useEffect(() => {
        setIsDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setIsHidden(props?.hidden !== undefined ? props?.hidden : false);
    }, [props?.disabled, props?.hidden]);

    // Render component
    return (
        <div
            className={classFromProperties(
                props,
                'menu-title' + 
                    (isDisabled ? ' disabled' : '') +
                    (isHidden ? ' hidden' : ''),
                Alignments, Paddings, Sizes, ['hidden', 'disabled']
            )}
            {...getOptionalProps(props, Excluded)}
        >
            {props?.title ? <h1>{props.title}</h1> : <></>}
            {props?.subTitle ? <h2>{props.subTitle}</h2> : <></>}
        </div>
    )
}

export default MenuTitle;