import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";

const ParamCrn = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Prestation de serment avant 2014"
            labelStyle={{ width: "260px" }}
            value={context.state[props.Outil + "CrnSermentAvt2014" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "CrnSermentAvt2014" + props.ClientConjoint)}
            options={[
              {
                value: false,
                label: "Non",
              },
              {
                value: true,
                label: "Oui",
              },
            ]}
            normal
          />
        </Column>
        <Column hpad>
          <DropDown
            label="Régime de Colmar et Metz"
            value={context.state[props.Outil + "CrnColmarMetz" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "CrnColmarMetz" + props.ClientConjoint)}
            options={[
              {
                value: false,
                label: "Non",
              },
              {
                value: true,
                label: "Oui",
              },
            ]}
            normal
          />
        </Column>
      </Row>
      <Row tpad>
        <Column>
          <DropDown
            label="Classe retraite"
            value={context.state[props.Outil + "ClasseRetraiteCrn" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCrn" + props.ClientConjoint)}
            options={[
              {
                value: "NC",
                label: "Défaut",
              },
              {
                value: "1",
                label: "1",
              },
              {
                value: "2",
                label: "2",
              },
              {
                value: "3",
                label: "3",
              },
              {
                value: "4",
                label: "4",
              },
              {
                value: "5",
                label: "5",
              },

              {
                value: "6",
                label: "6",
              },
              {
                value: "7",
                label: "7",
              },
              {
                value: "8",
                label: "8",
              },
            ]}
            normal
          />
        </Column>
        <Column hpad>
          <TextField
            label="Moyenne des produits de l'étude"
            labelStyle={{whiteSpace:"nowrap"}}
            value={context.state[props.Outil + "MoyenneProduitCrn" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "MoyenneProduitCrn" + props.ClientConjoint)}
            dataType="uint"
            format={{ thousands: " ", unit: " €" }}
            normal
          />
        </Column>
      </Row>
    </>
  );
};
export default ParamCrn;
