import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import Dividendes from "components/app/AuditComplet/SituationPro/Dividendes";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Pager from "components/ui/controls/pager/pager";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import ContexteFiscalPageModal from "pages/audit complet/contexte fiscal/contexte fiscal pager";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe, FaMoneyBillWave } from "react-icons/fa";

function RemExpressResAvtIS(props) {
  const { Periode,ClientConjoint } = props;
  const [modalResultatEntreprise, setmodalResultatEntreprise] = useState(false);
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="resultatRemExpress">
        <tbody>
          <tr>
            <td className="libelle">Résultat avant IS</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state.RemExpressResAvtIs}
                onChange={(e) => {
                  context.handleCalculRemExpress("res_avant_is", "RemExpressResAvtIs", ResultatTNS, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Impôt sur les sociétés</td>
            <td className="nouvelle pr4">
            <Button
                className="editIconOptiRem"
                onClick={() => {
                  setmodalResultatEntreprise(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
              {applyFormat(context.state.RemExpressImpotSoc , "uint", { thousands: " ", decimal: ",", unit: " €" })}
              
              </td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Mise en réserve</td>
            <td className="nouvelle pr4">{applyFormat(Number(context.state.RemExpressReserve), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Dividendes autres associés</td>
            <td className="nouvelle pr4">{applyFormat(Number(context.state.RemExpressDivAutres), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="extra">&nbsp;</td>
          </tr>
          <tr>
            <td className="libelle">Dividendes bruts</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state.RemExpressDividendeBrut}
                onChange={(e) => {
                  context.handleCalculRemExpress("dividende_brut", "RemExpressDividendeBrut", ResultatTNS, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
              />
            </td>
            <td className="extra">&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaGlobe />}
        visible={modalResultatEntreprise}
        onClose={() => {
          setmodalResultatEntreprise(false);
        }}
      >
       <Pager
        withMenu
        pages={[
          {
            label: "Dividendes",
            icon: <FaMoneyBillWave />,
            content: <Dividendes Outil={"RemExpress"} ClientConjoint="" />,
          },
        ]}
        page={0}
        navButtons={{
          // cancel: {
          //   onClick: (evt) => {
          //     setmodalResultatEntreprise(false);
          //   },
          // },
          // previous: {},
          // next: {},
          validate: {
            onClick: (evt) => {
              context.handleCalculRemExpress('dividende_brut',"", ResultatTNS, "");
              setmodalResultatEntreprise(false);
            },
          },
        }}
      />
      </Modal>
    </CardBox>
  );
}
export default RemExpressResAvtIS;
