import Modal from "components/ui/modal/modal";
import { FaUmbrella } from "react-icons/fa";
import ContratPrevoyancePager from "components/app/AuditComplet/Prevoyance/ContratPrevoyance/ContratPrevoyancePager";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";

const ContratPrevoyancePageModal=(props)=>{
  const context = useContext(AppContext);
  return (
    <>
      <Modal
       hideClose={true}
        width="80%"
        height="80%"
        title="Ajout d'un contrat de prévoyance existant"
        icon={<FaUmbrella />}
        visible={props?.visible}
        onClose={() => {
          props?.onClose?.();
        }}
      >
        <ContratPrevoyancePager
          AccessRemExpress={props.AccessRemExpress}
          visible={props?.visible}
          Outil={props.Outil}
          Statut={props.Statut}
          PayeurPrime={props.PayeurPrime}
          ClientConjoint={props.ClientConjoint}
          CliConj={props.CliConj}
          onClose={() => {
            props?.onClose?.();
          }}
        />
      </Modal>
    </>
  );
}

export default ContratPrevoyancePageModal;
