import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function InvalCondPart(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            { context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel" ? (
              <>
                <Column>
                  <TextField
                    label="Rente invalidité totale"
                    value={context.state.InvaliditeTotaleComplementaire}
                    onChange={context.handleMontantToTaux("InvaliditeTotaleComplementaire")}
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                    normal
                  />
                </Column>
                <Column>
                  <TextField
                    label="Capital invalidité totale"
                    value={context.state.CapitalInvalTotale}
                    onChange={context.handleInputChange("CapitalInvalTotale")}
                    dataType="uint"
                    format={{ thousands: " ", unit: " €" }}
                    normal
                  />
                </Column>
                <Column>
                  <TextField
                    label="Seuil de déclenchement de l'invalidité partielle"
                    labelStyle={{ width: "260px" }}
                    value={context.state.InvaliditePartielleComplementaire}
                    onChange={context.handleInputChange("InvaliditePartielleComplementaire")}
                    dataType="uint"
                    format={{ thousands: " ", decimal: ",", precision: 2, unit: "%" }}
                    normal
                  />
                </Column>
              </>
            ) : (
              <>
                <Row>
                  <Column>
                    <DropDown
                      label="Assiette de calcul"
                      value={context.state.BaseInvaliditeComplementaireSalarie}
                      onChange={context.handleInputChange("BaseInvaliditeComplementaireSalarie")}
                      options={[
                        { value: "SalaireBrut", label: "Salaire brut" },
                        { value: "SalaireNet", label: "Salaire net" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <table className="mono">
                      <thead>
                        <tr>
                          <th>
                            <TextField className="invisible" value={"Catégorie"} small />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Taux"} small />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "300px" }}>1 ière catégorie</td>
                          <td>
                            <TextField
                              value={context.state.PourcentageInvaliditeCategorie1}
                              onChange={context.handleInputChange("PourcentageInvaliditeCategorie1")}
                              dataType="ufloat"
                              format={{ thousands: " ", decimmal: ",", precision: 2, unit: "%" }}
                              small
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>2 ième catégorie</td>
                          <td>
                            <TextField
                              value={context.state.PourcentageInvaliditeCategorie2}
                              onChange={context.handleInputChange("PourcentageInvaliditeCategorie2")}
                              dataType="ufloat"
                              format={{ thousands: " ", decimmal: ",", precision: 2, unit: "%" }}
                              small
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>3 ième catégorie</td>
                          <td>
                            <TextField
                              value={context.state.PourcentageInvaliditeCategorie3}
                              onChange={context.handleInputChange("PourcentageInvaliditeCategorie3")}
                              dataType="ufloat"
                              format={{ thousands: " ", decimmal: ",", precision: 2, unit: "%" }}
                              small
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default InvalCondPart;
