import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { FaCaretDown } from 'react-icons/fa';
import { Alignments, classFromProperties, getOptionalProps, getUniqId, Paddings, Sizes } from '../../ui-helpers';
import './drop-down.css';

/**
 * Closes all dropdown components.
 */
const closeAllDropDownComponents = () => {
    // Close all dropdown values components
    const els = document.querySelectorAll('.drop-down-values');
    els.forEach((el) => {
        el.remove();
    })
}

// Add dropdown values surveillance
document.body.addEventListener('click', closeAllDropDownComponents);

/**
 * Generates a drop-down list component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const DropDown = (props) => {

    // Excluded properties for automatic properties propagation
    const Excluded = ['id', 'label', 'labelStyle', 'value', 'onClick', 'onChange', 'disabled', 'readOnly', 'options'].concat(Alignments, Paddings, Sizes);

    // Initialize states
    const [id] = useState(props?.id ? props.id : getUniqId())
    const [value, setValue] = useState(props?.value);
    const [isDisabled, setDisabled] = useState(props?.disabled !== undefined ? props?.disabled : false);
    const [isReadOnly, setReadOnly] = useState(props?.readOnly !== undefined ? props?.readOnly : false);

    /**
     * Show options.
     * @param {Array} options Option list.
     * @param {int} level Nesting level.
     * @returns Option list components.
     */
    const showOptions = (options, level = 0) => {
        return (
            <div className='drop-down-options'>
                {options.map((option, index) =>
                    <div key={level * 10000 + index} className='drop-down-option'>
                        {option.options
                            ? <>
                                <h1>{option.label}</h1>
                                {showOptions(option.options, level + 1)}
                            </>
                            :
                            <div className={'drop-down-option-content' + (option.value === value ? ' selected' : '')}
                                onClick={evt => { handleSelect(evt, option.value) }}
                            >
                                {option.icon ? option.icon : <></>}
                                <span>{option.label}</span>
                            </div>
                        }
                    </div>
                )}
            </div>
        )
    }

    /**
     * Opens the options.
     * @param {float} top Top coordinate.
     * @param {float} left Left coordinate.
     */
    const showValues = (top, left) => {

        // Create values div
        const divValues = document.createElement('div');
        divValues.classList.add('drop-down-values');
        divValues.style.position = 'absolute';
        divValues.style.top = '-10000px';

        // Check sizing props
        if (props?.small)
            divValues.classList.add('small');
        if (props?.normal)
            divValues.classList.add('normal');
        if (props?.large)
            divValues.classList.add('large');
        if (props?.xlarge)
            divValues.classList.add('xlarge');
        if (props?.xxlarge)
            divValues.classList.add('xxlarge');

        // Create react root
        const divValuesRoot = createRoot(divValues);

        // Add options
        divValuesRoot.render(showOptions(props?.options));

        // Add values to body
        document.body.querySelector('.App').append(divValues);

        // Ensure visible
        const width = divValues.offsetWidth;
        const height = divValues.offsetHeight;
        divValues.style.left = (left + width + 8 > document.body.offsetWidth ? document.body.offsetWidth - width - 8 : left) + 'px';
        divValues.style.top = (top + height + 8 > document.body.offsetHeight ? document.body.offsetHeight - height - 8 : top) + 'px';

        // Scroll to selected
        setTimeout(() => { divValues.querySelector('.selected')?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' }); }, 1);
    }

    /**
     * Handles a click on value to open options.
     * @param {ClickEvent} evt Click event.
     */
    const handleOpen = (evt) => {

        // Close previously opened dropdown components
        closeAllDropDownComponents();

        // Show values
        const dropdown = evt.target.closest('.drop-down');
        const rect = dropdown.getBoundingClientRect();
        showValues(rect.top, rect.left);

        // Stop propagation
        evt.stopPropagation();
    }

    /**
     * Closes the options.
     */
    const handleClose = () => {

        // Remove values
        closeAllDropDownComponents();
    }

    /**
     * Handles an option select.
     * @param {ClickEvent} evt Click event.
     * @param {any} value New value.
     */
    const handleSelect = (evt, value) => {
        setValue(value);
        handleClose();
        evt.target.value = value;
        props.onChange?.(evt, value);
    }

    /**
     * Finds label corresponding to given value.
     * @param {array} options Option list (or tree).
     * @param {any} current Current value.
     * @returns Found label or empty string.
     */
    const findLabel = (options, current) => {
        let result = null;
        options.some((option) => {
            if (option.options) {
                const childRes = findLabel(option.options, current);
                if (childRes !== null) {
                    result = childRes;
                    return true;
                }
            }
            else if (option.value === current) {
                result = <>
                    {option.icon ? option.icon : <></>}
                    <span>{option.label}</span>
                </>
                return true;
            }
            return false;
        });

        // Return result
        return result;
    }

    /**
     * On value property change.
     */
    useEffect(() => {
        setValue(props?.value);
        setDisabled(props?.disabled !== undefined ? props?.disabled : false);
        setReadOnly(props?.readOnly !== undefined ? props?.readOnly : false);
    }, [props?.value, props?.disabled, props?.readOnly])

    // Render component
    return (
        <div
            id={id}
            className={classFromProperties(
                props,
                'drop-down' +
                (isDisabled ? ' disabled' : '') +
                (isReadOnly ? ' readOnly' : ''),
                Alignments,
                Paddings,
                Sizes
            )}
            onClick={handleOpen}
            {...getOptionalProps(props, Excluded)}
        >
            {props?.label ? <label onClick={handleOpen} style={props?.labelStyle}>{props.label}</label> : <></>}
            <div className='drop-down-value'>
                {findLabel(props.options, value) || <span>&nbsp;</span>}
                <FaCaretDown className='arrow'/>
            </div>
        </div>
    )
}

export default DropDown;