import { useContext, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";

import { FaEdit, FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import CardBox from "components/ui/controls/card-box/card-box";
import Modal from "components/ui/modal/modal";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";

const ParamCavp = (props) => {
  const context = useContext(AppContext);
  let [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Row tpad>
        <Column>
          <DropDown
            label="Biologiste conventionné"
            value={context.state[props.Outil + "ConventionneCavp" + props.ClientConjoint]}
            onChange={context.handleInputChange(props.Outil + "ConventionneCavp" + props.ClientConjoint)}
            options={[
              {
                value: "oui",
                label: "Oui",
              },
              {
                value: "non",
                label: "Non",
              },
            ]}
            normal
          />
        </Column>
        <Column hpad>
          <Row>
            <Column style={{ paddingTop: 0 }}>
              <DropDown
                label="Classe retraite"
                value={context.state[props.Outil + "ClasseRetraiteCavp" + props.ClientConjoint]}
                onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCavp" + props.ClientConjoint)}
                options={[
                  {
                    value: "classe3",
                    label: "3",
                  },
                  {
                    value: "classe4",
                    label: "4",
                  },
                  {
                    value: "classe5",
                    label: "5",
                  },
                  {
                    value: "classe6",
                    label: "6",
                  },
                  {
                    value: "classe7",
                    label: "7",
                  },
                  {
                    value: "classe8",
                    label: "8",
                  },
                  {
                    value: "classe9",
                    label: "9",
                  },
                  {
                    value: "classe10",
                    label: "10",
                  },
                  {
                    value: "classe11",
                    label: "11",
                  },
                  {
                    value: "classe12",
                    label: "12",
                  },
                  {
                    value: "classe13",
                    label: "13",
                  },
                ]}
                normal
              />
            </Column>

            <Column style={{ alignSelf: "flex-end", marginBottom: "5px" }}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>

      <Modal
        width="30%"
        height="30%"
        title=" "
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column>
                          <TextField
                            label="TAUX DE RENDEMENT CAPI"
                            value={context.state[props.Outil + "TauxRendementCapi" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "TauxRendementCapi" + props.ClientConjoint)}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            label="TAUX DE CONVERSION CAPI"
                            value={context.state[props.Outil + "TauxConversionCapi" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "TauxConversionCapi" + props.ClientConjoint)}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                            normal
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};
export default ParamCavp;
