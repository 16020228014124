import axios from "axios";
import { RemoveUnecessaryData, ApiUrl } from "../ApiData";
import { User } from "../ApiUser";

const APIURL = ApiUrl;
export const RemunerationTNS = (n) => {};


export function CalculResultatEntreprise(Data, Outil, Input) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("Outil", Outil);
  formData.append("Input", Input);
  return axios({
    method: "post",
    url: `${APIURL}ResultatEntreprise.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    })
    .then((ResultatEntreprise) => {
      return ResultatEntreprise.data;
    });
}
export function CalculPartFiscale(Data) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  return axios({
    method: "post",
    url: `${APIURL}PartFiscale.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((ResultatEntreprise) => {
      return ResultatEntreprise.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      }
    });
}

export function GetCotisation83(Data, ClientConjoint) {
  let CleanData = RemoveUnecessaryData(Data);
  var formData = new FormData();
  formData.append("Data", JSON.stringify(CleanData));
  formData.append("ClientConjoint", ClientConjoint);
  return axios({
    method: "post",
    url: `${APIURL}CalculCotisation83.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    withCredentials: true,
  })
    .then((Resultat) => {
      return Resultat.data;
    })
    .catch(function (error) {
      if (parseInt(error.response.request.status) === 401) {
        var event = new CustomEvent("session-expired", {
          detail: {
            logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
          },
        });
        window.dispatchEvent(event);
      } else {
        User(
          {
            UserName: Data.Conseiller,
            ClientName: Data.Prenom + " " + Data.Nom,
            ClientDossier: Data.LibelleDossier,
            TypeError: "ERREUR COTISATION 83",
          },
          "SendMailToSupport"
        );
        alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
      }
    });
}
