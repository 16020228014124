import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CheckBox from "components/ui/controls/check-box/check-box";

const Client = (props) => {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <DropDown
                  label="Civilité"
                  value={context.state.Civilite}
                  options={[
                    { value: "Monsieur", label: "Monsieur" },
                    { value: "Madame", label: "Madame" },
                  ]}
                  onChange={context.handleCiviliteConjoint}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField label="Prénom" value={context.state.Prenom} onChange={context.handleInputChange("Prenom")} normal />
              </Column>
              <Column hpad>
                <TextField label="Nom" value={context.state.Nom} onChange={context.handleChangeNomClient} normal />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <TextField
                  label="Date de naissance"
                  onKeyUp={(e) => {
                    props.CheckLURA(e);
                  }}
                  value={context.state.DateNaissance}
                  onChange={context.handleInputChange("DateNaissance")}
                  dataType="date"
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <DropDown
                  label="Situation maritale"
                  value={context.state.StatutMarital}
                  options={[
                    {
                      value: "celibataire",
                      label: "Célibataire",
                    },
                    {
                      value: "marie",
                      label: "Marié(e)",
                    },
                    {
                      value: "pacse",
                      label: "Pacsé(e)",
                    },
                    {
                      value: "divorce",
                      label: "Divorcé(e)",
                    },
                    {
                      value: "veuf",
                      label: "Veuf(ve)",
                    },
                  ]}
                  onChange={context.handleInputChange("StatutMarital")}
                  normal
                />
              </Column>
              {(context.state.StatutMarital === "celibataire" || context.state.StatutMarital === "divorce" || context.state.StatutMarital === "veuf") && (
                <Column style={{ alignSelf: "flex-end" }}>
                  <CheckBox label="Parent isolé" before inline checked={true} unchecked={false} value={context.state.Isole} onChange={context.handleCheckChange("Isole")} />
                </Column>
              )}
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default Client;
