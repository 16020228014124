import { useEffect, useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import Button from "../controls/button/button";
import Column from "../layout/column/column";
import Row from "../layout/row/row";
import './subordinates.css';

/**
 * List of subordinates.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Subordinates = (props) => {

    // Initialize states
    const [root, setRoot] = useState(props.root);

    // On root change
    useEffect(() => {
        setRoot(props.root);
    }, [props.root])

    /**
     * Generates a user row.
     * @param {any} user User to generate.
     * @param {int} depth Tree depth.
     */
    const generateRows = (user, depth = 0) => {

        // Assert user
        if (!user || !user?.id) return;

        // Get user informations
        const firstName = user.firstName || 'Aucun prénom';
        const lastName = user.lastName || 'Aucun nom';
        const gender = user.gender === 1 ? 'Madame' : 'Monsieur';
        const children = user.children ? user.children : [];
        const padding = depth * 24;

        // Render row
        return (
            <>
                <Row center key={user.id}>
                    <Column>
                        <span style={{ paddingLeft: `${padding}px` }}>
                            {gender} {firstName} {lastName}
                        </span>
                    </Column>
                    <Column>
                        <Button
                            icon
                            onClick={() => {
                                props?.onSelect?.(user.id)
                            }}
                        >
                            <FaArrowCircleRight />
                        </Button>
                    </Column>
                </Row>
                {children.map((child) => {
                    return generateRows(child, depth + 1)
                })}
            </>
        )
    }

    return (
        <Column className="subordinates" fill>
            <Row>
                <blockquote>
                    Sélectionnez l'utilisateur avec lequel vous souhaitez vous connecter.
                </blockquote>
            </Row>
            <Row vpad className="subordinates-row" fill>
                <Column className="subordinates-content" fill scroll>
                    {generateRows(root)}
                </Column>
            </Row>
            <Row>
                <Column fill center>
                    <Button large onClick={props?.onCancel}>Annuler</Button>
                </Column>
            </Row>
        </Column>
    )
}
export default Subordinates;