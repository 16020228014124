import React from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";

const CapitauxMobiliers = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  console.log(Outil);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th>Paramètres des valeurs et capitaux mobiliers</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Application du PFU</td>
                  <td>
                    <DropDown
                      value={context.state[Outil + "OptionPfu"]}
                      onChange={context.handleOptionPfu(Outil)}
                      options={[
                        { value: "oui", label: "Oui" },
                        { value: "non", label: "Non" },
                      ]}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>Dividendes bruts issus des activités soumis aux PS de 17,2%</td>
                  <td>
                    <TextField
                      value={ResultatTNS.state[Outil+"DetailImpot"].AssietteTotaleIR?.DividendeActivitePrelevementSociaux}
                      disabled={true}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <tr>
                  <td>Dividendes bruts issus des activités non soumis aux PS de 17,2%</td>
                  <td>
                    <TextField
                          value={Math.max(0,ResultatTNS.state[Outil+"DetailImpot"].DividendeBrutImposable -ResultatTNS.state[Outil+"DetailImpot"].AssietteTotaleIR?.DividendeActivitePrelevementSociaux)}
                      disabled={true}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </td>
                </tr>
                <LigneImpotDetaille Libelle="Autres dividendes bruts et actions soumis aux PS de 17,2%" VarName1={Outil + "IRDirigeantAutreDividendeSoumisPS"} VarName2="" />
                <LigneImpotDetaille Libelle="Autres dividendes bruts et actions non soumis aux PS de 17,2%" VarName1={Outil + "IRDirigeantAutreDividendeNonSoumisPS"} VarName2="" />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default CapitauxMobiliers;
