import React, { Component } from "react";
import axios from "axios";
import { RevenusFutur } from "components/app/Handlers/RetraiteHandler";
import { CalculRetraite } from "components/app/Api/Retraite/ApiRetraite";
import { CalculArbitrageSalarie } from "components/app/Api/Remuneration/ApiRemuneration";
import { RemoveUnecessaryData, ApiUrl } from "./ApiData";
import { User } from "./ApiUser";
const APIURL = ApiUrl;

export const RemExpertResultatSalarie = React.createContext();

const InitialRemunerationSalarie = {
  CoutEntreprise: 0,
  CoutDividende: 0,
  ImpotSociete: 0,
  DividendeBrut: 0,

  CoutRemuneration: 0,
  CotisationsFacultativesEntreprise: 0,
  CoutEpargneSalariale: 0,
  TotalChargesPatronale: 0,
  SalaireBrut: 0,
  TotalChargesSalariale: 0,
  SalaireNet: 0,
  RevenuPercu: 0,
  BrutImposable: 0,
  DividendeBrutImposable: 0,
  IrDirigeant: 0,
  VersementFacultatifPerso: 0,
  RevenuImmediat: 0,
  TauxEfficaciteRiCe: 0,
  CotisationProductive: 0,
  DetailFicheDePaie: {},
  DetailImpot: {},
  GraphDisponible: {},
  Rapport: {},
  CPRetraiteObligatoire: 0,
  CPRetraiteFacultative: 0,
  CPPEEPERCO: 0,
  CPPrevoyance: 0,
  CPSante: 0,
  CPAvantageNature: 0,
  CPChomage: 0,
};
const InitialPrevSalarie = {
  IJMax: 0,
  TauxRempIJMax: 0,
  InvaliditeTotale: 0,
  TauxRempInvaliditeTotale: 0,
  CapitalDeces: 0,
  TabArretMaladieRegimeObligatoire: [],
  GraphArretMaladieRegimeObligatoire: [],
  TabInvalRegimeObligatoire: [],
  GraphInvalRegimeObligatoire: [],
  TabIJ: [],
  GraphIJ: [],
  TabSante: {},
  TabInval: {},
  GraphInval: {},
  TabDeces: {},
  TabRente: {},
  ImgGraphIncap: "",
  ImgGraphInval: "",
  DoubleEffet: 0,
  FraisObseque: 0,
};
const InitialRetraite = {
  TotalRetraiteNette: 0,
  TotalRetraiteNetteChoixDepartRetraite: 0,
  TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
  TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
  TotalCapitalChoixDepartRetraite: 0,
  TotalCapitalFractionneChoixDepartRetraite: 0,
  TauxRempRetraiteNetteChoixDepartRetraite: 0,
  InfosRetraiteChoixDepart: {},
  InfosRetraite: {},
  InfosRetraiteTauxPlein: {},
};
class ResultatRemExpertSalarieProvider extends Component {
  GetResultRemExpert = (Data, ClientConjoint) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);
      axios({
        method: "post",
        url: `${APIURL}RemunerationSALARIE.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((ResultatRemuneration) => {
          // MODIFICATION DE L'ASSIETTE POUR LA CARRIERE ACTUELLE
          // ATTENTION , Data est passée par référence et la carriere est directement modifié dans les variables de RemExpert
          const PeriodeToDisplay =
            ClientConjoint === ""
              ? CleanData.Carriere.filter((Periode) => Periode.id === "CarriereActuelle")
              : CleanData.CarriereConjoint.filter((Periode) => Periode.id === "CarriereActuelle");

          if (PeriodeToDisplay.length > 0) {
            PeriodeToDisplay.forEach((Periode) => {
              var CurrentYear = new Date().getFullYear();
              const NewDetailRetraiteFutur = RevenusFutur(CurrentYear, Periode.DetailRetraiteFutur, ResultatRemuneration.data.InfosRemuneration.AssietteRetraite, Periode.MontantEvolution);
              Periode.Detail = NewDetailRetraiteFutur;
              Periode.DetailRetraiteFutur = NewDetailRetraiteFutur;
            });

            const NewCarriere = PeriodeToDisplay[0];

            if (ClientConjoint === "") {
              CleanData.Carriere = CleanData.Carriere.filter((Periode) => Periode.id !== "CarriereActuelle");
              CleanData.Carriere = [...CleanData.Carriere, NewCarriere];
              Data.Carriere = CleanData.Carriere;
            } else {
              CleanData.CarriereConjoint = CleanData.CarriereConjoint.filter((Periode) => Periode.id !== "CarriereActuelle");
              CleanData.CarriereConjoint = [...CleanData.CarriereConjoint, NewCarriere];
              Data.CarriereConjoint = CleanData.CarriereConjoint;
            }
          }

          //Ajout de l'assiette dans le contrat 83
          const Contrat83 = CleanData.ListeContrat.filter((Contrat) => Contrat.Fiscalite === "Article83");
          if (Contrat83.length > 0) {
            Contrat83.forEach((Contrat) => {
              Contrat.Salaire = ResultatRemuneration.data.AffichageResultat.SalaireBrut;
            });

            const newContrat = Contrat83[0];
            CleanData.ListeContrat = CleanData.ListeContrat.filter((Contrat) => Contrat.Fiscalite !== "Article83");
            CleanData.ListeContrat = [...CleanData.ListeContrat, newContrat];
          }

          let SalaireReference = ResultatRemuneration.data.AffichageResultat.SalaireNet;
          const annee = new Date().getFullYear();
          if (ClientConjoint === "") {
            for (const periode of Data.Carriere) {
              if (annee in periode.Detail && periode.id !== "CarriereActuelle") {
                SalaireReference += Number(periode.Detail[annee]["Salaire"]);
              }
            }
            if (CleanData.CarriereSalaireReferenceSaisi === false) {
              Data.CarriereSalaireReference = SalaireReference;
              Data.CarriereSalaireReferenceCalcule = SalaireReference;
              CleanData.CarriereSalaireReference = SalaireReference;
            }
          } else {
            for (const periode of Data.CarriereConjoint) {
              if (annee in periode.Detail && periode.id !== "CarriereActuelle") {
                SalaireReference += Number(periode.Detail[annee]["Salaire"]);
              }
            }
            if (CleanData.CarriereSalaireReferenceSaisiConjoint === false) {
              Data.CarriereSalaireReferenceConjoint = SalaireReference;
              Data.CarriereSalaireReferenceCalculeConjoint = SalaireReference;
              CleanData.CarriereSalaireReferenceConjoint = SalaireReference;
            }
          }
          // var formData = new FormData();
          formData.append("Data", JSON.stringify(CleanData));

          this.setState(
            {
              [ClientConjoint !== "" ? "RemunerationSalarieConjoint" : "RemunerationSalarie"]: {
                CoutEntreprise: ResultatRemuneration.data.AffichageResultat.CoutEntreprise,
                CoutDividende: ResultatRemuneration.data.AffichageResultat.CoutDividende,
                ImpotSociete: ResultatRemuneration.data.AffichageResultat.ImpotSociete,
                DividendeBrut: ResultatRemuneration.data.AffichageResultat.DividendeBrut,
                DividendeNet: ResultatRemuneration.data.AffichageResultat.DividendeNet,

                CoutRemuneration: ResultatRemuneration.data.AffichageResultat.CoutRemuneration,
                CotisationsFacultativesEntreprise: ResultatRemuneration.data.AffichageResultat.CotisationsFacultativesEntreprise,
                CoutEpargneSalariale: ResultatRemuneration.data.AffichageResultat.CoutEpargneSalariale,
                TotalChargesPatronale: ResultatRemuneration.data.AffichageResultat.TotalChargesPatronale,
                SalaireBrut: ResultatRemuneration.data.AffichageResultat.SalaireBrut,
                TotalChargesSalariale: ResultatRemuneration.data.AffichageResultat.TotalChargesSalariale,
                SalaireNet: ResultatRemuneration.data.AffichageResultat.SalaireNet,
                RevenuPercu: ResultatRemuneration.data.AffichageResultat.RevenuPercu,
                BrutImposable: ResultatRemuneration.data.AffichageResultat.BrutImposable,
                DividendeBrutImposable: ResultatRemuneration.data.AffichageResultat.DividendeBrutImposable,
                IrDirigeant: ResultatRemuneration.data.AffichageResultat.IrDirigeant,
                VersementFacultatifPerso: ResultatRemuneration.data.AffichageResultat.VersementFacultatifPerso,
                RevenuImmediat: ResultatRemuneration.data.AffichageResultat.RevenuImmediat,
                TauxEfficaciteRiCe: ResultatRemuneration.data.AffichageResultat.TauxEfficaciteRiCe,
                CotisationProductive: ResultatRemuneration.data.AffichageResultat.CotisationProductive,
                DetailFicheDePaie: ResultatRemuneration.data.InfosRemuneration.DetailFicheDePaie,
                DetailImpot: ResultatRemuneration.data.InfosRemuneration.DetailImpot,
                GraphDisponible: ResultatRemuneration.data.GraphDisponible,
                Rapport: ResultatRemuneration.data.InfosRemuneration.Rapport,
              },
            },
            () => {
              CalculRetraite(CleanData, "", SalaireReference, ClientConjoint).then((ResultatRetraite) => {
                this.setState({
                  [ClientConjoint !== "" ? "RetraiteConjoint" : "Retraite"]: {
                    TotalRetraiteNetteChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteChoixDepartRetraite,
                    TauxRempRetraiteNetteChoixDepartRetraite: ResultatRetraite.TauxRempRetraiteNetteChoixDepartRetraite,
                    TotalRetraiteNetteObligatoireChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteObligatoireChoixDepartRetraite,
                    TotalRetraiteNetteFacultatifChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteFacultatifChoixDepartRetraite,
                    TotalCapitalChoixDepartRetraite: ResultatRetraite.TotalCapitalChoixDepartRetraite,
                    TotalCapitalFractionneChoixDepartRetraite: ResultatRetraite.TotalCapitalFractionneChoixDepartRetraite,
                    InfosRetraiteChoixDepart: ResultatRetraite.RetraiteChoixDepartRetraite,
                    InfosRetraite: ResultatRetraite.Retraite,
                    InfosRetraiteTauxPlein: ResultatRetraite.RetraiteTauxPlein,
                  },
                });
              });
            }
          );
          formData.append("Prevoyance83Salariale", ResultatRemuneration.data.InfosRemuneration.Prevoyance83Salariale);
          formData.append("Prevoyance83Patronale", ResultatRemuneration.data.InfosRemuneration.Prevoyance83Patronale);
          formData.append("DepassementSocial83", ResultatRemuneration.data.InfosRemuneration.DetailFicheDePaie.TabRecap.DepassementSocial83);
          formData.append("BaseSociale", ResultatRemuneration.data.InfosRemuneration.DetailFicheDePaie.AssietteFicheDePaie.AssietteSbDepassement);
          formData.append("RevenusBrut", ResultatRemuneration.data.AffichageResultat.SalaireBrut);
          formData.append("RevenusNet", ResultatRemuneration.data.AffichageResultat.SalaireNet);
          formData.append("AssietteMadelin", ResultatRemuneration.data.AffichageResultat.BrutImposable);
          formData.append("ClientConjoint", ClientConjoint);
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          } else {
            User(
              {
                UserName: Data.Conseiller,
                ClientName: Data.Prenom + " " + Data.Nom,
                ClientDossier: Data.LibelleDossier,
                TypeError: "ERREUR REMU SALARIE",
              },
              "SendMailToSupport"
            );
            alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
          }
        })
        .then((response) => {
          axios({
            method: "post",
            url: `${APIURL}PrevoyanceSALARIE.php`,
            data: formData,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            withCredentials: true,
          })
            .then((response) => {
              this.setState(
                {
                  LoadResultat: false,
                  [ClientConjoint !== "" ? "PrevSalarieConjoint" : "PrevSalarie"]: {
                    IJMax: response.data.AffichageResultat.IJMax,
                    TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
                    InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
                    TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
                    CapitalDeces: response.data.AffichageResultat.CapitalDeces,
                    TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
                    GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
                    GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
                    TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
                    TabIJ: response.data.TabIJ,
                    GraphIJ: response.data.GraphIJ,
                    TabSante: response.data.TabSante,
                    TabInval: response.data.TabInval,
                    GraphInval: response.data.GraphInval,
                    TabDeces: response.data.TabDeces,
                    TabRente: response.data.TabRente,
                    ImgGraphIncap: response.data.ImgGraphIncap,
                    ImgGraphInval: response.data.ImgGraphInval,
                    DoubleEffet: response.data.DoubleEffet,
                    FraisObseque: response.data.FraisObseque,
                  },
                },
                () => {
                  resolve(this.state[ClientConjoint !== "" ? "RemunerationSalarieConjoint" : "RemunerationSalarie"].BrutImposable);
                }
              );
            })
            .catch(function (error) {
              if (parseInt(error.response.request.status) === 401) {
                var event = new CustomEvent("session-expired", {
                  detail: {
                    logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                  },
                });
                window.dispatchEvent(event);
              } else {
                User(
                  {
                    UserName: Data.Conseiller,
                    ClientName: Data.Prenom + " " + Data.Nom,
                    ClientDossier: Data.LibelleDossier,
                    TypeError: "ERREUR PREV SALARIE",
                  },
                  "SendMailToSupport"
                );
                alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
              }
            });
        });
    });
  };
  GetSalaireBrutCalculPrestation = (Data, Periode, ClientConjoint) => {
    let CleanData = RemoveUnecessaryData(Data);
    this.setState({ LoadResultat: true });
    var formData = new FormData();
    formData.append("Data", JSON.stringify(CleanData));
    formData.append("Periode", JSON.stringify(Periode));
    formData.append("ClientConjoint", ClientConjoint);
    return axios({
      method: "post",
      url: `${APIURL}RemunerationSALARIE.php`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      withCredentials: true,
    })
      .then((ResultatRemuneration) => {
        return ResultatRemuneration.data.AffichageResultat.SalaireBrut;
      })
      .catch(function (error) {
        if (parseInt(error.response.request.status) === 401) {
          var event = new CustomEvent("session-expired", {
            detail: {
              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
            },
          });
          window.dispatchEvent(event);
        } else {
          User(
            {
              UserName: Data.Conseiller,
              ClientName: Data.Prenom + " " + Data.Nom,
              ClientDossier: Data.LibelleDossier,
              TypeError: "ERREUR REMU SALARIE POUR CALCUL PRESTA",
            },
            "SendMailToSupport"
          );
          alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
        }
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      LoadResultat: false,
      RemunerationSalarie: { ...InitialRemunerationSalarie },
      RemunerationSalarieConjoint: { ...InitialRemunerationSalarie },
      GSRemunerationSalarie: { ...InitialRemunerationSalarie },
      PrevSalarie: { ...InitialPrevSalarie },
      PrevSalarieConjoint: { ...InitialPrevSalarie },
      Retraite: { ...InitialRetraite },
      RetraiteConjoint: { ...InitialRetraite },
      OptiRemRemunerationSalarie: {
        CoutEntreprise: 0,
        CoutDividende: 0,
        ImpotSociete: 0,
        DividendeBrut: 0,

        CoutRemuneration: 0,
        CoutEpargneSalariale: 0,
        TotalChargesPatronale: 0,
        SalaireBrut: 0,
        TotalChargesSalariale: 0,
        SalaireNet: 0,
        RevenuPercu: 0,
        BrutImposable: 0,
        DividendeBrutImposable: 0,
        IrDirigeant: 0,
        VersementFacultatifPerso: 0,
        RevenuImmediat: 0,
        TauxEfficaciteRiCe: 0,
        CotisationProductive: 0,
        DetailFicheDePaie: {},
        DetailImpot: {},
        GraphDisponible: {},
        Rapport: {},
      },
      CDSRemunerationSalarie: {
        CoutEntreprise: 0,
        CoutDividende: 0,
        ImpotSociete: 0,
        DividendeBrut: 0,

        CoutRemuneration: 0,
        CoutEpargneSalariale: 0,
        TotalChargesPatronale: 0,
        SalaireBrut: 0,
        TotalChargesSalariale: 0,
        SalaireNet: 0,
        RevenuPercu: 0,
        BrutImposable: 0,
        DividendeBrutImposable: 0,
        IrDirigeant: 0,
        VersementFacultatifPerso: 0,
        RevenuImmediat: 0,
        TauxEfficaciteRiCe: 0,
        CotisationProductive: 0,
        DetailFicheDePaie: {},
        DetailImpot: {},
        GraphDisponible: {},
        Rapport: {},
      },
      OptiRemPrevSalarie: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        TabSante: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      CDSPrevSalarie: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        TabSante: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      OptiRemRetraite: {
        TotalRetraiteNette: 0,
        TotalRetraiteNetteChoixDepartRetraite: 0,
        TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
        TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
        TotalCapitalChoixDepartRetraite: 0,
        TotalCapitalFractionneChoixDepartRetraite: 0,
        TauxRempRetraiteNetteChoixDepartRetraite: 0,
        InfosRetraiteChoixDepart: {},
        InfosRetraite: {},
        InfosRetraiteTauxPlein: {},
      },
      CDSRetraite: {
        TotalRetraiteNette: 0,
        TotalRetraiteNetteChoixDepartRetraite: 0,
        TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
        TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
        TotalCapitalChoixDepartRetraite: 0,
        TotalCapitalFractionneChoixDepartRetraite: 0,
        TauxRempRetraiteNetteChoixDepartRetraite: 0,
        InfosRetraiteChoixDepart: {},
        InfosRetraite: {},
        InfosRetraiteTauxPlein: {},
      },
    };
  }

  render() {
    return (
      <RemExpertResultatSalarie.Provider
        value={{
          state: this.state,
          GetResultRemExpert: this.GetResultRemExpert,
          GetSalaireBrutCalculPrestation: this.GetSalaireBrutCalculPrestation,
        }}
      >
        {this.props.children}
      </RemExpertResultatSalarie.Provider>
    );
  }
}

export default ResultatRemExpertSalarieProvider;
