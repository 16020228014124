import "./../../../../pages/audit complet/simulation/simulation.css";
import "./DeclarationPER.css"
import { useState, useContext, useEffect } from "react";
import { FaPrint, FaSave, FaUndo } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { SyntheseDeclarationPER } from "components/app/Api/Rapport/ApiRapport";
import _ from "lodash";
import { GetCalculDeclaPER } from "components/app/Api/ApiSimulateur";

const DeclarationPER = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalSave, setModalSave] = useState(false);
  const initialValues={
    DeclaPERRemunerationImposable:0,
    DeclaPERRegimeFiscal:"tns",
    DeclaPERAbondementPERCO:0,
    DeclaPERRetraiteMadelin:0,
    DeclaPERPrevoyanceMadelin:0,
    DeclaPERRetraite83:0,
    DeclaPERRetraite163:0,
    DeclaPERAssietteDisponible:"compris",
  }
  const [values,setValues]=useState(initialValues)
  const [resultat,setResultat]=useState({
    MontantAssietteDisponible:0,
    MontantAssietteDisponibleT1:0,
    MontantAssietteDisponibleT2:0,
    MontantDisponibleT1:0,
    MontantDisponibleT2:0,
    MontantADeclarer:0,
  })
  
  useEffect(() => {
    let NewValues = _.cloneDeep(values);
    NewValues.DeclaPERRemunerationImposable=context.state.DeclaPERRemunerationImposable;
    NewValues.DeclaPERRegimeFiscal=context.state.DeclaPERRegimeFiscal;
    NewValues.DeclaPERAbondementPERCO=context.state.DeclaPERAbondementPERCO;
    NewValues.DeclaPERRetraiteMadelin=context.state.DeclaPERRetraiteMadelin;
    NewValues.DeclaPERPrevoyanceMadelin=context.state.DeclaPERPrevoyanceMadelin;
    NewValues.DeclaPERRetraite83=context.state.DeclaPERRetraite83;
    NewValues.DeclaPERRetraite163=context.state.DeclaPERRetraite163;
    NewValues.DeclaPERAssietteDisponible=context.state.DeclaPERAssietteDisponible;
    setValues(NewValues);
  }, []);

  const dt = new Date();
  const yearM1 = dt.getFullYear() - 1;
  const year = dt.getFullYear();
  const handleCalcul=(Property,value)=>{
    context.handleValueChange(Property, value);
    let NewValues = _.cloneDeep(values);
    NewValues[Property]=value;
    setValues(NewValues);
    GetCalculDeclaPER(NewValues,"CalculDeclaPER").then((res) => {
      if (typeof res === "object") {
        setResultat(res)
        context.handleValueChange("DeclaPERResultat",res);
      }
    })
  }
  return (
    <>
      <Column fill>
        <Row>
          <Column fill style={{ minWidth: "640px", maxWidth: "820px" }}>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td>Aide déclaration épargne retraite</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            <CardBox className="blockRoundedForce" bpad>
              <table className="declaPER" >
                <tbody>
                  <tr>
                    <td>Rémunération imposable {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERRemunerationImposable} onChange={(e) => {handleCalcul("DeclaPERRemunerationImposable",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                  <tr>
                    <td>Régime fiscal </td>
                    <td>
                    <DropDown
                      value={values.DeclaPERRegimeFiscal}
                      onChange={(e) => {handleCalcul("DeclaPERRegimeFiscal",e.target.value)}}
                      options={[
                        { value: "tns", label: "Revenus des gérants (art 62 du CGI)" },
                        { value: "bic", label: "BIC/BNC" },
                        { value: "salaire", label: "Traitement et salaires" },
                      ]}
                      normal
                    />
                    </td>
                  </tr>
                  <tr>
                    <td>Abondement PERCO {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERAbondementPERCO} onChange={(e) => {handleCalcul("DeclaPERAbondementPERCO",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                  <tr>
                    <td>Cotisations retraite Madelin {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERRetraiteMadelin} onChange={(e) => {handleCalcul("DeclaPERRetraiteMadelin",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                  <tr>
                    <td>Cotisations prévoyance et santé Madelin {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERPrevoyanceMadelin} onChange={(e) => {handleCalcul("DeclaPERPrevoyanceMadelin",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                  <tr>
                    <td>Cotisations retraite 83 {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERRetraite83} onChange={(e) => {handleCalcul("DeclaPERRetraite83",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                  <tr>
                    <td>Cotisations PER 163 quatervicies {yearM1}</td>
                    <td>
                      <TextField value={values.DeclaPERRetraite163} onChange={(e) => {handleCalcul("DeclaPERRetraite163",e.target.value)}} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>

          <Column fill style={{ maxWidth: "520px" }}>
            <Row>
              <Column fill>
                <CardBox bpad className=" blockRoundedForce">
                  <Column center fill style={{ padding: 0 }}>
                    <Row>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            context.handleRemiseDonneeDeclarationPER();
                            setValues(initialValues)
                          }}
                        >
                          <FaUndo />
                        </Button>
                      </Column>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            SyntheseDeclarationPER(context.state,resultat)
                          }}
                        >
                          <FaPrint title="Imprimer" />
                        </Button>
                      </Column>
                    </Row>
                    <Modal
                      width="450px"
                      height="25%"
                      title="Sauvegarde de la simulation"
                      icon={<FaSave />}
                      visible={modalSave}
                      onClose={() => {
                        setModalSave(false);
                      }}
                    >
                      <Column fill>
                        <Row fill center>
                          <Column fill center>
                            <TextField
                              labelStyle={{ color: "var(--app-fg-color)" }}
                              label="Libellé de la simulation"
                              value={context.state.LibelleOptimisation}
                              onChange={context.handleInputChange("LibelleOptimisation")}
                              style={{ width: "370px" }}
                            />
                          </Column>
                        </Row>
                        <Row className="pager-buttons">
                          <Column fill>
                            <Button
                              onClick={() => {
                                // context.handleNewSimulation("Audit prévoyance");
                                context.handleSaveAsOptimisation(context.state.IdOptimisation, "OptiRem");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer</span>
                            </Button>
                          </Column>
                          <Column fill>
                            <Button
                              onClick={() => {
                                context.handleSaveAsOptimisation("", "OptiRem");
                                // context.handleNewSimulation("Audit prévoyance");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer sous</span>
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    </Modal>
                  </Column>
                </CardBox>
              </Column>
            </Row>

            <CardBox  bpad className=" blockRoundedForce dark-title">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td>Détail de l'enveloppe Madelin</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad className="blockRoundedForce">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td>Assiette de calcul du disponible</td>
                    <td>    <DropDown
                      value={values.DeclaPERAssietteDisponible}
                      onChange={(e) => {handleCalcul("DeclaPERAssietteDisponible",e.target.value)}} 
                      options={[
                        { value: "compris", label: "Bénéfice imposable (Madelin compris)" },
                        { value: "exclu", label: "Bénéfice imposable (hors Madelin)" },
                      ]}
                      normal
                    /></td>
                  </tr>
                  <tr>
                    <td>Montant</td>
                    <td>{applyFormat(resultat.MontantAssietteDisponible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox  bpad className="blockRoundedForce">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td></td>
                    <td>Assiette</td>
                    <td>Taux</td>
                    <td>Montant</td>
                  </tr>
                  <tr>
                    <td>Limite des 10%</td>
                    <td>{applyFormat(resultat.MontantAssietteDisponibleT1, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>10%</td>
                    <td>{applyFormat(resultat.MontantDisponibleT1, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Limite des 15%</td>
                    <td>{applyFormat(resultat.MontantAssietteDisponibleT2, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>15%</td>
                    <td>{applyFormat(resultat.MontantDisponibleT2, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{textAlign:"right"}}>Cumul</td>
                    <td>{applyFormat(resultat.MontantDisponibleT1+resultat.MontantDisponibleT2, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox  bpad className="blockRoundedForce">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td  style={{textAlign:"right"}}>Cotisations PER Madelin {yearM1}</td>
                    <td>{applyFormat(values.DeclaPERRetraiteMadelin, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td  style={{textAlign:"right"}}>Montant à déclarer (Cotisation Madelin-Part des 15%)</td>
                    <td>{applyFormat(resultat.MontantADeclarer, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
           
                </tbody>
              </table>
            </CardBox>
          </Column>
        </Row>
      </Column>

    </>
  );
};

export default DeclarationPER;
