import React, { useContext, useEffect } from "react";
import Row from "components/ui/layout/row/row";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import { AppContext } from "components/app/RemExprertProvider";

const DetailDisponibleTNS = ({ GraphDisponible, GraphPerp }) => {
  const context = useContext(AppContext);
  useEffect(() => {
    if (GraphDisponible !== undefined) {
      document.getElementById("GraphDispoRetraiteMadelin").innerHTML = GraphDisponible?.GraphMadelin?.GraphDispoRetraite;
      document.getElementById("GraphDispoPrevSanteMadelin").innerHTML = GraphDisponible?.GraphMadelin?.GraphDispoPrevSante;
    }
    if (GraphPerp !== undefined) {
      document.getElementById("GraphDispoPerpTNS").innerHTML = GraphPerp;
    }
  }, []);

  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <h1>Disponibles fiscaux</h1>
          </Row>
          <Row style={{ marginLeft: "10%" }} tpad>
            <Column lpad hpad id="GraphDispoRetraiteMadelin"></Column>
            <Column hpad id="GraphDispoPrevSanteMadelin"></Column>
          </Row>
          <Row style={{ marginLeft: "10%" }} tpad>
            <Column lpad hpad id="GraphDispoPerpTNS"></Column>
            <Column style={{ alignSef: "center", marginLeft: "90px" }}>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color1 }}></Column>
                <Column>Disponible fiscal</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#405060" }}></Column>
                <Column>Dépassement</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: context.state.Color2 }}></Column>
                <Column>Versements effectués</Column>
              </Row>
              <Row>
                <Column style={{ width: "20px", height: "10px", padding: "0", marginTop: "12px", marginRight: "4px", backgroundColor: "#d3d3d3" }}></Column>
                <Column> Disponible restant</Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailDisponibleTNS;
