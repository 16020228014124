import { useEffect } from "react";
import { useState } from "react";
import { FaBuilding, FaPowerOff, FaUser } from "react-icons/fa";
import Button from "../../controls/button/button";
import Column from "../column/column";
import Row from "../row/row";
import './user-panel.css';
import Dialogs from "components/ui/dialogs/dialogs";

const UserPanel = (props) => {

    // Initialize states
    const [userId, setUserId] = useState(props?.userId || 0);
    const [gender, setGender] = useState(props?.gender || 0);
    const [firstName, setFirstName] = useState(props?.firstName || '');
    const [lastName, setLastName] = useState(props?.lastName || '');
    const [subUserId, setSubUserId] = useState(props?.subUserId || 0);
    const [subGender, setSubGender] = useState(props?.subGender || 0);
    const [subFirstName, setSubFirstName] = useState(props?.subFirstName || '');
    const [subLastName, setSubLastName] = useState(props?.subLastName || '');
    const [company, setCompany] = useState(props?.company || '');
    const [association, setAssociation] = useState(props?.association || '');

    // On user change
    useEffect(() => {
        setUserId(props?.userId || 0);
        setGender(props?.gender || 0);
        setFirstName(props?.firstName || 0);
        setLastName(props?.lastName || 0);
    }, [props?.userId, props?.gender, props?.firstName, props?.lastName]);

    // On sub user change
    useEffect(() => {
        setSubUserId(props?.subUserId || 0);
        setSubGender(props?.subGender || 0);
        setSubFirstName(props?.subFirstName || 0);
        setSubLastName(props?.subLastName || 0);
    }, [props?.subUserId, props?.subGender, props?.subFirstName, props?.subLastName]);

    // On company change
    useEffect(() => {
        setCompany(props?.company);
    }, [props?.company])

    // On association change
    useEffect(() => {
        setAssociation(props?.association);
    }, [props?.association]);

    // Define constants
    const genderString = ['M.', 'Mme'];

    // Render component
    return (
        <Row className="user-panel" center>
            <Column centerX>
                {userId !== 0 && <FaUser />}
            </Column>
            <Column>
                {userId !== 0 && <span className="user-panel-logged">{genderString[gender]} {firstName} {lastName}</span>}
                {userId !== 0 && subUserId !== userId && <span className="user-panel-actual">{genderString[subGender]} {subFirstName} {subLastName}</span>}
            </Column>
            <Column centerX>
                {company !== '' && <FaBuilding />}
            </Column>
            <Column>
                {company !== '' && <span className="user-panel-company">{company}</span>}
                {association !== '' && <span className="user-panel-association">{association}</span>}
            </Column>
            <Column>
                <Button
                    className="user-panel-disconnect"
                    icon
                    onClick={() => {
                        Dialogs.confirm(
                          "Vous êtes sur le point de vous déconnecter. Voulez-vous continuer ?",
                          "Déconnexion",
                          () => {
                            props?.onDisconnect?.()
                          }
                        );
                      }}
                    disabled={props?.disconnectDisabled}
                >
                    <FaPowerOff title="Déconnexion" />
                </Button>
            </Column>
        </Row>
    )
}
export default UserPanel;