import axios from "axios";
import { RemoveUnecessaryData, ApiUrl } from "./ApiData";
const APIURL = ApiUrl;

export function GetSimulateurData(Data,CRUD) {
  let CleanData = RemoveUnecessaryData(Data);


  var formData = new FormData();

  formData.append("Data", JSON.stringify(CleanData));
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}

export function GetCalculCalculatriceAgircArrco(CalculatriceTableauAgircArrco,CRUD) {


  var formData = new FormData();

  formData.append("CalculatriceTableauAgircArrco", JSON.stringify(CalculatriceTableauAgircArrco));
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}

export function GetCalculDeclaPER(Values,CRUD) {


  var formData = new FormData();

  formData.append("ValuesDeclaPER", JSON.stringify(Values));
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}
export function GetCalculCorrectionPER(Values,Year,ClientConjoint,CRUD) {


  var formData = new FormData();

  formData.append("ValuesDeclaPER", JSON.stringify(Values));
  formData.append("ClientConjoint", ClientConjoint);
  formData.append("Year", Year);
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}

export function GetCalculStrategiePER(Data,Year,CRUD) {


  var formData = new FormData();

  formData.append("Data", JSON.stringify(Data));
  formData.append("Year", Year);
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}

export function GetCalculRetraiteNetImposable(Data,Value,CRUD) {


  var formData = new FormData();

  formData.append("Data", JSON.stringify(Data));
  formData.append("RetraiteNetPS", Value);
  formData.append("CRUD", CRUD);

  return axios({
    method: "post",
    url: `${APIURL}Simulateurs.php`,
    data: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    withCredentials:true,
  }).then(res => {
    return res.data;
  });
}

