import { useCallback, useContext, useEffect, useState } from "react";
import { FaCog, FaCogs, FaHome, FaIdBadge, FaTable } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DossierPage from "pages/audit complet/dossier/dossier";
import SituationProRemExpressPage from "./situation professionnelle";
import ResultatRemExpressPage from "./resultat";
import RemExpressSimulationPage from "./simulation";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";

const AuditRemuPage = () => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);

  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;
        let Resultat = {
          CoutEntreprise:
            ResultatTNS.state.RemExpressRemuneration?.AffichageResultat.CoutEntreprise === undefined ? 0 : ResultatTNS.state.RemExpressRemuneration?.AffichageResultat.CoutEntreprise,
          RemunerationNette:
            ResultatTNS.state.RemExpressRemuneration?.AffichageResultat.RemunerationNette === undefined ? 0 : ResultatTNS.state.RemExpressRemuneration?.AffichageResultat.RemunerationNette,
          RevenuDisponible: context.state.RemExpressRevDispo,
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const [currentPage, setCurrentPage] = useState(1);
  const handlePages = () => {
    let pages = [];

    if (context.state.ApiUser === true) {
      pages = [
        {
          isTitle: true,
          title: "Audit de rémunération",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Situation professionnelle",
          icon: <FaIdBadge />,
          content: <SituationProRemExpressPage />,
        },
        {
          label: "Revenus",
          icon: <FaTable id="Revenus" />,
          content: <ResultatRemExpressPage GoToSimulation={() => setCurrentPage(3)} />,
        },
        {
          label: "Simulation",
          hidden: true,
          icon: <FaCogs />,
          content: <RemExpressSimulationPage />,
        },
      ];
    } else {
      pages = [
        {
          isTitle: true,
          title: "Audit de rémunération",
          subTitle: "pour " + context.state.Civilite + " " + context.state.Nom,
        },
        {
          label: "Dossier",
          icon: <FaHome id="DossierAuditRemunerationPage" />,
          content: <DossierPage />,
        },
        {
          label: "Situation professionnelle",
          icon: <FaIdBadge />,
          content: <SituationProRemExpressPage />,
        },
        {
          label: "Revenus",
          icon: <FaTable id="Revenus" />,
          content: <ResultatRemExpressPage GoToSimulation={() => setCurrentPage(4)} />,
        },
        {
          label: "Simulation",
          hidden: true,
          icon: <FaCog />,
          content: <RemExpressSimulationPage />,
        },
      ];
    }

    return pages;
  };
  return (
    <Pager
      withMenu
      pages={handlePages()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default AuditRemuPage;
