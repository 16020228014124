import { useContext, useState } from "react";
import { FaDatabase, FaUserGraduate, FaUserShield, FaUsers } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import ListeClients from "components/app/Extranet/ListeClients";
import BddContrat from "components/app/Extranet/BddContrat";
import { AuthContext } from "components/auth/auth-provider";
import Formation from "components/app/Extranet/Formation";
import Column from "components/ui/layout/column/column";

const ClientsPage = () => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const authContext = useContext(AuthContext);

  let newGuides = authContext.utilisateur_reel?.documents?.guides.filter((documents) => documents.isNew === true).length;
  let newNewsLetters = authContext.utilisateur_reel?.documents?.newsletter.filter((documents) => documents.isNew === true).length;
  let newSupports = authContext.utilisateur_reel?.documents?.supports.filter((documents) => documents.isNew === true).length;
  let totalNew = newGuides + newNewsLetters + newSupports;
  const handlePages = () => {
    let pages = [];
    pages = [
      {
        isTitle: true,
        title: "Extranet",
        subTitle: "Les données du cabinet",
      },
      {
        label: "Clients",
        icon: <FaUsers />,
        content: <ListeClients />,
      },
      {
        label: "Base de données",
        icon: <FaDatabase />,
        content: <BddContrat />,
      },
      {
        label: (
          <>
            <span style={{ paddingLeft: 0 }}>Formation</span>
            <div className={totalNew > 0 ? "badge" : ""}></div>
          </>
        ),
        icon: <FaUserGraduate />,
        content: <Formation />,
      },
    ];
    return pages;
  };

  return (
    <Pager
      withMenu
      pages={handlePages()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
    />
  );
};

export default ClientsPage;
