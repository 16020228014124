import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CardBox from "components/ui/controls/card-box/card-box";
import Button from "components/ui/controls/button/button";
import { applyFormat } from "components/ui/ui-helpers";
import Modal from "components/ui/modal/modal";
import { useContext, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaEdit, FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";

const DetailImpot = (props) => {
  const { DetailImpot, DetailAssiette, DetailAssietteConjoint } = props;
  const context = useContext(AppContext);
  const [modalRevenusClientOpen, setmodalRevenusClientOpen] = useState(false);
  const [modalDividendeOpen, setmodalDividendeOpen] = useState(false);
  const [modalRevenusConjointOpen, setmodalRevenusConjointOpen] = useState(false);

  return (
    "Dirigeant" in DetailImpot && (
      <>
        <Column fill>
          <CardBox>
            <Column fill>
              <Row>
                <table className="detailImpot ">
                  <thead>
                    <tr>
                      <th colSpan="3" className="dark-title text-center">
                        Impôt sur le revenu
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Revenus d'activités imposables {context.state.Prenom}</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.Dirigeant.NetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setmodalRevenusClientOpen(true);
                          }}
                          icon
                          style={{ display: "inline-block" }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                    {(context.state.InclureConjoint || DetailImpot.Conjoint?.AutresRevenusActivite > 0) === true && (
                      <tr>
                        <td>Revenus d'activités imposables {context.state.PrenomConjoint}</td>
                        <td>{applyFormat(Math.max(0, DetailImpot.Conjoint.NetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        <td>
                          {DetailAssietteConjoint !== undefined && (
                            <Button
                              onClick={() => {
                                setmodalRevenusConjointOpen(true);
                              }}
                              icon
                              style={{ display: "inline-block" }}
                            >
                              <FaEdit />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Dividendes nets imposables (BPI)</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.DividendeNetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setmodalDividendeOpen(true);
                          }}
                          icon
                          style={{ display: "inline-block" }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>

                    <tr>
                      <td>Autres revenus nets imposables</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.AutresRevenus), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">+</td>
                    </tr>
                    <tr>
                      <td>Déductions fiscales spécifiques</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.DeductionFiscale), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td>Cotisations épargne retraite déductibles</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.DeductionPerp+DetailImpot.DeductionPerpConjoint), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr className="light-title">
                      <td>Base imposable</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.BaseImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>Impôt avant quotient familial</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.IRavantQF), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>Quotient familial</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.QF), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr className="light-title">
                      <td>Impôt après quotient familial</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.IRapresQF), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>Réductions et crédits d’impôt</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.ReductionImpot), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td>Décote</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.Decote), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr className="light-title">
                      <td>Impôt après réductions, crédits d’impôt et décote</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.IRapresQF-DetailImpot.ReductionImpot-DetailImpot.Decote), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>PFU au taux de 12,8%</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.PFU), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">+</td>
                    </tr>
                    <tr>
                      <td>Contribution sur les hauts revenus</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.ContribHR), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">+</td>
                    </tr>
                    <tr>
                      <td>Prélèvements sociaux</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.PrelevementsSociaux), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center">+</td>
                    </tr>
                   
                    <tr className="dark-title">
                      <td>Impôt total</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.IRTotal), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>TMI applicable</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.Tmi), "uint", { thousands: " ", decimal: ",", unit: "%" })}</td>
                      <td className="text-center"></td>
                    </tr>
                    <tr>
                      <td>Nombre de parts fiscales</td>
                      <td>{applyFormat(Math.max(0, DetailImpot.PartFiscale), "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</td>
                      <td>{}</td>
                      <td className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
        </Column>

        <Modal
          width="40%"
          height="50%"
          title="Rémunération nette imposable "
          icon={<FaGlobe />}
          visible={modalRevenusClientOpen}
          onClose={() => {
            setmodalRevenusClientOpen(false);
          }}
        >
          {"AssietteFicheDePaie" in DetailAssiette ? (
            <DetailRevenusImposablesSalarie DetailImpot={DetailImpot.Dirigeant} DetailAssiette={DetailAssiette} />
          ) : (
            <DetailRevenusImposablesTNS DetailImpot={DetailImpot.Dirigeant} DetailAssiette={DetailAssiette} />
          )}
        </Modal>
        <Modal
          width="40%"
          height="50%"
          title="Rémunération nette imposable"
          icon={<FaGlobe />}
          visible={modalRevenusConjointOpen}
          onClose={() => {
            setmodalRevenusConjointOpen(false);
          }}
        >
          {DetailAssietteConjoint !== undefined && (
            <>
              {"AssietteFicheDePaie" in DetailAssietteConjoint ? (
                <DetailRevenusImposablesSalarie DetailImpot={DetailImpot.Conjoint} DetailAssiette={DetailAssietteConjoint} />
              ) : (
                <DetailRevenusImposablesTNS DetailImpot={DetailImpot.Conjoint} DetailAssiette={DetailAssietteConjoint} />
              )}
            </>
          )}
        </Modal>
        <Modal
          width="40%"
          height="50%"
          title="Dividendes imposables"
          icon={<FaGlobe />}
          visible={modalDividendeOpen}
          onClose={() => {
            setmodalDividendeOpen(false);
          }}
        >
          <DetailDividendes DetailImpot={DetailImpot} />
        </Modal>
      </>
    )
  );
};

export default DetailImpot;

const DetailRevenusImposablesTNS = (props) => {
  const { DetailImpot, DetailAssiette } = props;
  return (
    <Pager
      pages={[
        {
          content: (
            <Column fill>
              <CardBox>
                <Column fill>
                  <Row>
                    <table className="detailImpot">
                      <tbody>
                        <tr>
                          <td>Rémunération nette sur l'activité étudiée</td>
                          <td>{applyFormat(parseInt(DetailAssiette.DetailRemunerationNette) || 0, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>CSG non déductible + CRDS</td>
                          <td>{applyFormat( parseInt(DetailAssiette.DetailCsgNd) || 0, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Cotisations facultatives non déductibles</td>
                          <td>
                            {applyFormat(
                              parseInt(
                                Number(DetailImpot.MadelinNonDeductible) +
                                  DetailAssiette.Depassement +
                                  Number(DetailImpot.AssuranceVieEntreprise) +
                                  Number(DetailImpot.PerpPerToutPublicEntreprise)
                              ) || 0,
                              "uint",
                              { thousands: " ", decimal: ",", unit: " €" }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Avantages en nature</td>
                          <td>{applyFormat( parseInt(DetailAssiette.DetailAvantageNature)||0 , "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        {/* <tr>
                          <td>Cotisations facultatives personnelles déductibles</td>
                          <td>- {applyFormat ( parseInt(DetailAssiette.DetailCotisationsDeductiblesPerso)||0 , "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr> */}
                        <tr className="light-title" style={{ borderTopColor: "black", borderTopWidth: "1px", borderTopStyle: "solid" }}>
                          <td>Rémunération imposable</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.BrutImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Autres revenus d'activité</td>
                          <td>
                            {applyFormat(Math.max(0, Number(DetailImpot.AutresRevenusActivite)), "uint", {
                              thousands: " ",
                              decimal: ",",
                              unit: " €",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>Abattement</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.Abattement), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr className="light-title">
                          <td>Net imposable</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.NetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                </Column>
              </CardBox>
            </Column>
          ),
        },
      ]}
      page={0}
      navButtons={{}}
    />
  );
};
const DetailRevenusImposablesSalarie = (props) => {
  const { DetailImpot, DetailAssiette } = props;
  return (
    <Pager
      pages={[
        {
          content: (
            <Column fill>
              <CardBox>
                <Column fill>
                  <Row>
                    <table className="detailImpot">
                      <tbody>
                        <tr>
                          <td>Salaire net sur l'activité étudiée</td>
                          <td>{applyFormat(Math.max(0, DetailAssiette.TabRecap?.RecapSalaireNet), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>CSG non déductible + CRDS</td>
                          <td>{applyFormat(Math.max(0, DetailAssiette.TabRecap?.CsgNonDeductible), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Cotisations facultatives non déductibles</td>
                          <td>
                            {applyFormat(
                              Math.max(0, Number(DetailImpot.MadelinNonDeductible) + Number(DetailImpot.AssuranceVieEntreprise) + Number(DetailImpot.PerpPerToutPublicEntreprise)),
                              "uint",
                              { thousands: " ", decimal: ",", unit: " €" }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Avantages en nature</td>
                          <td>{applyFormat(Math.max(0, DetailAssiette.AssietteFicheDePaie.InfoAvantageNature), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Réintégration 83</td>
                          <td>{applyFormat(Math.max(0, DetailAssiette.TabRecap?.Reintegration83), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr className="light-title" style={{ borderTopColor: "black", borderTopWidth: "1px", borderTopStyle: "solid" }}>
                          <td>Rémunération imposable</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.BrutImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Autres revenus d'activité</td>
                          <td>
                            {applyFormat(Math.max(0, Number(DetailImpot.AutresRevenusActivite)), "uint", {
                              thousands: " ",
                              decimal: ",",
                              unit: " €",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>Abattement</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.Abattement), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr className="light-title">
                          <td>Net imposable</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.NetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                </Column>
              </CardBox>
            </Column>
          ),
        },
      ]}
      page={0}
      navButtons={{}}
    />
  );
};
const DetailDividendes = (props) => {
  const { DetailImpot } = props;
  return (
    <Pager
      pages={[
        {
          content: (
            <Column fill>
              <CardBox>
                <Column fill>
                  <Row>
                    <table className="detailImpot">
                      <tbody>
                        <tr>
                          <td>Dividendes bruts issus des activités étudiées</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.DividendeBrutImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Autres dividendes bruts</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.AutreDividendeBrutImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Abattement 40%</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.AbattementDividende), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                        <tr>
                          <td>Dividendes nets</td>
                          <td>{applyFormat(Math.max(0, DetailImpot.DividendeNetImposable), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                </Column>
              </CardBox>
            </Column>
          ),
        },
      ]}
      page={0}
      navButtons={{}}
    />
  );
};
