import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import ContratFacProModal from "pages/audit complet/simulation/contratfacultatifpro pager";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe } from "react-icons/fa";

function OptimisationRemunerationTns(props) {
  const { Prenom, ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  let CliConj = ClientConjoint === "" ? "" : "Conjoint";
  let CliConjRai = (ClientConjoint === ""  ||ClientConjoint === "ConjointAssocie" )? "" : "Conjoint";
  const [modalContrat, setModalContrat] = useState(false);
  let CoutRemSituationOrigine = 0;
  let CotisationsSituationOrigine = 0;
  let RemNetteSituationOrigine = 0;
  let RemImposableSituationOrigine = 0;
  let StatutOrigine = "";
  let StatutNouvelle = "";
  if (ClientConjoint === "ConjointAssocie") {
    StatutOrigine = context.state.PeriodeActuelle[0]?.StatutConjoint;
    StatutNouvelle = context.state["OptiRemPeriodeActuelle"]!==undefined ?context.state.OptiRemPeriodeActuelle[0]?.StatutConjoint:"";
    if (context.state.PeriodeActuelle[0]?.StatutConjoint === "TNS Article 62" || context.state.PeriodeActuelle[0]?.StatutConjoint === "TNS individuel") {
      const Resultat = ResultatTNS.state.RemunerationTNSConjoint;
      CoutRemSituationOrigine = Resultat.CoutRemuneration;
      CotisationsSituationOrigine = Number(Resultat.TotalChargesSociales) + Number(Resultat.CotisationsFacultativesEntreprise);
      RemNetteSituationOrigine = Resultat.RemunerationNette;
      RemImposableSituationOrigine = Resultat.BrutImposable;
    } else {
      const Resultat = ResultatSalarie.state.RemunerationSalarieConjoint;
      CoutRemSituationOrigine = Resultat.CoutRemuneration;
      CotisationsSituationOrigine = Number(Resultat.TotalChargesPatronale) + Number(Resultat.TotalChargesSalariale);
      RemNetteSituationOrigine = Resultat.SalaireNet;
      RemImposableSituationOrigine = Resultat.BrutImposable;
    }
  } else {
    StatutOrigine = context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut;
    StatutNouvelle =context.state["OptiRemPeriodeActuelle" + ClientConjoint]!==undefined? context.state["OptiRemPeriodeActuelle" + ClientConjoint][0]?.Statut:"";
    if (context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS Article 62" || context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS individuel") {
      const Resultat = ResultatTNS.state["RemunerationTNS" + ClientConjoint];
      CoutRemSituationOrigine = Resultat.CoutRemuneration;
      CotisationsSituationOrigine = Number(Resultat.TotalChargesSociales) + Number(Resultat.CotisationsFacultativesEntreprise);
      RemNetteSituationOrigine = Resultat.RemunerationNette;
      RemImposableSituationOrigine = Resultat.BrutImposable;
    } else {
      const Resultat = ResultatSalarie.state["RemunerationSalarie" + ClientConjoint];
      CoutRemSituationOrigine = Resultat.CoutRemuneration;
      CotisationsSituationOrigine = Number(Resultat.TotalChargesPatronale) + Number(Resultat.TotalChargesSalariale);
      RemNetteSituationOrigine = Resultat.SalaireNet;
      RemImposableSituationOrigine = Resultat.BrutImposable;
    }
  }

  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="simulation">
        <tbody>
          <tr>
            <td className="libelle">Statut social</td>
            <td className="origine">{StatutOrigine}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">{StatutNouvelle}</td>
            <td className="extra"></td>
            <td className="ecart"></td>
          </tr>
          <tr>
            <td className="libelle">Budget rémunération pour {Prenom}</td>
            <td className="origine">{applyFormat(CoutRemSituationOrigine, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state["OptiRemCoutRemuneration" + CliConj]}
                onChange={(e) => {
                  context.handleCalculOptiRem("cout_remuneration", "OptiRemCoutRemuneration" + CliConj, ClientConjoint, ResultatTNS,false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={context.state["OptiRemPeriodeActuelle"+ CliConj][0]?.Statut==="TNS individuel" && context.state.OptiRemUnlockCoutEntreprise===false}
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemCoutRemuneration" + CliConj]) - Number(CoutRemSituationOrigine))}</td>
          </tr>
          <tr>
            <td className="libelle">Cotisations obligatoires et facultatives</td>
            <td className="origine">{applyFormat(CotisationsSituationOrigine, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle pr4">
              <Button
                className="editIconOptiRem"
                onClick={() => {
                  setModalContrat(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
              {applyFormat(context.state["OptiRemCotisations" + CliConj], "uint", { thousands: " ", decimal: ",", unit: " €" })}
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemCotisations" + CliConj]) - Number(CotisationsSituationOrigine))}</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération nette</td>
            <td className="origine">{applyFormat(RemNetteSituationOrigine, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                // onKeyDown={(e)=>{context.handleMontantOptiRem("OptiRemRemNette" + CliConj,e)}}
                value={context.state["OptiRemRemNette" + CliConj]}
                onChange={(e) => {
                  context.handleCalculOptiRem("remuneration_nette", "OptiRemRemNette" + CliConj, ClientConjoint, ResultatTNS,false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={context.state["OptiRemPeriodeActuelle"+ CliConj][0]?.Statut==="TNS individuel" && context.state.OptiRemUnlockCoutEntreprise===false}
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemRemNette" + CliConj]) - Number(RemNetteSituationOrigine))}</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération imposable</td>
            <td className="origine">{applyFormat(RemImposableSituationOrigine, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                // onKeyDown={(e)=>{context.handleMontantOptiRem("OptiRemRemImposable" + CliConj,e)}}
                value={context.state["OptiRemRemImposable" + CliConj]}
                onChange={(e) => {
                  context.handleCalculOptiRem("brut_imposable", "OptiRemRemImposable" + CliConj, ClientConjoint, ResultatTNS,false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={context.state["OptiRemPeriodeActuelle"+ CliConj][0]?.Statut==="TNS individuel" && context.state.OptiRemUnlockCoutEntreprise===false}
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemRemImposable" + CliConj]) - Number(RemImposableSituationOrigine))}</td>
          </tr>
        </tbody>
      </table>

      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaGlobe />}
        visible={modalContrat}
        onClose={() => {
          context.handleCalculOptiRem("res_avant_is","OptiRemResAvtIs" + CliConjRai, CliConjRai, ResultatTNS,false, context.state["OptiRemResAvtIs" + CliConjRai]);
          setModalContrat(false);
        }}
      >
        <ContratFacProModal
          Outil="OptiRem"
          ClientConjoint={CliConj}
          visible={modalContrat}
          onClose={() => {
            context.handleCalculOptiRem("res_avant_is","OptiRemResAvtIs" + CliConjRai, CliConjRai, ResultatTNS,false, context.state["OptiRemResAvtIs" + CliConjRai]);
            setModalContrat(false);
          }}
        />
      </Modal>
    </CardBox>
  );
}
export default OptimisationRemunerationTns;
