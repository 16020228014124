import { FaInfoCircle } from "react-icons/fa";
import Column from "../layout/column/column";
import Row from "../layout/row/row";
import './dialog.css';

/**
 * Generates a dialog frame component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const Dialog = (props) => {

    //  Render component
    return (
        <Row fill center>
            <Column fill center>
                <Row style={{ justifyContent: 'center'}}>
                    <Column center className="dialog-frame">
                        <Row className="dialog-title">
                            {props?.title}
                        </Row>
                        <Row style={{ width: '100%', backgroundColor: 'whitesmoke', padding: '16px 64px' }}>
                            <Column center className="dialog-icon">
                                {props?.icon ? props.icon : <FaInfoCircle />}
                            </Column>
                            <Column fill center className="dialog-content">
                                {props?.message}
                            </Column>
                        </Row>
                        <Row tpad className="dialog-buttons">
                            {props?.buttons}
                        </Row>
                    </Column>
                </Row>
            </Column>
        </Row>
    )
}
export default Dialog;