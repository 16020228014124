import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import TextField from "components/ui/controls/text-field/text-field";

function InvalCondGen() {
  const context = useContext(AppContext);


  const calculRente = [
    {
      value: "T/66",
      label: "N/66",
    },
    {
      value: "T/66bis",
      label: "N/66 (% de l'inval totale entre 10 et 32%)",
    },
    {
      value: "3T/2",
      label: "3N/2",
    },

    {
      value: "(T-33)/33",
      label: "(N-33)/33",
    },
    {
      value: "T/100",
      label: "N/100",
    },
    {
      value: "33",
      label: "33% de l'invalidité totale",
    },
    {
      value: "50",
      label: "50% de l'invalidité totale",
    },
    {
      value: "51",
      label: "51% de l'invalidité totale",
    },
    {
      value: "Pourcentage",
      label: "% de l'invalidité totale",
    },
  ];
  const bareme = [
    {
      value: "Invalidité professionnelle",
      label: "Invalidité professionnelle",
    },
    {
      value: "Invalidité fonctionnelle",
      label: "Invalidité fonctionnelle",
    },
    {
      value: "Croisé",
      label: "Croisé",
    },
    {
      value: "Croisé professionnel",
      label: "Croisé professionnel",
    },
    {
      value: "Le plus favorable entre pro et croisé",
      label: "Le plus favorable entre pro et croisé",
    },
  ];
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <DropDown label="Barème d'invalidité" value={context.state.Bareme} onChange={context.handleInputChange("Bareme")} options={bareme} xlarge />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <DropDown label="Formule de calcul de la rente" value={context.state.CalculRente} onChange={context.handleInputChange("CalculRente")} options={calculRente} xlarge />
              </Column>
            </Row>
            {context.state.CalculRente === "Pourcentage"  && (
              <>
                <Row tpad>
                  <Column>
                    <TextField
                      label="A partir de 15%"
                      value={context.state.TauxInvalPartielleComplementaire15}
                      onChange={context.handleInputChange("TauxInvalPartielleComplementaire15")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="A partir de 33%"
                      value={context.state.TauxInvalPartielleComplementaire33}
                      onChange={context.handleInputChange("TauxInvalPartielleComplementaire33")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="A partir de 66%"
                      value={context.state.TauxInvalPartielleComplementaire66}
                      onChange={context.handleInputChange("TauxInvalPartielleComplementaire66")}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default InvalCondGen;
