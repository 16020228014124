import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";

function IncapCondPart(props) {
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            { context.state.TypeSimulation === "Audit prévoyance" ||props.Statut === "TNS Article 62" || props.Statut === "TNS individuel" ? (
              <>
                <Row>
                  <table className="mono">
                    <thead>
                      <tr>
                        <th>
                          <TextField className="invisible" value={"Montant de l'IJ"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Début de versement"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Fin de versement"} normal />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.MaladieComplementaire}
                            onKeyUp={context.handleMontantToTaux("MaladieComplementaire")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                        <td>
                          <DropDown value={context.state.IJ1} onChange={context.handleGestionIJ1("IJ1")} options={context.state.Tabij1} normal />
                        </td>
                        <td>
                          <DropDown value={context.state.IJ2} onChange={context.handleGestionIJ2("IJ2")} options={context.state.Tabij2} normal />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.MaladieComplementaire2}
                            onChange={context.handleInputChange("MaladieComplementaire2")}
                            disabled={context.state.IJ2 !== "1095" ? false : true}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField value={context.state.IJ3} onChange={context.handleInputChange("IJ3")} disabled dataType="uint" format={{ thousands: " ", unit: " jours" }} normal />
                        </td>
                        <td>
                          <DropDown value={context.state.IJ4} onChange={context.handleGestionIJ("IJ4")} options={context.state.Tabij4} normal />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.MaladieComplementaire3}
                            onChange={context.handleInputChange("MaladieComplementaire3")}
                            disabled={context.state.IJ2 !== "1095" && context.state.IJ4 !== "1095" ? false : true}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </td>
                        <td>
                          <TextField value={context.state.IJ5} onChange={context.handleInputChange("IJ5")} disabled dataType="uint" format={{ thousands: " ", unit: " jours" }} normal />
                        </td>
                        <td>
                          <DropDown
                            value={context.state.IJ6}
                            onChange={context.handleInputChange("IJ6")}
                            disabled={context.state.IJ2 !== "1095" && context.state.IJ4 !== "1095" ? false : true}
                            options={context.state.Tabij6}
                            normal
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Franchise réduite accident"
                      value={context.state.FranchiseAccidentComplementaire}
                      onChange={context.handleInputChange("FranchiseAccidentComplementaire")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " jours" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      label="Franchise réduite hospitalisation"
                      value={context.state.FranchiseHospitalisationComplementaire}
                      onChange={context.handleInputChange("FranchiseHospitalisationComplementaire")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " jours" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Capital maladies redoutées"
                      value={context.state.CapitalMaladieRedoutee}
                      onChange={context.handleInputChange("CapitalMaladieRedoutee")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Rachat psy"
                      value={context.state.RachatPsy}
                      onChange={context.handleInputChange("RachatPsy")}
                      options={[
                        { value: "Non", label: "Non" },
                        { value: "Oui", label: "Oui" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                {context.state.RachatPsy === "Oui" && (
                  <Row tpad>
                    <table className="mono">
                      <thead>
                        <tr>
                          <th>
                            <TextField className="invisible" value={"Délai de carence"} normal />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Condition de prise en charge"} normal />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Durée de versement"} normal />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <DropDown
                              value={context.state.RachatPsyCarence}
                              onChange={context.handleInputChange("RachatPsyCarence")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "Aucun" },
                                { value: "90", label: "90 jours" },
                                { value: "180", label: "180 jours" },
                                { value: "365", label: "365 jours" },
                              ]}
                              normal
                            />
                          </td>
                          <td>
                            <DropDown
                              value={context.state.RachatPsyDureeHospi}
                              onChange={context.handleInputChange("RachatPsyDureeHospi")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "Sans condition d'hospitalisation" },
                                { value: "7", label: "Sans condition mais franchise minimale de 30 jours" },
                                { value: "10", label: "Sans condition mais franchise minimale de 60 jours" },
                                { value: "8", label: "Sans condition mais franchise minimale de 90 jours" },
                                { value: "1", label: "3 jours en service psy ou 8 jours ailleurs" },
                                { value: "9", label: "Hospitalisation de 3 jours" },
                                { value: "2", label: "Hospitalisation de 5 jours" },
                                { value: "3", label: "Hospitalisation de 8 jours" },
                                { value: "4", label: "Hospitalisation de 10 jours" },
                                { value: "5", label: "Hospitalisation de 14 jours" },
                                { value: "6", label: "Hospitalisation de 15 jours" },
                              ]}
                              normal
                            />
                          </td>
                          <td>
                            <DropDown
                              value={context.state.RachatPsyDureeIJ}
                              onChange={context.handleInputChange("RachatPsyDureeIJ")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "365 jours" },
                                { value: "1", label: "365 jours sauf intervention chirurgicale" },
                                { value: "2", label: "365 jours sauf hospitalisation de + de 7 jours" },
                                { value: "3", label: "1095 jours" },
                              ]}
                              normal
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                )}
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Rachat dos"
                      value={context.state.RachatDos}
                      onChange={context.handleInputChange("RachatDos")}
                      options={[
                        { value: "Non", label: "Non" },
                        { value: "Oui", label: "Oui" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>

                {context.state.RachatDos === "Oui" && (
                  <Row tpad>
                    <table className="mono">
                      <thead>
                        <tr>
                          <th>
                            <TextField className="invisible" value={"Délai de carence"} normal />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Condition de prise en charge"} normal />
                          </th>
                          <th>
                            <TextField className="invisible" value={"Durée de versement"} normal />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <DropDown
                              label="Rachat dos"
                              value={context.state.RachatDosCarence}
                              onChange={context.handleInputChange("RachatDosCarence")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "Aucun" },
                                { value: "90", label: " 90 jours" },
                                { value: "180", label: " 180 jours" },
                                { value: "365", label: " 365 jours" },
                              ]}
                              normal
                            />
                          </td>
                          <td>
                            <DropDown
                              label="Rachat dos"
                              value={context.state.RachatDosDureeHospi}
                              onChange={context.handleInputChange("RachatDosDureeHospi")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "Sans condition d'hospitalisation" },
                                { value: "6", label: "Sans condition mais franchise minimale de 30 jours" },
                                { value: "9", label: "Sans condition mais franchise minimale de 60 jours" },
                                { value: "7", label: "Sans condition mais franchise minimale de 90 jours" },
                                { value: "8", label: "Couvert si une intervention chirurgicale est nécessaire" },
                                { value: "1", label: "Hospitalisation de 24h" },
                                { value: "2", label: "Hospitalisation de 24h ou intervention chirurgicale" },
                                { value: "3", label: "Hospitalisation de 48h" },
                                { value: "4", label: "Hospitalisation de 48h ou intervention chirurgicale" },
                                { value: "5", label: "Hospitalisation de 7 jours" },
                              ]}
                              normal
                            />
                          </td>
                          <td>
                            <DropDown
                              label="Rachat dos"
                              value={context.state.RachatDosDureeIJ}
                              onChange={context.handleInputChange("RachatDosDureeIJ")}
                              options={[
                                { value: "NC", label: "Non communiqué" },
                                { value: "0", label: "365 jours" },
                                { value: "1", label: "365 jours sauf intervention chirurgicale" },
                                { value: "2", label: "365 jours sauf hospitalisation de + de 48h" },
                                { value: "3", label: "1095 jours" },
                              ]}
                              normal
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Row>
                  <Column>
                    <DropDown
                      label="Assiette de calcul"
                      value={context.state.BaseMaladieComplementaireSalarie}
                      onChange={context.handleInputChange("BaseMaladieComplementaireSalarie")}
                      options={[
                        { value: "SalaireBrut", label: "Salaire brut" },
                        { value: "SalaireNet", label: "Salaire net" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <tr>
                      <th>
                          <TextField className="invisible" value={"Montant de l'IJ"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Début de versement"} normal />
                        </th>
                        <th>
                          <TextField className="invisible" value={"Fin de versement"} normal />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextField
                            value={context.state.PourcentageMaladieComplementaireSalarie}
                            onChange={context.handleInputChange("PourcentageMaladieComplementaireSalarie")}
                            dataType="ufloat"
                            format={{ thousands: " ", decimmal: ",", precision: 2, unit: "%" }}
                          />
                        </td>
                        <td>
                          <DropDown value={context.state.IJ1} onChange={context.handleGestionIJ1("IJ1")} options={context.state.Tabij1} normal />
                        </td>
                        <td>
                          <DropDown value={context.state.IJ2} onChange={context.handleGestionIJ2("IJ2")} options={context.state.Tabij2} normal />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default IncapCondPart;
